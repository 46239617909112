import React from "react";
// import { Link } from "react-router-dom";

function ClickJack() {
  return (
    <>
      <h2 style={{ color: "red" }}>
        If you see this page, Web App link you have clicked on is under
        Clickjacking security attack.
      </h2>
      <h2>
        Please inform team with the reference of the application from where you
        clicked this link.
      </h2>
      <h2>
        Click{" "}
        <a href={window.self.location.href} title="KSPACE" target="blank">
          here
        </a>{" "}
        to access WebApp safely.
      </h2>
    </>
  );
}

export default ClickJack;
