import React, { useState } from "react";
import CommonForm from "../../../../../../components/commonform/commonForm";
import "./style.css";
export default function OwnLand(props) {
  const [formvalues, setFormValues] = useState(props.formvalues);
  const handleChange = (e, item) => {
    let data = {};
    let loc = props.data.filter((a) => a.id === parseInt(e.target.value));

    data = {
      ...formvalues[0],
      [e.target.name]: e.target.value,
      location: loc[0].locations,
    };
    let datas = [{ ...data }];
    setFormValues(datas);
    setTimeout(() => {
      props.handleFormData(datas);
    }, 100);
  };
  const handleText = (e, item) => {
    let data = {};

    data = {
      ...formvalues[0],
      [e.target.name]: e.target.value,
    };
    let datas = [{ ...data }];
    setFormValues(datas);
    setTimeout(() => {
      props.handleFormData(datas);
    }, 100);
  };
  const handleNumber = (e, item) => {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(e.target.value)) {
      let data = {};

      data = {
        ...formvalues[0],
        [e.target.name]: e.target.value,
      };
      let datas = [{ ...data }];
      setFormValues(datas);
      setTimeout(() => {
        props.handleFormData(datas);
      }, 100);
    }
  };

  return (
    <div className="ownlad">
      <div>Enter Premises Details</div>

      <div className="table-container">
        <React.Fragment>
          <table className="table-bordered">
            <tbody>
              <tr>
                <td className="bg-col">
                  <div className="row mx-2">
                    <div className=" col-md-12 col-sm-12">
                      <div className="row mt-2">
                        <div className="ownd col-12">
                          <div className="">
                            {" "}
                            <label className={"control-label mylabel mb-2"}>
                              Location
                            </label>
                          </div>
                          <select
                            id="location"
                            className="form-select selectbox w-100"
                            required
                            label="bbbb"
                            disabled={props.disabled}
                            name="location_id"
                            onChange={(e) => handleChange(e)}
                            value={formvalues[0]?.location_id ?? ""}
                          >
                            <option value=""></option>
                            {props.data &&
                              props.data.map((item) => (
                                <option value={item.id}>
                                  {item.locations}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="ownd col-md-6 col-sm-12 ">
                          <div>
                            <CommonForm
                              fieldType="text"
                              onChange={(e) => handleNumber(e)}
                              disabled={props.disabled}
                              label="Available Area  in sq.ft"
                              value={formvalues[0]?.area_in_sqft ?? ""}
                              name="area_in_sqft"
                              reuired
                            />
                          </div>
                        </div>

                        <div className="ownd col-md-6 col-sm-12">
                          {/* <div>
                            <CommonForm
                              fieldType="text"
                              disabled={props.disabled}
                              label="Nature of holding"
                              onChange={(e) => handleText(e)}
                              value={formvalues[0]?.nature_of_holding ?? ""}
                              name="nature_of_holding"
                              reuired
                            />
                          </div> */}
                          <div className="">
                            <div className="control-label mylabel">
                              Nature of holding
                            </div>
                            <select
                              required
                              name="nature_of_holding"
                              disabled={props.disabled}
                              onChange={(e) => handleText(e)}
                              value={formvalues[0]?.nature_of_holding ?? ""}
                              id="nature_of_holding"
                              className="form-select selectbox w-100"
                            >
                              <option value=""></option>

                              <option value="Full-Ownersip">
                                Full-Ownersip
                              </option>
                              <option value="Joint Ownership">
                                Joint Ownership
                              </option>
                              <option value="Leased Line">Leased Line</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                      
                      <div className="ownd col-4  ">
                       
                          <CommonForm
                            fieldType="text"
                            onChange={(e) => handleText(e)}
                            disabled={props.disabled}
                            label="Survey no"
                            value={formvalues[0]?.survey_no ?? ""}
                            name="survey_no"
                            reuired
                          />
                     
                      </div>
                      <div className="ownd col-4  ">
                      
                          <CommonForm
                            fieldType="text"
                            onChange={(e) => handleText(e)}
                            disabled={props.disabled}
                            label="Block No"
                            value={formvalues[0]?.block_no ?? ""}
                            name="block_no"
                            reuired
                          />
                        
                      </div>
                      <div className="ownd col-4 ">
                       
                          <CommonForm
                            fieldType="text"
                            onChange={(e) => handleText(e)}
                            disabled={props.disabled}
                            label="Village Name"
                            value={formvalues[0]?.village_name ?? ""}
                            name="village_name"
                            reuired
                          />
                      
                      </div>
                    
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      </div>
    </div>
  );
}
