import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  institutions_detail,
  institutions_status_update,
} from "../../admin.api";
import "./view.css";
import Snackbar from "../../../../components/snackbar/snackbar";
import Loader from "../../../../components/loader/loader";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import moment from "moment";
export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const selectedParam = useParams();
  const getDetails = (id) => {
    institutions_detail(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadProof = () => {
    let path = `${result}${data.file_registration_proof}`;
    window.open(path, "_blank");
  };
  const handleDownloadRequest = () => {
    let path = `${result}${data.file_submitted_record}`;
    window.open(path, "_blank");
  };
  const saMessage = (status) => {
    Swal.fire({
      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let data = {};
        if (adminType === 1) {
          if (status === 1) {
            data = {
              status_id: status,
              institution_id: selectedParam.id,
              admin_message: message.value,
            };
          } else {
            data = {
              status_id: status,
              institution_id: selectedParam.id,
              user_message: message.value,
            };
          }
        } else {
          data = {
            status_id: status,
            institution_id: selectedParam.id,
            super_admin_message: message.value,
          };
        }
        // let data = { status_id: status, institution_id: selectedParam.id , message: message.value};
        setIsActive(true);
        institutions_status_update(data)
          .then((res) => {
            setSnackMessage(res.message);
            setIsSnackError(false);
            setSnackOpen(true);
            setIsActive(false);
          })
          .catch((error) => {
            setSnackMessage(error.message);
            setIsSnackError(true);
            setSnackOpen(true);
            setIsActive(false);
          });
      }
    });
  };
  const handleAdminActions = (status) => {
    let data = { status_id: status, institution_id: selectedParam.id };
    setIsActive(true);
    institutions_status_update(data)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/academicinstitution-list");
    }
  };
  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Academic Institution Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Name of the Institution</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.institution_name}</div>
          </div>
          
          <div className="row">
            <div className="col-3">Registered Office Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Phone number</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.phone_no}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternate Phone</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.alternate_phone && (
                <>
                  {data.country_code &&
                    data.country_code !== "" &&
                    data.country_code !== "undefined" &&
                    data.country_code !== "null" && (
                      <>
                        {data?.country_code ?? ""}{" "}
                        {data?.country_code && data?.country_code !== ""
                          ? "-"
                          : ""}{" "}
                      </>
                    )}
                </>
              )}
              {data.alternate_phone ?? ""}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Date of Registration</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.registration_date
                ? moment(data.registration_date).format("DD-MMM-YYYY")
                : ""}
            </div>
          </div> */}
          <div className="row">
            <div className="col-3">
              Affiliation Details with Affiliation Number
            </div>
            <div className="col-1">:</div>
            <div className="col-7">{props?.basicData?.affiliation ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3"> Upload Proof of Registration</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {/* <a href="#">
              <b>
                Download
              </b>
            </a> */}
              <button
                type="button"
                onClick={(e) => handleDownloadProof(data)}
                className="btn btn-reject btn-dark btn-sm"
              >
                Download
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-3">Created At</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.created_at ? moment(data?.created_at).format("DD-MM-YYYY") : ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Total Number of Students</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.total_students}</div>
          </div>
          <div className="row">
            <div className="col-3">Headquarters Details</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.headquaters_detail}</div>
          </div>
          <div className="row">
            <div className="col-3">
              Name and Designation of Authorized Person with contact details
            </div>
            <div className="col-1">:</div>
            <div className="col-7">{data.contact_person_details}</div>
          </div>
          <div className="row">
            <div className="col-3">Courses Offered</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.courses && data.courses.length !== 0 && (
                <>
                  {data.courses.map((item, index) => (
                    <>
                      <>{index !== 0 && " | "}</>
                      <>
                        <b>{item.course_type ? `${item.course_type} :` : ""}</b>{" "}
                        {item.course_name}
                      </>
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Brief note on Institution Activities</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.notes_institutions}</div>
          </div>
          {/* <div className="row">
            <div className="col-3">Languages Known</div>
            <div className="col-1">:</div>
            <div className="col-7">
              Malayalam&nbsp;&nbsp;|&nbsp;&nbsp;English&nbsp;&nbsp;|&nbsp;&nbsp;Hindi
            </div>
          </div> */}

          <div className="row">
            <div className="col-3">
              Submitted request letter in institution letter Head of the
              Institution/Signing Authority.
            </div>
            <div className="col-1">:</div>
            <div className="col-7">
              {/* <a href="#">
              <b>
                Download
              </b>
            </a> */}
              <button
                type="button"
                onClick={(e) => handleDownloadRequest(data)}
                className="btn btn-reject btn-dark btn-sm"
              >
                Download
              </button>
            </div>
          </div>
          {data?.admin_message && data?.admin_message !== "" && (
            <div className="row">
              <div className="col-3">Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data.admin_message}</div>
            </div>
          )}
          {data?.super_admin_message && data?.super_admin_message !== "" && (
            <div className="row">
              <div className="col-3">Super Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data.super_admin_message}</div>
            </div>
          )}

          {adminType === 1 && (
            <>
              {(data.approval_status === 0 || data.approval_status === 3) && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(1)}
                  >
                    Submit for Acceptance
                  </button>
                  <button
                    type="button"
                    className="btn btn-approve btn-warning mr-2"
                    onClick={(e) => saMessage(5)}
                  >
                    Revert
                  </button>

                  {/* <button type="button" className="btn btn-reject btn-danger">
              Reject 
            </button>  */}
                </div>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {data.approval_status === 1 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(2)}
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="btn btn-approve btn-warning mx-2"
                    onClick={(e) => saMessage(3)}
                  >
                    Revert
                  </button>

                  <button
                    type="button"
                    className="btn btn-reject  btn-danger "
                    onClick={(e) => handleAdminActions(4)}
                  >
                    Reject
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
