import React, { useEffect, useState } from "react";
import "./view.css";
import CommonForm from "../../../components/commonform/commonForm";
import {
  expertManPowerCreateLookup,
  expertManPowerCreate,
  common_file_upload,
} from "../admin.api";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";

export default function Add(props) {
  const [formData, setFormData] = useState({
    gender: 1,
  });
  const [areaOfInterest, setAreaOfInterest] = useState([]);
  const [category, setCategory] = useState([]);
  const [snackMessage, setSnackMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [count, setCount] = useState(1);
  const getInitialDatas = () => {
    expertManPowerCreateLookup().then((res) => {
      setAreaOfInterest(res.data?.areaOfInterest);
      setCategory(res.data?.category);
      setCount(count + 1);
    });
  };
  useEffect(() => {
    getInitialDatas();
  }, []);
  useEffect(() => {
    if (!formData.educational_qualifications) {
      setFormData({
        ...formData,
        educational_qualifications: [
          {
            examination_passed: "",
            board_of_university: "",
            year_of_pass: "",
            mark_grade: "",
            file_upload_certificate: null,
          },
        ],
      });
    }
    if (!formData.work_experience) {
      setFormData({
        ...formData,
        work_experience: [
          {
            organization_name: "",
            experience: "",
            nature_of_work: "",
            designation: "",
            reason_for_leaving: "",
            experience_certificate: null,
          },
        ],
      });
    }
    if (!formData.expertise_area) {
      setFormData({
        ...formData,
        expertise_area: [
          {
            expertise_name: "",
          },
        ],
      });
    }
  }, [
    formData,
    formData.educational_qualifications,
    formData.work_experience,
    formData.expertise_area,
  ]);
  // const getBase64 = (file) => {
  //   return new Promise((resolve) => {
  //     let baseURL = "";

  //     let reader = new FileReader();

  //     reader.readAsDataURL(file);

  //     reader.onload = () => {
  //       baseURL = reader.result;

  //       resolve(baseURL);
  //     };
  //   });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = formData;
    console.log(data);
    let selectedareaofinterest = [];
    areaOfInterest.forEach((item) => {
      if (item.checked) {
        selectedareaofinterest.push(item);
      }
    });
    data = {
      ...data,
      area_of_interest: selectedareaofinterest,
    };
    let edudata = [];
    let expData = [];
    let expArea = [];
    let areaInte = [];
    data.educational_qualifications.forEach((item) => {
      let items = {};
      // let fileBase64 = getBase64(item.file_upload_certificate)
      //   .then((result) => {
      //     item.experience_certificate["base64"] = result;
      //     console.log("File Is", item.experience_certificate);
      //     continueFlag = true;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      items["examination_passed"] = item.examination_passed;
      items["board_of_university"] = item.board_of_university;
      items["file_upload_certificate"] = item.file_upload_certificate;
      items["year_of_pass"] = item.year_of_pass;
      items["mark_grade"] = item.mark_grade;

      edudata.push(items);
    });

    data.work_experience.forEach((item) => {
      let items = {};
      // let fileBase64 = getBase64(item.experience_certificate)
      //   .then((result) => {
      //     item.experience_certificate["base64"] = result;
      //     console.log("File Is", item.experience_certificate);
      //     continueFlag = true;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      items["organization_name"] = item.organization_name;
      items["experience"] = item.experience;
      items["nature_of_work"] = item.nature_of_work;
      items["designation"] = item.designation;
      items["reason_for_leaving"] = item.reason_for_leaving;
      items["experience_certificate"] = item.experience_certificate;
      // item.experience_certificate;
      expData.push(items);
    });

    data.expertise_area.forEach((item) => {
      expArea.push(item.expertise_name);
    });

    data.area_of_interest.forEach((item) => {
      areaInte.push(item.uuid);
    });
    console.log(expData);
    console.log(edudata);
    console.log(expArea);
    console.log(areaInte);
    // setIsActive(true);
    const form = new FormData();
    console.log(data);
    let finalData = {};
    finalData = {
      address: data.address ?? null,
      age: data.age ?? null,
      category: data.category ?? null,
      //"category_name: data.category_name ?? null,
      email: data.email ?? null,
      //"file_name: data.file_name ?? null,
      gender: data.gender ?? null,
      mobile: data.mobile ?? null,
      ...(data.alternate_phone &&
        data.country_code &&
        data.country_code !== ""
          ? { country_code: data.country_code }
          : {}),
        alternate_phone: data.alternate_phone ?? "",
      full_name: data.name ?? null,
      photo: data.photo ?? null,
      experience: expData ?? null,
      expertise_area: expArea ?? null,
      education: edudata ?? null,
      area_of_interest: areaInte ?? null,
    };

    if (
      finalData.photo === null ||
      finalData.photo === undefined ||
      finalData.photo === ""
    ) {
      setSnackMessage("Please choose a valid photo file");
      setIsSnackError(true);
      setSnackOpen(true);
    } else {
      let validExp = true;
      finalData.experience.forEach((item) => {
        if (
          item.experience_certificate === null ||
          item.experience_certificate === undefined ||
          item.experience_certificate === ""
        ) {
          setSnackMessage(
            `Please choose a valid experience certificate file for ${item.organization_name}`
          );
          setIsSnackError(true);
          setSnackOpen(true);
          validExp = false;
        }
      });

      if (validExp) {
        let validEduc = true;
        finalData.education.forEach((item) => {
          if (
            item.file_upload_certificate === null ||
            item.file_upload_certificate === undefined ||
            item.file_upload_certificate === ""
          ) {
            setSnackMessage(
              `Please choose a valid educational certificate file for ${item.examination_passed}`
            );
            setIsSnackError(true);
            setSnackOpen(true);
            validEduc = false;
          }
        });
        if (validEduc) {
          setIsActive(true);
          expertManPowerCreate(finalData)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);

              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.response.data.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
        }
      }
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/manpower-list");
    }
  };

  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        uploadFile(e);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          photo: null,
          file_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        photo: null,
        file_name: null,
      });
    }
  };
  const uploadFile = (e) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/profile-pic");
    // file.append("type", "expert-manpower/education-qualifications");

    common_file_upload(file)
      .then((res) => {
        setFormData({
          ...formData,
          photo: res.data,
          file_name: e.target.files[0].name,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const eduUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/education-qualifications");
    common_file_upload(file)
      .then((res) => {
        let edu = formData.educational_qualifications;
        edu[i] = {
          ...edu[i],
          file_name: e.target.files[0].name,
          file_upload_certificate: res.data,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const expUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/experience");
    common_file_upload(file)
      .then((res) => {
        let exp = formData.work_experience;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          experience_certificate: res.data,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let edu = formData.educational_qualifications;

    if (type?.toLowerCase() === "png" || type?.toLowerCase() === "jpg" || type?.toLowerCase() === "jpeg" || type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        eduUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        edu[i] = {
          ...edu[i],
          file_name: null,
          file_upload_certificate: null,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      edu[i] = {
        ...edu[i],
        file_name: null,
        file_upload_certificate: null,
      };
      setFormData({
        ...formData,
        educational_qualifications: edu,
      });
    }
  };

  const handleExperienceFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let exp = formData.work_experience;

    if (type?.toLowerCase() === "png" || type?.toLowerCase() === "jpg" || type?.toLowerCase() === "jpeg" || type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        expUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        exp[i] = {
          ...exp[i],
          file_name: null,
          experience_certificate: null,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      exp[i] = {
        ...exp[i],
        file_name: null,
        experience_certificate: null,
      };
      setFormData({
        ...formData,
        work_experience: exp,
      });
    }
  };

  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.educational_qualifications.push({
      examination_passed: "",
      board_of_university: "",
      year_of_pass: "",
      mark_grade: "",
      file_upload_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.educational_qualifications.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleExperienceChange = (e, i) => {
    console.log(e, i);
    let data = { ...formData };
    data.work_experience[i] = {
      ...data.work_experience[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleExperticeChange = (e, i) => {
    let data = { ...formData };
    data.expertise_area[i] = {
      ...data.expertise_area[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const addExperience = (i) => {
    let data = { ...formData };

    data.work_experience.push({
      organization_name: "",
      experience: "",
      nature_of_work: "",
      designation: "",
      reason_for_leaving: "",
      experience_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExperiance = (i) => {
    let data = { ...formData };
    data.work_experience.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const addExpertice = (i) => {
    let data = { ...formData };

    data.expertise_area.push({
      expertise_name: "",
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExpertice = (i) => {
    let data = { ...formData };
    data.expertise_area.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleCheckChange = (e, item) => {
    let data = areaOfInterest;

    data[item]["checked"] = e.target.checked;

    //setFormData({ ...formData, any_other_flag: false });

    setAreaOfInterest(data);
  };

  const handleValid = () => {
    let flags = false;
    if (areaOfInterest) {
      areaOfInterest.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const textFieldChange = (e) => {
    let data = formData;

    data = {
      ...data,

      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const numberChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    let data = formData;
    data = {
      ...data,

      [name]: newValue,
    };
    setFormData(data);
  };
  const handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    if (value.length <= 10) {
      let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      let data = formData;
      data = {
        ...data,

        [name]: newValue,
      };
      setFormData(data);
    }
  };
  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const handleExperiancelNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.work_experience[i] = {
      ...data.work_experience[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };

  const genderFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      gender: Number(e.target.value),
    };
    setFormData(data);
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const selectFieldChange = (e) => {
    let value = e.target.value;
    const selectedCat = category?.find(({ uuid }) => uuid === value);

    let data = formData;
    data = {
      ...data,

      category: value,
      category_name: selectedCat.cat_name,
    };
    setFormData(data);
  };
  console.log(formData);
  return (
    <div className="add-per">
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <form onSubmit={handleSubmit}>
          <h6>
            <b>Add Expert Manpower</b>
          </h6>
          <hr />
          <div className="row">
            <div className="col-8 mb-2">
              <label for="exampleInputEmail1">Manpower Type</label>
              <select
                className="form-select selectbox mandatory-select "
                required
                name="category"
                value={formData?.category ?? ""}
                aria-label="Default select example"
                onChange={(e) => textFieldChange(e)}
              >
                <option value="">Select Expert Manpower Type</option>

                <option
                  value="Supervisor"
                  name="Supervisor"
                  // select={formData?.category_name === item.cat_name}
                >
                  Supervisor
                </option>
                <option
                  value="Worker"
                  name="Worker"
                  // select={formData?.category_name === item.cat_name}
                >
                  Worker
                </option>
                <option
                  value="Supervisor and Worker"
                  name="Supervisor and Worker"
                  // select={formData?.category_name === item.cat_name}
                >
                  Supervisor and Worker
                </option>
              </select>
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Full Name"
                required
                name="name"
                value={formData?.name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-5 mb-2">
              <CommonForm
                fieldType="text"
                label="Age"
                required
                name="age"
                maxLength="3"
                value={formData?.age ?? ""}
                onChange={(e) => {
                  numberChange(e);
                }}
                {...props}
              />
            </div>

            <div className="col-5 mb-2 mt-16">
              <label for="exampleInputEmail1">Gender</label>
              <div className="d-flex ">
                <CommonForm
                  fieldType="radio"
                  label="Male"
                  required
                  name="gender"
                  value={"1"}
                  checked={formData?.gender === 1}
                  onChange={(e) => genderFieldChange(e)}
                  {...props}
                />
                <CommonForm
                  fieldType="radio"
                  label="Female"
                  required
                  name="gender"
                  checked={formData?.gender === 2}
                  value={"2"}
                  onChange={(e) => genderFieldChange(e)}
                  {...props}
                />
                <CommonForm
                  fieldType="radio"
                  label="Other"
                  required
                  name="gender"
                  checked={formData?.gender === 3}
                  value={"3"}
                  onChange={(e) => genderFieldChange(e)}
                  {...props}
                />
              </div>
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Address"
                multiline
                required
                className="no-margin"
                name="address"
                value={formData?.address ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Phone Number"
                value={formData?.mobile}
                required
                onChange={(e) => {
                  handlePhoneNumberChange(e);
                }}
                name="mobile"
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
              <label className="control-label mylabel mb-2">
                Alternate Phone
              </label>

              <div className="d-flex align-items-end w-100">
                <div className="">
                  <CommonForm
                    fieldType="text"
                    name="country_code"
                    required = {props.international}
                    maxLength="4"
                    value={formData.country_code ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="p-1"></div>
                <div className="col-10">
                  <CommonForm
                    fieldType="text"
                    name="alternate_phone"
                    required = {props.international}
                    maxLength="10"
                    value={formData.alternate_phone ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="email"
                label="Email"
                // disabled
                value={formData?.email ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                required
                name="email"
                {...props}
              />
            </div>

            {/* -----Educational Qualification------------ */}
            <div className="col-8">
              <label for="exampleInputEmail1">Educational Qualification</label>

              {formData.educational_qualifications?.map((e, i) => (
                <div className="section-datas-cont">
                  <div className="section-Cont">
                    <div className="row ">
                      <div className="col-xs-12 col-sm-4">
                        <CommonForm
                          fieldType="text"
                          label="Examination passed"
                          required
                          name="examination_passed"
                          value={e?.examination_passed}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-12 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Board/University"
                          required
                          name="board_of_university"
                          value={e?.board_of_university}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-xs-6 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Year of Passing"
                          required
                          name="year_of_pass"
                          minlength="4"
                          maxLength="4"
                          value={e?.year_of_pass}
                          onChange={(e) => {
                            handleEducationalNumberChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-6 col-sm-2">
                        <CommonForm
                          fieldType="text"
                          label="Mark/grade"
                          required
                          minlength="1"
                          maxLength="4"
                          name="mark_grade"
                          value={e?.mark_grade}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-12">
                        <CommonForm
                          fieldType="file"
                          required={!e.file_name ? true : false}
                          label="Upload self attested photocopy of certificate"
                          name="file_upload_certificate"
                          {...props}
                          id="file_upload_certificate"
                          className="no-margin"
                          accept=".jpg,.jpeg,.png,.pdf"
                          type="file"
                          variant="outlined"
                          fileName={e?.file_name}
                          //filename={e?.file_name}
                          // onChange={handleFileChange}
                          onChange={(e) => {
                            handleEducationalFileChange(e, i);
                          }}
                        />
                        <div className="file-up-helper">
                          *Upload only .jpg , .jpeg , .pdf, .png format and Size
                          should be less than 1MB
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="more-btn-cnt">
                    {formData.educational_qualifications.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addeducationalQualifications(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.educational_qualifications.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => removeducationalQualifications(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* ----------edu close-------- */}

            {/* ------------experiance------------ */}
            <div className="col-8">
              <label for="exampleInputEmail1">Details of Experience </label>

              {formData.work_experience?.map((e, i) => (
                <div className="section-datas-cont">
                  <div className="section-Cont">
                    <div className="row">
                      <div className="col-xs-12 col-sm-4">
                        <CommonForm
                          fieldType="text"
                          label="Organization with Address"
                          required
                          name="organization_name"
                          value={e?.organization_name}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-12 col-sm-2">
                        <CommonForm
                          fieldType="text"
                          label="Experience (Yrs.)"
                          required
                          // minlength="4"
                          maxLength="2"
                          name="experience"
                          value={e?.experience}
                          onChange={(e) => {
                            handleExperiancelNumberChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Nature of  Work"
                          required
                          name="nature_of_work"
                          value={e?.nature_of_work}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-12 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Designation"
                          required
                          name="designation"
                          value={e?.designation}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12">
                        <CommonForm
                          fieldType="text"
                          label="Reason for Leaving"
                          multiline
                          required
                          value={e?.reason_for_leaving}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          className="no-margin"
                          name="reason_for_leaving"
                          {...props}
                        />
                      </div>
                      <div className="col-12">
                        <CommonForm
                          fieldType="file"
                          required={!e.file_name ? true : false}
                          label="Upload self attested photocopy of certificate"
                          name="experience_certificate"
                          onChange={(e) => {
                            handleExperienceFileChange(e, i);
                          }}
                          {...props}
                          fileName={e?.file_name}
                          //filename={e?.file_name}
                          id="experience_certificate"
                          accept=".jpg,.jpeg,.png,.pdf"
                          type="file"
                          variant="outlined"
                          // onChange={handleFileChange}
                        />
                        <div className="file-up-helper">
                          *Upload only .jpg , .png , .pdf , .jpeg , format and
                          Size should be less than 1MB
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="more-btn-cnt">
                    {formData.work_experience.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addExperience(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.work_experience.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => removeExperiance(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* ------------experiance--close---------- */}
            <div className="col-8 mb-2">
              {formData.expertise_area?.map((e, i) => (
                <div className="section-datas-cont">
                  {/* <div className="col-xs-12 col-sm-12"> */}
                  <CommonForm
                    fieldType="text"
                    label="Area of Expertise"
                    required
                    value={e?.expertise_name}
                    onChange={(e) => {
                      handleExperticeChange(e, i);
                    }}
                    className="no-margin"
                    name="expertise_name"
                    {...props}
                  />
                  {/* </div> */}

                  <div className="more-btn-cnt">
                    {formData.expertise_area.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addExpertice(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.expertise_area.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => removeExpertice(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="col-8 mb-2">
              <label for="exampleInputEmail1">Any other area of interest</label>
              <div className="row">
                {areaOfInterest?.map((item, index) => (
                  <div className="col-3 mt-2">
                    <div className="form-check">
                      <CommonForm
                        fieldType="checkbox"
                        value={item.uuid}
                        checked={item.checked}
                        // required={!handleValid()}
                        onChange={(e) => handleCheckChange(e, index)}
                        label={item.area_of_interest_name}
                        {...props}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div> */}

            <div className="col-8">
              <CommonForm
                fieldType="file"
                required={!formData?.file_name ? true : false}
                label="Upload  your Photo"
                name="photo"
                {...props}
                id="photo"
                fileName={formData?.file_name}
                //filename={formData.file_name}
                accept=".jpg,.jpeg,.png"
                type="file"
                variant="outlined"
                onChange={handleFileChange}
              />
              <div className="file-up-helper">
                *Upload only .jpg , .jpeg , .png format and Size should be less
                than 1MB
              </div>
            </div>
            <div className="col-8">
              <center>
                <button type="submit" className="btn btn-dark mt-3">
                  S U B M I T
                </button>
              </center>
            </div>
            {/* ---------------- */}
          </div>
        </form>
      </div>
    </div>
  );
}
