import React, { Component } from "react";
import CustomIcons from "../../../components/icons/kIcons.component";
import { user_tenders_list } from "../user.api";
import "./tenders.css";
import Moment from "react-moment";
import moment from "moment";

class PrivateTenders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      url: "",
    };
  }
  
  componentDidMount = () => {
    this.fetchTender();
  };
  fetchTender = () => {
    user_tenders_list().then((res) => {
      this.setState({
        data: res.data,
        url: res.current_url,
      });
    });
  };
  handleLink = (linkurl) => {
    let urlLower = linkurl.toLowerCase();
    let urlStart = urlLower.substring(0, 4);
    if (urlStart === "http") {
      window.open(`${urlLower}`, "_blank");
    } else {
      window.open(`//${urlLower}`, "_blank");
    }
  };
  render() {
    const { data, url } = this.state;
    //
    //
    const currentDate = moment().format("YYYY-MM-DD")
    return (
      <div className="tender bg-tender">
        <div>
          <div className="dash-banner ">
            <div className="tix">Tenders</div>
          </div>
        </div>
        <div className="container p-50">
          <div className="bg-white">
            {/* <div className="row tender-head ">
              <h4>Tenders</h4>
              <div>
                <div className="container-ul">
                  <hr className="top" />
                  <hr className="bottom" />
                </div>
              </div>
            </div> */}
            <div className="row tender-box">
              {data.length !== 0 ? (
                <>
                  {data.map((item) => (
                    <React.Fragment>
                      {item.tender_publish_status === 1 &&
                        item.type === "private" && moment(item?.due_date, "YYYY-MM-DD").isAfter(currentDate)  && (
                          <div className="bg-tender p-30">
                            <div className="tender-title mb-2">
                              <p className="head ">
                                <u>{item.tender_title}</u>
                              </p>
                            </div>
                            <div className="tender-datas">
                              <div className="col-lg-2 col-md-12 col-sm-12 label">
                                <p>Tender By</p>
                              </div>
                              <div className="col-lg-10 col-md-12 col-sm-12 contentx">
                                <p>{item.organized_by}</p>
                              </div>
                            </div>
                            {/* <div className="tender-datas">
                          <div className="col-lg-2 col-sm-12 label">
                            <p>Address</p>
                          </div>
                          <div className="col-lg-10 col-sm-12">
                            <p>
                            {item.organized_by}
                            </p>
                          </div>
                        </div> */}
                            {item.value_of_tender &&
                              item.value_of_tender != 0 && (
                                <div className="tender-datas">
                                  <div className="col-lg-2 col-md-12 col-sm-12 label">
                                    <p>Value of Tender</p>
                                  </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12 contentx">
                                    <p>{item.value_of_tender}</p>
                                  </div>
                                </div>
                              )}
                            <div className="tender-datas">
                              <div className="col-lg-2 col-md-12 col-sm-12 label">
                                <p>Description</p>
                              </div>
                              <div className="col-lg-10 col-md-12 col-sm-12 contentx note">
                                <p>{item.description}</p>
                              </div>
                            </div>
                            <div className="tender-datas">
                              <div className="col-lg-2 col-md-12 col-sm-12 label">
                                <p>Due Date</p>
                              </div>
                              <div className="col-lg-10 col-md-12 col-sm-12 contentx note">
                                <p>{item.due_date ? item?.due_date : "--"}</p>
                              </div>
                            </div>
                            <div className="tender-datas">
                              <div className="col-lg-2 col-md-12 col-sm-12 label">
                                <p>Link</p>
                              </div>
                              <div className="d-flex col-lg-10 col-md-12 col-sm-12 contentx overf">
                                <p className="blink_me">
                                  <CustomIcons iconName={"hand"} />
                                </p>
                                <span>
                                  &nbsp;
                                  <a
                                    // href="#"
                                    onClick={(e) =>
                                      window.open(item.link, "_blank")
                                    }
                                    className="break_link"
                                  >
                                    {" "}
                                    {item.link}
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  <div className="no-data">
                    <img src="resources/img/no1.png" alt="" />
                    <h4>No Records Found..!!</h4>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PrivateTenders;
