import React from "react";
import ReactHtmlParser from "react-html-parser";
import "../style.css";
export default function View(props) {
  const handleEdit = () => {};
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  return (
    <div className="demox">
      <div className="conti view-containe">
        <h6>
          <b>Basic Details</b>
        </h6>

        <hr />

        <div className="data">
          <div className="row">
            <div className="col-3">Company Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.company_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.person_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Designation</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.person_designation}</div>
          </div>
          <div className="row">
            <div className="col-3">Registration Number / CIN Number</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.cin_number}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.address ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.email ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Website Link</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.site_link ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternate Phone</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.alternate_phone && (
                <>
                  {props.basicData.country_code &&
                    props.basicData.country_code !== "" &&
                    props.basicData.country_code !== "undefined" &&
                    props.basicData.country_code !== "null" && (
                      <>
                        {props.basicData.country_code ?? ""}{" "}
                        {props.basicData.country_code &&
                        props.basicData.country_code !== ""
                          ? "-"
                          : ""}{" "}
                      </>
                    )}
                </>
              )}
              {props.basicData.alternate_phone ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Alternative Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.alternate_email ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Abstract Company Profile</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.abstract_project_proposal ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Product Profile</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData?.product_profiles?.map((item) => (
                <>
                  <div className="col-12 mb-1">
                    <b>{item.product_name ?? ""}</b>
                  </div>
                  <div className="col-12 mb-3 ">
                    <div>{ReactHtmlParser(item.product_desc)}</div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Domain Type</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData?.domain_types && (
                <>
                  {props.basicData?.domain_types?.map((dom, i) => (
                    <div>{dom.domain_name} </div>
                  ))}
                </>
              )}
              {/* {(props.basicData?.any_other_flag === "1" ||
                props.basicData?.any_other_flag) && (
                <>{props.basicData?.any_other_domain}</>
              )} */}
              {(props.basicData?.any_other_flag === "1" ||
                props.basicData?.any_other_flag) && (
                <div>{props.basicData?.any_other_domain}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Capital investment so far in lakhs</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.capital_investment ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Previous FY turnover Rs. in Crores</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.previous_fy_turnover ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3"> FE Component</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.fe_component ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3"> Direct Employment as on Date </div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.ex_man_power ?? ""}</div>
          </div>
          {/* <div className="row">
            <div className="col-3"> Direct Employment as on Date </div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.dir_employ_male ?? ""}</div>
          </div> */}
          <div className="row">
            <div className="col-12">
              <b>Direct Employment Split up</b>
            </div>
          </div>
          <div className="row">
            <div className="col-3"> Male </div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.dir_employ_male ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3"> Female </div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.dir_employ_female ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3"> Genderqueer </div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.dir_employ_others ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3"> SC/ST </div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.dir_employ_sc_st ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3"> Achievements of Companies</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.achievements ?? ""}</div>
          </div>
          
          <div className="row">
            <div className="col-3"> Certifications</div>
            <div className="col-1">:</div>
            {props.basicData?.certification && (
              <div className="col-7">
                {props.basicData?.certification != "Other"
                  ? props.basicData?.certification
                  : props.basicData?.otherCertification}
              </div>
            )}
          </div>
          {/* <div className="row">
            <div className="col-3">Document Uploaded</div>
            <div className="col-1">:</div>
            <div className="col-7 ">{props.basicData?.file_name}</div>
          </div> */}
          {props.update === true &&
          typeof props?.basicData?.upload_proposal === "string" ? (
            <div className="row">
              <div className="col-3 field-titles">Brochre / Leaflets</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                <a
                  onClick={() => {
                    window.open(
                      `${result}${props?.basicData?.upload_proposal}`,
                      "_blank"
                    );
                  }}
                  className="detailFile"
                >
                  View
                </a>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-3 field-titles">Document Uploaded</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.file_name}</div>
            </div>
          )}
        </div>

        <div className="footer-buttons">
          <div
            className="secondary-btn"
            onClick={(e) => {
              props.action(2);
            }}
          >
            GO BACK
          </div>
          {props.update ? (
            <div
              className="primary-btn"
              onClick={(e) => {
                props.action(3);
              }}
            >
              UPDATE
            </div>
          ) : (
            <div
              className="primary-btn"
              onClick={(e) => {
                props.action(3);
              }}
            >
              REGISTER
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
