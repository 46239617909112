import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./view.css";
import {
  get_project_proposal_details,
  update_proposal_status,
} from "../../admin.api";
import jwtDecode from "jwt-decode";
import TextField from "../../../../components/inputs/textfield";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import moment from "moment";
export default function View(props) {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [detail, setDetail] = useState();

  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const getDetails = () => {
    setIsActive(true);
    get_project_proposal_details(selectedParam.id)
      // .then((res) => res.data)
      .then((res) => {
        setData(res.data);
        setDetail(res.data.id);
        setIsActive(false);
        
        
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadProposal = () => {
    let path = `${result}${data.file_upload_proposal}`;
    window.open(path, "_blank");
  };
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/project-proposal-list");
    }
  };
  const handleAdminActions = (status) => {
    let data = {
      status_id: status,
      proposal_id: detail,
    };

    setIsActive(true);
    update_proposal_status(data)
      .then((res) => {
        setSnackMessage("Action completed successfully");
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  console.log(detail);
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {/* <Loader open={isActive} /> */}
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Project Proposal Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.title ? data.title + "." : "Mr."}{" "}
                {data.first_name ?? ""}{" "}
                {data.last_name ?? ""}</div>
          </div>
         
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Phone Number</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.mobile}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Proposal Title</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.project_proposal_title}</div>
          </div>
          <div className="row">
            <div className="col-3">Details of Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.short_details_proposal}</div>
          </div>
          {/* <div className="row">
            <div className="col-3">status</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.status}</div>
          </div> */}

          <div className="row">
            <div className="col-3"> Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {/* <a href="#">
                <b>Download</b>
              </a> */}
              <button
                type="button"
                className="btn btn-reject btn-dark btn-sm"
                onClick={(e) => handleDownloadProposal(e)}
              >
                Download Proposal
              </button>
            </div>
          </div>
          {data?.created_at &&
          <div className="row">
            <div className="col-3">Created At</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.created_at ? moment(data?.created_at).format("DD-MM-YYYY") : ""}</div>
          </div>}
         

          {adminType === 1 && (
            <>
              {data.status == 0 && (
                <>
                  {/* <div className="col-6 mt-3">
                    <TextField
                      type="text"
                      multiline
                      id="exampleInputEmail1"
                      onChange={handleChange}
                      placeholder="Message to Superadmin.."
                    />
                  </div> */}
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      type="button"
                      className="btn btn-approve btn-success mr-2"
                      onClick={(e) => handleAdminActions(1)}
                    >
                      Submit for Acceptance
                    </button>
                  </div>
                </>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {/* {data?.admin_message && (
                <div className="col-6 res mt-3">
                  <label htmlFor="">
                    <b>Message from Admin</b>
                  </label>
                  <p className="m-0">{data?.admin_message ?? ""}</p>
                </div>
              )} */}

              {data.status == 1 && (
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(4)}
                  >
                    Approve
                  </button>
                  <br />
                  <button
                    type="button"
                    className="btn btn-reject btn-danger ml-3"
                    onClick={(e) => handleAdminActions(5)}
                  >
                    Reject
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
