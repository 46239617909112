import React, { useEffect, useState } from "react";
import CustomIcons from "../../../icons/kIcons.component";
import BookingDialog from "./booking/dialog";
import BreadCrumbsComp from "../../../navigator/breadcrumbs";

export default function EventDetail(props) {
  let lang = props.state.language;
  const [data, setData] = useState(props.data);
  const [url, setUrl] = useState(props.url);
  const [booking, setBooking] = useState(false);

  useEffect(() => {
    setData(props.data);

    setUrl(props.url);
  }, [props.data, props.url]);
  const bookingClose = () => {
    setBooking(false);
  };
  const bookingOpen = () => {
    setBooking(true);
  };
  //
  return (
    <div className="container bgc-white">
      {booking && (
        <BookingDialog
          open={booking}
          event={data[lang]}
          handleClose={bookingClose}
        />
      )}
      <div className="row  head-detail eventdetail-bg">
        <div className="bar">
          <h3>{`${data[lang]?.Title ?? ""}`}</h3>
          {/* <h3>Practice</h3> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9 col-md-12 col-sm-12">
          <div className="detail-desc">
            <p>{`${data[lang]?.Description}`}</p>
            {data[lang]?.event_organized_type === "kspace" && data[lang]?.paid != 0 && (
              <>
                <p className="font-weight-bold">
                  Event Fee : <CustomIcons iconName={"rupee"} />{" "}
                  <span className="fee">{`${data[lang]?.paid ?? ""}`} /-</span>
                </p>
                <p className="font-weight-bold">
                  Bank Details :{" "}
                  <span className="fee">{`${
                    data[lang]?.bank_details ?? ""
                  }`}</span>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className="more-details">
            {data[lang]?.event_organized_type === "kspace" && (
              <div
                className="event-booknow-btn"
                disabled={data[lang]?.available_seats <= 0}
                onClick={(e) => {
                  bookingOpen(e);
                }}
              >
                Book Now
              </div>
            )}
            <div className="my-3 date">
              <p>Date</p>
              <p>
                <img src="/resources/img/events/date.png" alt="" />{" "}
                <span>{`${data[lang]?.eventDate ?? ""}`}</span>
              </p>
              <p>
                <img src="/resources/img/events/time.png" alt="" />{" "}
                <span> {`${data[lang]?.EventTimimg ?? ""}`}</span>
              </p>
            </div>
            <div className="my-3 locate">
              <p>Location</p>
              <p>
                <img src="/resources/img/events/location.png" alt="" />
                <span> {`${data[lang]?.location ?? ""}`}</span>
              </p>
            </div>
            <div className="my-3 org">
              <p>Organizer</p>
              <p>
                <img src="/resources/img/events/head.png" alt="" />
                <span> {`${data[lang]?.OrganizedBy ?? ""}`}</span>
              </p>
            </div>
            <div className="my-3 contact">
              <p>Contacts</p>
              <p>
                <img src="/resources/img/events/phone.png" alt="" />
                <span>{`${data[lang]?.contact_no ?? ""}`}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
