import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonForm from "../../../../components/commonform/commonForm";
import CustomIcons from "../../../../components/icons/kIcons.component";
import Loader from "../../../../components/loader/loader";
// import CustomIcons from "../../../../components"
//
import CustomTable from "../../../../components/table/table";
import { inter_details } from "../../user.api";

import "./style.css";
import moment from "moment";
export default function Addproposal(props) {
  const selectedParam = useParams();
  
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const [data, setData] = useState({});
  const [isActive, setIsActive] = useState(true);
  const getDetails = (id) => {
    inter_details(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
      console.log(tdata)
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  
  return (
    <div className="proj">
      {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
      <Loader open={isActive} />
      {/* <img src="../public/resources/pdf.png" alt="" /> */}
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Internship / Training Detail</div>
        </div>
      </div>
      <div className="container deta mt-3">
        <div className="row">
          {/* --------------------- */}
          <div className="col-md-9 col-sm-12 detail-pad mb-3 deta1">
            <div className="row">
              <center>
                <div className="col-6">
                  <img
                     src={`${result}${data?.file_upload_photo}`}
                    className="pic2 mb-2"
                    alt=""
                  />
                </div>
                <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Name</div>
                    <div className="col-1">:</div>
                    <div className="col-8">{data.full_name}</div>
                  </div>
                </div>
                <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Date of Birth</div>
                    <div className="col-1">:</div>
                    <div className="col-8">
                      {data.date_of_birth &&
                        moment(data.date_of_birth).format("DD-MM-YYYY")}
                    </div>
                  </div>
                </div>
                <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Gender</div>
                    <div className="col-1">:</div>
                    <div className="col-8">{data.gender}</div>
                  </div>
                </div>
                <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Institution</div>
                    <div className="col-1">:</div>
                    <div className="col-8">
                      {data?.institution?.[0]?.institution_name ?? ""}
                    </div>
                  </div>
                </div>
                <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Address</div>
                    <div className="col-1">:</div>
                    <div className="col-8">{data.address}</div>
                  </div>
                </div>
                {/* <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Email</div>
                    <div className="col-1">:</div>
                    <div className="col-8">Akhil Krishnan k</div>
                  </div>
                </div>
                <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Phone Number</div>
                    <div className="col-1">:</div>
                    <div className="col-8">Akhil Krishnan k</div>
                  </div>
                </div> */}
                <div className="col-11 mt-3 gray">
                  <div className="row ">
                    <div className="col-3 mt-2">Educational Qualification</div>

                    <div className="col-12 edux ">
                      <CustomTable
                        {...props}
                        columns={[
                          {
                            title: " Examination Passed",
                            field: "exam_passed",
                          },
                          { title: "Board", field: "board" },
                          { title: "Year of Passing", field: "year_passed" },
                          { title: "Mark", field: "mark" },
                        ]}
                        data={data.intern_qualification ?? []}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-11 mt-3 gray">
                  <div className="row">
                    <div className="col-3">Languages Known</div>
                    <div className="col-1">:</div>
                    <div className="col-8">
                      {data.intern_languages &&
                        data.intern_languages.length !== 0 &&
                        data.intern_languages.map((lang, i) => (
                          <>
                            {i > 0 && <sapn>&nbsp;|&nbsp;</sapn>}
                            <span>{lang.language}</span>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </center>
            </div>
          </div>

          {/* ---end card----- */}
          <div className="footer-buttons">
            <button
              onClick={(e) => props.history.push("/training-list")}
              className="primary-btn "
            >
              Back to Directory
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
