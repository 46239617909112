import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import {
  user_company_domain_list,
  user_company_details,
  create_company_request,
  update_company_registration,
  user_dash_list,
} from "../../user.api";

import BasicDetails from "./manage/form";
import View from "./manage/viewDetails";
import "./style.css";
import jwtDecode from "jwt-decode";

export default function NewCompanyRequest(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [basicData, setBasicData] = useState({});
  const myRef = useRef(null);
  const [domainData, setDomain] = useState([]);
  const [addCount, setCount] = useState(1);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const getDomainTypes = () => {
    setIsActive(true);
    user_company_domain_list().then((res) => {
      let domData = res?.data;
      getDetails(domData);
    });
  };
  const action = (id, data, key) => {
    myRef.current.scrollIntoView();
    if (id === 0) {
      let dom = [];
      domainData.forEach((item) => {
        if (item.checked)
          dom.push({ domain_name: item.domain_name, id: item.id });
      });

      setBasicData({ ...data, domain_types: dom });
      setActiveStep(1);
      //
    } else if (id === 2) {
      setdefaultkey(key);
      setActiveStep(0);

      // setBasicData({ ...data });
    } else if (id === 3) {
      // setBasicData({ ...data });
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    setIsActive(true);

    let areatype = [];
    if (basicData.area_management) {
      basicData?.area_management?.forEach((item, index) => {
        if (item.location_id) {
          areatype[index] = item;
        }
      });
    }
    const form = new FormData();
    console.log(basicData.product_profiles);
    form.append("company_name", basicData.company_name ?? null);
    form.append("phone_number", basicData.mobile_number);
    form.append("company_type", "kerala");
    form.append("person_name", basicData.person_name ?? null);
    form.append("person_designation", basicData.person_designation ?? null);
    form.append("address", basicData.address ?? null);
    form.append("email", basicData.email ?? null);
    form.append("alternate_email", basicData.alternate_email ?? "");
    form.append(
      "abstract_project_proposal",
      basicData.abstract_project_proposal ?? null
    );
    if (
      basicData.country_code &&
      basicData.country_code !== "" &&
      basicData.alternate_phone &&
      basicData.alternate_phone !== ""
    ) {
      form.append("country_code", basicData.country_code);
    }
    form.append("alternate_phone", basicData.alternate_phone ?? "");
    form.append("site_link" , basicData.site_link ?? "")
    form.append("upload_proposal", basicData.upload_proposal ?? "");
    form.append("capital_investment", basicData.capital_investment ?? null);
    form.append("ex_man_power", basicData.ex_man_power ?? null);
    form.append("cin_number" , basicData?.cin_number ?? "--")
    form.append("previous_fy_turnover", basicData.previous_fy_turnover ?? null);
    // form.append("product_profiles", basicData.product_profiles ?? null);
    form.append("dir_employ_male", basicData.dir_employ_male ?? null);
    form.append("dir_employ_female", basicData.dir_employ_female ?? null);
    form.append("dir_employ_others", basicData.dir_employ_others ?? null);
    form.append("dir_employ_sc_st", basicData.dir_employ_sc_st ?? null);
    form.append("achievements", basicData.achievements ?? null);
    form.append("fe_component", basicData.fe_component ?? null);
    if (basicData.certification === "Other") {
      form.append("certification", basicData.otherCertification);
  } else {
      form.append("certification", basicData.certification ?? null);
  }
    form.append("any_other_flag", basicData.any_other_flag == true ? "1" : "0");
    form.append(
      "any_other_domain",
      basicData.any_other_flag == true ? basicData.any_other_domain : ""
    );
    form.append("file_name", basicData.file_name ?? "");
    if (basicData.id) form.append("application_id", basicData.id);
    basicData.product_profiles.forEach((item, index) => {
      form.append(
        `product_profiles[${index}][product_name]`,
        item.product_name
      );
      form.append(
        `product_profiles[${index}][product_desc]`,
        item.product_desc
      );
    });

    basicData.domain_types.forEach((item, index) => {
      form.append(`domain_types[${index}][domain_name]`, item.domain_name);
      form.append(`domain_types[${index}][id]`, item.id);
    });

    // setIsActive(true);
    update_company_registration(userData?.user?.id, form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
    // user_dash_list()
  };

  // useEffect(() => {
  //   if (domainData.length === 0) {
  //     getDomainTypes();
  //   }
  //   handleChangedata("3");
  // }, []);
  let userData = jwtDecode(props.state.payload);
  console.log(userData);
  const getDetails = (domData) => {
    let otherDom = [];
    user_company_details(userData?.user?.id).then((res) => {
      // let mobile_number= res?.phone_no,
      let Data = res?.data;
      otherDom = Data?.domain_types?.filter(
        (ar1) => !domData.some((ar2) => ar1?.domain_name === ar2?.domain_name)
      );

      domData?.forEach((item) => {
        item["checked"] = Data?.domain_types?.some(
          (dom) => item?.domain_name === dom?.domain_name
        );
      });
      setDomain(domData);
      let d = {
        ...Data,
        any_other_flag:
          Data.any_other_flag && Data.any_other_flag !== "0" ? true : false,
        any_other_domain:
          Data.any_other_domain && Data.any_other_flag !== "0"
            ? Data.any_other_domain
            : "",
        country_code:
          Data.country_code === "null" || Data.country_code === "undefined"
            ? ""
            : Data.country_code,
      };
      let newCertification = ""
      if(Data.certification !== "KS9100" ||
      Data.certification !== "ISO9000/2050" ||
      Data.certification !== "Z_Certfication") {
newCertification = Data.certification

      }
      setBasicData({
        ...d,
        mobile_number: Data.phone_no,
        file_name: Data?.upload_proposal,
        ...newCertification !== "" ? {certification : "Other" , otherCertification : newCertification} : {certification : Data?.certification}
      });
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDomainTypes();
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});

      setDomain([]);
      // props.logout();
      props.history.push("/dashboard");
    }
  };
  console.log(basicData);
  return (
    <div className="page-bg" ref={myRef}>
      <div>
        <Loader open={isActive} />
        <div className="dash-banner ">
          <div className="tit-txt">Update - Affiliate an Existing Aerospace Unit in Kerala with KSPACE</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container  form-section">
          <div className="itemc">
            <div className="fomy form-inner-section">
              {activeStep === 0 && !isActive && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  update={true}
                  action={action}
                  formData={basicData}
                  setDomain={setDomain}
                  domainData={domainData}
                />
              )}

              {activeStep === 1 && (
                <View
                  {...props}
                  basicData={basicData}
                  action={action}
                  update={true}
                />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
