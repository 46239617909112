import React, { useState, useEffect } from "react";
import TextField from "../../../components/inputs/textfield";
import ResourseTile from "../../../components/page/tiles/resourse";
import "./style.css";
import {
  candidateDownloadedList,
  candidateList,
  expertManPowerList,
} from "../user.api";
import Loader from "../../../components/loader/loader";
let count = 100;
export default function ExpertManPowerList(props) {
  const [isActive, setIsActive] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const listData = () => {
    candidateDownloadedList().then((res) => {
      let dir = res.data;
      setIsActive(false);
      let finalData = [];
      // dir.forEach((item) => {
      //   if (
      //     item.expert_manpower?.is_resource_person === 0 &&
      //     item.expert_manpower?.admin_status === 4
      //   ) {
      //     finalData.push(item);
      //   }
      // });

      setSearchData(dir);
      console.log(searchData);

      setIsActive(false);
    });
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    listData();
  }, []);

  return (
    <div className="tender bg-tender">
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Downloaded Candidate Profiles</div>
        </div>
      </div>
      <div className="container p-50">
        <div className="res-page-cont">
          <div className="row tender-box">
            {/* <div className="col-xs-12 col-sm-3">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input type="text" className="form-control" placeholder="Search" />
              </div>
            </div> */}
            <div className="new-res-tile-cont">
              {searchData.length !== 0 ? (
                <>
                  {searchData?.map((item) => (
                    <>
                      <ResourseTile
                        data={item}
                        {...props}
                        path={`/candidate-details-view/${item?.candidate_details?.id}`}
                        name={item?.candidate_details?.name.charAt(0)}
                        // photo={`${result}${item?.photo}`}
                        title={`${item?.candidate_details?.prefix} . ${item?.candidate_details?.name}`}
                        email={item?.candidate_details?.email}
                        qualification={`${
                          item?.candidate_details?.qualification_type
                        } - ${item?.candidate_details?.qualification ?? ""}`}
                        interest={`${item?.candidate_details?.interest_type}`}
                        // interest-type = {item.interest}
                        expIn={item?.candidate_details?.area_of_expertise}
                        candidate={true}
                        status={item?.downloaded_profile_status}
                      />
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="no-data">
                    <img src="resources/img/no1.png" alt="" />
                    <h4>No Records Found..!!</h4>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
