import React, { useEffect, useState } from "react";
import "./view.css";
import CommonForm from "../../../components/commonform/commonForm";
import {
  expertManPowerCreateLookup,
  expertManPowerCreate,
  adminresourcePersonalCreate,
  common_file_upload,
  user_company_domain_list,
} from "../admin.api";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";

export default function Add(props) {
  const [formData, setFormData] = useState({
    gender: 1,
    is_willing_to_travel: 1,
  });
  const [areaOfInterest, setAreaOfInterest] = useState([]);

  const [snackMessage, setSnackMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [domainData, setDomain] = useState([]);
  const [count, setCount] = useState(1);

  const [languageData, setLanguageData] = useState([
    { language: "Malayalam", checked: false },
    { language: "English", checked: false },
    { language: "Hindi", checked: false },
  ]);
  const getInitialDatas = () => {
    expertManPowerCreateLookup().then((res) => {
      setAreaOfInterest(res.data?.areaOfInterest);

      setCount(count + 1);
    });
  };
  const getDomainTypes = () => {
    setIsActive(true);
    user_company_domain_list().then((res) => {
      setDomain(res.data);
      setCount(count + 1);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getInitialDatas();
    getDomainTypes();
  }, []);
  useEffect(() => {
    if (!formData.educational_qualifications) {
      setFormData({
        ...formData,
        educational_qualifications: [
          {
            examination_passed: "",
            board_of_university: "",
            year_of_pass: "",
            mark_grade: "",
            file_upload_certificate: null,
          },
        ],
      });
    }
    if (!formData.work_experience) {
      setFormData({
        ...formData,
        work_experience: [
          {
            organization_name: "",
            experience: "",
            nature_of_work: "",
            designation: "",
            reason_for_leaving: "",
            experience_certificate: null,
          },
        ],
      });
    }
    if (!formData.expertise_area) {
      setFormData({
        ...formData,
        expertise_area: [
          {
            expertise_name: "",
          },
        ],
      });
    }
    if (!formData.otherLanguages) {
      setFormData({
        ...formData,
        otherLanguages: [{ language: "" }],
      });
    }
  }, [
    formData,
    formData.educational_qualifications,
    formData.work_experience,
    formData.expertise_area,
    formData.otherLanguages,
  ]);
  // const getBase64 = (file) => {
  //   return new Promise((resolve) => {
  //     let baseURL = "";

  //     let reader = new FileReader();

  //     reader.readAsDataURL(file);

  //     reader.onload = () => {
  //       baseURL = reader.result;

  //       resolve(baseURL);
  //     };
  //   });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    let language = [];
    let area = [];
    let dom = [];
    if (formData.mobile.length !== 10) {
      setSnackMessage("Please enter a valid mobile no");
      setIsSnackError(true);
      setSnackOpen(true);
    } else {
      areaOfInterest.forEach((item) => {
        if (item.checked) {
          area.push(item.uuid);
        }
      });
      languageData.forEach((item) => {
        if (item.checked) {
          language.push(item.language);
        }
      });

      if (formData.any_other_lang) {
        if (formData.otherLanguages.length !== 0) {
          formData.otherLanguages.forEach((item) => {
            language.push(item.language);
          });
        }
      }

      domainData.forEach((item) => {
        if (item.checked) {
          dom.push(item.domain_name);
        }
      });

      if (formData.any_other_flag) {
        dom.push(formData.any_other_domain);
      }

      let exp = [];
      let edu = [];
      let expArea = [];
      formData?.work_experience?.forEach((item) => {
        let data = {
          organization_name: item.organization_name,
          experience: item.experience,
          nature_of_work: item.nature_of_work,
          designation: item.designation,
          reason_for_leaving: item.reason_for_leaving,
          experience_certificate: item.experience_certificate,
        };

        exp.push(data);
      });
      formData?.expertise_area?.forEach((item) => {
        expArea.push(item.expertise_name);
      });
      formData?.educational_qualifications?.forEach((item) => {
        let data = {
          examination_passed: item.examination_passed,
          board_of_university: item.board_of_university,
          file_upload_certificate: item.file_upload_certificate,
          mark_grade: item.mark_grade,
          year_of_pass: item.year_of_pass,
        };

        edu.push(data);
      });

      let finalData = {
        address: formData.address,
        age: formData.age,
        email: formData.email,
        gender: formData.gender,
        mobile: formData.mobile,
        ...(formData.alternate_phone &&
          formData.country_code &&
          formData.country_code !== ""
            ? { country_code: formData.country_code }
            : {}),
          alternate_phone: formData.alternate_phone ?? "",
        full_name: formData.name,
        photo: formData.photo,
        expertise_area: expArea,
        is_willing_to_travel:
          formData.is_willing_to_travel === 1 ? true : false,
          desc_of_current_work: formData.organization_details,
        languages_known: language,
        experience: exp,
        education: edu,
        area_of_interest: area,
        domain_types: dom,
      };
      if (
        finalData.photo === null ||
        finalData.photo === undefined ||
        finalData.photo === ""
      ) {
        setSnackMessage("Please choose a valid photo file");
        setIsSnackError(true);
        setSnackOpen(true);
      } else {
        let validExp = true;
        finalData.experience.forEach((item) => {
          if (
            item.experience_certificate === null ||
            item.experience_certificate === undefined ||
            item.experience_certificate === ""
          ) {
            setSnackMessage(
              `Please choose a valid experience certificate file for ${item.organization_name}`
            );
            setIsSnackError(true);
            setSnackOpen(true);
            validExp = false;
          }
        });

        if (validExp) {
          let validEduc = true;
          finalData.education.forEach((item) => {
            if (
              item.file_upload_certificate === null ||
              item.file_upload_certificate === undefined ||
              item.file_upload_certificate === ""
            ) {
              setSnackMessage(
                `Please choose a valid educational certificate file for ${item.examination_passed}`
              );
              setIsSnackError(true);
              setSnackOpen(true);
              validEduc = false;
            }
          });
          if (validEduc) {
            adminresourcePersonalCreate(finalData)
              .then((res) => {
                setSnackMessage(res.message);
                setIsSnackError(false);
                setSnackOpen(true);
              })
              .catch((error) => {
                setSnackMessage(error.response.data.message);
                setIsSnackError(true);
                setSnackOpen(true);
              });
          }
        }
      }
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/resource-list");
    }
  };

  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };

  const handleFileChange = (e) => {
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let size = e.target.files[0].size;

      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        uploadFile(e);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          photo: null,
          file_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        photo: null,
        file_name: null,
      });
    }
  };
  const uploadFile = (e) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/profile-pic");
    // file.append("type", "expert-manpower/education-qualifications");

    common_file_upload(file)
      .then((res) => {
        setFormData({
          ...formData,
          photo: res.data,
          file_name: e.target.files[0].name,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const eduUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/education-qualifications");
    common_file_upload(file)
      .then((res) => {
        let edu = formData.educational_qualifications;
        edu[i] = {
          ...edu[i],
          file_name: e.target.files[0].name,
          file_upload_certificate: res.data,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const expUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/experience");
    common_file_upload(file)
      .then((res) => {
        let exp = formData.work_experience;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          experience_certificate: res.data,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let edu = formData.educational_qualifications;

    if (type?.toLowerCase() === "png" || type?.toLowerCase() === "jpeg" || type?.toLowerCase() === "jpg" || type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        eduUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        edu[i] = {
          ...edu[i],
          file_name: null,
          file_upload_certificate: null,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      edu[i] = {
        ...edu[i],
        file_name: null,
        file_upload_certificate: null,
      };
      setFormData({
        ...formData,
        educational_qualifications: edu,
      });
    }
  };

  const handleExperienceFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let exp = formData.work_experience;
    if (type?.toLowerCase() === "png" || type?.toLowerCase() === "jpeg" || type?.toLowerCase() === "jpg" || type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        expUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);

        exp[i] = {
          ...exp[i],
          file_name: null,
          experience_certificate: null,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png /.pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      exp[i] = {
        ...exp[i],
        file_name: null,
        experience_certificate: null,
      };
      setFormData({
        ...formData,
        work_experience: exp,
      });
    }
  };

  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.educational_qualifications.push({
      examination_passed: "",
      board_of_university: "",
      year_of_pass: "",
      mark_grade: "",
      file_upload_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.educational_qualifications.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleExperienceChange = (e, i) => {
    console.log(e, i);
    let data = { ...formData };
    data.work_experience[i] = {
      ...data.work_experience[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleExperticeChange = (e, i) => {
    let data = { ...formData };
    data.expertise_area[i] = {
      ...data.expertise_area[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const addExperience = (i) => {
    let data = { ...formData };

    data.work_experience.push({
      organization_name: "",
      experience: "",
      nature_of_work: "",
      designation: "",
      reason_for_leaving: "",
      experience_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExperiance = (i) => {
    let data = { ...formData };
    data.work_experience.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const addExpertice = (i) => {
    let data = { ...formData };

    data.expertise_area.push({
      expertise_name: "",
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExpertice = (i) => {
    let data = { ...formData };
    data.expertise_area.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleCheckChange = (e, type, item) => {
    if (type === "domain") {
      let data = [...domainData];

      data[item]["checked"] = e.target.checked;
      // setFormData({ ...formData, any_other_flag: false });

      setDomain(data);
    } else if (type === "areaofinterest") {
      let data = [...areaOfInterest];

      data[item]["checked"] = e.target.checked;
      // setFormData({ ...formData, any_other_flag: false });

      setAreaOfInterest(data);
    } else if (type === "language") {
      let data = [...languageData];

      data[item]["checked"] = e.target.checked;
      setLanguageData(data);
    }
  };

  const handleValid = (type) => {
    let flags = false;

    if (type === "domain") {
      if (domainData) {
        domainData.forEach((item) => {
          if (item.checked) flags = true;
        });
      }
    } else if (type === "areaofinterest") {
      areaOfInterest.forEach((item) => {
        if (item.checked) flags = true;
      });
    } else if (type === "language") {
      languageData.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const textFieldChange = (e) => {
    let data = formData;

    data = {
      ...data,

      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const numberChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    let data = formData;
    data = {
      ...data,

      [name]: newValue,
    };
    setFormData(data);
  };
  const handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    if (value.length <= 10) {
      let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      let data = formData;
      data = {
        ...data,

        [name]: newValue,
      };
      setFormData(data);
    }
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const handleExperiancelNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.work_experience[i] = {
      ...data.work_experience[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };

  const genderFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      gender: Number(e.target.value),
    };
    setFormData(data);
  };
  const handleOtherChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const consentFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      is_willing_to_travel: Number(e.target.value),
    };
    setFormData(data);
  };
  const handleMultiChange = (e, i) => {
    let data = { ...formData };

    data.otherLanguages[i] = {
      ...data.otherLanguages[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  const handleOtherLangChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const addLanguage = (i) => {
    let data = { ...formData };

    data.otherLanguages.push({ language: "" });
    setFormData(data);
    setCount(count + 1);
  };
  const removeLanguage = (i) => {
    let data = { ...formData };
    data.otherLanguages.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  console.log(formData);
  return (
    <div className="add-per">
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <form onSubmit={handleSubmit}>
          <h6>
            <b>Add Resource Personnel</b>
          </h6>
          <hr />
          <div className="row">
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Full Name"
                required
                name="name"
                value={formData?.name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-5 mb-2">
              <CommonForm
                fieldType="text"
                label="Age"
                required
                name="age"
                maxLength="3"
                value={formData?.age ?? ""}
                onChange={(e) => {
                  numberChange(e);
                }}
                {...props}
              />
            </div>

            <div className="col-5 mb-2 mt-16">
              <label for="exampleInputEmail1">Gender</label>
              <div className="d-flex ">
                <CommonForm
                  fieldType="radio"
                  label="Male"
                  required
                  name="gender"
                  value={"1"}
                  checked={formData?.gender === 1}
                  onChange={(e) => genderFieldChange(e)}
                  {...props}
                />
                <CommonForm
                  fieldType="radio"
                  label="Female"
                  required
                  name="gender"
                  checked={formData?.gender === 2}
                  value={"2"}
                  onChange={(e) => genderFieldChange(e)}
                  {...props}
                />
                <CommonForm
                  fieldType="radio"
                  label="Other"
                  required
                  name="gender"
                  checked={formData?.gender === 3}
                  value={"3"}
                  onChange={(e) => genderFieldChange(e)}
                  {...props}
                />
              </div>
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Address"
                multiline
                required
                className="no-margin"
                name="address"
                value={formData?.address ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Phone Number"
                value={formData?.mobile}
                required
                onChange={(e) => {
                  handlePhoneNumberChange(e);
                }}
                name="mobile"
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
              <label className="control-label mylabel mb-2">
                Alternate Phone
              </label>

              <div className="d-flex align-items-end w-100">
                <div className="">
                  <CommonForm
                    fieldType="text"
                    name="country_code"
                    maxLength="4"
                    value={formData.country_code ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="p-1"></div>
                <div className="col-10">
                  <CommonForm
                    fieldType="text"
                    name="alternate_phone"
                    maxLength="10"
                    value={formData.alternate_phone ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="email"
                label="Email"
                // disabled
                value={formData?.email ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                required
                name="email"
                {...props}
              />
            </div>

            <div className="col-8 mb-2">
              <label for="exampleInputEmail1">Languages Known</label>
              <div className="row">
                {languageData?.map((item, index) => (
                  <div className="col-3 mt-2">
                    <div className="form-check">
                      <CommonForm
                        fieldType="checkbox"
                        value={item.uuid}
                        checked={item.checked}
                        required={
                          !formData.any_other_lang && !handleValid("language")
                        }
                        onChange={(e) =>
                          handleCheckChange(e, "language", index)
                        }
                        label={item.language}
                        {...props}
                      />
                    </div>
                  </div>
                ))}
                <div className="col-3 mt-2">
                  <div className="form-check">
                    <CommonForm
                      fieldType="checkbox"
                      checked={formData.any_other_lang}
                      value={formData.any_other_lang}
                      name="any_other_lang"
                      onChange={handleOtherLangChange}
                      label={"Others"}
                      {...props}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 mb-2">
              {formData.any_other_lang && (
                <>
                  {formData.otherLanguages?.map((e, i) => (
                    <>
                      <div>
                        <CommonForm
                          fieldType="text"
                          label="Language"
                          required
                          name="language"
                          value={e?.language}
                          onChange={(e) => {
                            handleMultiChange(e, i);
                          }}
                          {...props}
                        />
                      </div>

                      <div className="more-btn-cnt">
                        {formData.otherLanguages.length === i + 1 && (
                          <button
                            style={{ marginLeft: 5 }}
                            type="button"
                            {...props}
                            className="btn btn-primary"
                            onClick={(e) => addLanguage(i)}
                          >
                            Add More
                          </button>
                        )}
                        {formData.otherLanguages.length > 1 && (
                          <button
                            {...props}
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => removeLanguage(i)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>

            <div className="col-8 mb-2">
              <label for="exampleInputEmail1">Domain Types</label>
              <div className="row">
                {domainData &&
                  domainData.map((item, index) => (
                    <div className="col-3 mt-2">
                      <div className="form-check">
                        <CommonForm
                          fieldType="checkbox"
                          value={item.checked}
                          required={
                            !formData.any_other_flag && !handleValid("domain")
                          }
                          checked={item.checked}
                          onChange={(e) =>
                            handleCheckChange(e, "domain", index)
                          }
                          label={item.domain_name}
                          {...props}
                        />
                      </div>
                    </div>
                  ))}
                <div className="col-3 mt-2">
                  <div className="form-check">
                    <CommonForm
                      fieldType="checkbox"
                      onChange={handleOtherChange}
                      label="Any Other"
                      name="any_other_flag"
                      checked={formData.any_other_flag}
                      value={formData.any_other_flag}
                      {...props}
                    />
                  </div>
                </div>
              </div>
            </div>
            {formData.any_other_flag && (
              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="text"
                  placeholder="Please specify the Domain.."
                  required
                  name="any_other_domain"
                  value={formData.any_other_domain ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>
            )}

            <div className="col-8 mb-2">
              {formData.expertise_area?.map((e, i) => (
                <div className="section-datas-cont">
                  {/* <div className="col-xs-12 col-sm-12"> */}
                  <CommonForm
                    fieldType="text"
                    label="Area of Expertise"
                    required
                    value={e?.expertise_name}
                    onChange={(e) => {
                      handleExperticeChange(e, i);
                    }}
                    className="no-margin"
                    name="expertise_name"
                    {...props}
                  />
                  {/* </div> */}

                  <div className="more-btn-cnt">
                    {formData.expertise_area.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addExpertice(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.expertise_area.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => removeExpertice(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="col-8 mb-2">
              <label for="exampleInputEmail1">Any other area of interest</label>
              <div className="row">
                {areaOfInterest?.map((item, index) => (
                  <div className="col-3 mt-2">
                    <div className="form-check">
                      <CommonForm
                        fieldType="checkbox"
                        value={item.uuid}
                        checked={item.checked}
                        required={!handleValid("areaofinterest")}
                        onChange={(e) =>
                          handleCheckChange(e, "areaofinterest", index)
                        }
                        label={item.area_of_interest_name}
                        {...props}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div> */}

            {/* -----Educational Qualification------------ */}
            <div className="col-8  mb-2">
              <label for="exampleInputEmail1">Educational Qualification</label>

              {formData.educational_qualifications?.map((e, i) => (
                <div className="section-datas-cont">
                  <div className="section-Cont">
                    <div className="row ">
                      <div className="col-xs-12 col-sm-4">
                        <CommonForm
                          fieldType="text"
                          label="Examination passed"
                          required
                          name="examination_passed"
                          value={e?.examination_passed}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-12 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Board/University"
                          required
                          name="board_of_university"
                          value={e?.board_of_university}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-xs-6 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Year of Passing"
                          required
                          name="year_of_pass"
                          minlength="4"
                          maxLength="4"
                          value={e?.year_of_pass}
                          onChange={(e) => {
                            handleEducationalNumberChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-6 col-sm-2">
                        <CommonForm
                          fieldType="text"
                          label="Mark/grade"
                          required
                          minlength="1"
                          maxLength="4"
                          name="mark_grade"
                          value={e?.mark_grade}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-12">
                        <CommonForm
                          fieldType="file"
                          required={!e.file_name ? true : false}
                          label="Upload self attested photocopy of certificate"
                          name="file_upload_certificate"
                          {...props}
                          id="file_upload_certificate"
                          className="no-margin"
                          accept=".jpg,.jpeg,.png,.pdf"
                          type="file"
                          variant="outlined"
                          fileName={e?.file_name}
                          //filename={e?.file_name}
                          // onChange={handleFileChange}
                          onChange={(e) => {
                            handleEducationalFileChange(e, i);
                          }}
                        />
                        <div className="file-up-helper">
                          *Upload only .jpg , .jpeg , .png , .pdf format and
                          Size should be less than 1MB
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="more-btn-cnt">
                    {formData.educational_qualifications.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addeducationalQualifications(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.educational_qualifications.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => removeducationalQualifications(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* ----------edu close-------- */}

            {/* ------------experiance------------ */}
            <div className="col-8  mb-2">
              <label for="exampleInputEmail1">Details of Experience </label>

              {formData.work_experience?.map((e, i) => (
                <div className="section-datas-cont">
                  <div className="section-Cont">
                    <div className="row">
                      <div className="col-xs-12 col-sm-4">
                        <CommonForm
                          fieldType="text"
                          label="Organization with Addres"
                          required
                          name="organization_name"
                          value={e?.organization_name}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-12 col-sm-2">
                        <CommonForm
                          fieldType="text"
                          label="Experience (Yrs.)"
                          required
                          // minlength="4"
                          maxLength="2"
                          name="experience"
                          value={e?.experience}
                          onChange={(e) => {
                            handleExperiancelNumberChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Nature of  Work"
                          required
                          name="nature_of_work"
                          value={e?.nature_of_work}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-12 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Designation"
                          required
                          name="designation"
                          value={e?.designation}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12">
                        <CommonForm
                          fieldType="text"
                          label="Reason for Leaving"
                          multiline
                          required
                          value={e?.reason_for_leaving}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          className="no-margin"
                          name="reason_for_leaving"
                          {...props}
                        />
                      </div>
                      <div className="col-12">
                        <CommonForm
                          fieldType="file"
                          required={!e.file_name ? true : false}
                          label="Upload self attested photocopy of certificate"
                          name="experience_certificate"
                          onChange={(e) => {
                            handleExperienceFileChange(e, i);
                          }}
                          {...props}
                          fileName={e?.file_name}
                          //filename={e?.file_name}
                          id="experience_certificate"
                          accept=".jpg,.jpeg,.png,.pdf"
                          type="file"
                          variant="outlined"
                          // onChange={handleFileChange}
                        />
                        <div className="file-up-helper">
                          *Upload only .jpg , .jpeg , .png , .pdf format and
                          Size should be less than 1MB
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="more-btn-cnt">
                    {formData.work_experience.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addExperience(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.work_experience.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => removeExperiance(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* ------------experiance--close---------- */}

            <div className="col-8 mb-2">
              <CommonForm
                fieldType="file"
                required={!formData?.file_name ? true : false}
                label="Upload  your Photo"
                name="photo"
                {...props}
                id="photo"
                fileName={formData?.file_name}
                //filename={formData.file_name}
                accept=".jpg,.jpeg,.png"
                type="file"
                variant="outlined"
                onChange={handleFileChange}
              />
              <div className="file-up-helper">
                *Upload only .jpg , .jpeg ,.png format and Size should be less
                than 1MB
              </div>
            </div>
            <div className="col-8  mb-2">
              <CommonForm
                fieldType="text"
                label="Are you currently attached  / committed to any Organization ? Give details"
                multiline
                required
                className="no-margin"
                name="organization_details"
                value={formData.organization_details}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-8  mb-2">
              <div className="header-label">
                Willingness to travel within India / abroad
              </div>
              <div className="check-box-cont">
                <div className="d-flex ">
                  <CommonForm
                    fieldType="radio"
                    label="Yes"
                    required
                    name="is_willing_to_travel"
                    value={1}
                    checked={formData?.is_willing_to_travel === 1}
                    onChange={(e) => consentFieldChange(e)}
                    {...props}
                  />
                  <CommonForm
                    fieldType="radio"
                    label="No"
                    required
                    name="is_willing_to_travel"
                    checked={formData?.is_willing_to_travel === 0}
                    value={0}
                    onChange={(e) => consentFieldChange(e)}
                    {...props}
                  />
                </div>
              </div>
            </div>
            <div className="col-8  mb-2">
              <center>
                <button type="submit" className="btn btn-dark mt-3">
                  S U B M I T
                </button>
              </center>
            </div>
            {/* ---------------- */}
          </div>
        </form>
      </div>
    </div>
  );
}
