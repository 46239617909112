import React, { useEffect, useState } from "react";
import CSVExport from "../../../../components/csvExport";
import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../../components/table/table";
import {
  get_technology_proposal_list,
  admin_techprop_delete,
} from "../../admin.api";
import Loader from "../../../../components/loader/loader";
import "./view.css";
import Snackbar from "../../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";
import moment from "moment";
export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);

  const onSearch = () => {
    //
  };
  async function fetchExportData() {
    const exportdata = [];
    data.forEach((item) => {
      exportdata.push({
        ["Proposal Title"]: item.project_proposal_title,
        Detail: item.short_details_proposal,
        Date: item.created_at
          ? moment(item.created_at).format("DD-MMM-YYYY hh:mm A")
          : "--",
        Status:
          item.status == 0
            ? "Pending"
            : item.status == 1
            ? "Requested"
            : item.status == 5
            ? "Rejected"
            : item.status == 2
            ? "Approved"
            : "",
      });
    });
    return exportdata;
  }
  const handleProjectProposalList = () => {
    setIsActive(true);
    get_technology_proposal_list(adminType).then((res) => {
      let tdata = res.data;
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tdata);
      setIsActive(false);
    });
  };

  useEffect(() => {
    handleProjectProposalList();
  }, []);

  const handleView = (data) => {
    let path = `/technology-proposal-view/${data.id}`;
    props.history.push(path);
  };
  const handleEdit = () => {};
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_techprop_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDownload = (item) => {
    let path = `${result}${item.file_upload_proposal}`;
    window.open(path, "_blank");
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleProjectProposalList();
    }
  };
  const handleAddNew = () => {
    let path = "/tech-prop-add";
    props.history.push(path);
  };
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        {/* <h6>
          <b>Technology Transfer Proposal</b>
        </h6> */}
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">
            <b>Technology Transfer Proposal</b>
          </h6>
          <CSVExport title="Technology Transfer" fetchData={fetchExportData} />
        </div>
        <hr />
        {/* --------------admin----- */}
        {adminType === 1 && (
          <>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => handleAddNew(e)}
            >
              Add New
            </button>
            <CustomTable
              {...props}
              columns={[
                { title: "Proposal Title", field: "project_proposal_title" },

                {
                  title: "Proposal",
                  field: "file_upload_proposal",
                  type: "download",
                },
                {
                  title: "Status",
                  field: "status",
                  type: "transferStatus",
                },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },

                // { type: "delete", handleClick: handleEdit },
              ]}
              handleDownload={handleDownload}
            />
          </>
        )}

        {/* -------------super-admin----- */}
        {adminType === 2 && (
          <CustomTable
            {...props}
            columns={[
              { title: "Proposal Title", field: "project_proposal_title" },

              {
                title: "Proposal",
                field: "file_upload_proposal",
                type: "download",
              },

              {
                title: "Status",
                field: "status",
                type: "transferStatus",
              },
            ]}
            data={data}
            actions={[
              { type: "view", handleClick: handleView },

              { type: "delete", handleClick: handleDelete },
            ]}
            handleDownload={handleDownload}
          />
        )}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Technology Transfer Proposal ?"
          data={activeData}
          message="Are you sure to delete this Technology Transfer Proposal?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
