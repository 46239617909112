import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/table/table";

import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import Snackbar from "../../../../components/snackbar/snackbar";
import { FormValidate } from "../../../../components/formValidate";
import {
  admin_accomplishment_list,
  admin_accomplishment_update,
} from "../../admin.api";
import "./view.css";
import TextField from "../../../../components/inputs/textfield";
import Loader from "../../../../components/loader/loader";

export default function View(props) {
  const [data, setdata] = useState({});
  const [addNew, setAddNew] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [activeData, setActiveData] = useState({});
  const [view, setView] = useState(false);

  const handleEventList = () => {
    setIsActive(true);
    admin_accomplishment_list().then((res) => {
      let tempData = res.data[0];

      setdata(tempData);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handleClose = () => {
    setEdit(false);

    handleEventList();
  };
  const handleEdit = () => {
    setEdit(true);
  };

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  // const handleDeleteConfirm = (data) => {
  //   setOpenAction(false);
  //   admin_notification_delete(data.uuid)
  //     .then((res) => {
  //       setSnackMessage(res.message);
  //       setIsSnackError(false);
  //       setSnackOpen(true);
  //     })
  //     .catch((error) => {
  //       setSnackMessage(error.message);
  //       setIsSnackError(true);
  //       setSnackOpen(true);
  //     });
  // };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
      setEdit(false);
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let formData = data;
    formData = { ...formData, [name]: value };
    setdata(formData);
  };
  const handleSubmit = (e) => {
    setIsActive(true);
    admin_accomplishment_update(data.uuid, data)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Our Accomplishments</b>
        </h6>
        <hr />
        <div className="d-flex justify-content-start">
          {!edit ? (
            <button
              type="button"
              className="btn btn-primary btn-sm "
              onClick={(e) => handleEdit(e)}
            >
              Edit
            </button>
          ) : (
            <React.Fragment>
              <button
                type="button"
                className="btn btn-secondary mange-btns "
                onClick={(e) => handleClose(e)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary mange-btns mx-3"
                onClick={(e) => handleSubmit(e)}
              >
                Update
              </button>
            </React.Fragment>
          )}
        </div>
        <div className="row mt-3">
          <div className="col-6 accominput">
            <p>
              <b>Companies operating within Kerala Spacepark</b>
            </p>
            <TextField
              type="number"
              label="Number Of Companies"
              className="form-control  mt-2"
              id="exampleInputPassword1"
              value={data.no_companies_kspace ?? 0}
              name="no_companies_kspace"
              disabled={!edit}
              onChange={(e) => handleChange(e)}
            />
            <TextField
              type="number"
              label="Number Of Employees"
              className="form-control  "
              id="exampleInputPassword1"
              value={data.no_employees_kspace ?? 0}
              disabled={!edit}
              onChange={(e) => handleChange(e)}
              name="no_employees_kspace"
            />
            <TextField
              type="number"
              label="Capital Investment (In Crore)"
              className="form-control  mt-2"
              id="exampleInputPassword1"
              value={data.capital_investment_kspace ?? 0}
              disabled={!edit}
              onChange={(e) => handleChange(e)}
              name="capital_investment_kspace"
            />
            <TextField
              type="number"
              label="Annual Turn Over (In Crore)"
              className="form-control  mt-2"
              id="exampleInputPassword1"
              value={data.annual_turn_over_kspace ?? 0}
              disabled={!edit}
              onChange={(e) => handleChange(e)}
              name="annual_turn_over_kspace"
            />
          </div>
          <div className="col-6 accominput">
            <p>
              <b>Companies operating within Kerala</b>
            </p>
            <TextField
              type="number"
              label="Number Of Companies"
              className="form-control myinput"
              id="exampleInputPassword1"
              value={data.no_companies_kerala ?? 0}
              disabled={!edit}
              onChange={(e) => handleChange(e)}
              name="no_companies_kerala"
            />
            <TextField
              type="number"
              label="Number Of Employees"
              className="form-control mt-2"
              id="exampleInputPassword1"
              value={data.no_employees_kerala ?? 0}
              disabled={!edit}
              onChange={(e) => handleChange(e)}
              name="no_employees_kerala"
            />
            <TextField
              type="number"
              label="Capital Investment (In Crore)"
              className="form-control  mt-2"
              id="exampleInputPassword1"
              value={data.capital_investment_kerala ?? 0}
              disabled={!edit}
              onChange={(e) => handleChange(e)}
              name="capital_investment_kerala"
            />
            <TextField
              type="number"
              label="Annual Turn Over (In Crore)"
              className="form-control  mt-2"
              id="exampleInputPassword1"
              name="annual_turn_over_kerala"
              value={data.annual_turn_over_kerala ?? 0}
              disabled={!edit}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        {/* <CustomTable
          {...props}
          columns={[
            { title: "SI No", field: "slno" },
            { title: "title", field: "title" },
            { title: "Type", field: "type" },
            { title: "Status", field: "status" },
          ]}
          data={data}
          actions={[
            { type: "view", handleClick: handleView },
            { type: "edit", handleClick: handleEdit },
            { type: "delete", handleClick: handleDelete },
          ]}
        /> */}
      </div>

      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Our Accomplishment ?"
          data={activeData}
          message="Are you sure to delete this accomplishment?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          // handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
