import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { common_file_upload } from "../../../user.api";

export default function Qualifications(props) {
  const [formData, setFormData] = useState(props.data);
  const [count, setCount] = useState(1);

  const [defaultkey, setdefaultkey] = useState(props.defaultkey);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [keyvalue, setkeyvalue] = useState(1);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setFormData(props.data);
  }, [props.data]);
  const eduUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "resource-person/education-qualifications");
    common_file_upload(file)
      .then((res) => {
        let edu = formData.educational_qualifications;
        edu[i] = {
          ...edu[i],
          file_name: e.target.files[0].name,
          file_upload_certificate: res.data,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const expUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "resource-person/experience");
    common_file_upload(file)
      .then((res) => {
        let exp = formData.work_experience;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          experience_certificate: res.data,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let edu = formData.educational_qualifications;

    if (type?.toLowerCase() === "png" || type?.toLowerCase() === "jpeg" || type?.toLowerCase() === "jpg" || type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        eduUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        edu[i] = {
          ...edu[i],
          file_name: null,
          file_upload_certificate: null,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      edu[i] = {
        ...edu[i],
        file_name: null,
        file_upload_certificate: null,
      };
      setFormData({
        ...formData,
        educational_qualifications: edu,
      });
    }
  };

  const handleExperienceFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let exp = formData.work_experience;

    if (type?.toLowerCase() === "png" || type?.toLowerCase() === "jpeg" || type?.toLowerCase() === "jpg" || type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        expUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        exp[i] = {
          ...exp[i],
          file_name: null,
          experience_certificate: null,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      exp[i] = {
        ...exp[i],
        file_name: null,
        experience_certificate: null,
      };
      setFormData({
        ...formData,
        work_experience: exp,
      });
    }
  };
  const handleCheck = (e, id) => {
    setdefaultkey(id);

    setFormData({
      ...formData,
      area_type: e.target.name,
      // area_management: props.areamange?.area_management,
    });
  };
  const uploadFile = (e) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "resource-person/profile-pic");
    // file.append("type", "resource-person/education-qualifications");

    common_file_upload(file)
      .then((res) => {
        setFormData({
          ...formData,
          photo: res.data,
          file_name: e.target.files[0].name,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const handleFileChange = (e) => {
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let size = e.target.files[0].size;

      let mb = size / 1024;

      if (mb < 1024) {
        uploadFile(e);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          photo: null,
          file_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        photo: null,
        file_name: null,
      });
    }
  };
  const textFieldChange = (e) => {
    let name = e.target.name;

    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);
    setkeyvalue(keyvalue + 1);
  };
  const handleAccord = (e, name) => {
    setdefaultkey(e);
    props.handleChangedata(e);

    setTimeout(() => {
      setFormData({
        ...formData,
        area_type: name,
        // area_management: props.areamange?.area_management,
      });
    }, 100);
  };
  const handleFormData = (data) => {
    setFormData({ ...formData, area_management: data });
    // setTimeout(() => {
    //   setCount(count + 1);
    // }, 150);
  };
  const handleValidate = () => {
    let flag = false;

    if (formData.area_management) {
      formData.area_management?.forEach((item) => {
        Object.keys(item).forEach((sub) => {
          if (!item[sub] && [sub] !== "id") {
            flag = true;
          }
        });
      });
      return flag;
    }
  };
  const handlepreview = (e) => {
    e.preventDefault();
    if (
      formData.photo === null ||
      formData.photo === undefined ||
      formData.photo === ""
    ) {
      setSnackMessage("Please choose a valid photo file");
      setIsSnackError(true);
      setSnackOpen(true);
    } else {
      let validExp = true;
      formData.work_experience.forEach((item) => {
        if (
          item.experience_certificate === null ||
          item.experience_certificate === undefined ||
          item.experience_certificate === ""
        ) {
          setSnackMessage(
            `Please choose a valid experience certificate file for ${item.organization_name}`
          );
          setIsSnackError(true);
          setSnackOpen(true);
          validExp = false;
        }
      });

      if (validExp) {
        let validEduc = true;
        formData.educational_qualifications.forEach((item) => {
          if (
            item.file_upload_certificate === null ||
            item.file_upload_certificate === undefined ||
            item.file_upload_certificate === ""
          ) {
            setSnackMessage(
              `Please choose a valid educational certificate file for ${item.examination_passed}`
            );
            setIsSnackError(true);
            setSnackOpen(true);
            validEduc = false;
          }
        });
        if (validEduc) {
          props.action(2, formData, defaultkey);
        }
      }
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.educational_qualifications.push({
      examination_passed: "",
      board_of_university: "",
      year_of_pass: "",
      mark_grade: "",
      file_upload_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.educational_qualifications.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleExperienceChange = (e, i) => {
    let data = { ...formData };

    data.work_experience[i] = {
      ...data.work_experience[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };

  const handleExperiancelNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.work_experience[i] = {
      ...data.work_experience[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const addExperience = (i) => {
    let data = { ...formData };

    data.work_experience.push({
      organization_name: "",
      experience: "",
      nature_of_work: "",
      designation: "",
      reason_for_leaving: "",
      experience_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExperiance = (i) => {
    let data = { ...formData };
    data.work_experience.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  console.log(formData);
  return (
    <div className="">
      <Loader open={isActive} />
      <form onSubmit={handlepreview}>
        <div className="header-label mt-1">Educational Qualification</div>
        <div>
          <div>
            {formData?.educational_qualifications?.map((e, i) => (
              <div className="section-datas-cont">
                <div className="section-Cont">
                  <div className="row ">
                    <div className="col-xs-12 col-sm-4 ">
                      <CommonForm
                        fieldType="text"
                        label="Examination passed"
                        required
                        name="examination_passed"
                        value={e?.examination_passed}
                        onChange={(e) => {
                          handleEducationalChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-12 col-sm-3">
                      <CommonForm
                        fieldType="text"
                        label="Board/University"
                        required
                        name="board_of_university"
                        value={e?.board_of_university}
                        onChange={(e) => {
                          handleEducationalChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-3">
                      <CommonForm
                        fieldType="text"
                        label="Year of Passing"
                        required
                        name="year_of_pass"
                        minlength="4"
                        maxLength="4"
                        value={e?.year_of_pass}
                        onChange={(e) => {
                          handleEducationalNumberChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-6 col-sm-2">
                      <CommonForm
                        fieldType="text"
                        label="Mark/grade"
                        required
                        minlength="1"
                        maxLength="4"
                        name="mark_grade"
                        value={e?.mark_grade}
                        onChange={(e) => {
                          handleEducationalChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-12">
                      <CommonForm
                        fieldType="file"
                        required={!e.file_name ? true : false}
                        label="Upload self attested photocopy of certificate"
                        name="file_upload_certificate"
                        {...props}
                        id="file_upload_certificate"
                        className="no-margin"
                        accept=".jpg,.jpeg,.png,.pdf"
                        type="file"
                        variant="outlined"
                        fileName={e?.file_name}
                        //filename={e?.file_name}
                        // onChange={handleFileChange}
                        onChange={(e) => {
                          handleEducationalFileChange(e, i);
                        }}
                      />
                      <div className="file-up-helper">
                        *Upload only .jpg , .png , .jpeg , .pdf format and Size
                        should be less than 1MB
                      </div>
                    </div>
                  </div>
                </div>

                <div className="more-btn-cnt">
                  {formData.educational_qualifications.length === i + 1 && (
                    <button
                      style={{ marginLeft: 5 }}
                      type="button"
                      {...props}
                      className="btn btn-primary"
                      onClick={(e) => addeducationalQualifications(i)}
                    >
                      Add More
                    </button>
                  )}
                  {formData.educational_qualifications.length > 1 && (
                    <button
                      {...props}
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => removeducationalQualifications(i)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="header-label">Experience Details </div>
        <div>
          <div>
            {formData?.work_experience?.map((e, i) => (
              <div className="section-datas-cont">
                <div className="section-Cont">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <CommonForm
                        fieldType="text"
                        label="Organization with Address"
                        required
                        name="organization_name"
                        value={e?.organization_name}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-3">
                      <CommonForm
                        fieldType="text"
                        label="Experience (Yrs.)"
                        required
                        name="experience"
                        maxLength="2"
                        value={e?.experience}
                        onChange={(e) => {
                          handleExperiancelNumberChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-3">
                      <CommonForm
                        fieldType="text"
                        label="Nature of  Work"
                        required
                        name="nature_of_work"
                        value={e?.nature_of_work}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-12 col-sm-2">
                      <CommonForm
                        fieldType="text"
                        label="Designation"
                        required
                        name="designation"
                        value={e?.designation}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      <CommonForm
                        fieldType="text"
                        label="Reason for Leaving"
                        multiline
                        required
                        className="no-margin"
                        name="reason_for_leaving"
                        value={e?.reason_for_leaving}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-12">
                      <CommonForm
                        fieldType="file"
                        required={!e.file_name ? true : false}
                        label="Upload self attested photocopy of certificate"
                        name="experience_certificate"
                        {...props}
                        id="experience_certificate"
                        accept=".jpg,.jpeg,.png,.pdf"
                        type="file"
                        fileName={e?.file_name}
                        variant="outlined"
                        onChange={(e) => handleExperienceFileChange(e, i)}
                      />
                      <div className="file-up-helper">
                        *Upload only .jpg , .jpeg , .png , .pdf format and Size
                        should be less than 1MB
                      </div>
                    </div>
                  </div>
                </div>

                <div className="more-btn-cnt">
                  {formData.work_experience.length === i + 1 && (
                    <button
                      style={{ marginLeft: 5 }}
                      type="button"
                      {...props}
                      className="btn btn-primary"
                      onClick={(e) => addExperience(i)}
                    >
                      Add More
                    </button>
                  )}
                  {formData.work_experience.length > 1 && (
                    <button
                      {...props}
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => removeExperiance(i)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Are you currently attached  / committed to any Organization ? Give details"
            multiline
            required
            className="no-margin"
            name="organization_details"
            value={formData.organization_details}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
   
        <div className="col-12">
          <CommonForm
            fieldType="file"
            required={!formData?.file_name ? true : false}
            label="Upload your Photo"
            name="photo"
            {...props}
            id="photo"
            fileName={formData?.file_name}
            //filename={formData.file_name}
            accept=".jpg,.jpeg"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />
          <div className="file-up-helper">
            *Upload only .jpg , .png , .jpeg , format and Size should be less
            than 1MB
          </div>
        </div>

        <div className="footer-buttons">
          <div
            className="secondary-btn"
            onClick={(e) => {
              props.action(1, formData);
            }}
          >
            GO BACK
          </div>
          <button type="submit" className="primary-btn">
            SUBMIT TO PREVIEW
          </button>
        </div>

        <Snackbar
          message={snackMessage}
          error={isSnackError}
          open={snackOpen}
          close={snackbarClose}
        />
      </form>
    </div>
  );
}
