import React, { useState, useRef, useEffect } from "react";
import Snackbar from "../../../../components/snackbar/snackbar";
import { resourcePersonalCreate } from "../../user.api";
import {
  builtup,
  euip,
  jointVenture,
  landon,
  ownland,
} from "./components/areadata";
import BasicDetails from "./components/basicDetails";
import Qualifications from "./components/qualifications";
import View from "./components/viewDetails";
import {
  user_company_domain_list,
  expertManPowerCreateLookup,
  user_new_request,
} from "../../user.api";
import "./style.css";
import Loader from "../../../../components/loader/loader";
import moment from "moment";
let eighteenYearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
export default function NewResPersonal(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [defaultkey, setdefaultkey] = useState("");
  const myRef = useRef(null);
  const [basicData, setBasicData] = useState({
    mobile: props.phone,
    date_of_birth: moment(eighteenYearsAgo).format("DD-MM-YYYY"),
    gender: 1,
    educational_qualifications: [
      {
        examination_passed: "",
        board_of_university: "",
        year_of_pass: "",
        mark_grade: "",
        file_upload_certificate: null,
      },
    ],
    work_experience: [
      {
        organization_name: "",
        experience: "",
        nature_of_work: "",
        designation: "",
        reason_for_leaving: "",
        experience_certificate: null,
      },
    ],
    expertise_area: [
      {
        expertise_name: "",
      },
    ],
    otherLanguages: [{ language: "" }],
    is_willing_to_travel: 1,
  });
  const [areaData, setAreaData] = useState({});
  const [domainData, setDomain] = useState([]);
  const [languageData, setLanguageData] = useState([
    { language: "Malayalam", checked: false },
    { language: "English", checked: false },
    { language: "Hindi", checked: false },
  ]);
  const [areaOfInterest, setAreaofInterest] = useState([]);
  const [addCount, setCount] = useState(1);
  const [data, setData] = useState({});
  const [openView, setOpenView] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const getDomainTypes = () => {
    setIsActive(true);
    user_company_domain_list().then((res) => {
      setDomain(res.data);
      setCount(addCount + 1);
      setIsActive(false);
    });
  };

  const getLookUpData = () => {
    setIsActive(true);
    expertManPowerCreateLookup().then((res) => {
      setAreaofInterest(res.data?.areaOfInterest);
      setCount(addCount + 1);
      setIsActive(false);
    });
  };

  const action = (id, data, key) => {
    myRef.current.scrollIntoView();
    if (id === 0) {
      let dom = [];
      // domainData.forEach((item) => {
      //   if (item.checked)
      //     dom.push({ domain_name: item.domain_name, id: item.id });
      // });

      setBasicData({ ...data });
      setActiveStep(1);
      //
    } else if (id === 2) {
      setdefaultkey(key);

      setBasicData({ ...data });
      setActiveStep(2);
    } else if (id === 1) {
      setBasicData({ ...data });
      setActiveStep(0);
    } else if (id === 3) {
      setActiveStep(1);
    } else if (id === 4) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    let language = [];
    let area = [];
    let dom = [];

    // areaOfInterest.forEach((item) => {
    //   if (item.checked) {
    //     area.push(item.uuid);
    //   }
    // });
    languageData.forEach((item) => {
      if (item.checked) {
        language.push(item.language);
      }
    });

    if (basicData.any_other_lang) {
      if (basicData.otherLanguages.length !== 0) {
        basicData.otherLanguages.forEach((item) => {
          language.push(item.language);
        });
      }
    }

    domainData.forEach((item) => {
      if (item.checked) {
        dom.push(item.domain_name);
      }
    });

    if (basicData.any_other_flag) {
      dom.push(basicData.any_other_domain);
    }

    let exp = [];
    let edu = [];
    let expArea = [];
    basicData?.work_experience?.forEach((item) => {
      let data = {
        organization_name: item.organization_name,
        experience: item.experience,
        // desc_of_current_work: item.nature_of_work,
        nature_of_work: item.nature_of_work,
        designation: item.designation,
        reason_for_leaving: item.reason_for_leaving,
        experience_certificate: item.experience_certificate,
      };

      exp.push(data);
    });
    basicData?.expertise_area?.forEach((item) => {
      expArea.push(item.expertise_name);
    });
    basicData?.educational_qualifications?.forEach((item) => {
      let data = {
        examination_passed: item.examination_passed,
        board_of_university: item.board_of_university,
        file_upload_certificate: item.file_upload_certificate,
        mark_grade: item.mark_grade,
        year_of_pass: item.year_of_pass,
      };

      edu.push(data);
    });

    let finalData = {
      // full_name: basicData.full_name,
      first_name: basicData.first_name,
      last_name: basicData.last_name,
      ...(basicData.title ? { title: basicData.title } : { title: "Mr" }),

      address: basicData.address,

      date_of_birth: basicData.date_of_birth
        ? moment(basicData.date_of_birth, "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      email: basicData.email,
      gender: basicData.gender,
      mobile: basicData.mobile,
      ...(basicData.alternate_phone &&
      basicData.country_code &&
      basicData.country_code !== ""
        ? { country_code: basicData.country_code }
        : {}),
      alternate_phone: basicData.alternate_phone ?? "",
      name: basicData.name,
      photo: basicData.photo,
      expertise_area: expArea,
      travell_interest: basicData.is_willing_to_travel === 1 ? true : false,

      desc_of_current_work: basicData.organization_details
        ? basicData.organization_details
        : "",
      languages_known: language,
      experience: exp,
      education: edu,
      area_of_interest: area,
      domain_types: dom,
    };
    console.log(finalData);
    setIsActive(true);
    resourcePersonalCreate(finalData)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const navLabel = [
    { id: 0, activeStep: 0, name: "Basic Information" },
    { id: 1, activeStep: 1, name: "Qualifications" },
  ];

  const headlperText = {
    header: "* All fields are Mandatory",
  };

  useEffect(() => {
    if (domainData.length === 0) {
      getDomainTypes();
    }
    // handleChangedata("3");
  }, []);
  useEffect(() => {
    if (domainData.length === 0) {
      getLookUpData();
    }
    // handleChangedata("3");
  }, []);

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});
      // handleChangedata(0);
      setDomain([]);
      props.logout();
    }
  };
  console.log(basicData);
  return (
    <div className="page-bg" ref={myRef}>
      <div>
        <Loader open={isActive} />
        <div className="dash-banner ">
          <div className="tit-txt">Resource Personnel</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="itemc">
            <div>
              {activeStep < 2 && (
                <>
                  <div className="header-helper-text">
                    {headlperText.header}
                  </div>
                  <div className="d-flex">
                    {navLabel.map((item, index) => (
                      <div
                        className={
                          index % 2 === 1
                            ? item.activeStep === activeStep
                              ? "headerbuttoneven active"
                              : "headerbuttoneven"
                            : item.activeStep === activeStep
                            ? "headerbuttonodd active"
                            : "headerbuttonodd"
                        }
                      >
                        <div
                          className={
                            index % 2 === 1 ? "indexCont even" : "indexCont "
                          }
                        >
                          {index + 1}
                        </div>
                        <div> {item.name}</div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div className="fomy form-inner-sections">
                {activeStep === 0 && (
                  <BasicDetails
                    {...props}
                    data={basicData}
                    action={action}
                    formData={basicData}
                    domainData={domainData}
                    areaOfInterest={areaOfInterest}
                    setDomain={setDomain}
                    languageData={languageData}
                    setLanguageData={setLanguageData}
                    setAreaofInterest={setAreaofInterest}
                  />
                )}
                {activeStep === 1 && (
                  <Qualifications
                    {...props}
                    key={addCount}
                    data={basicData}
                    defaultkey={defaultkey}
                    action={action}
                  />
                )}
                {activeStep === 2 && (
                  <View
                    {...props}
                    basicData={basicData}
                    domainData={domainData}
                    languageData={languageData}
                    areaOfInterest={areaOfInterest}
                    action={action}
                  />
                )}
              </div>
              <Snackbar
                message={snackMessage}
                error={isSnackError}
                open={snackOpen}
                close={snackbarClose}
              />
              {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
