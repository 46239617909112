import React from "react";

const NewsTile = (props) => {
  console.log(props);
  const handleNav = (linkurl, file) => {
    let urlLower = linkurl?.toLowerCase();
    let urlStart = urlLower?.substring(0, 4);
    if (linkurl && linkurl != "null") {
      if (urlStart === "http") {
        window.open(`${urlLower}`, "_blank");
      } else {
        window.open(`//${urlLower}`, "_blank");
      }
    } else if (file && file != "null") {
      window.open(`https://kspace.inovace.in/${file}`, "_blank");
    } else {
      props.viewMore();
    }
  };
  return (
    <div className="news-updates-tiles-container h-100">
      <div className="news-updates-tile-box-container">
        <div className=" "></div>
        <div className="event-tile-data-container ">
          <div
            className="news-updates-tile-box-title"
            onClick={() => handleNav(props?.data?.link, props?.data?.file)}
          >
            {props?.data?.title}
          </div>

          <div className="event-tile-box-desc" style={{ textAlign: "left" }}>
            {props?.data?.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsTile;
