import React, { useEffect, useState } from "react";
import RadioSelect from "../../../components/inputs/radio";
import TextField from "../../../components/inputs/textfield";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";
import { EmailValidate, FormValidate } from "../../../components/formValidate";
import { admin_spaceparkadvisers_add } from "../admin.api";
import "./view.css";
let required = [
  "mobile",
  "photo",
  "name",
  "biodata",
  "address",
  "designation",
  "email",
];
export default function AddSpaceparkAdvisors(props) {
  const [formData, setFormData] = useState({
    member: true,
    head: false,
    governing_member: true,
    council_number: false,
  });

  const [snackMessage, setSnackMessage] = useState("");
  const [fileChanged, setFileChanged] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.name === "email") {
      let erroremail = !EmailValidate(e.target.value);
      setEmailError(erroremail);
    }
    let data = formData;
    data = { ...data, [name]: value };
    setFormData(data);
  };
  const handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    if (value.length <= 10) {
      let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      let data = formData;
      data = { ...data, [name]: newValue };
      setFormData(data);
    }
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type?.toLowerCase() === "pdf" || type?.toLowerCase() === "doc" || type?.toLowerCase() === "docx") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          // file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };

  const handlePhotoFileChange = (e) => {
    setIsActive(true)
    try{
    let flag = false;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;
          console.log(this.type);

          if (height === 400 || width === 400) {
            flag = true;
          }
        };
      };
      setTimeout(() => {
        if (flag) {
          setFileChanged(true);
          setFormData({
            ...formData,
            // file_name: e.target.files[0].name,
            [e.target.name]: e.target.files[0],
            
          });
          setIsActive(false)
        } else {
          setSnackMessage("Incorrect image dimension");
          setIsSnackError(true);
          setSnackOpen(true);
          setIsActive(false)
        }
      }, 2000);
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false)
    }
  }catch{
   setIsActive(false)
  }};
  const handleCheckChange = (e) => {
    let name = e.target.name;

    let data = formData;

    if (name === "governing_member") {
      data = { ...data, governing_member: true, council_number: false };
    } else if (name === "council_number") {
      data = { ...data, governing_member: false, council_number: true };
    } else if (name === "member") {
      data = { ...data, member: true, head: false };
    } else if (name === "head") {
      data = { ...data, member: false, head: true };
    }

    setFormData(data);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = FormValidate(formData, required);
    setIsActive(true);
    if (Object.keys(errors).length === 0) {
      if (!emailError) {
        if (formData.mobile.length === 10) {
          const form = new FormData();
          form.append("mobile", formData.mobile);
          form.append("email", formData.email);
          form.append("address", formData.address);
          form.append(
            "is_executive_council_member",
            formData.council_number ? 1 : 0
          );
          form.append("is_head_position", formData.head ? 1 : 0);

          form.append("photo", formData.photo);
          form.append("biodata", formData.biodata);
          form.append("designation", formData.designation);
          form.append("name", formData.name);

          admin_spaceparkadvisers_add(form)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);
              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
          // console.log(formData);
        } else {
          setSnackMessage("Enter a valid 10 digit Mobile No");
          setIsSnackError(true);
          setSnackOpen(true);
          setIsActive(false);
        }
      } else {
        setSnackMessage("Please enter a valid email address");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      }
    } else {
      setSnackMessage("Please fill all mandatory fields");
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/advisors-list");
    }
  };
  return (
    <div className="add-per">
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Add Spaceparks Advisors</b>
        </h6>
        <hr />
        <div className="row">
          <div className="col-8 ">
            <TextField
              fullwidth
              label="Name"
              required
              value={formData.name ?? ""}
              onChange={(e) => handleChange(e)}
              name="name"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 ">
            <TextField
              fullwidth
              label="Designation"
              required
              value={formData.designation ?? ""}
              onChange={(e) => handleChange(e)}
              name="designation"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 ">
            <TextField
              type="text"
              label="Email"
              required
              value={formData.email ?? ""}
              onChange={(e) => handleChange(e)}
              name="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 ">
            <TextField
              type="text"
              label="Phone number"
              required
              id="exampleInputEmail1"
              value={formData.mobile ?? ""}
              onChange={(e) => handlePhoneNumberChange(e)}
              name="mobile"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-4"></div>

          <div className="col-3 ">
            <label for="exampleInputEmail1">Council Type</label>
            <div className="d-flex">
              <div className="mop">
                <RadioSelect
                  {...props}
                  required
                  label={"Governing"}
                  name="governing_member"
                  value={formData.governing_member}
                  checked={formData.governing_member}
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>

              <div className="mop">
                <RadioSelect
                  required
                  checked={formData.council_number}
                  value={formData.council_number}
                  name="council_number"
                  {...props}
                  label={"Executive"}
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-3 ">
            <label for="exampleInputEmail1">Position</label>
            <div className="d-flex">
              <div className="mop">
                <RadioSelect
                  {...props}
                  label={"Member"}
                  checked={formData.member}
                  value={formData.member}
                  name="member"
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>

              <div className="mop">
                <RadioSelect
                  {...props}
                  label={"Chief"}
                  checked={formData.head}
                  value={formData.head}
                  name="head"
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-8">
            <TextField
              required
              label="Address"
              onChange={(e) => handleChange(e)}
              name="address"
              value={formData.address}
              aria-describedby="emailHelp"
              multiline
              fullwidth
            />
          </div>
          <div className="col-8">
            <TextField
              // type="file"
              // className="form-control form-control-sm"
              label="Photo"
              required
              id="formFileSm"
              onChange={(e) => handlePhotoFileChange(e)}
              accept=".jpg,.jpeg,.png"
              name="photo"
              type="file"
            />
            <small className="pers">
              *Upload only .jpg , .jpeg , .png format and Size should be less
              than 1MB of width: 400 Height: 400
            </small>
          </div>
          <div className="col-8">
            <TextField
              // type="file"
              // className="form-control form-control-sm"
              label="Biodata"
              required
              onChange={(e) => handleFileChange(e)}
              id="formFileSm"
              name="biodata"
              accept=".pdf,.docx,.doc"
              type="file"
            />
            <small className="pers">
              *Upload only .pdf , .doc, .docx , format and Size should be less
              than 5MB
            </small>
          </div>
          <div className="col-8">
            <center>
              <button
                type="button"
                className="btn btn-dark mt-3"
                onClick={(e) => handleSubmit(e)}
              >
                Submit for Acceptance
              </button>
            </center>
          </div>
          {/* ---------------- */}
        </div>
      </div>
    </div>
  );
}
