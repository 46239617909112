import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { CSSTransition } from "react-transition-group";
import ManageDialog from "./manage/manage";
import MenuComp from "./menu.comp";

export default function Header(props) {
  console.log(props);
  const activelocation = sessionStorage.getItem("active_path");
  const [openMenu, setOpenMenu] = useState(props.menuState);

  const [openProfile, setOpenProfile] = useState(props.prof);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [activeMenu, setActiveMenu] = useState("");

  useEffect(() => {
    setOpenMenu(props.menuState);
  }, [props.menuState]);
  useEffect(() => {
    setOpenProfile(props.prof);
  }, [props.prof]);
  const handlelanguage = () => {
    let swicthlan = props.state.language === "en" ? "SWITCHMAL" : "SWITCHENG";
    props.setlang(swicthlan);
  };
  const updateBody = (value) => {
    props.setMenu(value);
  };
  const updateProf = (value) => {
    props.setProf(value);
  };
  const closeDialog = () => {
    setOpenDialog(false);
  };
  const handleMenuClick = (e, data) => {
    e.stopPropagation();

    sessionStorage.setItem("active_path", data.path);
    if (data.next) {
      sessionStorage.setItem("next_path", data.next);
    } else {
      sessionStorage.setItem("next_path", "/dashboard");
    }
    if (data.isDirect) {
      setOpenDialog(false);
      props.history.push(data.path);
    } else {
      setOpenDialog(true);
      setActiveItem(data);
    }
    setOpenMenu(false);
    setActiveMenu(null);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const handleLogin = () => {
    props.userLogin();
  };

  let userData = props.state.payload ? jwtDecode(props.state.payload) : null;
  return (
    <div className="w-100">
      <div className="container-fluid">
        <div className="row m-auto">
          <div className="col-4 d-flex align-items-center hed-padi">
            <div className="pt-3">
              <div
                className="new-menuIcon-cont"
                onClick={() => {
                  setOpenMenu(!openMenu);
                  updateProf(false);
                  updateBody(!openMenu);
                  setActiveMenu(null);
                }}
              >
                <GiHamburgerMenu
                  onClick={() => {
                    setOpenMenu(!openMenu);
                    updateBody(!openMenu);
                    updateProf(false);
                    setActiveMenu(null);
                  }}
                  className="header-menu-icon menu-cont"
                />
              </div>
              <div className="menu-head-text-cont hideonMobile">
                <div>Menu</div>
              </div>
            </div>
            <div
            className="d-flex align-items-center justify-content-center logo-link"
            onClick={() => {
              setOpenMenu(false);
              props.history.push("/");
            }}
          >
            
            <div className="hideonMobile">
              <img
                src="/resources/logo/kg.png"
                className="header-logo-image-kg"
                alt="logo"
              />
            </div>
            <div className="hideonMobile d-md-none d-lg-block">
            <div className=" h-100 d-flex align-items-center justify-content-end">
              <img
                src="/resources/logo/kit.png"
                className="kit-logo ml-4"
                alt="logo"
              />
            </div>
            </div>
          </div>
          </div>
          <div className="col-4 d-flex align-items-center justify-content-center hed-padi pt-2">
           
            <div
              onClick={() => {
                setOpenMenu(false);
                props.history.push("/");
              }}
              className="d-flex align-items-center h-100 justify-content-center"
            >
              <img
                src="/resources/logo/logo.png"
                className="header-logo-image logo-link h-100 "
                alt="logo"
                // style={{"height" : "65px !important"}}
              />
            </div>
            <div
              onClick={() => {
                setOpenMenu(false);
                props.history.push("/");
              }}
              className="header-title hideonMobileSocial logo-link"
            >
              Kerala Spacepark
            </div>
          </div>

          
          
          <div
            className="col-4 d-flex align-items-center justify-content-end"
            onClick={(e) => {
              setOpenMenu(false);
            }}
          >
            <div className="hideonMobileSocial">
              <div className="social-buttons">
                <a
                  href="#"
                  className="social-buttons__button social-button social-button--facebook"
                  aria-label="Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="#"
                  className="social-buttons__button social-button social-button--twitter"
                  aria-label="LinkedIn"
                >
                  {/* <i className="fa-brands fa-x-twitter"></i>
                   */}
                   <img src="/twitter.png" alt="x" className="twitter_icon"/>
                </a>
                <a
                  href="#"
                  className="social-buttons__button social-button social-button--youtube"
                  aria-label="Youtube"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  href="#"
                  className="social-buttons__button social-button social-button--insta"
                  aria-label="GitHub"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="#"
                  className="social-buttons__button social-button social-button--linkedin"
                  aria-label="Linkedin"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>

              {/* <div className="social-media-container d-flex">
                <div className="insta">
                  <img src="/resources/img/footer/ig.png" />
                </div>
                <div className="insta">
                  <img src="/resources/img/footer/fb.png" />
                </div>
                <div className="insta">
                  <img src="/resources/img/footer/tw.png" />
                </div>
                <div className="insta">
                  <img src="/resources/img/footer/yt.png" />
                </div>
                <div className="insta">
                  <img src="/resources/img/footer/ln.png" />
                </div>
              </div> */}
            </div>
            <div className="d-flex">
              <div className="lang-container">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handlelanguage()}
                >
                  {props.state.language === "en" ? "ML " : "EN"}
                </span>
              </div>
              <div className="login-status-container">
                {!props.state.isValid ? (
                  <div className="new-nav-item ">
                    {props.state.isUserAuthenticated ? (
                      <>
                        <div
                          className="userAvatar"
                          onClick={() => {
                            updateProf(!openProfile);
                          }}
                        >
                          {userData?.user?.name.substring(0, 2)}
                        </div>
                        <div className="header-profile-title">Profile</div>
                      </>
                    ) : (
                      <>
                      <div
                        className="login-head"
                        onClick={(e) => {
                          handleLogin();
                        }}
                        title="For those registered"
                      >
                        Login
                      </div>
                      <span style={{fontSize:"9px", color:"gray"}}>For Registered Users</span>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="nav-item ">
                    <div
                      onClick={(e) => {
                        props.logout();
                        props.history.push("/");
                      }}
                      
                    >
                      Cancel
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openMenu && (
        <MenuComp
          {...props}
          handleMenuClick={handleMenuClick}
          userData={userData}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          handleClose={handleCloseMenu}
          auth={props.state.isUserAuthenticated}
        />
      )}
      {openProfile && (
        <div className="new-submenu">
          <div
            className="new-dropdown-item"
            onClick={() => {
              updateProf(false);
              // <Navigate to="/dashboard" />;
              props.history.push("/dashboard");
            }}
          >
            Dashboard
          </div>

          <div
            className="new-dropdown-item"
            onClick={() => {
              updateProf(false);
              props.logout();
              props.history.push("/");
            }}
          >
            Logout
          </div>
        </div>
      )}
      <ManageDialog
        {...props}
        open={openDialog}
        data={activeItem}
        handleClose={closeDialog}
      />
    </div>
  );
}
