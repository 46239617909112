export const statusRegType = [
  { id: 0, title: "Pending", className: "pending" },
  { id: 1, title: "Submitted", className: "inprogress" },
  { id: 2, title: "Accepted", className: "Accepted" },
  { id: 3, title: "SA Reverted", className: "inprogress" },
  { id: 4, title: "Rejected", className: "rejected" },
  { id: 5, title: "Reverted", className: "inprogress" },
  { id: 6, title: "Agreement Execution", className: "agrementexecution" },
];
export const createdFromAdmin = [
  { id: 0, title: "User", className: "pending" },
  { id: 1, title: "Admin", className: "inprogress" },
];
