import React from "react";
import "./launch.css";

function Launch() {
  const handlenav = () => {
    window.open("https://kspace.kerala.gov.in/", "_blank");
  };
  return (
    <>
      <div className="launchheader">
        <img src="resources/img/kg.png" alt="#" className="launchmain-logo" />
        <img src="resources/img/logo.png" alt="" className="launchmain-logo" />
      </div>
      <div className="launchmain-hero-wrapper">
        <div
          className="launchhero-area"
          style={{
            backgroundImage: ` linear-gradient(0deg, rgba(12, 12, 12, 0.5), rgba(18, 17, 17, 0.5)), url(${"/resources/img/banner.jpg"})`,
          }}
        ></div>
      </div>
      <div className="launchcontent-area mt-4">
        <div className="mb-5">
          <h1 className="text-white">KERALA SPACEPARK</h1>
          <h1 className="text-white">WEBSITE LAUNCH</h1>
        </div>
        <div className="mb-5">
          <img src="resources/img/cm.jpg" alt="" className="launchcm-img" />
        </div>
        <div className="mb-5">
          <h2>By Honorable Chief Minister</h2>
          <h2 className="text-danger">Sri. Pinarayi Vijayan</h2>
        </div>
        <div className="mb-5">
          <button onClick={handlenav}>Launch</button>
        </div>
      </div>
      <div className="launchfooter">
        <img src="resources/img/new.png" alt="#" className="launchfooter-logo" />
        <img src="resources/img/itd3.png" alt="" className="launchfooter-logo" />
      </div>
    </>
  );
}

export default Launch;
