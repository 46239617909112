import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import { EmailValidate } from "../../../../../components/formValidate";
import TextEditer from "../../../../../components/inputs/texteditor";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { is_email_registered } from "../../../user.api";
let reuired = [
  "company_name",
  "person_name",
  "address",
  "email",
  "abstract_project_proposal",
  "ex_man_power",
  "capital_investment",
  "upload_proposal",
];
let flag = false;
export default function BasicDetails(props) {
  console.log(props);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const [formData, setFormData] = useState(props.formData);
  const [keyvalue, setKeyvalue] = useState(0);
  const [count, setCount] = useState(1);
  const [error, setError] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  // useEffect(() => {
  //   setFormData(props.data);
  // }, [props.data]);
  console.log(formData);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  const textFieldChange = (e) => {
    
    
    if (e.target.name === "email" || e.target.name === "alternate_email") {
      let erroremail = !EmailValidate(e.target.value);
      
console.log(erroremail)
      setError({ ...error, [e.target.name]: erroremail });
    }
    // if (e.target.name === "product_desc" && e.taget.value === '<p><br></p>' ) {
    //   console.log("hi")
    //   setSnackMessage("Please fill all reuired fields");
    //   setIsSnackError(false);
    //   setSnackOpen(true);

    // }
    let name = e.target.name;

    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);
    setKeyvalue(keyvalue + 1);
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };

  const addNewProductProfile = (i) => {
    let data = { ...formData };

    data.product_profiles.push({ product_name: "", product_desc: "" });
    setFormData(data);
    setCount(count + 1);
  };
  const RemoveProduct = (i) => {
    let data = { ...formData };
    data.product_profiles.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleMultiChange = (e, i) => {
    let data = { ...formData };

    data.product_profiles[i] = {
      ...data.product_profiles[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  const handleNextSubmit = (e) => {
    e.preventDefault();
    let emailData = { email: formData.email };
    if(emailRegex.test(formData?.email) === true && (emailRegex.test(formData?.alternate_email)  === true ||!formData?.alternate_email||formData?.alternate_email === "" )){
    if (!props.update )
      is_email_registered(emailData)
        .then((res) => {
          if (
            formData.upload_proposal !== null &&
            formData.upload_proposal !== undefined &&
            formData.upload_proposal !== ""
          ) {
            let productFlag = false;
            productFlag = formData?.product_profiles?.some(
              (pr) => pr?.product_desc === ""
            );
            if (!productFlag) {
              props.action(0, formData);
            } else {
              setSnackOpen(true);
              setSnackMessage("Please fill the product description");
              setIsSnackError(true);
            }
          } else {
            setSnackOpen(true);
            setSnackMessage("Please choose a valid file");
            setIsSnackError(true);
          }
        })
        .catch((error) => {
          setSnackMessage(error.response.data.message);
          setIsSnackError(true);
          setSnackOpen(true);
          // setIsActive(false);
        });
    else {
      if (
        formData.upload_proposal !== null &&
        formData.upload_proposal !== undefined &&
        formData.upload_proposal !== "" 
      ) {
        props.action(0, formData);
      } else {
        setSnackOpen(true);
        setSnackMessage("Please choose a valid file");
        setIsSnackError(true);
      }
    } } else {
      setSnackOpen(true);
      setSnackMessage("Please enter a valid email");
      setIsSnackError(true);
    }
    // const errors = FormValidate(formData, reuired);
    // if (Object.keys(errors).length === 0) {

    // } else {
    //   setSnackMessage("Please fill all reuired fields");
    //   setIsSnackError(false);
    //   setSnackOpen(true);
    // }
  };

  const handleCheckChange = (e, item) => {
    flag = false;
    let data = [...props.domainData];

    data[item]["checked"] = e.target.checked;
    //setFormData({ ...formData, any_other_flag: false });
    if (props.domainData) {
      props.domainData.forEach((item) => {
        if (item.checked) flag = true;
      });
    }
    props.setDomain(data);
  };
  const handleValid = () => {
    let flags = false;
    if (props.domainData) {
      props.domainData.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const handleOtherChange = (e) => {
    // let data = [...props.domainData];
    // data.forEach((item) => {
    //   item["checked"] = false;
    // });
    //
    // props.setDomain(data);
    setFormData({ ...formData, [e.target.name]: e.target.checked });
    console.log(e.target.value);
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type?.toLowerCase() === "doc" || type?.toLowerCase() === "docx" || type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
        if (props.update) {
          props.handlefile();
        }
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          file_name: null,
          [e.target.name]: null,
        });
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        file_name: null,
        [e.target.name]: null,
      });
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  useEffect(() => {
    setFormData({
      ...props.formData,
      // any_other_domain:
      //   props.formData.any_other_flag === 1 ||
      //   props.formData.any_other_flag === "1"
      //     ? props.formData.any_other_domain
      //       ? props.formData.any_other_domain
      //       : ""
      //     : "",
    });
    if (!formData.product_profiles) {
      setFormData({
        ...formData,
        product_profiles: [{ product_name: "", product_desc: "" }],
      });
    }
  }, [props.formData]);
  const handleProductDesc = (val, i) => {
    console.log(val);
    console.log(i);
    let data = { ...formData };

    data.product_profiles[i] = {
      ...data.product_profiles[i],

      product_desc: val === "<p><br></p>" ? "" : val,
    };

    setFormData(data);
    setCount(count + 1);
  };
  console.log(props.domainData);
console.log(error)
const handleFieldNumberChange = (e) => {
  let data = { ...formData };
  let name = e.target.name;
  let value = e.target.value;
  let newValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except for the decimal point
  newValue = newValue.match(/^\d{0,3}(?:\.\d{0,2})?$/); // Apply the regex pattern to ensure the format
  if (newValue) {
      data = { ...data, [name]: newValue[0] }; // If the newValue matches the pattern, update the data object
  }
  setFormData(data);
};


const handleEmploymentNumberChange = (e) => {
  let data = { ...formData };
  let name = e.target.name;
  let value = e.target.value;
  let newValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except for the decimal point
  newValue = newValue.match(/^\d{0,4}(?:\.\d{0,2})?$/); // Apply the regex pattern to ensure the format
  if (newValue) {
      data = { ...data, [name]: newValue[0] }; // If the newValue matches the pattern, update the data object
  }
  setFormData(data);
};
  return (
    <div className="">
      <form onSubmit={handleNextSubmit}>
        <div className="">
          <CommonForm
            fieldType="text"
            label="Company Name"
            required
            name="company_name"
            disabled={props.edit}
            value={formData.company_name ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />

          <CommonForm
            fieldType="text"
            label="Contact Person Name"
            required
            name="person_name"
            value={formData.person_name ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
          <CommonForm
            fieldType="text"
            label="Contact Person Designation"
            required
            name="person_designation"
            value={formData.person_designation ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
          <CommonForm
            fieldType="multiline"
            label="Address"
            required
            name="address"
            value={formData.address ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
          <CommonForm
            fieldType="email"
            label="Email"
            error={error.email}
            required
            disabled={props.edit}
            name="email"
            value={formData.email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
          <CommonForm
            fieldType="text"
            label="Website Link"
            
            name="site_link"
            value={formData.site_link ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
          {!props.update && (
            <CommonForm
              fieldType="text"
              label="Mobile Number"
              error={error.mobile_number}
              required
              disabled={true}
              name="mobile_number"
              value={formData.mobile_number ?? ""}
              {...props}
            />
          )}
          <label className="control-label mylabel mb-2">Alternate Phone</label>
          <div className="d-flex col-12 align-items-end ">
            <div className="col-1 ">
              <CommonForm
                fieldType="text"
                error={error.country_code}
                name="country_code"
                maxLength="4"
                value={formData.country_code ?? ""}
                onChange={(e) => {
                  handleNumberChange(e);
                }}
                {...props}
              />
            </div>
            <div className="p-1"></div>
            <div className="col-11">
              <CommonForm
                fieldType="text"
                error={error.alternate_phone}
                name="alternate_phone"
                maxLength="10"
                value={formData.alternate_phone ?? ""}
                onChange={(e) => {
                  handleNumberChange(e);
                }}
                {...props}
              />
            </div>
          </div>
          <CommonForm
            fieldType="email"
            label="Alternative Email"
            error={error.alternate_email}
            name="alternate_email"
            value={
              formData.alternate_email && formData.alternate_email !== "null"
                ? formData.alternate_email
                : ""
            }
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
          <CommonForm
            fieldType="multiline"
            label="Abstract Project Proposal"
            required
            name="abstract_project_proposal"
            value={formData.abstract_project_proposal ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>

        <div>
          {formData.product_profiles?.map((e, i) => (
            <div className="section-datas-cont" key={i + "thprofile"}>
              <div className="header-label">Product Profile</div>

              <div className="section-Cont  pb-1">
                <div className="fieldset">
                  <CommonForm
                    fieldType="text"
                    label="Product Name"
                    required
                    name="product_name"
                    value={e?.product_name}
                    onChange={(e) => {
                      handleMultiChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div key={i}>
                  <TextEditer
                    // fieldType="multiline"
                    label="Product Description (Required)"
                    required
                    name="product_desc"
                    value={e?.product_desc}
                    keyValue={i}
                    key={i + 1}
                    onChange={handleProductDesc}
                    {...props}
                  />
                </div>
              </div>
              <div className="mb-3">
          {/* <div className="file-up-label-cont">Upload Proposal</div> */}

         
        </div>

              <div className="more-btn-cnt">
                {formData.product_profiles.length === i + 1 && (
                  <button
                    style={{ marginLeft: 5 }}
                    type="button"
                    {...props}
                    className="btn btn-primary"
                    onClick={(e) => addNewProductProfile(i)}
                  >
                    Add More
                  </button>
                )}
                {formData.product_profiles.length > 1 && (
                  <button
                    {...props}
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => RemoveProduct(i)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <CommonForm
            fieldType="file"
            required={!formData.file_name ? true : false}
            name="upload_proposal"
            {...props}
            id="upload_proposal"
            fileName={formData.file_name}
            label="Upload Project Proposal"
            accept=".pdf,.docx,.doc"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />

          <div className="file-up-helper mx-lg-1">
            *Upload only .pdf , .docx , .doc and Size should be less than 5MB
          </div>
          {typeof formData.upload_proposal === "string" &&
            formData.upload_proposal !== "" && (
              <button
                type="button"
                className="primary-btn m-0"
                onClick={() => {
                  window.open(`${result}${formData.upload_proposal}`, "_blank");
                }}
              >
                View File
              </button>
            )}
        <div>
          <div className="header-label">Domain Type</div>
          <div className="check-box-cont mb-2">
            {props.domainData &&
              props.domainData.map((item, index) => {
                // console.log(item);
                return (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    required={!formData.any_other_flag && !handleValid()}
                    checked={item.checked}
                    onChange={(e) => handleCheckChange(e, index)}
                    label={item.domain_name}
                    {...props}
                  />
                );
              })}

            <CommonForm
              fieldType="checkbox"
              onChange={handleOtherChange}
              label="Any Other"
              name="any_other_flag"
              checked={formData.any_other_flag}
              value={formData.any_other_flag}
              {...props}
            />
          </div>
          {/* if any other field checked ,below is mandatory */}
          {formData.any_other_flag && formData.any_other_flag !== "0" && (
            <div>
              <CommonForm
                fieldType="text"
                placeholder="Please specify the Domain.."
                required
                name="any_other_domain"
                value={formData.any_other_domain ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          )}
          {/* ////////////////////////////// */}
        </div>
        {/* ///////////////////////////////// */}
        {/* file upload */}
        

        {/* ////////////////////////// */}
        {/* //////// Others///////////// */}
        <div>
          <CommonForm
            fieldType="text"
            label="Capital Investment expected in Crores"
            required
            // maxLength={3}
            name="capital_investment"
            value={formData.capital_investment ?? ""}
            onChange={(e) => {
              handleFieldNumberChange(e);
            }}
            {...props}
          />
          <CommonForm
            fieldType="text"
            label="Initial Direct Employment"
            required
            // maxLength={4}
            
            name="ex_man_power"
            value={formData.ex_man_power ?? ""}
            onChange={(e) => {
              handleEmploymentNumberChange(e);
            }}
            {...props}
          />
        </div>
        {/* ///////////////////////////////////// */}
        {/* ///actions ////////////// */}
        <center>
          <div className="footer-buttons-newreg pix m-2">
            <button type="submit" className="primary-btn basic-submit">
              SUBMIT TO NEXT STEP
            </button>
          </div>
        </center>
      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
