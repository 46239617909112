import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  user_company_details,
  user_institutions_detail,
  user_resource_detail,
  user_expert_detail,
  user_get_pplication,
  user_get_candidate_details,
} from "../../../user.api";
import ReactHtmlParser from "react-html-parser";
import CustomTable from "../../../../../components/table/table";
import moment from "moment";

const DashProfileDetails = (props) => {
  console.log(props);
  const [data, setData] = useState({});
  const [userId, setUserId] = useState(props?.data?.user_id);
  const [id, setId] = useState(props?.data?.id);
  const [userRole, setUserRole] = useState(props?.userType);

  // console.log(props?.data?.userType)
  useEffect(() => {
    if (userRole == 5 || userRole == 6) {
      user_company_details(userId).then((res) => {
        setData(res?.data);
      });
    } else if (userRole == 8) {
      user_institutions_detail(id).then((res) => {
        setData(res?.data);
      });
    } else if (userRole == 9) {
      user_resource_detail(userId).then((res) => {
        setData(res?.data[0]);
      });
    } else if (userRole == 10) {
      user_expert_detail(userId).then((res) => {
        setData(res?.data[0]);
      });
    } else if (userRole == 4 || userRole == 0) {
      user_get_pplication().then((res) => {
        setData(res.data);
      });
    } else if (userRole == 20) {
      user_get_candidate_details(id).then((res) => {
        setData(res.data);
      });
    }
  }, []);

  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const fileDownload = (data) => {
    let url = `${result}/${data}`;
    window.open(url, "_blank");
  };
  const fullfileDownload = (data) => {
    let url = `${data}`;
    window.open(url, "_blank");
  };
  console.log(userRole, data);
  return (
    <>
      <div className="dahContent-header-section">Application Details</div>

      <div className="dash-details-body">
        <div className="dash-datas">
          <div className="dash-items-data">
            {data?.company_name && (
              <div className="row">
                <div className="col-3">Company Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.company_name}</div>
              </div>
            )}
            {data?.native_kerala && data?.native_kerala == 1 && (
              <div className="row">
                <div className="col-3">Are you a Native Keralite?</div>
                <div className="col-1">:</div>
                <div className="col-8">Yes</div>
              </div>
            )}
            {data?.native_kerala && data?.native_kerala == 0 && (
              <div className="row">
                <div className="col-3">Are you a Native Keralite?</div>
                <div className="col-1">:</div>
                <div className="col-8">No</div>
              </div>
            )}
            {data?.native_kerala == 0 && (
              <div className="row">
                <div className="col-3">Are you a NRI Keralite?</div>
                <div className="col-1">:</div>
                <div className="col-8">Yes</div>
              </div>
            )}
            {data?.name && (
              <div className="row">
                <div className="col-3">Name</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.prefix}. {data?.name}
                </div>
              </div>
            )}
            {data?.dob && (
              <div className="row">
                <div className="col-3">Date of Birth</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.dob}</div>
              </div>
            )}
            {data?.category && (
              <div className="row">
                <div className="col-3">Manpower Type</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.category}</div>
              </div>
            )}
            {data?.fresher && (
              <div className="row">
                <div className="col-3">Fresher</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.fresher}</div>
              </div>
            )}
            {data?.full_name && (
              <div className="row">
                <div className="col-3">Full Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.full_name}</div>
              </div>
            )}
            {data?.cin_number && (
              <div className="row">
                <div className="col-3">Registration Number / CIN Number</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.cin_number}</div>
              </div>
            )}
            <>
              {data?.title && (
                <>
                  <div className="row">
                    <div className="col-3">Name</div>
                    <div className="col-1">:</div>
                    <div className="col-8">
                      {data?.title ? data?.title + "." : "Mr."}{" "}
                      {data?.first_name ?? ""} {data?.last_name ?? ""}
                    </div>
                  </div>
                </>
              )}
            </>
            {data?.gender && data?.gender !== "" && userRole != 20 && (
              <div className="row">
                <div className="col-3">Gender</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.gender == 1
                    ? "Male"
                    : data?.gender == 2
                    ? "Female"
                    : "Others"}
                </div>
              </div>
            )}
            {data?.gender && data?.gender !== "" && userRole == 20 && (
              <div className="row">
                <div className="col-3">Gender</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.gender}</div>
              </div>
            )}

            {data?.institution_name && (
              <div className="row">
                <div className="col-3">Institution Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.institution_name}</div>
              </div>
            )}
            {data?.headquaters_detail && (
              <div className="row">
                <div className="col-3">HQ Details</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.headquaters_detail}</div>
              </div>
            )}
            {data?.registration_date && (
              <div className="row">
                <div className="col-3">Registration Date</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.registration_date}</div>
              </div>
            )}
            {data?.affiliation && (
              <div className="row">
                <div className="col-3">
                  Affiliation Details with Affiliation Number
                </div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.affiliation}</div>
              </div>
            )}
            {data?.contact_person_details && (
              <div className="row">
                <div className="col-3">Contact Person Details</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.contact_person_details}</div>
              </div>
            )}
            {data?.file_registration_proof &&
              data?.file_registration_proof !== "" && (
                <div className="row">
                  <div className="col-3">Registration Proof</div>
                  <div className="col-1">:</div>
                  <div className="col-8">
                    <button
                      onClick={() => {
                        fileDownload(data?.file_registration_proof);
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              )}

            {data?.total_students && (
              <div className="row">
                <div className="col-3">Total Students</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.total_students}</div>
              </div>
            )}
            {data?.person_name && (
              <div className="row">
                <div className="col-3">Contact Person Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.person_name}</div>
              </div>
            )}
            {data?.date_of_birth && (
              <div className="row">
                <div className="col-3">Date of Birth</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.date_of_birth
                    ? moment(data?.date_of_birth, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )
                    : ""}
                </div>
              </div>
            )}
            {data?.person_designation && (
              <div className="row">
                <div className="col-3">Contact Person Designation</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.person_designation}</div>
              </div>
            )}
            {data?.address && (
              <div className="row">
                <div className="col-3">Address</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.address}</div>
              </div>
            )}
            {data?.email && (
              <div className="row">
                <div className="col-3">Email</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.email}</div>
              </div>
            )}
            {data?.marital_status && (
              <div className="row">
                <div className="col-3">Marital Status</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.marital_status}</div>
              </div>
            )}
            {data?.parent_name && (
              <div className="row">
                <div className="col-3">Parent / Spouse</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.parent_name}</div>
              </div>
            )}
            {data?.caste && (
              <div className="row">
                <div className="col-3">Category</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.caste}</div>
              </div>
            )}
            {data?.handicapped && (
              <div className="row">
                <div className="col-3">Physiclly Challenged</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.handicapped}</div>
              </div>
            )}
            {data?.site_link && (
              <div className="row">
                <div className="col-3">Website Link</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.site_link}</div>
              </div>
            )}
            {data?.phone_no && (
              <div className="row">
                <div className="col-3">Phone no</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.phone_no}</div>
              </div>
            )}
            {data?.contact && (
              <div className="row">
                <div className="col-3">Phone no</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.contact}</div>
              </div>
            )}
            {data?.alt_contact && (
              <div className="row">
                <div className="col-3">Alternate Phone no</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.alt_contact}</div>
              </div>
            )}
            {data?.alt_email && (
              <div className="row">
                <div className="col-3">Alternate Email</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.alt_email}</div>
              </div>
            )}
            {data?.pin_code && (
              <div className="row">
                <div className="col-3">Pin Code</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.pin_code}</div>
              </div>
            )}
            {data?.blood_group && (
              <div className="row">
                <div className="col-3">Blood Group</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.blood_group}</div>
              </div>
            )}
            {data?.identity_type && (
              <div className="row">
                <div className="col-3">Identity Type</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.identity_type}</div>
              </div>
            )}
            {data?.identity_no && (
              <div className="row">
                <div className="col-3">Identity No</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.identity_no}</div>
              </div>
            )}
            {data?.photo && (
              <div className="row">
                <div className="col-3">Photo</div>
                <div className="col-1">:</div>
                <div
                  className="col-8 ovr text-warning"
                  onClick={() => fullfileDownload(data?.photo)}
                >
                  Download
                </div>
              </div>
            )}
            {data?.identity_file && (
              <div className="row">
                <div className="col-3">Identity Number</div>
                <div className="col-1">:</div>
                <div
                  className="col-8 ovr text-warning"
                  onClick={() => fullfileDownload(data?.identity_file)}
                >
                  Download
                </div>
              </div>
            )}
            {data?.qualification_type && (
              <div className="row">
                <div className="col-3">Highest Qualification</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.qualification_type}</div>
              </div>
            )}
            {data?.qualification && (
              <div className="row">
                <div className="col-3">Qualification</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.qualification}</div>
              </div>
            )}
            {data?.alternate_phone && (
              <div className="row">
                <div className="col-3">Alternate Phone</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">
                  {data?.country_code &&
                    data?.country_code !== "null" &&
                    data?.country_code !== "undefined" &&
                    data?.alternate_phone &&
                    data?.country_code !== "" && <>{data?.country_code} - </>}

                  {data?.alternate_phone ?? ""}
                </div>
              </div>
            )}
            {data?.alternate_email && (
              <div className="row">
                <div className="col-3">Alternative Email</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.alternate_email}</div>
              </div>
            )}
            {data?.abstract_project_proposal &&
              (userRole === 0 || userRole === 4) && (
                <div className="row">
                  <div className="col-3">Abstract Project Proposal</div>
                  <div className="col-1">:</div>
                  <div className="col-8">{data?.abstract_project_proposal}</div>
                </div>
              )}
            {data?.abstract_project_proposal && userRole === 5 && (
              <div className="row">
                <div className="col-3">Abstract Company Profile</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.abstract_project_proposal}</div>
              </div>
            )}
            {data?.product_profiles && data?.product_profiles.length > 0 && (
              <div className="row">
                <div className="col-3">Product Profile</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.product_profiles.map((item) => (
                    <>
                      <div className="col-12 mb-1">
                        <b>{item.product_name ?? ""} </b>{" "}
                        {ReactHtmlParser(item.product_desc)}
                      </div>
                      {/* <div className="col-12 mb-3 ">
                          <div>{ReactHtmlParser(item.product_desc)}</div>
                        </div> */}
                    </>
                  ))}
                </div>
              </div>
            )}
            {data?.courses && data?.courses.length > 0 && (
              <div className="row">
                <div className="col-3">Courses Offered</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.courses.map((item) => (
                    <>
                      <div className="col-12 mb-1">
                        <b>{item.course_type ?? ""} </b> :{" "}
                        {item.course_name ?? ""}{" "}
                      </div>
                      {/* <div className="col-12 mb-3 ">
                          <div>{ReactHtmlParser(item.product_desc)}</div>
                        </div> */}
                    </>
                  ))}
                </div>
              </div>
            )}
            {data?.domain_types && data?.domain_types?.length > 0 && (
              <div className="row">
                <div className="col-3">Domain Type</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.domain_types && (
                    <>
                      {data?.domain_types.map((dom, i) => (
                        <>
                          <div>{dom.domain_name}</div>
                        </>
                      ))}
                    </>
                  )}
                  {(data?.any_other_flag === 1 ||
                    (data?.any_other_flag &&
                      data?.any_other_domain !== "null")) && (
                    <div>{data?.any_other_domain}</div>
                  )}
                </div>
              </div>
            )}
            {/* {data?.area_of_expertise && data?.domain_types?.length > 0 && (
              <div className="row">
                <div className="col-3">Area of Expertise</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.area_of_expertise && (
                    <>
                      {data?.area_of_expertise.map((dom, i) => (
                        <>
                          {i === 0 ? (
                            <>{dom.expertise_name} </>
                          ) : (
                            <> |&nbsp; {dom.expertise_name} </>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )} */}
            {data?.languages && data?.languages?.length > 0 && (
              <div className="row">
                <div className="col-3">Languages Known</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.languages && (
                    <>
                      {data?.languages?.map((dom, i) => (
                        <>
                          {i !== 0 && <> |&nbsp; </>}
                          {dom.language_name}
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}
            {data?.area_of_expertise && data?.area_of_expertise?.length > 0 && (
              <div className="row">
                <div className="col-3">Area of Expertise</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.area_of_expertise && (
                    <>
                      {data?.area_of_expertise?.map((dom, i) => (
                        <>
                          {i === 0 ? (
                            <>{dom.expertise_name} </>
                          ) : (
                            <> |&nbsp; {dom.expertise_name} </>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}
            {data?.interest_type && (
              <div className="row">
                <div className="col-3">Area of Interest</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.interest_type}</div>
              </div>
            )}
            {data?.interest && (
              <div className="row">
                <div className="col-3">Interests</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data && data?.interest && (
                    <>
                      {JSON.parse(data?.interest)?.map((item, index) => (
                        <div>{item}</div>
                      ))}
                      {data?.any_other_domain && (
                        <div>{data?.any_other_domain}</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {data?.education &&
              data?.education?.length > 0 &&
              userRole !== 20 && (
                <div className="row">
                  <div className="col-3">Educational Qualifications</div>
                  <div className="col-1">:</div>
                  <div className="col-8 overflow-auto">
                    <CustomTable
                      className="areatable"
                      {...props}
                      columns={[
                        {
                          title: "No",
                          field: "area_management_id",
                          type: "dynId",
                        },
                        {
                          title: "Examination Passed",
                          field: "examination_passed",
                        },

                        {
                          title: "Board / University",
                          field: "board_of_university",
                        },
                        {
                          title: "Year of Pass",
                          field: "year_of_pass",
                        },
                        { title: "Marks / Grade", field: "mark_grade" },
                       
                      ]}
                      data={data?.education ?? []}
                    />
                  </div>
                </div>
              )}
            {data?.education &&
              data?.education?.length > 0 &&
              userRole === 20 && (
                <div className="row">
                  <div className="col-3">Educational Qualifications</div>
                  <div className="col-1">:</div>
                  <div className="col-8 overflow-auto">
                    <CustomTable
                      className="areatable"
                      {...props}
                      columns={[
                        {
                          title: " Board/University",
                          field: "university",
                        },
                        { title: "Location", field: "location" },
                        { title: "Year of Passing", field: "year" },
                        { title: "Grade", field: "grade" },
                        { title: "GPA", field: "gpa" },
                        { title: "Percentage", field: "percentage" },
                      ]}
                      data={data?.education ?? []}
                    />
                  </div>
                </div>
              )}
            {data?.employment && data?.employment?.length > 0 && (
              <>
                <div className="row">
                  <div className="col-3">Experience </div>
                  <div className="col-1">:</div>
                  <div className="col-8 overflow-auto">
                    <>
                      {data?.employment?.map((item, index) => (
                        <div className="white">
                          <div className="d-flex">
                            <div className="col-3">Employer </div>
                            <div className="col-1">:</div>
                            <div className="col-8">{item?.employer}</div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Contact </div>
                            <div className="col-1">:</div>
                            <div className="col-8">{`${item?.contact}`}</div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Email</div>
                            <div className="col-1">:</div>
                            <div className="col-8">{item?.email}</div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Position</div>

                            <div className="col-1">:</div>
                            <div className="col-8">{item?.position}</div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Currently Working</div>

                            <div className="col-1">:</div>
                            <div className="col-8">{item?.working ?? ""}</div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Period</div>
                            <div className="col-1">:</div>
                            <div className="col-8">
                              {item?.from} - {item?.to ?? ""}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Reason for Leaving</div>
                            <div className="col-1">:</div>{" "}
                            <div className="col-8">{item?.reason ?? ""}</div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Address</div>
                            <div className="col-1">:</div>
                            <div className="col-8">{item?.address}</div>
                          </div>
                          <div className="d-flex">
                            <div className="col-3">Certificate</div>
                            <div className="col-1">:</div>
                            <div className="col-8">
                              <div
                                className="text-warning"
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  fullfileDownload(item?.exp_certificate)
                                }
                              >
                                Download File
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </>
            )}

            {data?.reference_name1 && (
              <>
                <div className="row">
                  <div className="col-3">Reference 1 </div>
                  <div className="col-1">:</div>
                  <div className="col-8 overflow-auto">
                    <>
                      <div className="white">
                        <div className="d-flex">
                          <div className="col-3">Name </div>
                          <div className="col-1">:</div>
                          <div className="col-8">{data.reference_name1}</div>
                        </div>
                        <div className="d-flex">
                          <div className="col-3">Designation </div>
                          <div className="col-1">:</div>
                          <div className="col-8">
                            {data.reference_designation1}
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-3">Contact </div>
                          <div className="col-1">:</div>
                          <div className="col-8">{data.reference_contact1}</div>
                        </div>
                        <div className="d-flex">
                          <div className="col-3">Email </div>
                          <div className="col-1">:</div>
                          <div className="col-8">{data.reference_email1}</div>
                        </div>

                        <hr />
                      </div>
                    </>
                  </div>
                </div>
              </>
            )}

            {data?.reference_name2 && (
              <>
                <div className="row">
                  <div className="col-3">Reference 2 </div>
                  <div className="col-1">:</div>
                  <div className="col-8 overflow-auto">
                    <>
                      <div className="white">
                        <div className="d-flex">
                          <div className="col-3">Name </div>
                          <div className="col-1">:</div>
                          <div className="col-8">{data.reference_name2}</div>
                        </div>
                        <div className="d-flex">
                          <div className="col-3">Designation </div>
                          <div className="col-1">:</div>
                          <div className="col-8">
                            {data.reference_designation2}
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-3">Contact </div>
                          <div className="col-1">:</div>
                          <div className="col-8">{data.reference_contact2}</div>
                        </div>
                        <div className="d-flex">
                          <div className="col-3">Email </div>
                          <div className="col-1">:</div>
                          <div className="col-8">{data.reference_email2}</div>
                        </div>

                        <hr />
                      </div>
                    </>
                  </div>
                </div>
              </>
            )}

            {data?.capital_investment && (userRole === 0 || userRole === 4) && (
              <div className="row">
                <div className="col-3">
                  Capital Investment Expected in Crores
                </div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.capital_investment ?? ""}</div>
              </div>
            )}
            {data?.capital_investment && userRole === 5 && (
              <div className="row">
                <div className="col-3">Capital Investment so far Crores</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.capital_investment ?? ""}</div>
              </div>
            )}
            {data?.previous_fy_turnover && (
              <div className="row">
                <div className="col-3">Previous FY turnover Rs. in Crores</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.previous_fy_turnover ?? ""}</div>
              </div>
            )}
            {data?.fe_component && (
              <div className="row">
                <div className="col-3">FE Component </div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.fe_component ?? ""}</div>
              </div>
            )}
            {data?.travell_interest && (
              <div className="row">
                <div className="col-3">
                  Willingness to travel within India / abroad
                </div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.travell_interest == 0 ? "No" : "Yes"}
                </div>
              </div>
            )}
            {data?.desc_of_current_work && (
              <div className="row">
                <div className="col-3">Current work details</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.desc_of_current_work ?? "--"}
                </div>
              </div>
            )}
            {data?.ex_man_power && (
              <div className="row">
                <div className="col-3"> Initial Direct Employment</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.ex_man_power ?? ""}</div>
              </div>
            )}
            {data?.dir_employ_male && (
              <div className="row">
                <div className="col-3"> Male Employees</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.dir_employ_male ?? ""}</div>
              </div>
            )}
            {data?.dir_employ_female && (
              <div className="row">
                <div className="col-3"> Female Employees</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.dir_employ_female ?? ""}</div>
              </div>
            )}
            {data?.dir_employ_others && (
              <div className="row">
                <div className="col-3"> Genderqueer Employees</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.dir_employ_others ?? ""}</div>
              </div>
            )}
            {data?.dir_employ_sc_st && (
              <div className="row">
                <div className="col-3"> SC / ST Employees</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.dir_employ_sc_st ?? ""}</div>
              </div>
            )}
            {data?.certification && (
              <div className="row">
                <div className="col-3"> Certifications</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.certification ?? ""}</div>
              </div>
            )}
            {data?.achievements && (
              <div className="row">
                <div className="col-3"> Achievments</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.achievements ?? ""}</div>
              </div>
            )}
            {data?.file_name && (
              <div className="row">
                <div className="col-3">Document Uploaded</div>
                <div className="col-1">:</div>
                <div className="col-8 ">{data?.file_name}</div>
              </div>
            )}
            {data?.file_name && userRole == 8 && (
              <div className="row">
                <div className="col-3">Institutional Activities</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  <button
                    onClick={() => {
                      fileDownload(data?.file_name);
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            )}
            {data?.notes_institutions && (
              <div className="row">
                <div className="col-3">Institution Activities</div>
                <div className="col-1">:</div>
                <div className="col-8 ">{data?.notes_institutions}</div>
              </div>
            )}
          </div>
          {data?.area_management && data?.area_management?.length > 0 && (
            <div>
              <div className="dahContent-header-section">Area Requirements</div>
            </div>
          )}

          <div className="dash-items-datas">
            {data?.area_type === "built_up_space" && (
              <div className="row">
                <div className="col-3">Built-up space on Rent per Month</div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      { title: "Location", field: "location" },
                      { title: "Area in sq.ft", field: "area_in_sqft" },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
            {data?.area_type === "equipped_facility" && (
              <div className="row">
                <div className="col-3">Equipped Facility per Month </div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      { title: "Location", field: "location" },
                      { title: "Area in sq.ft", field: "area_in_sqft" },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
            {data?.area_type === "land_on" && (
              <div className="row">
                <div className="col-3">Land on 33 years Lease </div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      { title: "Location", field: "location" },
                      { title: "Area  in Acres", field: "area_in_acres" },
                      { title: "Years", field: "lease_year" },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
            {data?.area_type === "joint_venture" && (
              <div className="row">
                <div className="col-3">Joint Venture</div>
                <div className="col-1">:</div>
                <div className="col-8 venure">
                  {data?.area_management.map((item, i) => (
                    <div
                      className={`row venture ${
                        i !== data?.area_management.length - 1
                          ? "joint-view-border"
                          : ""
                      }`}
                    >
                      <div className="col-12 mb-1">
                        {/* <label>Location&nbsp;:&nbsp;</label> */}
                        <b>{item.location}</b>
                      </div>
                      <div className="col-12 mb-1">
                        <label>
                          <b>
                            {" "}
                            Area&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                          </b>
                        </label>
                        {item.area_in_sqft}
                      </div>
                      <div className="col-12 mb-1 text-justify">
                        <label>
                          <b>Area Type</b>&nbsp;:&nbsp;
                        </label>
                        {item.area}
                      </div>
                      <div className="col-12 mb-1 text-justify">
                        <label>
                          <b>Investment Model</b>&nbsp;:&nbsp;
                        </label>
                        {item.investment_model}
                      </div>
                      <div className="col-12 mb-2 pb-1 text-justify">
                        <label>
                          <b>Management Plan</b>&nbsp;:&nbsp;
                        </label>
                        {item.management_plan}
                      </div>
                      <div className="col-12 mb-2 pb-1 text-justify">
                        <label>
                          <b>Address</b>&nbsp;:&nbsp;
                        </label>
                        {item.land_address}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {data?.area_type === "own_land" && (
              <div className="row">
                <div className="col-3">Own Premises </div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      { title: "Location", field: "location" },
                      { title: "Available Area", field: "area_in_sqft" },
                      // { title: "Land Address", field: "land_address" },
                      { title: "Survey Number", field: "survey_no" },
                      { title: "Block Number", field: "block_no" },
                      { title: "Village Name", field: "village_name" },
                      // { title: "Available Area", field: "area" },
                      // { title: "Available Area", field: "area" },
                      {
                        title: "Nature Of Holding",
                        field: "nature_of_holding",
                      },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="goto-my-profile">
          <div
            className="dashusermyprof"
            onClick={(e) => props.handleMenus("application")}
          >
            My Profile
          </div>
        </div>
      </div>
    </>
  );
};

export default DashProfileDetails;
