import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  update_tech_proposal_status,
  get_tech_proposal_details,
} from "../../admin.api";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import "./view.css";
import TextField from "../../../../components/inputs/textfield";
import jwtDecode from "jwt-decode";
import moment from "moment";
export default function View(props) {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const getDetails = () => {
    setIsActive(true);
    get_tech_proposal_details(selectedParam.id)
      .then((res) => res.data)
      .then((res) => {
        setData(res);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadProposal = () => {
    let path = `${result}${data.file_upload_proposal}`;
    window.open(path, "_blank");
  };
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const handleAdminActions = (status) => {
    let data = {};
    if (message !== null && message !== undefined && message !== "") {
      data = {
        status: status,
        message: message,
      };
    } else {
      data = { status_id: status, proposal_id: selectedParam.id };
    }

    setIsActive(true);
    update_tech_proposal_status(adminType, selectedParam.id, data)
      .then((res) => {
        setSnackMessage("Action completed successfully");
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/transfer-proposal-list");
    }
  };
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Technology Transfer Proposal Details</b>
          </h6>
          {/*
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Proposal Title</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.project_proposal_title}</div>
          </div>
         
          <div className="row">
            <div className="col-3">Details of Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.short_details_proposal}</div>
          </div>

          <div className="row">
            <div className="col-3"> Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {/* <a href="#">
                <b>Download</b>
              </a> */}
              <button
                type="button"
                className="btn btn-reject btn-dark btn-sm"
                onClick={(e) => handleDownloadProposal(e)}
              >
                Download Proposal
              </button>
            </div>
          </div>
          {data?.created_at &&
          <div className="row">
            <div className="col-3">Created At</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.created_at ? moment(data?.created_at).format("DD-MM-YYYY") : ""}</div>
          </div>}

          {adminType === 1 && (
            <>
              {data.status == 0 && (
                <>
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      type="button"
                      className="btn btn-approve btn-success mr-2"
                      onClick={(e) => handleAdminActions(1)}
                    >
                      Submit for Acceptance
                    </button>
                  </div>
                </>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              <>
                {data.status == 1 && (
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      type="button"
                      className="btn btn-approve btn-success mr-2"
                      onClick={(e) => handleAdminActions(2)}
                    >
                      Approve
                    </button>
                    <br />
                    <button
                      type="button"
                      className="btn btn-reject btn-danger ml-3"
                      onClick={(e) => handleAdminActions(5)}
                    >
                      Reject
                    </button>
                  </div>
                )}
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
