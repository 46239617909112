import React, { useEffect, useState } from "react";
import { Basepath } from "../../../../utilities/reducers/basepath";
import { public_scope_list } from "../../public.api";
import { Card } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";
export default function ScopeAct(props) {
  const [data, setdata] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const handleEventList = () => {
    setIsActive(true);
    public_scope_list().then((res) => {
      setdata(res.data);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  let basepath = Basepath();
  let lang = props.state.language;
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8];
  console.log(data);
  return (
    <div className="scope-container">
      <div className="scope-title">
        {props.state.language === "ma"
          ? "പ്രവർത്തനങ്ങളുടെ വ്യാപ്തി"
          : "Scope of Activities"}
      </div>
      <div className="scope-items-cont">
        <div className="scope-left-image">
          <img src="/resources/img/scopeban1.png" className="scopeCover" />
        </div>
        <div className="scope-text-box-con text-">
          {isActive ? (
            <>
              {numbers.map((item, index) => (
                <>
                  {index % 2 === 0 && (
                    <div className="scope-items">
                      <div className="">
                        {" "}
                        <img
                          className="scope-icons-new"
                          src={`/resources/img/scopes/${index + 1}.png`}
                          alt=""
                        />
                      </div>
                      <div className="scope-text-box ">
                        <Placeholder
                          as={Card.Text}
                          animation="glow"
                          style={{
                            color: "#009FE3",
                          }}
                        >
                          {/* <Placeholder xs={7} /> <Placeholder xs={4} />{" "} */}
                          <Placeholder xs={12} /> <Placeholder xs={12} />{" "}
                          <Placeholder xs={12} />
                        </Placeholder>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {data.map((item, index) => (
                <>
                  {index % 2 === 0 && (
                    <div className="scope-items">
                      <div className="">
                        {" "}
                        <img
                          className="scope-icons-new"
                          src={`/resources/img/scopes/${index + 1}.png`}
                          alt=""
                        />
                      </div>
                      <div className="scope-text-box text-start">
                        {item[lang]?.Title}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="scope-items-cont scope-right-direction">
        <div className="scope-right-image">
          <img src="/resources/img/scopeban2.png" className="scopeCover" />
        </div>
        <div className="scope-left-text-box-con">
          {isActive ? (
            <>
              {numbers.map((item, index) => (
                <>
                  {index % 2 === 1 && (
                    <div className="scope-items">
                      <div className="">
                        {" "}
                        <img
                          className="scope-icons-new"
                          src={`/resources/img/scopes/${index + 1}.png`}
                          alt=""
                        />
                      </div>
                      <div className="scope-text-box ">
                        <Placeholder
                          as={Card.Text}
                          animation="glow"
                          style={{
                            color: "#009FE3",
                          }}
                        >
                          <Placeholder xs={12} /> <Placeholder xs={12} />{" "}
                          <Placeholder xs={12} />
                        </Placeholder>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {data.map((item, index) => (
                <>
                  {index % 2 === 1 && (
                    <div className="scope-items">
                      <div className="">
                        <img
                          className="scope-icons-new"
                          src={`/resources/img/scopes/${index + 1}.png`}
                          alt=""
                        />
                      </div>
                      <div className="scope-text-box text-start">
                        {item[lang]?.Title}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
