import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../../components/table/table";
import ResourseCard from "../../../../components/page/tiles/resourcecard";
import {
  candidate_download,
  interview_candidate_details,
  job_candidate_details,
  pdf_download,
  user_get_candidate_details,
  user_get_downloaded_candidate_details,
  user_get_manpower_details,
} from "../../user.api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../style.css";
import BreadCrumbsComp from "../../../../components/navigator/breadcrumbs";
import Loader from "../../../../components/loader/loader";
import { useReactToPrint } from "react-to-print";
let count = 100;
export default function ResourcePersonalDetails(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [print, setPrint] = useState(false);
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const pageRef = useRef(null);
  const getDetails = (id) => {
    user_get_downloaded_candidate_details(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadIdCert = (data) => {
    let path = `${data}`;
    window.open(path, "_blank");
  };
  // const handleCompanyDownload = () => {
  //   const payload = {
  //     candidate_id: selectedParam.id,
  //   };
  //   candidate_download(payload).then(() => {});
  // };

  // const handleDownload = async () => {
  //   // handleCompanyDownload();
  //   const element = pageRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const imgProps = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width ;

  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("download.pdf");
  // };
  // const handleDownload = useReactToPrint({
  //   content: () => pageRef.current,
  //   documentTitle: "Downloaded Candidate Details",
  //   // onAfterPrint: () => alert("Print success!"),
  // });
  const handleDownload = () => {
    pdf_download(selectedParam.id).then((res) => {
      // console.log(res)
      window.open(res.pdf, "_blank");
    });
  }
  const handleInterview = (id) => {
    setIsActive(true);
    interview_candidate_details(id).then((res) => {
      getDetails(selectedParam.id);
      setIsActive(false);
    });
  };
  const handleJob = (id) => {
    setIsActive(true);
    job_candidate_details(id).then((res) => {
      getDetails(selectedParam.id);
      setIsActive(false);
    });
  };
  return (
    <div className="tender bg-tender">
      {/* <Loader open={isActive} /> */}
      <BreadCrumbsComp section="downloaded_candidates_details" />
      <div className="container p-50">
        <div className="res-page-cont-details">
          <div className="res-det-con " ref={pageRef}>
            <div className="row tender-head ">
              <h4> Downloaded Candidate Details </h4>
              <div>
                <div className="container-ul">
                  <hr className="top hr" />
                  <hr className="bottom hr" />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-xs-12 ">
                <div className="profile-box ">
                  <div className="res-bannerContainer d-flex ">
                    <div className="res-ban-image">
                      {/* <img
                        src="/resources/img/userdashbanner.png"
                        className="w-100 res-ban-img"
                        alt=""
                      /> */}
                    </div>
                    <div className="resbandata py-4 mt-2">
                      <div className="d-flex justify-content-center w-100">
                      {!data?.candidate_details?.photo ?
                        <div className="res-name">{data?.candidate_details?.name?.charAt(0)}</div>
:
                        <div>
                          <img
                            src={`${data?.candidate_details?.photo}`}
                            className="res-profile-img"
                            alt=""
                          />
                        </div>}
                        <div className="profile-sres-con ">
                          <div className="res-person-name">
                            {data?.candidate_details?.prefix
                              ? data?.candidate_details?.prefix + "."
                              : "Mr."}{" "}
                            {data?.candidate_details?.name ?? ""}
                          </div>
                          <div className="qualification-container">
                            <div className="res-exp mb-1">Qualification :</div>
                            <div className="exp-values ">
                              {data?.candidate_details?.qualification ?? ""}
                              {`(${data?.candidate_details?.qualification_type})`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="res-about-sec">
                    <div className="res-abo-head">About</div>
                    <hr />
                    <div className="res-about-cont">
                      <div className="row">
                        <div className="col-4">Name</div>
                        <div className="col-1">:</div>
                        <div className="col-7 font-bold">
                          {" "}
                          <b>
                            {data?.candidate_details?.prefix
                              ? data?.candidate_details?.prefix + "."
                              : "Mr."}{" "}
                            {data?.candidate_details?.name ?? ""}{" "}
                          </b>
                        </div>
                        <div className="col-4">Native of Kerala</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {" "}
                          {data?.candidate_details?.native_kerala == 1
                            ? "Yes"
                            : "No" ?? ""}
                        </div>
                        {data?.candidate_details?.native_kerala == 0 && (
                          <>
                            <div className="col-4">NRI Keralite</div>
                            <div className="col-1">:</div>
                            <div className="col-7"> Yes</div>{" "}
                          </>
                        )}
<div className="col-4 ">Fresher</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {" "}                        
                          {data?.candidate_details?.fresher ?? ""}{" "}
                        </div>
                        <div className="col-4">Date of Birth</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.dob}
                        </div>

                        <div className="col-4">Gender</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.gender}
                        </div>

                        <div className="col-4">Address</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.address}
                        </div>

                        <div className="col-4">Email</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.email}
                        </div>

                        <div className="col-4">Marital Status</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.marital_status}
                        </div>

                        <div className="col-4">Father / Spouse Name</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.parent_name}
                        </div>

                        <div className="col-4">Category</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.caste}
                        </div>

                        <div className="col-4">Physiclly Challenged</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.handicapped}
                        </div>

                        <div className="col-4">Phone Number</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.contact}
                        </div>

                        <div className="col-4">Alternate Email</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.alt_email}
                        </div>

                        <div className="col-4">Alternate Phone</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                        {data?.candidate_details?.alt_code ? "+" : ""}{data?.candidate_details?.alt_code} {data?.candidate_details?.alt_contact}
                        </div>

                        <div className="col-4">Pin Code</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.pin_code}
                        </div>

                        <div className="col-4">Blood Group</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.blood_group}
                        </div>
                        <div className="col-4">Identity File Type</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.identity_type}
                        </div>

                        <div className="col-4">Identity Number</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.identity_no}
                        </div>
                        {/* 
                        <div className="col-4">
                          Identity Number
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.identity_no}
                        </div> */}

                        <div className="col-4">Identity File Copy</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          <div
                            className="text-warning"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              handleDownloadIdCert(
                                data?.candidate_details?.identity_file
                              )
                            }
                          >
                            Download File
                          </div>
                        </div>

                        <div className="col-4">Highest Qualification</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.qualification_type}
                        </div>

                        <div className="col-4">Qualification</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.qualification}
                        </div>

                        {/* <div className="col-4">
                          Area of Interest
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.area_of_interest && (
                            <>
                              {data?.candidate_details?.area_of_interest?.map((item, index) => (
                                <>
                                  {index !== 0 && <>&nbsp;|&nbsp;</>}
                                  {item.expertise_name}
                                </>
                              ))}
                            </>
                          )}
                        </div> */}

                        <div className="row-padding">
                          <div className="col-12 max-bold">
                            Educational Qualification
                          </div>

                          <div className="col-12  overflow-auto">
                            {" "}
                            <CustomTable
                              className="areatable"
                              {...props}
                              columns={[
                                {
                                  title: " Board/University",
                                  field: "university",
                                },
                                { title: "Location", field: "location" },
                                { title: "Year of Passing", field: "year" },
                                { title: "Grade", field: "grade" },
                                { title: "GPA", field: "gpa" },
                                { title: "Percentage", field: "percentage" },
                              ]}
                              data={data?.candidate_details?.education ?? []}
                            />
                          </div>
                        </div>
                        <div className="row-padding">
                          <div className="col-12  max-bold">
                            Experience Details
                          </div>
                          <hr />
                          {data &&
                            data?.candidate_details?.employment &&
                            data?.candidate_details?.employment?.length !==
                              0 && (
                              <>
                                {data?.candidate_details?.employment?.map(
                                  (item, index) => (
                                    <div className="white">
                                      <div className="d-flex">
                                        <div className="col-4">Employer </div>
                                        <div className="col-1">:</div>
                                        <div className="col-7">
                                          {item.employer}
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="col-4">Contact : </div>
                                        <div className="col-1">:</div>
                                        <div className="col-7">{`${item.contact}`}</div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="col-4">Email</div>
                                        <div className="col-1">:</div>
                                        <div className="col-7">
                                          {item.email}
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="col-4">Position</div>

                                        <div className="col-1">:</div>
                                        <div className="col-7">
                                          {item.position}
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="col-4">Currently Working</div>

                                        <div className="col-1">:</div>
                                        <div className="col-7">
                                          {item.working == "Yes" ? "Yes" : "No"}
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="col-4">Period</div>
                                        <div className="col-1">:</div>
                                        <div className="col-7">
                                          {item.from} {item?.working == "No" && <>- {item?.to}</>}
                                        </div>
                                      </div>
                                      {item?.working == 'No' &&
                                      <div className="d-flex">
                                        <div className="col-4">
                                          Reason for Leaving
                                        </div>
                                        <div className="col-1">:</div>{" "}
                                        <div className="col-7">
                                          {item.reason}
                                        </div>
                                      </div>}
                                      <div className="d-flex">
                                        <div className="col-4">Address</div>
                                        <div className="col-1">:</div>
                                        <div className="col-7">
                                          {item.address}
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="col-4">Certificate</div>
                                        <div className="col-1">:</div>
                                        <div className="col-7">
                                          <div
                                            className="text-warning"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              handleDownloadIdCert(
                                                item.exp_certificate
                                              )
                                            }
                                          >
                                            Download File
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  )
                                )}
                              </>
                            )}
                        </div>

                        <div className="">
                          <div className="col-12  max-bold">Reference 1</div>
                          <hr />

                          <>
                            <div className="white">
                              <div className="d-flex">
                                <div className="col-4">Name </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.candidate_details?.reference_name1}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Designation </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {
                                    data.candidate_details
                                      ?.reference_designation1
                                  }
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Contact </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.candidate_details?.reference_contact1}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Email </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.candidate_details?.reference_email1}
                                </div>
                              </div>

                              <hr />
                            </div>
                          </>
                        </div>

                        <div className="">
                          <div className="col-12  max-bold">Reference 2</div>
                          <hr />

                          <>
                            <div className="white">
                              <div className="d-flex">
                                <div className="col-4">Name </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.candidate_details?.reference_name2}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Designation </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {
                                    data.candidate_details
                                      ?.reference_designation2
                                  }
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Contact </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.candidate_details?.reference_contact2}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Email </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.candidate_details?.reference_email2}
                                </div>
                              </div>

                              <hr />
                            </div>
                          </>
                        </div>

                        <div className="col-4">Area of Interest</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.interest_type}
                        </div>

                        <div className="col-4">Interests</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {data &&
                            data?.candidate_details?.interest &&
                            data?.candidate_details?.interest.length !== 0 && (
                              <>
                                {JSON.parse(
                                  data?.candidate_details?.interest
                                )?.map((item, index) => (
                                  <div>{item}</div>
                                ))}
                                {data?.candidate_details?.any_other_domain && (
                                  <div>
                                    {data?.candidate_details?.any_other_domain}
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data?.downloaded_profile_status == 1 && (
                <div>Contact admin to make payment</div>
              )}
            </div>
            <div className="d-flex mt-3 justify-content-center">
              <button
                type="button"
                className="btn btn-approve btn-success m"
                onClick={(e) => handleDownload()}
              >
                Download
              </button>
              {data?.downloaded_profile_status == 0 && (
                <button
                  type="button"
                  className="btn btn-approve btn-success m"
                  onClick={(e) => handleInterview(data?.downloaded_profile_id)}
                >
                  Schedule Interview
                </button>
              )}
              {data?.downloaded_profile_status == 2 && (
                <button
                  type="button"
                  className="btn btn-approve btn-success m"
                  onClick={(e) => handleJob(data?.downloaded_profile_id)}
                >
                  Offer Job
                </button>
              )}
              {/* {data?.downloaded_profile_status == 1 && (
                <button
                  type="button"
                  className="btn btn-approve btn-success m"
                  // onClick={(e) => handleJob()}
                  disabled
                >
                  Contact admin to make payment
                </button>
              )} */}
            </div>
            {/* pdf download */}
          </div>
        </div>
      </div>
    </div>
  );
}
