import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/table/table";
import "./view.css";
import { get_resourse_details, update_resourse_status } from "../admin.api";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import moment from "moment";
export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const handleEdit = () => {};
  const handleDownload = (item) => {
    let path = `${result}${item.file_upload_certificate}`;
    window.open(path, "_blank");
  };
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const selectedParam = useParams();
  const getDetails = (id) => {
    get_resourse_details(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadBiodata = () => {
    let path = `${result}${data.biodata}`;
    window.open(path, "_blank");
  };
  const handleDownloadExpCert = (data) => {
    let path = `${result}${data}`;
    window.open(path, "_blank");
  };

  const saMessage = (status) => {
    Swal.fire({
      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let rpdetails = data[0]?.id;
        console.log(rpdetails);
        let rdata = {};
        if (adminType === 1) {
          if (status === 1) {
            rdata = {
              status_id: status,
              rp_id: rpdetails,
              admin_message: message.value,
            };
          } else {
            rdata = {
              status_id: status,
              rp_id: rpdetails,
              user_message: message.value,
            };
          }
        } else {
          rdata = {
            status_id: status,
            rp_id: rpdetails,
            super_admin_message: message.value,
          };
        }
        setIsActive(true);
        console.log(data);
        update_resourse_status(rdata)
          .then((res) => {
            setSnackMessage(res.message);
            setIsSnackError(false);
            setSnackOpen(true);
            setIsActive(false);
          })
          .catch((error) => {
            setSnackMessage(error.message);
            setIsSnackError(true);
            setSnackOpen(true);
            setIsActive(false);
          });
      }
    });
  };
  console.log(selectedParam.id);
  const handleAdminActions = (status) => {
    let rpdetails = data[0]?.id;
    let rdata = { status_id: status, rp_id: rpdetails };
    setIsActive(true);
    update_resourse_status(rdata)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/resource-list");
    }
  };
  const arrayToString = (data) => {
    // var str= '["YES","NO"]';
    console.log(data);
    var replace = data.replace(/[["'\]]/g, " ");
    console.log(replace);
    var array = replace.split(",");
    console.log(array);
    return array;
  };
  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Resource Personnel Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Full Name</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data[0]?.title ? data[0]?.title + "." : "Mr."}{" "}
              {data[0]?.first_name ?? ""} {data[0]?.last_name ?? ""}
            </div>
          </div>
          
          <div className="row">
            <div className="col-3">Date of Birth</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data[0]?.date_of_birth
                ? moment(data[0]?.date_of_birth, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                  )
                : ""}
              ,
            </div>
          </div>
          <div className="row">
            <div className="col-3">Gender</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data[0]?.gender === "1"
                ? "Male"
                : data[0]?.gender === "2"
                ? "Female"
                : data[0]?.gender === "3"
                ? "Other"
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Phone Number</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.phone_no}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternate Phone</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data[0]?.alternate_phone &&
                data[0]?.country_code &&
                data[0]?.country_code !== "" &&
                data[0]?.country_code !== "null" &&
                data[0]?.country_code !== "undefined" && (
                  <>
                    {data[0]?.country_code ?? ""}{" "}
                    {data[0]?.country_code && data[0]?.country_code !== ""
                      ? "-"
                      : ""}{" "}
                  </>
                )}
              {data[0]?.alternate_phone ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Languages Known</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data[0]?.languages.map((item, index) => (
                <span>
                  {index !== 0 && " | "}
                  {item.language_name}
                </span>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-3">Domain Types</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {/* {data?.expert_manpower?.domain_types
                ? arrayToString(data?.expert_manpower?.domain_types)
                : "N/A"} */}
              {data[0]?.domain_types.map((item, index) => (
                <div>{item.domain_name}</div>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-3">Area of Expertise:</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data[0]?.area_of_expertise &&
                data[0]?.area_of_expertise.length !== 0 && (
                  <>
                    {data[0]?.area_of_expertise?.map((item, index) => (
                      <span>
                        {index !== 0 && " | "}
                        {item.expertise_name}
                      </span>
                    ))}
                  </>
                )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Any other area of interest</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data.area_of_interest &&
                data?.area_of_interest.length !== 0 && (
                  <>
                    {data?.area_of_interest?.map((item, index) => (
                      <span>
                        {index !== 0 && " | "}
                        {item.area_of_interest_name}
                      </span>
                    ))}
                  </>
                )}
            </div>
          </div> */}

          <div className="row">
            <div className="col-3">Educational Qualification</div>
            <div className="col-1">:</div>
            <div className="col-7  mt-00">
              <CustomTable
                {...props}
                columns={[
                  { title: " Examination passed", field: "examination_passed" },
                  { title: "Board/University", field: "board_of_university" },
                  { title: "Year of Passing", field: "year_of_pass" },
                  { title: "Mark/grade", field: "mark_grade" },
                  {
                    title: "Certificate",
                    field: "file_upload_certificate",
                    type: "download",
                  },
                ]}
                data={data[0]?.education ?? []}
                handleDownload={handleDownload}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-3">Experience Details</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data[0]?.experience &&
                data[0]?.experience.length !== 0 && (
                  <>
                    {data[0]?.experience?.map((item, index) => (
                      <div className="white">
                        <div>
                          <b>Organization with Address</b>
                          <p>{item.organization_name}</p>
                        </div>
                        <div>
                          <b>Experience</b>
                          <p>{`${item.experience} Years`}</p>
                        </div>
                        <div>
                          <b>Nature of Work</b>
                          <p>{item.nature_of_work}</p>
                        </div>
                        <div>
                          <b>Designation</b>
                          <p>{item.designation}</p>
                        </div>
                        <div>
                          <b>Reason for Leaving</b>
                          <p>{item.reason_for_leaving}</p>
                        </div>
                        <div>
                          <b>Certificate</b>
                          <p>
                            <button
                              className="btn btn-approve btn-success"
                              onClick={(e) =>
                                handleDownloadExpCert(
                                  item.experience_certificate
                                )
                              }
                            >
                              Download File
                            </button>
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Are you currently attached / committed to any Organization ? Give
              details
            </div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.desc_of_current_work}</div>
          </div>
          <div className="row">
            <div className="col-3">
              Willingness to travel within India / abroad
            </div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data[0]?.travell_interest == 1 ? "Yes" : "No"}
            </div>
          </div>
          <div className="row">
            <div className="col-3"> Photo</div>
            <div className="col-1">:</div>
            <div className="col-7 ">
              <img
                className="res_photo"
                src={`${result}${data[0]?.file_upload}`}
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">Created At</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.created_at ? moment(data[0]?.created_at).format("DD-MM-YYYY") : ""}</div>
          </div>
          {data[0]?.admin_message && data[0]?.admin_message !== "" && (
            <div className="row">
              <div className="col-3">Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data[0]?.admin_message}</div>
            </div>
          )}
          {data[0]?.super_admin_message &&
            data[0]?.super_admin_message !== "" && (
              <div className="row">
                <div className="col-3">Super Admin Message</div>
                <div className="col-1">:</div>
                <div className="col-7">{data[0].super_admin_message}</div>
              </div>
            )}

          {adminType === 1 && (
            <>
              {(data[0]?.approval_status === 0 ||
                data[0]?.approval_status === 3) && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(1)}
                  >
                    Submit for Acceptance
                  </button>
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(5)}
                  >
                    Revert
                  </button>

                  {/* <button type="button" className="btn btn-reject btn-danger">
                    Reject
                  </button> */}
                </div>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {data[0]?.approval_status === 1 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    onClick={(e) => handleAdminActions(2)}
                    className="btn btn-approve btn-success mr-2"
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    onClick={(e) => saMessage(3)}
                    className="btn btn-approve btn-warning mr-2"
                  >
                    Revert
                  </button>
                  <br />
                  <button
                    type="button"
                    onClick={(e) => handleAdminActions(4)}
                    className="btn btn-reject  btn-danger "
                  >
                    Reject
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
