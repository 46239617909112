import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import CommonForm from "../../../../../components/commonform/commonForm";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import CustomTable from "../../../../../components/table/table";
import { user_area_types_list, user_locations_list } from "../../../user.api";
import JointVentureTable from "./optionTable/joint-venture";
import FormOptionTable from "./optionTable/optionTable";
import OwnLand from "./optionTable/ownLand";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useParams } from "react-router-dom";
let area_management = [{ id: "1" }, { id: "2" }, { id: "3" }];

export default function AreaDetails(props) {
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState(props.areamange);
  const [count, setCount] = useState(1);
  const [locLease, setLocLease] = useState([]);
  const [baseArea, setBaseArea] = useState({});
  const [defaultkey, setdefaultkey] = useState(props.defaultkey);
  const [builtUpTypes, setbuiltUpTypes] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const premise = useParams().id;
  console.log(premise);
  const getspaceAreaTypes = () => {
    setIsActive(true);
    user_area_types_list().then((res) => {
      setBaseArea(res.data);
      setIsActive(false);
    });
  };
  const getlocation = () => {
    setIsActive(true);
    user_locations_list().then((res) => {
      setLocLease(res.data);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getspaceAreaTypes();
    getlocation();
  }, []);

  const handleCheck = (e, id) => {
    setdefaultkey(id);
    setFormData({
      ...formData,
      area_type: e.target.name,
      // area_management: props.areamange?.area_management,
    });
  };

  const handleAccord = (e, name) => {
    if (!props.update) {
      setdefaultkey(e);
      // if (!props.update) {
      props.handleChangedata(e, name);
      // }
      //
      setTimeout(() => {
        setFormData({
          ...formData,
          area_type: name,
          // area_management: props.areamange?.area_management,
        });
      }, 100);
    }
  };
  const handleFormData = (data) => {
    setFormData({ ...formData, area_management: data });
    // setTimeout(() => {
    //   setCount(count + 1);
    // }, 150);
  };
  const handleChangedata = (name) => {
    let data = {
      location_id: "",
      location: "",
      area_in_sqft: "",
      // area_management_id: 1,
    };
    let land_on = {
      area_in_acres: "",
      location_id: "",
      location: "",
      lease_year: "",
      // area_management_id: 2,
    };
    let joint = {
      location_id: "",
      location: "",
      area_in_sqft: "",
      investment_model: "",
      management_plan: "",
      area: "",
      land_address: "",
      // area_management_id: 3,
    };
    let own = {
      location_id: "",
      location: "",
      land_address: "",
      nature_of_holding: "",
      area_in_sqft: "",
      // area_management_id: 4,
    };
    if (name === "built_up_space") {
      return data;
    } else if (name === "equipped_facility") {
      return data;
    } else if (name === "land_on") {
      return land_on;
    } else if (name === "joint_venture") {
      return joint;
    } else if (name === "own_land") {
      return own;
    } else {
      return data;
    }
  };
  const handleValidate = () => {
    // let flag = false;

    // if (formData.area_management) {
    //   formData.area_management?.forEach((item) => {
    //     handleChangedata(formData.area_type)?.forEach((res) => {
    //       Object.keys(res).forEach((sub) => {
    //         if (!item[sub] && [sub] !== "id") {
    //           flag = true;
    //         }
    //       });
    //     });
    //   });
    //   return flag;
    // }
    console.log(formData);
    if (formData.area_management && formData.area_management !== "") {
      for (let i = 0; i < formData?.area_management?.length; ) {
        let flag = false;
        console.log("1");
        Object.keys(handleChangedata(formData.area_type)).forEach((sub) => {
          // console.log(formData?.area_management[i][sub], sub);
          if (!formData?.area_management[i][sub] && [sub] !== "id") {
            if (
              (!formData?.area_management[0]["location"] ||
                formData?.area_management[0]["location"] == "") &&
              (!formData?.area_management[2]["location"] ||
                formData?.area_management[2]["location"] == "") &&
              (!formData?.area_management[1]["location"] ||
                formData?.area_management[1]["location"] == "")
            ) {
              flag = true;
            }
            console.log("2");
          } else {
            if (
              sub === "location" &&
              formData?.area_management[i]["location"] !== ""
            ) {
              console.log("3");
              if (
                formData.area_type === "built_up_space" ||
                formData.area_type === "equipped_facility"
              ) {
                console.log("4");
                console.log(formData?.area_management[i]["area_in_sqft"]);
                if (formData?.area_management[i]["area_in_sqft"] === "") {
                  flag = true;
                  console.log("5");
                }
              }
              if (formData.area_type === "land_on") {
                console.log("6");
                console.log(formData?.area_management[i]["area_in_acres"]);
                if (formData?.area_management[i]["area_in_acres"] === "") {
                  flag = true;
                  console.log("7");
                }
              }
              if (formData.area_type === "joint_venture") {
                console.log("8");
                console.log(formData?.area_management[i]["area_in_sqft"]);
                if (
                  formData?.area_management[i]["area_in_sqft"] === "" ||
                  !formData?.area_management[i]["area_in_sqft"] ||
                  formData?.area_management[i]["investment_model"] === "" ||
                  formData?.area_management[i]["management_plan"] === "" ||
                  formData?.area_management[i]["area"] === "" ||
                  !formData?.area_management[i]["area"] ||
                  formData?.area_management[i]["land_address"] === "" ||
                  !formData?.area_management[i]["land_address"]
                ) {
                  flag = true;
                  console.log("9");
                }
              }
              if (formData.area_type === "own_land") {
                console.log("10");
                console.log(formData?.area_management[i]["area_in_sqft"]);
                if (
                  formData?.area_management[i]["area_in_sqft"] === "" ||
                  formData?.area_management[i]["nature_of_holding"] === "" ||
                  // formData?.area_management[i]["land_address"] === ""
                  formData?.area_management[i]["survey_no"] === "" ||
                  formData?.area_management[i]["block_no"] === "" ||
                  formData?.area_management[i]["village_name"] === ""
                ) {
                  flag = true;
                  console.log("11");
                }
              }
            }
          }
        });

        if (flag) {
          return flag;
        } else {
          i++;
        }
      }
    }
  };
  const handlepreview = (e) => {
    let error = handleValidate();
    // console.log(error);
    if (!error) {
      props.action(2, formData, defaultkey);
    } else {
      setSnackMessage("Please fill all mandatory fields");
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  function CustomToggle({ children, onClick }) {
    return (
      <button
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          background: "#cdecf5",
          cursor: "pointer",
          padding: "10px",
          border: "1px solid white",
          color: "white",
        }}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
  // console.log(formData?.area_type);
  return (
    <div className="">
      <Loader open={isActive} />
      {!props?.update && (
        <div className="header-label">Select your required space</div>
      )}
      <div>
        <Accordion defaultActiveKey={defaultkey}>
          {(premise === "kspace" ||
            props?.basics?.area_type == "built_up_space") && (
            <Accordion.Item eventKey="0">
              <CustomToggle
                onClick={(e) => handleAccord("0", "built_up_space")}
              >
                <div className="optionLabelHeader-cont">
                  <CommonForm
                    fieldType="radio"
                    disabled={props.update}
                    className="to-left-side"
                    name="built_up_space"
                    value={formData.area_type === "built_up_space" ?? false}
                    checked={formData.area_type === "built_up_space" ?? false}
                    onChange={(e) => handleCheck(e, "0")}
                    label="Built-up space on Rent per Month"
                    {...props}
                  />
                </div>
              </CustomToggle>
              <Accordion.Collapse eventKey={defaultkey}>
                <Accordion.Body className="area-accordin-body">
                  <div className="table-list-cont">
                    <div className="tableHeading">Available Space</div>
                    <div className="header-body-spacing">
                      <CustomTable
                        columns={[
                          {
                            title: "No",
                            field: "id",
                            type: "dynId",
                          },
                          {
                            title: "Location",
                            field: "location",
                          },
                          {
                            title: "Area in sq.ft",
                            field: "area_in_sqft",
                          },
                          {
                            title: " Rs/sq.ft",
                            field: "rupees_in_sqft",
                          },
                        ]}
                        data={baseArea?.built_up ?? []}
                      />
                    </div>

                    <div className="header-body-spacing">
                      <FormOptionTable
                        key={count}
                        disabled={props.update}
                        data={baseArea?.built_up}
                        formData={formData}
                        handleFormData={handleFormData}
                        formvalues={formData.area_management}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
          {/* ////////////////////////////////// */}

          {/* ///////////Equiped facility */}
          {/* {(premise === "kspace" ||
            props?.basics?.area_type == "equipped_facility") && (
            <Accordion.Item eventKey="1">
              <CustomToggle
                onClick={(e) => handleAccord("1", "equipped_facility")}
              >
                <div className="optionLabelHeader-cont">
                  <CommonForm
                    fieldType="radio"
                    disabled={props.update}
                    className="to-left-side"
                    label="Equipped Facility  per Month"
                    name="equipped_facility"
                    value={formData.area_type === "equipped_facility" ?? false}
                    checked={
                      formData.area_type === "equipped_facility" ?? false
                    }
                    onChange={(e) => handleCheck(e, "1")}
                    {...props}
                  />
                </div>
              </CustomToggle>
              <Accordion.Collapse eventKey={defaultkey}>
                <Accordion.Body>
                  <div className="table-list-cont">
                    <div className="tableHeading">Available Space</div>
                    <div className="container scrolltable">
                      <div className="header-body-spacing">
                        <CustomTable
                          columns={[
                            {
                              title: "No",
                              type: "dynId",
                              field: "id",
                            },
                            {
                              title: "Location",
                              field: "location",
                            },
                            {
                              title: "Facility",
                              field: "facility",
                            },
                            {
                              title: "Area in sq.ft",
                              field: "area_in_sqft",
                            },
                            {
                              title: " Rs/sq.ft",
                              field: "rupees_in_sqft",
                            },
                          ]}
                          data={baseArea?.equipped_facility ?? []}
                        />
                      </div>
                    </div>
                    <div className="header-body-spacing">
                      <FormOptionTable
                        data={baseArea?.equipped_facility}
                        formData={formData}
                        key={count}
                        disabled={props.update}
                        handleFormData={handleFormData}
                        formvalues={formData.area_management}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Collapse>
            </Accordion.Item>
          )} */}
          {/* ///////////////////// */}
          {/* ////Lease/////////////////// */}
          {(premise === "kspace" || props?.basics?.area_type == "land_on") && (
            <Accordion.Item eventKey="2">
              <CustomToggle onClick={(e) => handleAccord("2", "land_on")}>
                <div className="optionLabelHeader-cont">
                  <CommonForm
                    fieldType="radio"
                    className="to-left-side"
                    label="Land on Lease"
                    name="land_on"
                    disabled={props.update}
                    value={formData.area_type === "land_on" ?? false}
                    checked={formData.area_type === "land_on" ?? false}
                    onChange={(e) => handleCheck(e, "2")}
                    {...props}
                  />
                </div>
              </CustomToggle>
              <Accordion.Collapse eventKey={defaultkey}>
                <Accordion.Body>
                  <div className="table-list-cont">
                    <div className="tableHeading">Available Space</div>
                    <div className="header-body-spacing">
                      <CustomTable
                        columns={[
                          {
                            title: "No",
                            type: "dynId",
                            field: "id",
                          },
                          {
                            title: "Location",
                            field: "location",
                          },
                          {
                            title: "Area in acres",
                            field: "area_in_acres",
                          },
                          {
                            title: " Rs lakhs/acres",
                            field: "rupees_in_lakhs_or_acres",
                          },
                        ]}
                        data={baseArea?.land_on ?? []}
                      />
                    </div>
                    <div className="header-body-spacing">
                      <FormOptionTable
                        data={baseArea?.land_on ?? []}
                        key={count}
                        disabled={props.update}
                        formData={formData}
                        handleFormData={handleFormData}
                        formvalues={formData?.area_management ?? []}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
          {/* //////////////////////////// */}
          {/* ////////////Joint Venture//////////// */}
          {(premise === "kspace" ||
            props?.basics?.area_type == "joint_venture") && (
            <Accordion.Item eventKey="3">
              <CustomToggle onClick={(e) => handleAccord("3", "joint_venture")}>
                <div className="optionLabelHeader-cont">
                  <CommonForm
                    fieldType="radio"
                    className="to-left-side"
                    label="Joint Venture"
                    name="joint_venture"
                    disabled={props.update}
                    value={formData.area_type === "joint_venture" ?? false}
                    checked={formData.area_type === "joint_venture" ?? false}
                    onChange={(e) => handleCheck(e, "3")}
                    {...props}
                  />
                </div>
              </CustomToggle>
              <Accordion.Collapse eventKey={defaultkey}>
                <Accordion.Body>
                  <JointVentureTable
                    {...props}
                    data={locLease}
                    key={count}
                    disabled={props.update}
                    formData={formData}
                    handleFormData={handleFormData}
                    formvalues={formData.area_management}
                  />
                </Accordion.Body>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
          {/* ////////////////////////////////////////////// */}
          {/* ////////////Own Land//////////// */}
          {(premise === "own" || props?.basics?.area_type == "own_land") && (
            <Accordion.Item eventKey="4">
              <CustomToggle onClick={(e) => handleAccord("4", "own_land")}>
                <div className="optionLabelHeader-cont">
                  <CommonForm
                    fieldType="radio"
                    className="to-left-side"
                    label="Own Premises"
                    disabled={props.update}
                    name="own_land"
                    value={formData.area_type === "own_land" ?? false}
                    checked={formData.area_type === "own_land" ?? false}
                    onChange={(e) => handleCheck(e, "4")}
                    {...props}
                  />
                </div>
              </CustomToggle>
              <Accordion.Collapse eventKey={defaultkey}>
                <Accordion.Body>
                  <OwnLand
                    {...props}
                    data={locLease}
                    key={count}
                    disabled={props.update}
                    formData={formData}
                    handleFormData={handleFormData}
                    formvalues={formData.area_management}
                  />
                </Accordion.Body>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
          {/* ////////////////////////////////////////////// */}
        </Accordion>
      </div>
      <div className="footer-buttons-newreg">
        <div
          className="secondary-btn"
          onClick={(e) => {
            props.action(1, formData);
          }}
        >
          GO BACK
        </div>
        <button onClick={handlepreview} className="primary-btn">
          SUBMIT TO PREVIEW
        </button>
      </div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
