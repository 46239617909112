import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../../components/commonform/commonForm";
import Snackbar from "../../../../../../components/snackbar/snackbar";
import { EmailValidate } from "../../../../../../components/formValidate";
// import { user_area_types_list, user_locations_list } from "../../../user.api";

let area_management = [{ id: "1" }, { id: "2" }, { id: "3" }];
export default function AreaDetails(props) {
  const [formData, setFormData] = useState(props.data);
  const [count, setCount] = useState(1);

  const [defaultkey, setdefaultkey] = useState(props.defaultkey);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    if (!formData.students) {
      setFormData({
        ...formData,
        students: [
          {
            branch: "",
            student_name: "",
            semester: "",
            mark: "",
            upload_proposal: null,
          },
        ],
      });
    }
  }, [formData.students, formData]);

  const handlepreview = (e) => {
    e.preventDefault();
    let flag = true;
    if (
      formData.file_upload_proposal !== null &&
      formData.file_upload_proposal !== undefined &&
      formData.file_upload_proposal !== ""
    ) {
      formData.students.forEach((item) => {
        if (
          item.photo === null ||
          item.photo === undefined ||
          item.photo === ""
        ) {
          flag = false;
          setSnackOpen(true);
          setSnackMessage(
            `Please choose a valid photo for ${item.student_name}`
          );
          setIsSnackError(true);
        }
        if (error.project_guide1_email === true || error.project_guide2_email === true) {
          flag = false;
          setSnackOpen(true);
          setSnackMessage(`Please enter a valid email`);
          setIsSnackError(true);
        }
      });
      if (flag) {
        props.action(0, formData);
      }
    } else {
      setSnackOpen(true);
      setSnackMessage("Please choose a valid proposal file");
      setIsSnackError(true);
    }

    // props.action(0, formData, defaultkey);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    data.students[i] = {
      ...data.students[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  // ========================

  // =============================
  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.students.push({
      branch: "",
      student_name: "",
      semester: "",
      mark: "",
      upload_proposal: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.students.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let data = { ...formData };
    if (type === "png" || type === "jpeg" || type === "jpg") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        data.students[i] = {
          ...data.students[i],
          [e.target.name]: e.target.files[0],
          // "photoimage":e.target.files[0],
          file_name: e.target.files[0].name,
        };
        setFormData(data);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        data.students[i] = {
          ...data.students[i],
          [e.target.name]: null,
          file_name: null,
        };
        setFormData(data);
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png  format");
      setIsSnackError(true);
      setSnackOpen(true);
      data.students[i] = {
        ...data.students[i],
        [e.target.name]: null,
        file_name: null,
      };
      setFormData(data);
    }
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    console.log(type, size);
    if (
      type?.toLowerCase() === "pdf" ||
      type?.toLowerCase() === "doc" ||
      type?.toLowerCase() === "docx"
    ) {
      let mb = size / 1024;
      console.log(mb);
      if (mb < 5024) {
        setFormData({
          ...formData,
          file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          file_name: null,
          [e.target.name]: null,
        });
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        file_name: null,
        [e.target.name]: null,
      });
    }
  };
  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.students[i] = {
      ...data.students[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const textFieldChange = (e) => {
    if (
      e.target.name === "project_guide1_email" ||
      e.target.name === "project_guide2_email"
    ) {
      let erroremail = !EmailValidate(e.target.value);
      console.log(erroremail);
      setError({ ...error, [e.target.name]: erroremail });
      console.log(error)
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  console.log(formData);
  return (
    <>
      <form onSubmit={handlepreview}>
        <div className="expert">
          <div>
            <div>
              <div className="col-xs-12 col-sm-12">
                <CommonForm
                  fieldType="text"
                  label="Proposal Title"
                  required
                  name="project_title"
                  value={formData.project_title ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>
              {/* <div className="col-xs-12 col-sm-12">
                <CommonForm
                  fieldType="email"
                  label="Email"
                  required
                  name="email"
                  value={formData.email ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div> */}
              <div className="row">
                <div className="col-xs-12 col-sm-12 ">
                  <div className="control-label mylabel">Project Guide</div>
                  <div className="col-xs-12 col-sm-12">
                    <CommonForm
                      fieldType="text"
                      placeholder="Project Guide 1"
                      required
                      className="no-margin"
                      name="project_guide1"
                      value={formData.project_guide1 ?? ""}
                      onChange={(e) => {
                        textFieldChange(e);
                      }}
                      {...props}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12">
                    <CommonForm
                      fieldType="text"
                      placeholder="Phone number of Project Guide 1"
                      required
                      className="no-margin"
                      name="project_guide1_phone"
                      maxLength="10"
                      value={formData.project_guide1_phone ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      {...props}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12">
                    <CommonForm
                      fieldType="email"
                      placeholder="Email of Project Guide 1"
                      required
                      className="no-margin"
                      name="project_guide1_email"
                      value={formData.project_guide1_email ?? ""}
                      onChange={(e) => {
                        textFieldChange(e);
                      }}
                      {...props}
                    />
                  </div>
                  <br />
                  <div className="col-xs-12 col-sm-12">
                    <CommonForm
                      fieldType="text"
                      placeholder="Project Guide 2"
                      required
                      value={formData.project_guide2 ?? ""}
                      className="no-margin"
                      name="project_guide2"
                      onChange={(e) => {
                        textFieldChange(e);
                      }}
                      {...props}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12">
                    <CommonForm
                      fieldType="text"
                      placeholder="Phone number of Project Guide 2"
                      required
                      className="no-margin"
                      name="project_guide2_phone"
                      maxLength="10"
                      value={formData.project_guide2_phone ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      {...props}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12">
                    <CommonForm
                      fieldType="email"
                      placeholder="Email of Project Guide 2"
                      required
                      className="no-margin"
                      name="project_guide2_email"
                      value={formData.project_guide2_email ?? ""}
                      onChange={(e) => {
                        textFieldChange(e);
                      }}
                      {...props}
                    />
                  </div>
                </div>
              </div>

              <div className="header-label">Add Students</div>
              {formData.students?.map((e, i) => (
                <div className="section-datas-cont mb-3">
                  <div className="section-Cont">
                    <div className="row">
                      <div className="col-xs-12 col-sm-4">
                        <CommonForm
                          fieldType="text"
                          label="Student Name"
                          required
                          name="student_name"
                          value={e?.student_name ?? ""}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-12 col-sm-2">
                        <CommonForm
                          fieldType="text"
                          label="Semester"
                          required
                          name="semester"
                          minlength="1"
                          maxLength="1"
                          value={e?.semester ?? ""}
                          onChange={(e) => {
                            handleEducationalNumberChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-xs-6 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Branch"
                          required
                          name="branch"
                          value={e?.branch ?? ""}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                          {...props}
                        />
                      </div>{" "}
                      <div className="col-xs-6 col-sm-3">
                        <CommonForm
                          fieldType="text"
                          label="Previous Year Mark"
                          required
                          maxLength="4"
                          name="mark"
                          value={e?.mark ?? ""}
                          onChange={(e) => {
                            handleEducationalNumberChange(e, i);
                          }}
                          {...props}
                        />
                      </div>
                      <div className="col-12">
                        <CommonForm
                          fieldType="file"
                          required={!e.file_name ? true : false}
                          fileName={e?.file_name}
                          label="Upload Student Photo"
                          name="photo"
                          {...props}
                          id="upload_proposal"
                          className="no-margin"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          variant="outlined"
                          onChange={(e) => {
                            handleEducationalFileChange(e, i);
                          }}
                          // onChange={handleFileChange}
                        />
                        <div className="file-up-helper">
                          *Upload only .jpg , .jpeg , .png format and File size
                          should be less than size 1MB
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="more-btn-cnt">
                    {formData.students.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addeducationalQualifications(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.students.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => removeducationalQualifications(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 mt-0">
            <CommonForm
              fieldType="text"
              label="Short Details  of Proposal"
              multiline
              required
              value={formData.short_proposal_details ?? ""}
              className="no-margin"
              name="short_proposal_details"
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
          </div>

          <div className="section-datas-cont">
            <div className="col-12">
              <CommonForm
                fieldType="file"
                required={!formData.file_name ? true : false}
                label="Upload  Your Proposal"
                name="file_upload_proposal"
                {...props}
                fileName={formData.file_name}
                id="file_upload_proposal"
                accept=".pdf,.doc,.docx"
                type="file"
                variant="outlined"
                onChange={handleFileChange}
              />
              <div className="file-up-helper">
                *Upload only .pdf,.doc ,docx format and Size should be less than
                5MB
              </div>
            </div>
          </div>

          <div className="footer-buttons">
            <button type="submit" className="primary-btn">
              SUBMIT TO PREVIEW
            </button>
          </div>
          <Snackbar
            message={snackMessage}
            error={isSnackError}
            open={snackOpen}
            close={snackbarClose}
          />
        </div>
      </form>
    </>
  );
}
