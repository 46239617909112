import React, { Component } from "react";
import AboutTileContainer from "../../../../components/page/home/about-tile/about.tile.component";
import { public_about_list } from "../../public.api";
import { Card } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";

let numbers = [1, 2, 3, 4, 5];
class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      desc: [],
      url: "",
      isActive: false,
    };
  }
  componentDidMount = () => {
    this.fetchAboutUs();
  };
  fetchAboutUs = () => {
    this.setState({
      isActive: true,
    });
    public_about_list().then((res) => {
      //
      this.setState({
        data: res.data,
        isActive: false,
        url: res.current_url,
      });
    });
  };
  render() {
    const { data, desc, url, isActive } = this.state;
    let lang = this.props.state.language;

    return (
      <div className="about-new">
        <div className="about-left">
          <div className="about-left-title">
            {" "}
            {this.props.state.language === "ma"
              ? "ഞങ്ങളെക്കുറിച്ച്"
              : "About us"}
          </div>
          <div className="about-left-desc w-100 text-start">
            {isActive ? (
              <div className="w-100">
                <Placeholder as={Card.Text} animation="glow">
                  {/* <Placeholder xs={7} /> <Placeholder xs={4} />{" "} */}
                  <Placeholder xs={12} bg="light" />
                  <Placeholder xs={12} bg="light" />
                  <Placeholder xs={12} bg="light" />
                  <Placeholder xs={12} bg="light" />
                  <Placeholder xs={12} bg="light" />
                  <Placeholder xs={12} bg="light" />
                  <Placeholder xs={12} bg="light" />
                  <Placeholder xs={12} bg="light" />
                </Placeholder>
              </div>
            ) : (
              <>{data[0]?.Description[lang]?.contents}</>
            )}
          </div>
        </div>
        <div className="about-right">
          {data && data.length !== 0 ? (
            <>
              {Object.keys(data[0]).map((key) => (
                <>
                  {key !== "Description" && (
                    <AboutTileContainer
                      {...this.props}
                      section={String(key)}
                      isActive={isActive}
                      data={data && data[0][key]}
                    />
                  )}
                </>
              ))}
            </>
          ) : (
            [1, 2, 3].map((item) => (
              <AboutTileContainer
                {...this.props}
                section={""}
                isActive={isActive}
                data={[]}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

export default About;
