import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../../components/loader/loader";
// import Snackbar from "../../../../../../components/snackbar/snackbar";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { create_accademic_institution } from "../../../user.api";
import {
  builtup,
  euip,
  jointVenture,
  landon,
  ownland,
} from "./components/areadata";

import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";
import moment from "moment";
export default function NewCompanyRequest(props) {
  const [activeStep, setActiveStep] = useState(0);

  const [basicData, setBasicData] = useState({
    phone_number: props.phone,
    course_details: [{ course_name: "", course_type: "Technical" }],
  });

  const [addCount, setCount] = useState(1);
  const myRef = useRef(null);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [areamange, setareaManage] = useState(builtup);
  // const getDomainTypes = () => {
  //   user_company_domain_list().then((res) => {
  //     setDomain(res.data);
  //     setCount(addCount + 1);
  //   });
  // };
  const action = (id, data, key) => {
    myRef.current.scrollIntoView();
    if (id === 0) {
      setActiveStep(1);
      setBasicData({ ...data });
    } else if (id === 1) {
      setActiveStep(0);
    } else if (id === 2) {
      handleSubmit();
    }
  };
  const fetchError = (error) => {
    console.log(error.response.data);
    let errmsg = "";
    let errArry = [];

    if (error.response.data.message) {
      console.log("hi");
      errmsg = error.response.data;
    }
    if (error.response.data.error) {
      Object.keys(error.response.data.error).forEach((key) => {
        let err = String(error.response.data.error[key]);
        errArry.push(err);
      });
      errmsg = String(errArry);
    }

    return errmsg;
  };
  const handleSubmit = () => {
    let areatype = [];
    if (basicData.area_management) {
      basicData?.area_management?.forEach((item, index) => {
        if (item.location_id) {
          areatype[index] = item;
        }
      });
    }
    const form = new FormData();

    form.append("institution_name", basicData.institution_name ?? null);
    form.append("phone_number", basicData.phone_number);
    form.append("email", basicData.email);
    form.append("address", basicData.address ?? null);
    form.append("registration_date", moment().format("DD-MM-YYYY") ?? null);
    form.append("affiliation", basicData?.affiliation ?? null);
    form.append("upload_proposal", basicData.upload_proposal ?? null);
    form.append("total_students", basicData.total_students ?? null);
    form.append("headquaters_detail", basicData.headquaters_detail ?? "");
    if (
      basicData.country_code &&
      basicData.country_code !== "" &&
      basicData.alternate_phone &&
      basicData.alternate_phone !== ""
    ) {
      form.append("country_code", basicData.country_code);
    }
    form.append("alternate_phone", basicData.alternate_phone ?? "");
    form.append(
      "abstract_project_proposal",
      basicData.abstract_project_proposal ?? null
    );
    form.append(
      "contact_person_details",
      basicData.contact_person_details ?? ""
    );
    form.append("notes_institutions", basicData.notes_institutions ?? null);
    form.append(
      "file_submitted_record",
      basicData.file_submitted_record ?? null
    );
    form.append(
      "file_registration_proof",
      basicData.file_registration_proof ?? null
    );

    basicData.course_details.forEach((item, index) => {
      form.append(`course_details[${index}][course_name]`, item.course_name);
    });
    basicData.course_details.forEach((item, index) => {
      form.append(`course_details[${index}][course_type]`, item.course_type);
    });

    setIsActive(true);
    create_accademic_institution(form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(fetchError(error));
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const headlperText = {
    header: "",
  };

  // useEffect(() => {
  //   if (domainData.length === 0) {
  //     getDomainTypes();
  //   }
  //   // handleChangedata("3");
  // }, []);

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.logout();
    }
  };

  return (
    <div className="page-bg" ref={myRef}>
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Academic Institution</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="itemc">
            <div className="fomy form-inner-section">
              {activeStep === 0 && (
                <BasicDetails {...props} action={action} formData={basicData} />
              )}

              {activeStep === 1 && (
                <View {...props} basicData={basicData} action={action} />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
