import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import CustomTable from "../../../components/table/table";
import {
  admin_location_list,
  admin_spacearea_create,
  admin_spacearea_delete,
  admin_spacearea_list,
  admin_spacearea_update,
} from "../admin.api";
import ManageArea from "./manage/manage";
import jwtDecode from "jwt-decode";
import "./view.css";

export default function View(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [locationData, setLocationData] = useState([]);
  const [baseLocationData, setBaseLocationData] = useState([]);
  const [spaceLocationData, setSpaceLocationData] = useState([]);
  const [snackMessage, setSnackMessage] = useState("");
  const [areaType, setAreaType] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [openAction, setOpenAction] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [view, setView] = useState(false);

  const fetchLocation = () => {
    setIsActive(true);
    admin_location_list().then((res) => {
      let tdata = res.data;
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setLocationData(tdata);
      setIsActive(false);
    });
  };

  const fetchSpaceLocation = () => {
    setIsActive(true);
    admin_spacearea_list("land_on").then((res) => {
      setSpaceLocationData(res.data);
      setIsActive(false);
    });
  };
  const onInit = () => {
    fetchLocation();

    fetchSpaceLocation();
  };
  const handleAddNew = (e, value) => {
    setAddNew(true);
    setView(false);
    setAreaType(value);
  };

  const handleClose = (id) => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    if (id) {
      fetchSpaceLocation();
    }
    // handleEventList();
  };

  const handleViewTwo = (data) => {
    setAreaType("landlease");
    setFormData(data);
    setAddNew(true);
    setView(true);
  };

  const handleEditTwo = (data) => {
    setAreaType("landlease");
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    admin_spacearea_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      fetchSpaceLocation();
    }
  };
  const handlesubmit = (id, data) =>
    edit
      ? admin_spacearea_update(id, data).then((res) => res)
      : admin_spacearea_create(data).then((res) => res);
  useEffect(() => {
    onInit();
  }, []);
  const onSearch = () => {
    //
  };

  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Land on Lease</b>
        </h6>
        <hr />
        {adminType === 1 && (
          <button
            type="button"
            className="btn btn-primary btn-sm mt-2"
            onClick={(e) => handleAddNew(e, "landlease")}
          >
            Add New
          </button>
        )}
        <CustomTable
          {...props}
          columns={[
            { title: "Location", field: "locations" },
            { title: "Area in Acres", field: "area_in_acres" },
            { title: "Rupees/lakhs/acres", field: "rupees_in_lakhs_or_acres" },
          ]}
          data={spaceLocationData}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleViewTwo },
                  { type: "edit", handleClick: handleEditTwo },
                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleViewTwo },
                  { type: "edit", handleClick: handleEditTwo },
                ]
          }
        />
      </div>
      {addNew && (
        <ManageArea
          open={addNew}
          edit={edit}
          areaType={areaType}
          view={view}
          areaId={3}
          locationData={locationData}
          handleClose={handleClose}
          submit={handlesubmit}
          formData={formData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete This Area ?"
          data={activeData}
          message="Are you sure to delete this area?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
