import React, { useState, useEffect } from "react";
import { useDate } from "../../../../hooks/useDate";

export default function Footer(props) {
  const [date, setDate] = useState(new Date());

  //Replaces componentDidMount and componentWillUnmount
  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <div className="container-fluid d-flex">
      <div className="copyright ">
        {`Copyright @ ${new Date().getFullYear()} KSPACE , Maintained by `}
        <a
          href="https://inovace.in"
          target="_blank"
          rel="noreferrer"
          className="inovace"
        >
          INOVACE ® DIGITAL
        </a>
      </div>
    </div>
  );
}
