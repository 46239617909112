import React from "react";
import { Modal, Button } from "react-bootstrap";
import CustomIcons from "../../../icons/kIcons.component";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

export default function MoreDetails(props) {
  console.log(props.data);
  const handleNav = (d, a) => {
    if (a === "live") {
      window.open(`https://kspace.inovace.in/${d}`, "_blank");
    } else {
      if (d.startsWith("https://")) {
        window.open(`${d}`, "_blank");
      } else {
        window.open(`https://${d}`, "_blank");
      }
    }
  };
  return (
    <Modal
      {...props}
      show={props.open}
      onHide={(e) => props.handleClose()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.data.company_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="">
            <div className="comp-item-header">
              <div className="comp-item-logo">
                {props.data?.company_name?.substring(0, 1)}
              </div>
              <div className="comp-item-header-data">
                <div className="comp-item-person-name">
                  {props.data.person_name}
                </div>

                <div className="d-flex">
                  <div>
                    <CustomIcons
                      iconName="mail"
                      iconClass="comp-item-data-icons"
                    />
                  </div>
                  <div className="text-break">{props.data.email}</div>
                </div>

                {/* <div className="d-flex">
                  <div>
                    <CustomIcons
                      iconName="phone1"
                      iconClass="comp-item-data-icons"
                    />{" "}
                  </div>
                  <div className="text-break">
                    {" "}
                   {props.data.users[0]?.mobile_number} 
                  </div>
                </div> */}

                {/* <div className="d-flex">
                  <div>
                    {" "}
                    <CustomIcons
                      iconName="building1"
                      iconClass="comp-item-data-icons"
                    />
                  </div>
                  <div className="text-break">
                    {props.data.area_type === "own_land"
                      ? "Own Land"
                      : props.data.area_type === "joint_venture"
                      ? "Joint Venture"
                      : props.data.area_type === "built_up_space"
                      ? "Builtup Space"
                      : props.data.area_type === "equipped_facility"
                      ? "Equipped Facility"
                      : props.data.area_type === "land_on"
                      ? "Land on Lease"
                      : ""}
                  </div>
                </div> */}
                {props.data.ex_man_power &&
                  props.data.ex_man_power !== "null" && (
                    <div className="d-flex ">
                      <div>
                        <CustomIcons
                          iconName="people"
                          iconClass="comp-item-data-icons"
                        />
                      </div>
                      <div className="text-break">
                        {props.data.ex_man_power}
                      </div>
                    </div>
                  )}
                {props?.data?.site_link && (
                  <div className="d-flex " style={{ cursor: "pointer" }}>
                    <div>
                      <CustomIcons
                        iconName="globe"
                        iconClass="comp-item-data-icons"
                      />
                    </div>
                    <div
                      className="text-break"
                      onClick={() => handleNav(props?.data?.site_link)}
                    >
                      {props?.data?.site_link}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start mt-4">
                <div className="comp-item-dom-cont">
                  {props?.data?.any_other_domain !== null &&
                    props?.data?.any_other_domain !== "" &&
                    props?.data?.any_other_domain !== undefined && (
                      <div className="comp-item-dom">
                        {`#${props?.data?.any_other_domain}`}
                      </div>
                    )}
                  {props?.data?.domain_types?.map((dom) => (
                    <div className="comp-item-dom">{`#${dom.domain_name}`}</div>
                  ))}
                </div>
              </div>
              <div className="d-flex " style={{ cursor: "pointer" }}>
                <div>
                  <Button
                    className="bg-primary"
                    onClick={(e) =>
                      handleNav(props?.data?.upload_proposal, "live")
                    }
                  >
                    Brochure
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="comp-item-comp-title">
              {props.data.company_name}
            </div> */}
            {/* <div className="comp-item-dom-cont">
              {props.data.domain_types?.map((dom) => (
                <div className="comp-item-dom">{`#${dom.domain_name}`}</div>
              ))}
            </div> */}

            <div className="comp-item-product-prof-cont">
              <b>Product Profile</b>
              <hr />
              {props.data.product_profiles?.map((produ, index) => (
                <div>
                  <div className="comp-item-prod-prof-title">
                    {index + 1}.&nbsp;
                    {produ.product_name}
                  </div>
                  <div className="comp-item-prod-prof-desc">
                    <div>{ReactHtmlParser(produ.product_desc)}</div>
                  </div>
                </div>
              ))}
            </div>

            {props.india && (
              <div className="comp-item-product-prof-cont">
                <b>Abstract Company Profile</b>
                <hr />

                <div>
                  <div className="comp-item-prod-prof-title">
                    {props?.data?.abstract_project_proposal}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e) => props.handleClose()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
