import React, { useMemo, useState } from "react";
import CustomIcons from "../icons/kIcons.component";
import Pagination from "./Pagination";
import moment from "moment";
import "./style.css";
import jwtDecode from "jwt-decode";
import { statusRegType } from "./store";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

let PageSize = 10;

export default function CustomTable(props) {
  const [currentPage, setCurrentPage] = useState(1);
  // console.log(props)

  let adminType = props?.state?.payload
    ? Number(jwtDecode(props.state.payload)?.user_type)
    : 99;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return props.data.slice(firstPageIndex, lastPageIndex);
  }, [props.data, currentPage]);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const handleNotification = (item) => {
    //
    props.handleNotification(item);
  };
  const handleDownload = (item) => {
    props.handleDownload(item);
  };

  return (
    <>
      <table className="table-bordered">
        <thead className="thead-white thed">
          <tr>
            {props.columns.map((item) => (
              <td
                className={
                  item.type === "status"
                    ? "statuscoloum"
                    : item.type === "notification"
                    ? "messagecoloum"
                    : item.type === "dynId"
                    ? "noFieldTableWidth"
                    : ""
                }
                style={{ color: "white", backgroundColor: "#666" }}
              >
                {item.title}
              </td>
            ))}
            {props.actions && props.actions?.length !== 0 && (
              <td className="action-column" style={{ color: "white" }}>
                {" "}
                Actions
              </td>
            )}
          </tr>
        </thead>
        <tbody style={{ borderRight: "1px solid black" }}>
          {currentTableData && (
            <>
              {currentTableData.length !== 0 ? (
                currentTableData.map((item, index) => {
                  return (
                    <React.Fragment key={"mainrow" + index}>
                      <tr>
                        {props.columns &&
                          props.columns.map((items) => {
                            // console.log(items);
                            return (
                              <>
                                {items?.render ? (
                                  <td>{items?.render(item)}</td>
                                ) : (
                                  <React.Fragment>
                                    {items.type === "view" ? (
                                      <td>
                                        <div className="viewContainer">
                                          <CustomIcons
                                            iconName="view"
                                            iconClass="viewItem"
                                          />
                                        </div>
                                      </td>
                                    ) : items.type === "notification" ? (
                                      <td>
                                        <div
                                          className="notificationContainer"
                                          onClick={(e) =>
                                            handleNotification(item)
                                          }
                                        >
                                          <CustomIcons
                                            iconName="bell"
                                            iconClass={
                                              item[items.field] === 1
                                                ? "activenoti"
                                                : "notificationItem"
                                            }
                                          />
                                        </div>
                                      </td>
                                    ) : items.type === "download" ? (
                                      <td>
                                        <div
                                          className="notificationContainer"
                                          onClick={(e) => handleDownload(item)}
                                        >
                                          <CustomIcons
                                            iconName="download"
                                            iconClass="notificationItem"
                                          />
                                        </div>
                                      </td>
                                    ) : items.type === "fullDate" ? (
                                      <td>
                                        {moment(item[items.field]).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                    ) : items.type === "status" ? (
                                      <td>
                                        {item[items.field] === "1" ? (
                                          <div
                                            className={
                                              item.statuscode ??
                                              item[items.field]
                                            }
                                          >
                                            {"Enabled"}
                                          </div>
                                        ) : (
                                          <div
                                            className={
                                              item.statuscode ??
                                              item[items.field]
                                            }
                                          >
                                            {"Disabled"}
                                          </div>
                                        )}
                                      </td>
                                    ) : items.type === "bookingStatus" ? (
                                      <td>
                                        <div className={item[items.field]}>
                                          {item[items.field]}
                                        </div>
                                      </td>
                                    ) : items.type === "commonStatus" ? (
                                      <td>
                                        <div className={item[items.field]}>
                                          {/* {console.log(item[items.field])} */}
                                          {item[items.field] == 0 ? (
                                            <>
                                              <div className={"pending"}>
                                                {"Pending"}
                                              </div>
                                            </>
                                          ) : item[items.field] == 1 ? (
                                            <div className={"inprogress"}>
                                              {"Requested"}
                                            </div>
                                          ) : item[items.field] == 3 ||
                                            item[items.field] == 5 ? (
                                            <div className={"rejected"}>
                                              {"Rejected"}
                                            </div>
                                          ) : item[items.field] == 2 ? (
                                            <div className={"Accepted"}>
                                              {"Approved"}
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    ) : items.type ===
                                      "commonBusinessStatus" ? (
                                      <td>
                                        <div className={item[items.field]}>
                                          {/* {console.log(item[items.field])} */}
                                          {item[items.field] == 0 ? (
                                            <>
                                              <div className={"pending"}>
                                                {"Pending"}
                                              </div>
                                            </>
                                          ) : item[items.field] == 1 ? (
                                            <div className={"inprogress"}>
                                              {"Requested"}
                                            </div>
                                          ) : item[items.field] == 3 ||
                                            item[items.field] == 5 ? (
                                            <div className={"rejected"}>
                                              {"Rejected"}
                                            </div>
                                          ) : item[items.field] == 4 ? (
                                            <div className={"Accepted"}>
                                              {"Approved"}
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    ) : items.type === "advisorStatus" ? (
                                      <td>
                                        <div className={item[items.field]}>
                                          {/* {console.log(item[items.field])} */}
                                          {item[items.field] == 1 ? (
                                            <div className={"inprogress"}>
                                              {"Requested"}
                                            </div>
                                          ) : item[items.field] == 3 ? (
                                            <div className={"inprogress"}>
                                              {"Submitted"}
                                            </div>
                                          ) : item[items.field] == 4 ? (
                                            <div className={"Accepted"}>
                                              {"Approved"}
                                            </div>
                                          ) : (
                                            <div className={"rejected"}>
                                              {"Rejected"}
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    ) : items.type === "downloadedStatus" ? (
                                      <td>
                                        {/* {console.log(item[items.field])} */}
                                        {item[items.field] == 1 ? (
                                          <div className={"inprogress"}>
                                            {"Interview Scheduled"}
                                          </div>
                                        ) : item[items.field] == 3 ? (
                                          <div className={"Accepted"}>
                                            {"Job Offered"}
                                          </div>
                                        ) : item[items.field] == 2 ? (
                                          <div className={"Accepted"}>
                                            {"Payment Done"}
                                          </div>
                                        ) : (
                                          <div className={"rejected"}>
                                            {"Pending"}
                                          </div>
                                        )}
                                      </td>
                                    ) : items.type ===
                                      "commonStatusInternship" ? (
                                      <td>
                                        <div className={item[items.field]}>
                                          {/* {console.log(item[items.field])} */}
                                          {item[items.field] == 0 ? (
                                            <>
                                              <div className={"pending"}>
                                                {"Pending"}
                                              </div>
                                            </>
                                          ) : item[items.field] == 1 ? (
                                            <>
                                              <div className={"inprogress"}>
                                                {"Inprogress"}
                                              </div>
                                            </>
                                          ) : item[items.field] == 3 ||
                                            item[items.field] == 5 ? (
                                            <div className={"rejected"}>
                                              {"Rejected"}
                                            </div>
                                          ) : item[items.field] == 4 ? (
                                            <div className={"Accepted"}>
                                              {"Approved"}
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    ) : items.type === "transferStatus" ? (
                                      <td>
                                        <div className={item[items.field]}>
                                          {/* {console.log(item[items.field])} */}
                                          {item[items.field] == 0 ? (
                                            <>
                                              <div className={"pending"}>
                                                {"Pending"}
                                              </div>
                                            </>
                                          ) : item[items.field] == 1 ? (
                                            <>
                                              <div className={"inprogress"}>
                                                {"Requested"}
                                              </div>
                                            </>
                                          ) : item[items.field] == 5 ? (
                                            <div className={"rejected"}>
                                              {"Rejected"}
                                            </div>
                                          ) : item[items.field] == 2 ? (
                                            <div className={"Accepted"}>
                                              {"Approved"}
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    ) : items.type === "statusType" ? (
                                      <td>
                                        {item[items.field] === 0 ||
                                        item[items.field] === "0" ? (
                                          <div className={"pending"}>
                                            {"Pending"}
                                          </div>
                                        ) : item[items.field] === 1 ? (
                                          <div className={"inprogress"}>
                                            {"In Progress"}
                                          </div>
                                        ) : item[items.field] === 2 ||
                                          item[items.field] === "2" ? (
                                          <div className={"rejected"}>
                                            {"Reject"}
                                          </div>
                                        ) : item[items.field] === 3 ||
                                          item[items.field] === "3" ? (
                                          <div className={"acceptance"}>
                                            {"Submit for Acceptance"}
                                          </div>
                                        ) : item[items.field] === 4 ||
                                          item[items.field] === "4" ? (
                                          <div className={"resubmit"}>
                                            {"Re-submitted"}
                                          </div>
                                        ) : item[items.field] === 5 ||
                                          item[items.field] === "5" ? (
                                          <div className={"Accepted"}>
                                            {"Accepted"}
                                          </div>
                                        ) : (
                                          <div className={"agrementexecution"}>
                                            {"Agreement Execution"}
                                          </div>
                                        )}
                                      </td>
                                    ) : items.type === "regstatusType" ? (
                                      <td>
                                        <div
                                          className={
                                            statusRegType[
                                              parseInt(item[items.field])
                                            ]?.className ?? ""
                                          }
                                        >
                                          {statusRegType[
                                            parseInt(item[items.field])
                                          ]?.title ?? ""}
                                        </div>
                                      </td>
                                    ) : items?.type === "candidatesList" ? (
                                      <td>
                                        {console.log(item[items.field])}
                                        {item[items.field] == 0 ? (
                                          <>
                                            <div className={"pending"}>
                                              {"Pending"}
                                            </div>
                                          </>
                                        ) : item[items.field] == 1 ? (
                                          <>
                                            <div className={"inprogress"}>
                                              {"Submitted"}
                                            </div>
                                          </>
                                        ) : item[items.field] == 4 ? (
                                          <>
                                            <div className={"pending"}>
                                              {"Reverted to admin"}
                                            </div>
                                          </>
                                        ) : item[items.field] == 3 ? (
                                          <>
                                            <div className={"pending"}>
                                              {"Reverted to user"}
                                            </div>
                                          </>
                                        ) : [items.field] == 5 ? (
                                          <div className={"rejected"}>
                                            {"Rejected"}
                                          </div>
                                        ) : item[items.field] == 2 ? (
                                          <div className={"Accepted"}>
                                            {"Approved"}
                                          </div>
                                        ) : null}
                                      </td>
                                    ) : items.type === "video" ? (
                                      <td>
                                        <div className="img-list">
                                          {
                                            <video
                                              width="150"
                                              height="50"
                                              controls
                                            >
                                              <source
                                                src={`${result}/${
                                                  item[items.field]
                                                }`}
                                                type="video/mp4"
                                              />
                                            </video>
                                          }
                                        </div>
                                      </td>
                                    ) : items.type === "image" ? (
                                      <td>
                                        <div
                                          className={
                                            items.fullwidth
                                              ? "full-img-list "
                                              : "img-list w-100 d-flex justify-content-start "
                                          }
                                        >
                                          {
                                            <img
                                              src={`${result}/${
                                                item[items.field]
                                              }`}
                                            ></img>
                                          }
                                        </div>
                                      </td>
                                    ) : items.type === "dynId" ? (
                                      <td className="noFieldTableWidth">
                                        {index + 1}
                                      </td>
                                    ) : items.type === "bool" ? (
                                      <td>
                                        {item[items.field] === 1 ? "Yes" : "No"}
                                      </td>
                                    ) : items.type === "intinst" ? (
                                      // item.institution.map((inst, i) => {
                                      //   <td>{console.log(inst.institution_name)}inst.institution_name</td>;
                                      // })
                                      <td>
                                        {item.institution[0]?.institution_name}
                                      </td>
                                    ) : items.type === "AdminDownload" ? (
                                      // item.institution.map((inst, i) => {
                                      //   <td>{console.log(inst.institution_name)}inst.institution_name</td>;
                                      // })
                                      <td>
                                        {item?.candidate_details[items.field]}
                                      </td>
                                    ) : items.type === "content" ? (
                                      <td className="parserfont">
                                        {item[items.field]
                                          ? ReactHtmlParser(item[items.field])
                                          : ""}
                                      </td>
                                    ) : items.type === "date" ? (
                                      <td>
                                        {/* {console.log(item[items.field])} */}
                                        {
                                          item[items.field]
                                          // ? moment(item[items.field]).format(
                                          //     "DD-MMM-YYYY"
                                          //   )
                                          // : "" }
                                        }
                                      </td>
                                    ) : (
                                      <td>{item[items.field]}</td>
                                    )}
                                  </React.Fragment>
                                )}
                              </>
                            );
                          })}
                        {props.actions && (
                          <td
                            className="d-flex action-column"
                            style={{ borderLeft: "none" }}
                          >
                            <>
                              {props.actions?.map((act) => (
                                <React.Fragment>
                                  <button
                                    className={`action-button ${act.type}`}
                                    disabled={act.disabled}
                                    onClick={(e) => act.handleClick(item)}
                                  >
                                    <CustomIcons
                                      iconName={act.type}
                                      iconClass="d-flex"
                                    />
                                  </button>
                                </React.Fragment>
                              ))}{" "}
                            </>
                          </td>
                        )}
                      </tr>
                      {props.expansion && (
                        <>
                          {" "}
                          {/* <tr className="" style={{backgroundColor:"#666", color:"#fff"}}>
                            <th colSpan={props.actions?props.columns?.length+1:props.columns?.length}><b>{props.expansion.title}</b></th>
                          </tr> */}
                          <tr>
                            <td
                              colSpan={
                                props.actions
                                  ? props.columns?.length + 1
                                  : props.columns?.length
                              }
                            >
                              <b>{props.expansion.title}: </b>
                              {item[props.expansion.field]}
                            </td>
                          </tr>
                        </>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td
                      style={{ textAlign: "center" }}
                      colSpan={
                        props.actions
                          ? props.columns.length + 1
                          : props.columns.length
                      }
                    >
                      No Records found
                    </td>
                  </tr>
                </>
              )}{" "}
            </>
          )}
        </tbody>
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={props.data.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
}
