module.exports = {
  default: {
    title: "Default",
    menus: [],
  },
  adminMenu: {
    title: "ADMIN",
    menus: [
      {
        id: 0,
        name: "Dashboard",
        icon: "structure",
        parent: "",
        path: "/admin-dashboard",
      },
      // {
      //   id: 1,
      //   name: "New Company Request",
      //   path: "/company-request-list",
      //   icon: "newcompany",
      //   parent: "",
      // },
      {
        id: 2,
        name: "Area Management",
        icon: "areamanage",
        parent: "",
        path: "",
        child: true,
      },
      {
        id: 80,
        name: "Built Up Space",
        icon: "areamanage",
        parent: 2,
        path: "/builtup-space",
      },
      {
        id: 81,
        name: "Equipped Facility",
        icon: "areamanage",
        parent: 2,
        path: "/equip-facility",
      },
      {
        id: 82,
        name: "Land On Lease",
        icon: "areamanage",
        parent: 2,
        path: "/land-lease",
      },
      // {
      //   id: 83,
      //   name: "Joint Venture",
      //   icon: "areamanage",
      //   parent: 2,
      //   path: "/joint-venture",
      // },
      // {
      //   id: 84,
      //   name: "Own Land",
      //   icon: "areamanage",
      //   parent: 2,
      //   path: "/own-land",
      // },
      {
        id: 3,
        name: "Homepage Management",
        parent: "",
        child: true,
        icon: "house",
      },
      {
        id: 4,
        icon: "slide",
        path: "/banner-list",
        name: "Banner Images",
        parent: 3,
      },
      {
        id: 5,
        path: "/scrolling-list",
        icon: "bell",
        name: "Scrolling Notification",
        parent: 3,
      },
      {
        id: 6,
        path: "/scope-activity-list",
        icon: "activity",
        name: "Scope of Activities",
        parent: 3,
      },
      {
        id: 51,
        path: "/maintext-list",
        icon: "maintext",
        name: "Main-Text",
        parent: 7,
      },
      {
        id: 52,
        path: "/organization-list",
        icon: "structure",
        name: "Organizational Structure",
        parent: 7,
      },
      {
        id: 8,
        path: "/quotes-list",
        icon: "quote",
        name: "Messages",
        parent: 3,
      },
      {
        id: 9,
        path: "accomplishment-list",
        icon: "flag",
        name: "Our Accomplishments",
        parent: 3,
      },
      {
        id: 7,
        name: "About KSPACE",
        parent: 3,
        icon: "about",
        child: true,
      },
      {
        id: 10,
        path: "/domain-list",
        icon: "domain",
        name: "Domains",
        parent: "",
      },

      {
        id: 11,
        name: "Companies",
        parent: "",
        icon: "company",
        child: true,
      },
      // {
      //   id: 13,
      //   path: "/newrequest-application-kspace",
      //   icon: "application",
      //   name: "New Applications",
      //   parent: 12,
      // },
      // {
      //   id: 14,
      //   path: "/companies-in-kspace",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 12,
      // },
      {
        id: 21,
        name: "KSPACE",
        icon: "companylist",
        path: "/company-request-list/kspace",
        parent: 11,
        // child: true,
      },
      {
        id: 21,
        name: "KSPACE - Own Premises",
        icon: "companylist",
        path: "/company-request-list/own",
        parent: 11,
        // child: true,
      },
      {
        id: 15,
        name: "Kerala",
        path: "/kerala-companies-list",
        icon: "companylist",
        parent: 11,
        // child: true,
      },
      // {
      //   id: 12,
      //   name: "KSPACE",
      //   icon: "companylist",
      //   parent: 11,
      //   child: true,
      // },

      // {
      //   id: 17,
      //   path: "/kerala-companies-list",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 15,
      // },
      // {
      //   id: 131,
      //   path: "/newrequest-application-kerala",
      //   icon: "application",
      //   name: "New Applications",
      //   parent: 15,
      // },

      {
        id: 21,
        name: "International",
        icon: "companylist",
        path: "/international-companies-list",
        parent: 11,
        // child: true,
      },
      // {
      //   id: 211,
      //   path: "/newrequest-application-international",
      //   icon: "application",
      //   name: "New Applications",
      //   parent: 21,
      // },
      {
        id: 18,
        name: "India",
        icon: "companylist",
        path: "/india-companies-list",
        parent: 11,
        // child: true,
      },

      // {
      //   id: 22,
      //   path: "/international-companies-list",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 21,
      // },
      // {
      //   id: 20,
      //   path: "/india-companies-list",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 18,
      // },
      // {
      //   id: 201,
      //   path: "/newrequest-application-india",
      //   icon: "application",
      //   name: "New Applications",
      //   parent: 18,
      // },
      {
        id: 23,
        path: "/resource-list",
        name: "Resource Personnel",
        icon: "resource",
        parent: "",
      },

      {
        id: 26,
        path: "/manpower-list",
        name: "Expert Manpower Registry",
        parent: "",
        icon: "expert",
      },
      {
        id: 27,
        path: "/candidates-list",
        name: "Job Seeker Registry",
        parent: "",
        icon: "expert",
      },
      {
        id: 28,
        path: "/profiles-list",
        name: "Downloaded Profiles",
        parent: "",
        icon: "resource",
      },
      {
        id: 64,
        path: "/policies-list",
        name: "Policies & Regulations",
        parent: "",
        icon: "application",
      },
      {
        id: 65,
        path: "/incentives-list",
        name: "Financial Support and Incentives",
        parent: "",
        icon: "resource",
      },
      {
        id: 66,
        path: "/news-list",
        name: "News and Updates",
        parent: "",
        icon: "application",
      },

      {
        id: 29,
        name: "Market Research",
        icon: "market",
        parent: "",
        child: true,
      },
      {
        id: 30,
        path: "/tender-list",
        icon: "application",
        name: "Tender",
        parent: 29,
      },
      {
        id: 31,
        path: "/publication-list",
        icon: "activity",
        name: "Publications",
        parent: 29,
      },
      {
        id: 32,
        name: "New Business Ideas",
        icon: "ideas",
        parent: "",
        child: true,
      },
      {
        id: 33,
        path: "/project-proposal-list",
        icon: "proposals",
        name: "Project Proposals",
        parent: 32,
      },
      {
        id: 34,
        path: "/transfer-proposal-list",
        icon: "technology",
        name: "Technology Transfer Proposals",
        parent: 32,
      },
      // {
      //   id: 35,
      //   path: "/professionalbodies-list",
      //   name: "Professional Bodies",
      //   icon: "house",
      //   parent: "",
      // },

      {
        id: 38,
        name: "Academic Institutions",
        icon: "academic",
        parent: "",
        child: true,
      },
      {
        id: 39,
        path: "/academicinstitution-list",
        icon: "application",
        name: "Applications",
        parent: 38,
      },

      {
        id: 42,
        path: "/institutionproposal-list",
        icon: "directory",
        name: "Student Proposal",
        parent: 38,
      },

      {
        id: 44,
        path: "/intershiptraining-list",
        icon: "directory",
        name: "Internship/Training",
        parent: 38,
      },

      {
        id: 45,
        icon: "advisors",
        path: "/advisors-list",
        name: "Spacepark Advisors",
        parent: "",
      },

      {
        id: 46,
        name: "Events",
        icon: "events",
        parent: "",
        child: true,
      },
      {
        id: 47,
        icon: "eventlist",
        name: "Event List",
        path: "/event-list",
        parent: 46,
      },
      {
        id: 48,
        icon: "book",
        name: "Booking",
        path: "/event-booking",
        parent: 46,
      },
      {
        id: 49,
        icon: "slide",
        name: "Gallery",
        path: "",
        child: true,
        parent: "",
      },
      {
        id: 61,
        icon: "slide",
        name: "Images",
        path: "/images",
        parent: 49,
      },
      {
        id: 62,
        icon: "slide",
        name: "Videos",
        path: "/videos",
        parent: 49,
      },
      {
        id: 63,
        path: "/career-list",
        icon: "application",
        name: "Career",
        parent: 29,
      },
      // { id: 50, icon: "about", name: "User Roles", parent: "" },
    ],
  },
  intMdAdminMenu: {
    title: "ADMIN",
    menus: [
      {
        id: 0,
        name: "Dashboard",
        icon: "structure",
        parent: "",
        path: "/admin-dashboard",
      },

      {
        id: 2,
        name: "Area Management",
        icon: "areamanage",
        parent: "",
        path: "",
        child: true,
      },
      {
        id: 80,
        name: "Built Up Space",
        icon: "areamanage",
        parent: 2,
        path: "/builtup-space",
      },
      {
        id: 81,
        name: "Equipped Facility",
        icon: "areamanage",
        parent: 2,
        path: "/equip-facility",
      },
      {
        id: 82,
        name: "Land On Lease",
        icon: "areamanage",
        parent: 2,
        path: "/land-lease",
      },
      // {
      //   id: 83,
      //   name: "Joint Venture",
      //   icon: "areamanage",
      //   parent: 2,
      //   path: "/joint-venture",
      // },
      // {
      //   id: 84,
      //   name: "Own Land",
      //   icon: "areamanage",
      //   parent: 2,
      //   path: "/own-land",
      // },
      {
        id: 3,
        name: "Homepage Management",
        parent: "",
        child: true,
        icon: "house",
      },
      {
        id: 4,
        icon: "slide",
        path: "/banner-list",
        name: "Banner Images",
        parent: 3,
      },

      {
        id: 5,
        path: "/scrolling-list",
        icon: "bell",
        name: "Scrolling Notification",
        parent: 3,
      },
      {
        id: 6,
        path: "/scope-activity-list",
        icon: "activity",
        name: "Scope of Activities",
        parent: 3,
      },

      {
        id: 8,
        path: "/quotes-list",
        icon: "quote",
        name: "Messages ",
        parent: 3,
      },
      {
        id: 9,
        path: "accomplishment-list",
        icon: "flag",
        name: "Our Accomplishments",
        parent: 3,
      },
      {
        id: 7,
        name: "About KSPACE",
        parent: 3,
        icon: "about",
        child: true,
      },
      {
        id: 51,
        path: "/maintext-list",
        icon: "maintext",
        name: "Main-Text",
        parent: 7,
      },
      {
        id: 52,
        path: "/organization-list",
        icon: "structure",
        name: "Organizational Structure",
        parent: 7,
      },
      {
        id: 10,
        path: "/domain-list",
        icon: "domain",
        name: "Domains",
        parent: "",
      },

      {
        id: 11,
        name: "Companies",
        parent: "",
        icon: "company",
        child: true,
      },
      // {
      //   id: 13,
      //   path: "/newrequest-application-kspace",
      //   icon: "application",
      //   name: "New Applications",
      //   parent: 12,
      // },
      // {
      //   id: 14,
      //   path: "/companies-in-kspace",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 12,
      // },
      {
        id: 21,
        name: "KSPACE",
        icon: "companylist",
        path: "/company-request-list/kspace",
        parent: 11,
        // child: true,
      },
      {
        id: 21,
        name: "KSPACE - Own Premises",
        icon: "companylist",
        path: "/company-request-list/own",
        parent: 11,
        // child: true,
      },

      {
        id: 21,
        name: "International",
        icon: "companylist",
        parent: 11,
        child: true,
      },
      {
        id: 18,
        name: "India",
        icon: "companylist",
        parent: 11,
        child: true,
      },

      {
        id: 22,
        path: "/international-companies-list",
        icon: "intitutions",
        name: "Institutions",
        parent: 21,
      },
      {
        id: 20,
        path: "/india-companies-list",
        icon: "intitutions",
        name: "Institutions",
        parent: 18,
      },

      {
        id: 29,
        name: "Market Research",
        icon: "market",
        parent: "",
        child: true,
      },
      {
        id: 30,
        path: "/tender-list",
        icon: "application",
        name: "Tender",
        parent: 29,
      },
      {
        id: 31,
        path: "/publication-list",
        icon: "activity",
        name: "Publications",
        parent: 29,
      },

      // {
      //   id: 35,
      //   path: "/professionalbodies-list",
      //   name: "Professional Bodies",
      //   icon: "house",
      //   parent: "",
      // },

      {
        id: 46,
        name: "Events",
        icon: "events",
        parent: "",
        child: true,
      },
      {
        id: 47,
        icon: "eventlist",
        name: "Event List",
        path: "/event-list",
        parent: 46,
      },
      {
        id: 48,
        icon: "book",
        name: "Booking",
        path: "/event-booking",
        parent: 46,
      },
      {
        id: 49,
        icon: "slide",
        name: "Gallery",
        path: "",
        child: true,
        parent: "",
      },
      {
        id: 61,
        icon: "slide",
        name: "Images",
        path: "/images",
        parent: 49,
      },
      {
        id: 62,
        icon: "slide",
        name: "Videos",
        path: "/videos",
        parent: 49,
      },
      {
        id: 63,
        path: "/career-list",
        icon: "application",
        name: "Careers",
        parent: 29,
      },
    ],
  },
  superAdminMenu: {
    title: "ADMIN",
    menus: [
      {
        id: 0,
        name: "Dashboard",
        icon: "structure",
        parent: "",
        path: "/admin-dashboard",
      },
      // {
      //   id: 1,
      //   name: "New Company Request",
      //   path: "/company-request-list",
      //   icon: "newcompany",
      //   parent: "",
      // },
      {
        id: 2,
        name: "Area Management",
        icon: "areamanage",
        parent: "",
        path: "",
        child: true,
      },
      {
        id: 80,
        name: "Built Up Space",
        icon: "areamanage",
        parent: 2,
        path: "/builtup-space",
      },
      {
        id: 81,
        name: "Equipped Facility",
        icon: "areamanage",
        parent: 2,
        path: "/equip-facility",
      },
      {
        id: 82,
        name: "Land On Lease",
        icon: "areamanage",
        parent: 2,
        path: "/land-lease",
      },
      // {
      //   id: 83,
      //   name: "Joint Venture",
      //   icon: "areamanage",
      //   parent: 2,
      //   path: "/joint-venture",
      // },
      // {
      //   id: 84,
      //   name: "Own Land",
      //   icon: "areamanage",
      //   parent: 2,
      //   path: "/own-land",
      // },

      {
        id: 3,
        name: "Homepage Management",
        parent: "",
        child: true,
        icon: "house",
      },
      {
        id: 4,
        icon: "slide",
        path: "/banner-list",
        name: "Banner Images",
        parent: 3,
      },
      {
        id: 5,
        path: "/scrolling-list",
        icon: "bell",
        name: "Scrolling Notification",
        parent: 3,
      },
      {
        id: 6,
        path: "/scope-activity-list",
        icon: "activity",
        name: "Scope of Activities",
        parent: 3,
      },
      {
        id: 51,
        path: "/maintext-list",
        icon: "maintext",
        name: "Main-Text",
        parent: 7,
      },
      {
        id: 52,
        path: "/organization-list",
        icon: "structure",
        name: "Organizational Structure",
        parent: 7,
      },
      {
        id: 8,
        path: "/quotes-list",
        icon: "quote",
        name: "Messages",
        parent: 3,
      },
      {
        id: 9,
        path: "accomplishment-list",
        icon: "flag",
        name: "Our Accomplishments",
        parent: 3,
      },
      {
        id: 7,
        name: "About KSPACE",
        parent: 3,
        icon: "about",
        child: true,
      },
      {
        id: 10,
        path: "/domain-list",
        icon: "domain",
        name: "Domains",
        parent: "",
      },

      {
        id: 11,
        name: "Companies",
        parent: "",
        icon: "company",
        child: true,
      },
      // {
      //   id: 13,
      //   path: "/newrequest-application-kspace",
      //   icon: "application",
      //   name: "New Applications",
      //   parent: 12,
      // },
      // {
      //   id: 21,
      //   name: "KSPACE",
      //   icon: "companylist",
      //   path: "/company-request-list",
      //   parent: 11,
      //   // child: true,
      // },
      {
        id: 21,
        name: "KSPACE",
        icon: "companylist",
        path: "/company-request-list/kspace",
        parent: 11,
        // child: true,
      },
      {
        id: 21,
        name: "KSPACE - Own Premises",
        icon: "companylist",
        path: "/company-request-list/own",
        parent: 11,
        // child: true,
      },
      // {
      //   id: 14,
      //   path: "/companies-in-kspace",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 12,
      // },
      // {
      //   id: 1,
      //   name: "KSPACE",
      //   path: "/company-request-list",
      //   icon: "newcompany",
      //   parent: 11,
      // },
      {
        id: 15,
        name: "Kerala",
        icon: "companylist",
        path: "/kerala-companies-list",
        parent: 11,
        // child: true,
      },
      // {
      //   id: 12,
      //   name: "KSPACE",
      //   icon: "companylist",
      //   parent: 11,
      //   child: true,
      // },

      // {
      //   id: 17,
      //   path: "/kerala-companies-list",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 15,
      // },
      {
        id: 21,
        name: "International",
        path: "/international-companies-list",
        icon: "companylist",
        parent: 11,
        // child: true,
      },
      {
        id: 18,
        name: "India",
        path: "/india-companies-list",
        icon: "companylist",
        parent: 11,
        // child: true,
      },

      // {
      //   id: 22,
      //   path: "/international-companies-list",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 21,
      // },
      // {
      //   id: 20,
      //   path: "/india-companies-list",
      //   icon: "intitutions",
      //   name: "Institutions",
      //   parent: 18,
      // },
      {
        id: 23,
        path: "/resource-list",
        name: "Resource Personnel",
        icon: "resource",
        parent: "",
      },

      {
        id: 26,
        path: "/manpower-list",
        name: "Expert Manpower Registry",
        parent: "",
        icon: "expert",
      },
     
      {
        id: 27,
        path: "/candidates-list",
        name: "Job Seeker Registry",
        parent: "",
        icon: "expert",
      },
      {
        id: 28,
        path: "/profiles-list",
        name: "Downloaded Profiles",
        parent: "",
        icon: "resource",
      },
      {
        id: 64,
        path: "/policies-list",
        name: "Policies & Regulations",
        parent: "",
        icon: "application",
      },
      {
        id: 65,
        path: "/incentives-list",
        name: "Financial Support and Incentives",
        parent: "",
        icon: "resource",
      },
      {
        id: 66,
        path: "/news-list",
        name: "News and Updates",
        parent: "",
        icon: "application",
      },
      {
        id: 29,
        name: "Market Research",
        icon: "market",
        parent: "",
        child: true,
      },
      {
        id: 30,
        path: "/tender-list",
        icon: "application",
        name: "Tender",
        parent: 29,
      },
      {
        id: 31,
        path: "/publication-list",
        icon: "activity",
        name: "Publications",
        parent: 29,
      },
      {
        id: 32,
        name: "New Business Ideas",
        icon: "ideas",
        parent: "",
        child: true,
      },
      {
        id: 33,
        path: "/project-proposal-list",
        icon: "proposals",
        name: "Project Proposals",
        parent: 32,
      },
      {
        id: 34,
        path: "/transfer-proposal-list",
        icon: "technology",
        name: "Technology Transfer Proposals",
        parent: 32,
      },
      // {
      //   id: 35,
      //   path: "/professionalbodies-list",
      //   name: "Professional Bodies",
      //   icon: "house",
      //   parent: "",
      // },

      {
        id: 38,
        name: "Academic Institutions",
        icon: "academic",
        parent: "",
        child: true,
      },
      {
        id: 39,
        path: "/academicinstitution-list",
        icon: "application",
        name: "Applications",
        parent: 38,
      },

      {
        id: 42,
        path: "/institutionproposal-list",
        icon: "directory",
        name: "Student Proposal",
        parent: 38,
      },

      {
        id: 44,
        path: "/intershiptraining-list",
        icon: "directory",
        name: "Internship/Training",
        parent: 38,
      },

      {
        id: 45,
        icon: "advisors",
        path: "/advisors-list",
        name: "Spacepark Advisors",
        parent: "",
      },

      {
        id: 46,
        name: "Events",
        icon: "events",
        parent: "",
        child: true,
      },
      {
        id: 47,
        icon: "eventlist",
        name: "Event List",
        path: "/event-list",
        parent: 46,
      },
      {
        id: 48,
        icon: "book",
        name: "Booking",
        path: "/event-booking",
        parent: 46,
      },
      {
        id: 49,
        icon: "slide",
        name: "Gallery",
        path: "",
        child: true,
        parent: "",
      },
      {
        id: 61,
        icon: "slide",
        name: "Images",
        path: "/images",
        parent: 49,
      },
      {
        id: 62,
        icon: "slide",
        name: "Videos",
        path: "/videos",
        parent: 49,
      },
      {
        id: 63,
        path: "/career-list",
        icon: "application",
        name: "Careers",
        parent: 29,
      },
      // { id: 50, icon: "about", name: "User Roles", parent: "" },
    ],
  },
};
