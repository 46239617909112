import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../../components/commonform/commonForm";
import Snackbar from "../../../../../../components/snackbar/snackbar";
import moment from "moment";
import DatePicker, { DateObject } from "react-multi-date-picker";

// import { user_area_types_list, user_locations_list } from "../../../user.api";
let flag = false;
let eighteenYearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
export default function AreaDetails(props) {
  const [formData, setFormData] = useState(props.data);
  const [count, setCount] = useState(1);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [keyvalue, setKeyValue] = useState(1);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [err, setErr] = useState(false);
  useEffect(() => {
    if (!formData.qualifications) {
      setFormData({
        ...formData,
        qualifications: [
          {
            exam_passed: "",
            board: "",
            year_passed: "",
            mark: "",
            certificate_photo: null,
          },
        ],
      });
    }

    if (!formData.otherLanguages) {
      setFormData({
        ...formData,
        otherLanguages: [{ language: "" }],
      });
    }
  }, [formData, formData.qualifications, formData.otherLanguages]);

  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.qualifications[i] = {
      ...data.qualifications[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const addLanguage = (i) => {
    let data = { ...formData };

    data.otherLanguages.push({ language: "" });
    setFormData(data);
    setCount(count + 1);
  };
  const removeLanguage = (i) => {
    let data = { ...formData };
    data.otherLanguages.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let edu = formData.qualifications;

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        edu[i] = {
          ...edu[i],
          file_name: e.target.files[0].name,
          certificate_photo: e.target.files[0],
        };
        setFormData({
          ...formData,
          qualifications: edu,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        edu[i] = {
          ...edu[i],
          file_name: null,
          certificate_photo: null,
        };
        setFormData({
          ...formData,
          qualifications: edu,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      edu[i] = {
        ...edu[i],
        file_name: null,
        certificate_photo: null,
      };
      setFormData({
        ...formData,
        qualifications: edu,
      });
    }
  };
  const handleDatePickerChange = (e, name) => {
    let date = e.value;
    const dateRegex = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
    console.log(date);
    console.log(dateRegex.test(date));
    if (dateRegex.test(date)) {
      setErr(false);
    } else {
      setErr(true);
    }
    setFormData({ ...formData, [name]: date });
    setKeyValue(keyvalue + 1);
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        setFormData({
          ...formData,
          file_name: e.target.files[0].name,
          photo: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          file_name: null,
          photo: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        file_name: null,
        photo: null,
      });
    }
  };
  const textFieldChange = (e) => {
    let name = e.target.name;

    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);
    setKeyValue(keyvalue + 1);
  };

  const handlepreview = (e) => {
    e.preventDefault();

    let maxDate = new Date(moment(new Date(eighteenYearsAgo)));

    if (
      moment(formData.date_of_birth, "DD-MM-YYYY") <=
      moment(maxDate, "DD-MM-YYYY")
    ) {
      if (
        formData.photo === null ||
        formData.photo === undefined ||
        formData.photo === ""
      ) {
        setSnackMessage("Please choose a valid photo file");
        setIsSnackError(true);
        setSnackOpen(true);
      } else {
        let validExp = true;

        if (
          formData.photo === null ||
          formData.photo === undefined ||
          formData.photo === ""
        ) {
          setSnackMessage(`Please choose a valid photo`);
          setIsSnackError(true);
          setSnackOpen(true);
          validExp = false;
        }

        if (validExp) {
          let validEduc = true;
          formData.qualifications.forEach((item) => {
            if (
              item.certificate_photo === null ||
              item.certificate_photo === undefined ||
              item.certificate_photo === ""
            ) {
              setSnackMessage(
                `Please choose a valid educational certificate file for ${item.exam_passed}`
              );
              setIsSnackError(true);
              setSnackOpen(true);
              validEduc = false;
            }
          });
          if (validEduc) {
            props.action(0, formData);
          }
        }
      }
    } else {
      setSnackMessage(`Please Choose a valid Date of Birth`);
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    data.qualifications[i] = {
      ...data.qualifications[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.qualifications.push({
      exam_passed: "",
      board: "",
      year_passed: "",
      mark: "",
      certificate_photo: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.qualifications.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleOtherLangChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleMultiChange = (e, i) => {
    let data = { ...formData };

    data.otherLanguages[i] = {
      ...data.otherLanguages[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  const genderFieldChange = (e) => {
    console.log(e.target.value);
    let data = formData;
    data = {
      ...data,

      gender: e.target.value,
    };
    setFormData(data);
  };
  // console.log(formData);
  const handleValid = (type) => {
    let flags = false;

    if (type === "language") {
      props.languageData.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const handleCheckChange = (e, type, item) => {
    flag = false;
    if (type === "language") {
      let data = [...props.languageData];

      data[item]["checked"] = e.target.checked;
      props.setLanguageData(data);
    }
  };
  // console.log(props);
  // console.log(formData);

  // console.log(formData.date_of_birth);

  return (
    <div className="expert">
      <small className="mand">* All fields are Mandatory</small>{" "}
      <form onSubmit={handlepreview}>
        <div>
          <div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Full Name"
                required
                name="full_name"
                value={formData.full_name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="col-xs-12 col-sm-6">
                <CommonForm
                  fieldType="date"
                  label={`Date of Birth `}
                  required
                  name="date_of_birth"
                  maxDate={new Date(eighteenYearsAgo)}
                  value={formData?.date_of_birth ?? ""}
                  onChange={(e) => {
                    handleDatePickerChange(e, "date_of_birth");
                  }}
                  {...props}
                />
                {err && (
                  <div className="file-up-helper mt-1">
                    Please select a valid date
                  </div>
                )}
              </div>
              <div className="col-xs-12 col-sm-5 ml-5 fieldset ">
                <div className=" mylabel mb-2">Gender</div>
                <div className="d-flex ">
                  <CommonForm
                    fieldType="radio"
                    label="Male"
                    required
                    name="gender"
                    value={"Male"}
                    checked={formData?.gender === "Male"}
                    onChange={(e) => genderFieldChange(e)}
                    {...props}
                  />
                  <CommonForm
                    fieldType="radio"
                    label="Female"
                    required
                    name="gender"
                    checked={formData?.gender === "Female"}
                    value={"Female"}
                    onChange={(e) => genderFieldChange(e)}
                    {...props}
                  />
                  <CommonForm
                    fieldType="radio"
                    label="Other"
                    required
                    name="gender"
                    checked={formData?.gender === "Other"}
                    value={"Other"}
                    onChange={(e) => genderFieldChange(e)}
                    {...props}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="file"
                required={!formData.file_name ? true : false}
                label="Photo"
                name="photo"
                {...props}
                id="file_submitted_record"
                accept=".jpg,.jpeg,.png"
                type="file"
                variant="outlined"
                fileName={formData.file_name}
                onChange={handleFileChange}
              />
              <div className="file-up-helper">
                *Upload only .jpg , .jpeg ,.png format and Size should be less
                than 1MB
              </div>
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Address"
                multiline
                required
                value={formData.address}
                className="no-margin"
                name="address"
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="fieldset">
              <div className="header-label">Languages Known</div>
              <div
              // className="check-box-cont"
              >
                <div className="check-box-cont">
                  {props.languageData &&
                    props.languageData.map((item, index) => (
                      <CommonForm
                        fieldType="checkbox"
                        required={
                          !formData.any_other_lang && !handleValid("language")
                        }
                        value={item.checked}
                        checked={item.checked}
                        onChange={(e) =>
                          handleCheckChange(e, "language", index)
                        }
                        label={item.language}
                        {...props}
                      />
                    ))}

                  <CommonForm
                    fieldType="checkbox"
                    checked={formData.any_other_lang}
                    value={formData.any_other_lang}
                    name="any_other_lang"
                    onChange={handleOtherLangChange}
                    label={"Others"}
                    {...props}
                  />
                </div>
                {formData.any_other_lang && (
                  <>
                    {formData.otherLanguages?.map((e, i) => (
                      <>
                        <div>
                          <CommonForm
                            fieldType="text"
                            label="Language"
                            required
                            name="language"
                            value={e?.language}
                            onChange={(e) => {
                              handleMultiChange(e, i);
                            }}
                            {...props}
                          />
                        </div>

                        <div className="more-btn-cnt">
                          {formData.otherLanguages.length === i + 1 && (
                            <button
                              style={{ marginLeft: 5 }}
                              type="button"
                              {...props}
                              className="btn btn-primary"
                              onClick={(e) => addLanguage(i)}
                            >
                              Add More
                            </button>
                          )}
                          {formData.otherLanguages.length > 1 && (
                            <button
                              {...props}
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => removeLanguage(i)}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>

            <div className="header-label">Educational Qualification</div>
            {formData.qualifications?.map((e, i) => (
              <div className="section-datas-cont mb-3">
                <div className="section-Cont">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <CommonForm
                        fieldType="text"
                        label="Examination Passed"
                        required
                        name="exam_passed"
                        value={e?.exam_passed}
                        onChange={(e) => {
                          handleEducationalChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-12 col-sm-3">
                      <CommonForm
                        fieldType="text"
                        label="Board/University"
                        required
                        name="board"
                        value={e?.board}
                        onChange={(e) => {
                          handleEducationalChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-3">
                      <CommonForm
                        fieldType="text"
                        maxLength="4"
                        minLength="4"
                        label="Year of Passing"
                        required
                        name="year_passed"
                        value={e?.year_passed}
                        onChange={(e) => {
                          handleEducationalNumberChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-6 col-sm-2">
                      <CommonForm
                        fieldType="text"
                        label="Mark"
                        required
                        name="mark"
                        minlength="1"
                        maxLength="4"
                        value={e?.mark}
                        onChange={(e) => {
                          handleEducationalNumberChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-12">
                      <CommonForm
                        fieldType="file"
                        // required
                        label="Upload self attested photocopy of certificate"
                        name="certificate_photo"
                        {...props}
                        id="upload_proposal"
                        className="no-margin"
                        fileName={e?.file_name}
                        required={!e?.file_name ? true : false}
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        variant="outlined"
                        onChange={(e) => {
                          handleEducationalFileChange(e, i);
                        }}
                      />
                      <div className="file-up-helper">
                        *Upload only .jpg , .jpeg ,.png format and Size should
                        be less than 1MB
                      </div>
                    </div>
                  </div>
                </div>

                <div className="more-btn-cnt">
                  {formData.qualifications.length === i + 1 && (
                    <button
                      style={{ marginLeft: 5 }}
                      type="button"
                      {...props}
                      className="btn btn-primary"
                      onClick={(e) => addeducationalQualifications(i)}
                    >
                      Add More
                    </button>
                  )}
                  {formData.qualifications.length > 1 && (
                    <button
                      {...props}
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => removeducationalQualifications(i)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="footer-buttons">
          <button type="submit" className="primary-btn">
            SUBMIT TO PREVIEW
          </button>
        </div>
      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
