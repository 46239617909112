import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import {
  user_company_domain_list,
  update_company_registration,
  admin_company_details,
} from "../admin.api";
import { useParams } from "react-router-dom";
import BasicDetails from "../common/addCompany/addCompany";

import "./style.css";
import CommonForm from "../../../components/commonform/commonForm";
import { EmailValidate } from "../../../components/formValidate";
import TextEditer from "../../../components/inputs/texteditor";
let flag = false;
export default function EditIndiaRequest(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [basicData, setBasicData] = useState({});
  const [keyvalue, setKeyvalue] = useState(0);
  const [domainData, setDomain] = useState([]);
  const [count, setCount] = useState(1);

  const [error, setError] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const selectedParam = useParams();
  const getDomainTypes = () => {
    setIsActive(true);
    user_company_domain_list().then((res) => {
      let domData = res?.data;
      // setDomain(res?.data);
      setCount(count + 1);
      getDetails(domData);
      setIsActive(false);
    });
  };
  const handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    if (value.length <= 10) {
      let newValue = value.replace(/[^0-9]/g, "");
      let data = basicData;
      data = {
        ...data,

        [name]: newValue,
      };
      setBasicData(data);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();

    form.append("company_name", basicData.company_name ?? null);
    form.append("phone_number", basicData.mobile_number);
    form.append("company_type", "india");
    form.append("person_name", basicData.person_name ?? null);
    form.append("person_designation", basicData.person_designation ?? null);
    form.append("address", basicData.address ?? null);
    form.append("email", basicData.email ?? null);
    if (
      basicData.country_code &&
      basicData.country_code !== "" &&
      basicData.alternate_phone &&
      basicData.alternate_phone !== ""
    ) {
      form.append("country_code", basicData.country_code);
    }

    form.append("alternate_phone", basicData.alternate_phone ?? "");

    form.append("alternate_email", basicData.alternate_email ?? "");

    form.append(
      "abstract_project_proposal",
      basicData.abstract_project_proposal ?? null
    );
    form.append("upload_proposal", basicData.upload_proposal ?? "");
    form.append("capital_investment", basicData.capital_investment ?? null);
    // form.append("previous_fy_turnover", basicData.previous_fy_turnover ?? null);
    form.append("ex_man_power", basicData.ex_man_power ?? null);
    form.append("site_link", basicData.site_link ?? null);
    // form.append("product_profiles", basicData.product_profiles ?? null);

    form.append("any_other_flag", basicData.any_other_flag == true ? "1" : "0");
    form.append(
      "any_other_domain",
      basicData.any_other_flag == true ? basicData.any_other_domain : ""
    );
    form.append("file_name", basicData.file_name ?? "");
    if (basicData.id) form.append("application_id", basicData.id);
    basicData.product_profiles.forEach((item, index) => {
      form.append(
        `product_profiles[${index}][product_name]`,
        item.product_name
      );
      form.append(
        `product_profiles[${index}][product_desc]`,
        item.product_desc
      );
    });
    // update-api: admin/update api from userside
    domainData.forEach((item, index) => {
      if (item.checked) {
        form.append(`domain_types[${index}][domain_name]`, item.domain_name);
        form.append(`domain_types[${index}][id]`, item.id);
      }
    });

    setIsActive(true);
    console.log(form);
    update_company_registration(selectedParam.id, form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  useEffect(() => {
    getDomainTypes();

    // handleChangedata("3");
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});

      setDomain([]);
      props.history.push("/india-companies-list");
    }
  };
  const getDetails = (domData) => {
    let otherDom = [];
    admin_company_details(selectedParam.id).then((res) => {
      let Data = res?.data;
      otherDom = Data?.domain_types?.filter(
        (ar1) => !domData.some((ar2) => ar1?.domain_name === ar2?.domain_name)
      );

      domData?.forEach((item) => {
        item["checked"] = Data?.domain_types?.some(
          (dom) => item?.domain_name === dom?.domain_name
        );
      });
      setDomain(domData);
      let d = {
        ...Data,
        any_other_flag:
          Data.any_other_flag && Data.any_other_flag !== "0" ? true : false,
        any_other_domain:
          Data.any_other_domain && Data.any_other_flag !== "0"
            ? Data.any_other_domain
            : "",
      };
      setBasicData({
        ...d,
        mobile_number: Data.phone_no,
        file_name: Data?.upload_proposal,
        product_profiles: Data?.product_profiles,
        country_code:
          Data.country_code === "null" || Data.country_code === "undefined"
            ? ""
            : Data.country_code,
      });
      setCount(count + 1);
    });
    setIsActive(false);
  };
  // useEffect(() => {
  //   getDetails(selectedParam.id);
  // }, []);
  const textFieldChange = (e) => {
    if (e.target.name === "email" || e.target.name === "alternate_email") {
      let erroremail = !EmailValidate(e.target.value);

      setError({ ...error, [e.target.name]: erroremail });
    }
    let name = e.target.name;

    let value = e.target.value;
    let data = basicData;
    data[name] = value;
    setBasicData(data);
    setKeyvalue(keyvalue + 1);
  };
  const addNewProductProfile = (i) => {
    let data = { ...basicData };

    data.product_profiles.push({ product_name: "", product_desc: "" });
    setBasicData(data);
    setCount(count + 1);
  };
  const RemoveProduct = (i) => {
    let data = { ...basicData };
    data.product_profiles.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleMultiChange = (e, i) => {
    let data = { ...basicData };

    data.product_profiles[i] = {
      ...data.product_profiles[i],
      [e.target.name]: e.target.value,
    };

    setBasicData(data);
    setCount(count + 1);
  };
  const handleProductDesc = (val, i) => {
    console.log(val);
    console.log(i);
    let data = { ...basicData };

    data.product_profiles[i] = {
      ...data.product_profiles[i],

      product_desc: val,
    };

    setBasicData(data);
    setCount(count + 1);
  };

  const handleCheckChange = (e, item) => {
    flag = false;
    let data = [...domainData];

    data[item]["checked"] = e.target.checked;
    //setBasicData({ ...basicData, any_other_flag: false });
    if (domainData) {
      domainData.forEach((item) => {
        if (item.checked) flag = true;
      });
    }
    setCount(count + 1);
    setDomain(data);
  };
  const handleValid = () => {
    let flags = false;
    if (domainData) {
      domainData.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const handleOtherChange = (e) => {
    // let data = [...props.domainData];
    // data.forEach((item) => {
    //   item["checked"] = false;
    // });
    //
    // props.setDomain(data);
    setBasicData({ ...basicData, [e.target.name]: e.target.checked });
    // setCount(count + 1);
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type?.toLowerCase() === "pdf" || type?.toLowerCase() === "doc" || type?.toLowerCase() === "docx") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setBasicData({
          ...basicData,
          file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });

        // handlefile();
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setBasicData({
          ...basicData,
          file_name: null,
          [e.target.name]: null,
        });
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setBasicData({
        ...basicData,
        file_name: null,
        [e.target.name]: null,
      });
    }
  };
  const handleNumberChange = (e) => {
    let data = { ...basicData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setBasicData(data);
  };
  return (
    <>
      <Loader open={isActive} />
      <div className="add-per">
        <div className="view-container">
          <form onSubmit={handleSubmit}>
            <h6>
              <b>Update International Company</b>
            </h6>
            <hr />
            <div className="row">
              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="text"
                  label="Company Name"
                  required
                  name="company_name"
                  // disabled
                  value={basicData.company_name ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>

              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="text"
                  label="Contact Person Name"
                  required
                  name="person_name"
                  value={basicData.person_name ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>

              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="text"
                  label="Contact Person Designation"
                  required
                  name="person_designation"
                  value={basicData.person_designation ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>

              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="multiline"
                  label="Address"
                  required
                  name="address"
                  value={basicData.address ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>

              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="email"
                  label="Email"
                  error={error.email}
                  required
                  // disabled
                  name="email"
                  value={basicData.email ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>

              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="text"
                  label="Phone Number"
                  maxLength="10"
                  minLength="10"
                  pattern="[0-9]{10}"
                  value={basicData?.mobile_number}
                  required
                  onChange={(e) => {
                    handlePhoneNumberChange(e);
                  }}
                  name="mobile_number"
                  {...props}
                />
              </div>
              <div className="col-8 mb-2">
                <label className="control-label mylabel mb-2">
                  Alternate Phone
                </label>

                <div className="d-flex align-items-end w-100">
                  <div className="">
                    <CommonForm
                      fieldType="text"
                      error={error.country_code}
                      name="country_code"
                      maxLength="4"
                      value={basicData?.country_code ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      {...props}
                    />
                  </div>
                  <div className="p-1"></div>
                  <div className="col-10">
                    <CommonForm
                      fieldType="text"
                      error={error.alternate_phone}
                      name="alternate_phone"
                      maxLength="10"
                      value={basicData?.alternate_phone ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      {...props}
                    />
                  </div>
                </div>
              </div>
              <div className="col-8 mb-2">
                <CommonForm
                  fieldType="email"
                  label="Alternative Email"
                  error={error.alternate_email}
                  name="alternate_email"
                  value={
                    basicData.alternate_email &&
                    basicData.alternate_email !== "null"
                      ? basicData.alternate_email
                      : ""
                  }
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>

              <div className="col-8 mb-2 mt-2">
                <CommonForm
                  fieldType="multiline"
                  label="Abstract Project Proposal"
                  required
                  name="abstract_project_proposal"
                  value={basicData.abstract_project_proposal ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>
            </div>

            <div className="col-8 mb-2">
              <div>
                {basicData.product_profiles?.map((e, i) => (
                  <div className="section-datas-cont" key={i}>
                    <div className="header-label">Product Profile</div>

                    <div className="section-Cont">
                      <CommonForm
                        fieldType="text"
                        label="Product Name"
                        required
                        name="product_name"
                        value={e?.product_name}
                        onChange={(e) => {
                          handleMultiChange(e, i);
                        }}
                        {...props}
                      />
                      <TextEditer
                        // fieldType="multiline"
                        label="Product Description (Required)"
                        required
                        name="product_desc"
                        value={e?.product_desc}
                        keyValue={i}
                        // key={`${i}key${props.keyValue}`}
                        onChange={handleProductDesc}
                        {...props}
                      />
                    </div>

                    <div className="more-btn-cnt">
                      {basicData.product_profiles.length === i + 1 && (
                        <button
                          style={{ marginLeft: 5 }}
                          type="button"
                          {...props}
                          className="btn btn-primary"
                          onClick={(e) => addNewProductProfile(i)}
                        >
                          Add More
                        </button>
                      )}
                      {basicData.product_profiles.length > 1 && (
                        <button
                          {...props}
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => RemoveProduct(i)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-8 mb-2">
              <div>
                <div className="header-label">Domain Type</div>
                <div className="check-box-cont">
                  {domainData &&
                    domainData.map((item, index) => (
                      <CommonForm
                        fieldType="checkbox"
                        value={item.checked}
                        required={!basicData.any_other_flag && !handleValid()}
                        checked={item.checked}
                        onChange={(e) => handleCheckChange(e, index)}
                        label={item.domain_name}
                        {...props}
                      />
                    ))}

                  <CommonForm
                    fieldType="checkbox"
                    onChange={handleOtherChange}
                    label="Any Other"
                    name="any_other_flag"
                    checked={basicData.any_other_flag}
                    value={basicData.any_other_flag}
                    {...props}
                  />
                </div>

                {/* if any other field checked ,below is mandatory */}
                {basicData.any_other_flag &&
                  basicData.any_other_flag !== "0" && (
                    <div>
                      <CommonForm
                        fieldType="text"
                        placeholder="Please specify the Domain.."
                        required
                        name="any_other_domain"
                        value={basicData.any_other_domain ?? ""}
                        onChange={(e) => {
                          textFieldChange(e);
                        }}
                        {...props}
                      />
                    </div>
                  )}
                {/* ////////////////////////////// */}
              </div>
              {/* ///////////////////////////////// */}
              {/* file upload */}
            </div>

            <div className="col-8 mb-2">
              <div>
                {/* <div className="file-up-label-cont">Upload Proposal</div> */}

                <CommonForm
                  fieldType="file"
                  required={!basicData.file_name ? true : false}
                  name="upload_proposal"
                  {...props}
                  label="Upload Proposal"
                  id="upload_proposal"
                  fileName={basicData.file_name}
                  accept=".pdf,.docx,.doc"
                  type="file"
                  variant="outlined"
                  onChange={handleFileChange}
                />

                <div className="file-up-helper">
                  *Upload only .pdf , .doc, .docx and Size should be less than
                  5MB
                </div>
              </div>
            </div>

            <div className="col-8 mb-2">
              {/* ////////////////////////// */}
              {/* //////// Others///////////// */}

              <CommonForm
                fieldType="number"
                label="Capital investment so far in lakhs"
                required
                name="capital_investment"
                value={basicData.capital_investment ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>

            <div className="col-8 mb-2">
              <CommonForm
                fieldType="number"
                label=" Expected Direct employment as on Date"
                required
                name="ex_man_power"
                value={basicData.ex_man_power ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>

            {/* ///////////////////////////////////// */}
            {/* ///actions ////////////// */}
            <div className="">
              <div className="col-8 mb-2">
                <center>
                  <button type="submit" className="primary-btn basic-submit">
                    SUBMIT
                  </button>
                </center>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </>
  );
}
