import React, { useState, useEffect } from "react";

import SearchButton from "../../../components/buttons/search/search-btn";
import CustomTable from "../../../components/table/table";
import "./view.css";
import Snackbar from "../../../components/snackbar/snackbar";
import CSVExport from "../../../components/csvExport";
import { user_company_list, super_comp_delete, admin_csv } from "../admin.api";
import Loader from "../../../components/loader/loader";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import jwtDecode from "jwt-decode";
import { statusRegType } from "../../../components/table/store";
import moment from "moment";
import CommonForm from "../../../components/commonform/commonForm";
const adminStatus = [{ id: 0, label: "Pending" }];

export default function View(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [view, setView] = useState(false);
  const [data, setData] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [status, setStatus] = useState(9);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [upl, setUpl] = useState(false);
  const [file, setFile] = useState({ file_name: "" });
  let statusData = [
    { id: 9, label: "All" },
    { id: 0, label: "Pending" },
    { id: 1, label: "Submitted for Acceptance" },
    {
      id: 4,
      label: "Approve",
    },
    { id: 5, label: "Reject" },
  ];

  const handleEdit = (data) => {
    let path = "/edit-application-india/" + data.user_id;
    props.history.push(path);
  };
  const handleView = (data) => {
    let path = "/view-application-india/" + data.user_id;
    props.history.push(path);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  async function fetchExportData() {
    const exportdata = [];
    data.forEach((item) => {
      exportdata.push({
        ["Company Name"]: item.company_name,
        ["Phone Number"]: item.phone_no,
        POC : item?.person_name,
        ["POC Designation"] : item?.person_designation,
        ["Address"] : item?.address,
        Email: item.email,
        Date: item.created_at
          ? moment(item.created_at).format("DD-MMM-YYYY hh:mm A")
          : "--",
        // Status:
        //   item.project_status === 0
        //     ? "Pending"
        //     : item.project_status === 1
        //     ? "Submitted"
        //     : item.project_status === 2
        //     ? "Accepted"
        //     : item.project_status === 3
        //     ? "SA Reverted"
        //     : item.project_status === 5
        //     ? "Reverted"
        //     : "Rejected",
      });
    });
    return exportdata;
  }
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    super_comp_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleListData = () => {
    console.log(adminType);
    setIsActive(true);
    let param = {
      type: "india",
      status: status === 9 ? "" : status,
      isadmin: 1,
    };
    user_company_list(param, adminType).then((resp) => {
      setData(resp.data);
      setIsActive(false);
    });
  };
  const onSearch = () => {
    handleListData();
  };
  useEffect(() => {
    handleListData();
  }, []);
  const handleNew = (e) => {
    props.history.push("/newrequest-application-india");
  };
  const handleChangeStatus = (e) => {
    let val = Number(e.target.value);
    console.log(val);
    setStatus(val);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleListData();
    }
  };

  const upload = () => {
    setUpl(!upl);
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    console.log(type);

    if (
      type?.toLowerCase() === "xls" ||
      type?.toLowerCase() === "xlsx" ||
      type?.toLowerCase() === "ods"
    ) {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFile({
          file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
        if (props.update) {
          props.handlefile();
        }
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFile({
          file_name: "",
          [e.target.name]: null,
        });
      }
    } else {
      setSnackMessage("File should be in .xls , .xlsx format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFile({
        file_name: "",
        [e.target.name]: null,
      });
    }
  };
  const handleCSVUpload = () => {
    console.log(file);
    const payload = new FormData();
    payload.append("file", file.company_upload);
    admin_csv(payload)
      .then((res) => {
        setSnackMessage("CSV Uploaded Successfully");
        setIsSnackError(false);
        setSnackOpen(true);
        setFile({ file_name: "" });
        setUpl(false);
      })
      .catch((err) => {
        setSnackMessage("Something Went Wrong");
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />

      <Loader open={isActive} />
      <div className="view-container">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">
            <b>Companies in India</b>
          </h6>
          <div className="d-flex">
            {data?.length !== 0 && 
            <CSVExport title="Companies-India" fetchData={fetchExportData} />}
            <div className="p-2"></div>
            {upl === false ? (
              <button
                type="button"
                className=" btn btn-primary btn-sm"
                onClick={(e) => upload(e)}
              >
                Upload Csv
              </button>
            ) : (
              <>
                <div>
                  {/* <div className="file-up-label-cont">Upload Proposal</div> */}

                  <CommonForm
                    fieldType="file"
                    required={upl ? true : false}
                    name="company_upload"
                    {...props}
                    className="p-0"
                    fileHeightAdjust={true}
                    // label="Upload Company Brochure / Leaflets"
                    id="company_upload"
                    fileName={file.file_name ?? "Upload file"}
                    accept=".xls,.xlsx"
                    type="file"
                    variant="outlined"
                    onChange={handleFileChange}
                  />

                  {/* <div className="file-up-helper">
                *Upload only .xls or .xlsx files and Size should be less than 2MB
              </div> */}
                </div>{" "}
                <div className="p-1"></div>{" "}
                <button
                  type="button"
                  className=" btn btn-primary btn-sm"
                  onClick={(e) => handleCSVUpload(e)}
                >
                  Upload
                </button>{" "}
              </>
            )}
          </div>
        </div>
        <hr />

        <div className="row font-z">
          <div className="col-3 ">
            {/* <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleChangeStatus}
            >
              <option
                value={9}
                // key={key}
                selected={status}
              >
                All
              </option>
              {statusRegType.map((item, key) => (
                <>
                  {item.id !== 6 && (
                    <option
                      value={item.id}
                      key={key}
                      selected={item.id === status}
                    >
                      {item.title}
                    </option>
                  )}
                </>
              ))}
            </select> */}
          </div>

          <div className="col-1 d-flex">
            {/* <SearchButton {...props} onClick={onSearch} /> */}
          </div>
          {adminType === 1 && (
            <div className="col-8 d-flex justify-content-end pb-2">
              <button
                type="button"
                className=" btn btn-primary btn-sm"
                onClick={(e) => handleNew(e)}
              >
                Add New Company
              </button>
            </div>
          )}
        </div>
        <CustomTable
          {...props}
          columns={[
            { title: "Company Name", field: "company_name" },
            // { title: "Application ID", field: "id" },
            { title: "Phone Number", field: "phone_no" },
            { title: "Email", field: "email" },
            // { title: "Current Project Status", field: "projectstatus" },

            { title: "Status", field: "project_status", type: "regstatusType" },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },
                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                  // { type: "delete", handleClick: handleDelete },
                ]
          }
        />
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Company ?"
          data={activeData}
          message="Are you sure to delete this Company?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
