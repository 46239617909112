import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../../components/table/table";
import ResourseCard from "../../../../components/page/tiles/resourcecard";
import {
  candidate_download,
  pdf_download,
  user_get_candidate_details,
  user_get_manpower_details,
} from "../../user.api";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";
import "../style.css";
import BreadCrumbsComp from "../../../../components/navigator/breadcrumbs";
import Loader from "../../../../components/loader/loader";
import { useReactToPrint } from "react-to-print";
import Snackbar from "../../../../components/snackbar/snackbar";
let count = 100;
export default function ResourcePersonalDetails(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let result = servInstance.substring(0, servInstance?.length - 4);
  const pageRef = useRef();
  const getDetails = (id) => {
    user_get_candidate_details(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadIdCert = (data) => {
    let path = `${data}`;
    window.open(path, "_blank");
  };
  const handleCompanyDownload = () => {
    const payload = {
      candidate_id: selectedParam.id,
    };
    candidate_download(payload).then(() => {
      pdf_download(selectedParam.id)
        .then((res) => {
          window.open(res.pdf, "_blank");
        })
       
    }) .catch((error) => {
      setSnackOpen(true);
      setSnackMessage(error.response.data.error);
      setIsSnackError(true);
    });;
    // pdf_download(selectedParam.id).then((res) => {
    //   // console.log(res)
    //   window.open(res.pdf, "_blank");
    // });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleDownload = async () => {
    handleCompanyDownload();
    // handlePrint()
  };
  const handlePrint = useReactToPrint({
    content: () => pageRef.current,
    documentTitle: "Downloaded Candidate Details",
    // onAfterPrint: () => alert("Print success!"),
  });
  return (
    <>
    <div className="tender bg-tender">
      {/* <Loader open={isActive} /> */}
      <BreadCrumbsComp section="candidates_details" />
      <div className="container p-50">
        <div className="res-page-cont-details">
          <div className="res-det-con " ref={pageRef}>
            <div className="row tender-head ">
              <h4> Candidate Details </h4>
              <div>
                <div className="container-ul">
                  <hr className="top hr" />
                  <hr className="bottom hr" />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-xs-12 ">
                <div className="profile-box ">
                  <div className="res-bannerContainer d-flex ">
                    <div className="res-ban-image">
                      {/* <img
                        src="/resources/img/userdashbanner.png"
                        className="w-100 res-ban-img"
                        alt=""
                      /> */}
                    </div>
                    <div className="resbandata py-5 mt-2">
                      <div className="res-ban-data">
                        {!data?.photo ? (
                          <div className="res-name">
                            {data?.name?.charAt(0)}
                          </div>
                        ) : (
                          <div>
                            <img
                              src={`${data?.photo}`}
                              className="res-profile-img"
                              alt=""
                            />
                          </div>
                        )}
                        <div className="profile-sres-con">
                          <div className="res-person-name">
                            {data?.prefix ? data?.prefix + "." : "Mr."}{" "}
                            {data?.name ?? ""}
                          </div>
                          <div className="qualification-container">
                            <div className="res-exp mb-1">Qualification :</div>
                            <div className="exp-values ">
                              {data?.qualification ?? ""}
                              {`(${data?.qualification_type})`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="res-about-sec">
                    <div className="res-abo-head">About</div>
                    <hr />
                    <div className="res-about-cont">
                      <div className="row">
                        <div className="col-4 ">Native of Kerala</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {" "}
                          {data?.native_kerala == 1 ? "Yes" : "No" ?? ""}
                        </div>
                        {data?.native_kerala == 0 && (
                          <>
                            <div className="col-4 ">NRI Keralite</div>
                            <div className="col-1 p-1">:</div>
                            <div className="col-7"> Yes</div>{" "}
                          </>
                        )}

                        <div className="col-4 ">Fresher</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.fresher ?? ""}</div>
                        <div className="col-4 ">Name</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {" "}
                          {data?.prefix ? data?.prefix + "." : "Mr."}{" "}
                          {data?.name ?? ""}{" "}
                        </div>

                        <div className="col-4 ">Date of Birth</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.dob}</div>

                        <div className="col-4 ">Gender</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.gender}</div>

                        <div className="col-4 ">Address</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.address}</div>

                        <div className="col-4 ">Email</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.email}</div>

                        <div className="col-4 ">Marital Status</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.marital_status}</div>

                        <div className="col-4 ">Father / Spouse Name</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.parent_name}</div>

                        <div className="col-4 ">Caste</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.caste}</div>

                        <div className="col-4 ">Physiclly Challenged</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.handicapped}</div>

                        <div className="col-4 ">Phone Number</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">+91 {data?.contact}</div>

                        <div className="col-4 ">Alternate Email</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.alt_email}</div>

                        <div className="col-4 ">Alternate Phone</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {data?.alt_code ? "+" : ""}
                          {data?.alt_code} {data?.alt_contact}
                        </div>

                        <div className="col-4 ">Pin Code</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.pin_code}</div>

                        <div className="col-4 ">Blood Group</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.blood_group}</div>
                        <div className="col-4 ">Identity File Type</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.identity_type}</div>

                        <div className="col-4 ">Identity Number</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.identity_no}</div>

                        {/* <div className="col-4 ">
                          Identity Number
                        </div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {data?.identity_no}
                        </div> */}

                        <div className="col-4 ">Identity File Copy</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          <div
                            className="text-warning"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              handleDownloadIdCert(data?.identity_file)
                            }
                          >
                            Download File
                          </div>
                        </div>

                        <div className="col-4 ">Highest Qualification</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.qualification_type}</div>

                        <div className="col-4 ">Qualification</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.qualification}</div>

                        {/* <div className="col-4 ">
                          Area of Interest
                        </div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {data?.area_of_interest && (
                            <>
                              {data?.area_of_interest?.map((item, index) => (
                                <>
                                  {index !== 0 && <>&nbsp;|&nbsp;</>}
                                  {item.expertise_name}
                                </>
                              ))}
                            </>
                          )}
                        </div> */}

                        <div className="row-padding">
                          <div className="col-12 max-bold">
                            Educational Qualification
                          </div>

                          <div className="col-12  overflow-auto">
                            {" "}
                            <CustomTable
                              className="areatable"
                              {...props}
                              columns={[
                                {
                                  title: " Board/University",
                                  field: "university",
                                },
                                { title: "Location", field: "location" },
                                { title: "Year of Passing", field: "year" },
                                { title: "Grade", field: "grade" },
                                { title: "GPA", field: "gpa" },
                                { title: "Percentage", field: "percentage" },
                              ]}
                              data={data?.education ?? []}
                            />
                          </div>
                        </div>
                        <div className="row-padding">
                          <div className="col-12  max-bold">
                            Experience Details
                          </div>
                          <hr />
                          {data &&
                            data?.employment &&
                            data?.employment?.length !== 0 && (
                              <>
                                {data?.employment?.map((item, index) => (
                                  <div className="white">
                                    <div className="d-flex">
                                      <div className="col-4">Employer </div>
                                      <div className="col-1">:</div>
                                      <div className="col-7">
                                        {item.employer}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-4">Contact : </div>
                                      <div className="col-1">:</div>
                                      <div className="col-7">{`${item.contact}`}</div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-4">Email</div>
                                      <div className="col-1">:</div>
                                      <div className="col-7">{item.email}</div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-4">Position</div>

                                      <div className="col-1">:</div>
                                      <div className="col-7">
                                        {item.position}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-4">
                                        Currently Working?
                                      </div>

                                      <div className="col-1">:</div>
                                      <div className="col-7">
                                        {item.working == "Yes" ? "Yes" : "No"}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-4">Period</div>
                                      <div className="col-1">:</div>
                                      <div className="col-7">
                                        {item.from}
                                        {item.working == "No" && (
                                          <> - {item?.to}</>
                                        )}
                                      </div>
                                    </div>
                                    {item.working != "Yes" && (
                                      <div className="d-flex">
                                        <div className="col-4">
                                          Reason for Leaving
                                        </div>
                                        <div className="col-1">:</div>{" "}
                                        <div className="col-7">
                                          {item.reason}
                                        </div>
                                      </div>
                                    )}
                                    <div className="d-flex">
                                      <div className="col-4">Address</div>
                                      <div className="col-1">:</div>
                                      <div className="col-7">
                                        {item.address}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-4">Certificate</div>
                                      <div className="col-1">:</div>
                                      <div className="col-7">
                                        <div
                                          className="text-warning"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) =>
                                            handleDownloadIdCert(
                                              item.exp_certificate
                                            )
                                          }
                                        >
                                          Download File
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                  </div>
                                ))}
                              </>
                            )}
                        </div>

                        <div className="row-padding">
                          <div className="col-12  max-bold">Reference 1</div>
                          <hr />

                          <>
                            <div className="white">
                              <div className="d-flex">
                                <div className="col-4">Name </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_name1}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Designation </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_designation1}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Contact </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_contact1}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Email </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_email1}
                                </div>
                              </div>

                              <hr />
                            </div>
                          </>
                        </div>

                        <div className="row-padding">
                          <div className="col-12  max-bold">Reference 2</div>
                          <hr />

                          <>
                            <div className="white">
                              <div className="d-flex">
                                <div className="col-4">Name </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_name2}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Designation </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_designation2}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Contact </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_contact2}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-4">Email </div>
                                <div className="col-1">:</div>
                                <div className="col-7">
                                  {data.reference_email2}
                                </div>
                              </div>

                              <hr />
                            </div>
                          </>
                        </div>

                        <div className="col-4 ">Area of Interest</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">{data?.interest_type}</div>

                        <div className="col-4 ">Interests</div>
                        <div className="col-1 p-1">:</div>
                        <div className="col-7">
                          {data &&
                            data?.interest &&
                            data?.interest.length !== 0 && (
                              <>
                                {JSON.parse(data?.interest)?.map(
                                  (item, index) => (
                                    <div>{item}</div>
                                  )
                                )}
                                {data?.any_other_domain && (
                                  <div>{data?.any_other_domain}</div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <iframe src={`https://kspace.inovace.in/storage/uploads/applications/candidates/exp_certificates/1724320545_test.pdf`} style={{height:"1000px", width:"100%"}}/> */}
            <div className="d-flex mt-3 justify-content-center">
              <button
                type="button"
                className="btn btn-approve btn-success m"
                onClick={(e) => handleDownload()}
              >
                Download
              </button>
            </div>
        
          </div>
        </div>
      </div>
     
     
    </div>
        <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      </>
  );
}
