import moment from "moment";
import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { common_file_upload, is_email_registered } from "../../../user.api";
let eighteenYearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
export default function AreaDetails(props) {
  const admin_type = [
    {
      domain_name: "Admin/HR",
      checked: false,
    },
    {
      domain_name: "Finance/Accounts",
      checked: false,
    },
    {
      domain_name: "Marketing/Sales",
      checked: false,
    },
  ];

  const technical_type = [
    {
      domain_name: "Design/Analysis",
      checked: false,
    },
    {
      domain_name: "Production",
      checked: false,
    },
    {
      domain_name: "Planning",
      checked: false,
    },
    {
      domain_name: "Operations",
      checked: false,
    },
    {
      domain_name: "Quality Control",
      checked: false,
    },
    {
      domain_name: "Testing",
      checked: false,
    },
    {
      domain_name: "Supervision",
      checked: false,
    },
    {
      domain_name: "Technician",
      checked: false,
    },
  ];

  const management_type = [
    {
      domain_name: "Project Management",
      checked: false,
    },
    {
      domain_name: "Operations Management",
      checked: false,
    },
    {
      domain_name: "Logistics Management",
      checked: false,
    },
    {
      domain_name: "Planning",
      checked: false,
    },
    {
      domain_name: "Finance",
      checked: false,
    },
  ];

  const [formData, setFormData] = useState(props.data);
  const [count, setCount] = useState(1);
  const [keyvalue, setKeyvalue] = useState(0);
  const [defaultkey, setdefaultkey] = useState(props.defaultkey);
  const [err, setErr] = useState(false);
  const [pinErr, setPinErr] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [decOne, setDecOne] = useState([]);
  const [decTwo, setDecTwo] = useState(false);
  const [intArea, setIntArea] = useState([]);
  useEffect(() => {
    setFormData(props.data);
    setCount(count + 1);
  }, [props.data]);
  console.log(props.data);
  const handlepreview = (e) => {
    e.preventDefault();
    let maxDate = new Date(moment(new Date(eighteenYearsAgo)));
    let emailData = { email: formData.email };
    let refEmail1 = formData.reference_email1;
    let refEmail2 = formData.reference_email2;
    let Data = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    let selectedareaofinterest = [];
    formData?.employment.forEach((item, i) => {
      if (emailRegex.test(item?.email) === false && formData?.fresher === "No") {
        setDecOne(...decOne);
      }
    });
    props.areaOfInterest.forEach((item) => {
      if (item.checked) {
        selectedareaofinterest.push(item);
      }
    });
    Data = {
      ...Data,
      area_of_interest: selectedareaofinterest,
    };
    if (moment(formData.dob, "DD-MM-YYYY") <= moment(maxDate, "DD-MM-YYYY")) {
      if (emailRegex.test(formData?.email) === true)
        if (!props.update) {
          is_email_registered(emailData)
            .then((res) => {
              if (
         
                formData.identity_file === null ||
                formData.identity_file === undefined ||
                formData.identity_file === ""
              ) {
                setSnackMessage("Please choose a valid identification file");
                setIsSnackError(true);
                setSnackOpen(true);
              } else {
                let validExp = true;
                formData.employment.forEach((item) => {
                  if (
                    formData?.fresher === "No" &&(
                    item.exp_certificate === null ||
                    item.exp_certificate === undefined ||
                    item.exp_certificate === "")
                  ) {
                    setSnackMessage(
                      `Please choose a valid experience certificate file `
                    );
                    setIsSnackError(true);
                    setSnackOpen(true);
                    validExp = false;
                  }
                });

                if (validExp) {
                  let validEduc = true;
                  // formData.education.forEach((item) => {
                  //   if (
                  //     item.file_upload_certificate === null ||
                  //     item.file_upload_certificate === undefined ||
                  //     item.file_upload_certificate === ""
                  //   ) {
                  //     setSnackMessage(
                  //       `Please choose a valid educational certificate file for ${item.examination_passed}`
                  //     );
                  //     setIsSnackError(true);
                  //     setSnackOpen(true);
                  //     validEduc = false;
                  //   }
                  // });
                  if (validEduc) {
                    if (
                      formData?.native_kerala &&
                      formData?.gender &&
                      formData?.marital_status &&
                      formData?.handicapped &&
                      formData?.caste &&
                      formData?.qualification_type &&
                      formData?.qualification &&
                      formData?.interest_type &&
                      formData?.fresher
                    ) {
                      if (
                        emailRegex.test(formData?.reference_email1) === true
                      ) {
                        if (
                          emailRegex.test(formData?.reference_email2) === true
                        ) {
                          if (decOne.length === 0) {
                            if (formData?.conf1 && formData?.conf2) {
                              if(formData?.photo){
                                props.action(0, Data, "basicData");
                              } else {
                                setSnackMessage(`Plerase select a photo`);
                              setIsSnackError(true);
                              setSnackOpen(true);
                              }
                              
                            } else {
                              setSnackMessage(`Please check the disclaimers`);
                              setIsSnackError(true);
                              setSnackOpen(true);
                            }
                          } else {
                            setSnackMessage(
                              `Please check the emails provided for experience details`
                            );
                            setIsSnackError(true);
                            setSnackOpen(true);
                          }
                        } else {
                          setSnackMessage(
                            `Please choose a valid email for reference 2 `
                          );
                          setIsSnackError(true);
                          setSnackOpen(true);
                        }
                      } else {
                        setSnackMessage(
                          `Please choose a valid email for reference 1 `
                        );
                        setIsSnackError(true);
                        setSnackOpen(true);
                      }
                    } else {
                      setSnackMessage(`Please fill in all the required fields`);
                      setIsSnackError(true);
                      setSnackOpen(true);
                    }
                  }
                }
              }
              // setSnackOpen(true);
            })
            .catch((error) => {
              setSnackMessage(error.response.data.message);
              setIsSnackError(true);
              setSnackOpen(true);
              // setIsActive(false);
            });
        } else {
          if (
            formData.identity_file === null ||
            formData.identity_file === undefined ||
            formData.identity_file === ""
          ) {
            setSnackMessage("Please choose a valid identification file");
            setIsSnackError(true);
            setSnackOpen(true);
          } else {
            let validExp = true;
            formData.employment.forEach((item) => {
              if (
                item.exp_certificate === null ||
                item.exp_certificate === undefined ||
                item.exp_certificate === ""
              ) {
                setSnackMessage(
                  `Please choose a valid experience certificate file for ${item.employer}`
                );
                setIsSnackError(true);
                setSnackOpen(true);
                validExp = false;
              }
            });

            if (validExp) {
              let validEduc = true;
              // formData.education.forEach((item) => {
              //   if (
              //     item.file_upload_certificate === null ||
              //     item.file_upload_certificate === undefined ||
              //     item.file_upload_certificate === ""
              //   ) {
              //     setSnackMessage(
              //       `Please choose a valid educational certificate file for ${item.examination_passed}`
              //     );
              //     setIsSnackError(true);
              //     setSnackOpen(true);
              //     validEduc = false;
              //   }
              // });
              if (validEduc) {
                if (formData?.conf1 && formData?.conf2) {
                  if (emailRegex.test(formData?.reference_email1) === true) {
                    if (emailRegex.test(formData?.reference_email2) === true) {
                      if (decOne.length === 0) {
                        if(formData?.photo){
                          props.action(0, Data, "basicData");
                        } else {
                          setSnackMessage(`Please check the disclaimers`);
                        setIsSnackError(true);
                        setSnackOpen(true);
                        }
                      } else {
                        setSnackMessage(
                          `Please check the emails provided for experience details`
                        );
                        setIsSnackError(true);
                        setSnackOpen(true);
                      }
                    } else {
                      setSnackMessage(
                        `Please choose a valid email for reference 2 `
                      );
                      setIsSnackError(true);
                      setSnackOpen(true);
                    }
                  } else {
                    setSnackMessage(
                      `Please choose a valid email for reference 1 `
                    );
                    setIsSnackError(true);
                    setSnackOpen(true);
                  }
                } else {
                  setSnackOpen(true);
                  setSnackMessage("Please check the disclaimers");
                  setIsSnackError(true);
                }
              }
            }
          }
        }
      else {
        setSnackOpen(true);
        setSnackMessage("Please enter a valid email");
        setIsSnackError(true);
      }
    } else {
      setSnackMessage(`Please Choose a valid Date of Birth`);
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleOtherChange = (e) => {
    // let data = [...props.domainData];
    // data.forEach((item) => {
    //   item["checked"] = false;
    // });
    //
    // props.setDomain(data);
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleDatePickerChange = (e, name) => {
    let date = e.value;
    const dateRegex = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
    console.log(date);
    console.log(dateRegex.test(date));
    if (dateRegex.test(date)) {
      setErr(false);
    } else {
      setErr(true);
    }
    setFormData({ ...formData, [name]: date });
    setKeyvalue(keyvalue + 1);
  };
  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    data.education[i] = {
      ...data.education[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };

  const uploadFile = (e) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/profile-pic");
    // file.append("type", "expert-manpower/education-qualifications");

    common_file_upload(file)
      .then((res) => {
        setFormData({
          ...formData,
          photo: res.data,
          file_name: e.target.files[0].name,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const eduUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/education-qualifications");
    common_file_upload(file)
      .then((res) => {
        let edu = formData.education;
        edu[i] = {
          ...edu[i],
          file_name: e.target.files[0].name,
          file_upload_certificate: res.data,
        };
        setFormData({
          ...formData,
          education: edu,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const expUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/experience");
    common_file_upload(file)
      .then((res) => {
        let exp = formData.employment;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          exp_certificate: res.data,
        };
        setFormData({
          ...formData,
          employment: exp,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        // uploadFile(e);
        setFormData({
          ...formData,
          identity_file: e.target.files[0],
          file_name: e.target.files[0].name,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          identity_file: null,
          file_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        identity_file: null,
        file_name: null,
      });
    }
  };
  const handlePhotoChange = (e) => {
    let size = e.target.files[0].size;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        // uploadFile(e);
        setFormData({
          ...formData,
          photo: e.target.files[0],
          photo_name: e.target.files[0].name,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          photo: null,
          photo_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        photo: null,
        photo_name: null,
      });
    }
  };
  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let edu = formData.education;
    if (
      type?.toLowerCase() === "png" ||
      type?.toLowerCase() === "jpeg" ||
      type?.toLowerCase() === "jpg" ||
      type?.toLowerCase() === "pdf"
    ) {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        eduUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        edu[i] = {
          ...edu[i],
          file_name: null,
          file_upload_certificate: null,
        };
        setFormData({
          ...formData,
          education: edu,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      edu[i] = {
        ...edu[i],
        file_name: null,
        file_upload_certificate: null,
      };
      setFormData({
        ...formData,
        education: edu,
      });
    }
  };

  const handleExperienceFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let exp = formData.employment;
    let type = e.target.files[0].name.split(".").pop();
    let data = e.target.files[0];
    if (type === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        // expUploadFile(e, i);
        let exp = formData.employment;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          exp_certificate: data,
        };
        setFormData({
          ...formData,
          employment: exp,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        exp[i] = {
          ...exp[i],
          file_name: null,
          exp_certificate: null,
        };
        setFormData({
          ...formData,
          employment: exp,
        });
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      exp[i] = {
        ...exp[i],
        file_name: null,
        experience_certificate: null,
      };
      setFormData({
        ...formData,
        employment: exp,
      });
    }
  };

  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.education.push({
      university: "",
      year: "",
      location: "",
      grade: "",
      gpa: "",
      percentage : ""
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.education.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleExperienceChange = (e, i) => {
    let data = { ...formData };
    data.employment[i] = {
      ...data.employment[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleCheckChange = (e, index) => {
    let data = { ...formData };

    // Update the checked status of the specific checkbox
    data.expertise_area[index].checked = e.target.checked;

    // Update the formData state
    setFormData(data);
  };
  const handleConfirmationChange = (e) => {
    let data = { ...formData };
    if (e.target.name == "conf1") {
      data.conf1 = e.target.checked;
    } else {
      data.conf2 = e.target.checked;
    }
    setFormData(data);
  };
  const handleExperticeChange = (e, i) => {
    let data = { ...formData };
    data.expertise_area[i] = {
      ...data.expertise_area[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleExpDatePickerChange = (e, i, name) => {
    let data = { ...formData };
    let date = e.value;
    const dateRegex = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
    console.log(date);
    console.log(dateRegex.test(date));
    if (dateRegex.test(date)) {
      setErr(false);
    } else {
      setErr(true);
    }
    data.employment[i] = {
      ...data.employment[i],
      [name]: date,
    };
    setFormData(data);
    setKeyvalue(keyvalue + 1);
  };
  const handleexpCheckChange = (e,i) => {
    let data = {...formData}
    console.log(e)
    data.employment[i] = {...data?.employment[i],working : e.target.checked }
    setFormData(data)
  }
  const addExperience = (i) => {
    let data = { ...formData };

    data.employment.push({
      employer: "",
      contact: "",
      address: "",
      from: "",
      to: "",
      email: "",
      reason: "",
      position: "",
      exp_certificate: null,
      working : false
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExperiance = (i) => {
    let data = { ...formData };
    data.employment.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const addExpertice = (i) => {
    let data = { ...formData };

    // data.expertise_area.push({
    //   expertise_name: "",
    // });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExpertice = (i) => {
    let data = { ...formData };
    data.expertise_area.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleValid = () => {
    let flags = false;
    if (props.areaOfInterest) {
      props.areaOfInterest.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const textFieldChange = (e) => {
    let data = formData;

    if (e.target.name === "interest_type") {
      if (e.target.value === "Administration") {
        data = {
          ...data,
          expertise_area: admin_type,
          [e.target.name]: e.target.value,
        };
      } else if (e.target.value === "Technical") {
        data = {
          ...data,
          expertise_area: technical_type,
          [e.target.name]: e.target.value,
        };
      } else if (e.target.value === "Management") {
        data = {
          ...data,
          expertise_area: management_type,
          [e.target.name]: e.target.value,
        };
      } else {
        data = {
          ...data,

          expertise_area: [],
          [e.target.name]: e.target.value,
        };
      }

      // setFormData(data)
    } else {
      data = {
        ...data,

        [e.target.name]: e.target.value,
      };
    }

    setFormData(data);
  };
  const numberChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    let data = formData;
    data = {
      ...data,

      [name]: newValue,
    };
    setFormData(data);
  };

  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.education[i] = {
      ...data.education[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const handlePinChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    const regex = /^6\d{5}$/;
    console.log(newValue);
    if (!newValue.startsWith(6)) {
      newValue = 6 + newValue.slice(0, 5); // Ensure it is a maximum of 6 characters long
    } else {
      newValue = newValue.slice(0, 6); // Ensure it is a maximum of 6 characters long
    }
    data = { ...data, [name]: newValue };
    if (regex.test(newValue)) {
      setPinErr(false);
    } else {
      setPinErr(true);
    }
    setFormData(data);
  };
  const handleExperiancelNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.employment[i] = {
      ...data.employment[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };

  const genderFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      gender: e.target.value,
    };
    setFormData(data);
  };
  const nativeFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      native_kerala: Number(e.target.value),
    };
    setFormData(data);
  };
  const nrinativeFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      nri_native: Number(e.target.value),
    };
    setFormData(data);
  };
  // const selectFieldChange = (e) => {
  //   let value = e.target.value;
  //   const selectedCat = props.category?.find(({ uuid }) => uuid === value);

  //   let data = formData;
  //   data = {
  //     ...data,

  //     category: value,
  //     category_name: selectedCat.cat_name,
  //   };
  //   setFormData(data);
  // };
  console.log(formData);
  return (
    <div className="expert-man-power">
      <Loader open={isActive} />
      <form onSubmit={handlepreview}>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label">Are you a native of Kerala? </div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="native_kerala"
            value={formData?.native_kerala ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value={1}
              // select={formData?.category_name === item.cat_name}
            >
              Yes
            </option>
            <option
              value={0}
              // select={formData?.category_name === item.cat_name}
            >
              No
            </option>
          </select>
        </div>
        {formData?.native_kerala == 0 && (
          <div className="col-xs-12 col-sm-12 mb-3">
            <div className="header-label">Are you a NRI Keralite? </div>
            <select
              className="form-select selectbox mandatory-select "
              required
              name="category"
              value={formData?.nri_kerala ?? ""}
              aria-label="Default select example"
              // onChange={(e) => selectFieldChange(e)}
              onChange={(e) => textFieldChange(e)}
            >
              <option
                value={1}
                // select={formData?.category_name === item.cat_name}
              >
                Yes
              </option>
              {/* <option
            value={false}
            // select={formData?.category_name === item.cat_name}
          >
            No
          </option> */}
            </select>
          </div>
        )}
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label">Are you a fresher? </div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="fresher"
            value={formData?.fresher ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value="Yes"
              // select={formData?.category_name === item.cat_name}
            >
              Yes
            </option>
            <option
              value="No"
              // select={formData?.category_name === item.cat_name}
            >
              No
            </option>
          </select>
        </div>

        <div className="d-flex align-items-end col-12 ">
          <div className="col-3   mb-3">
            <label className={`control-label mylabel mb-0`}>Name</label>
            <select
              id="prefix"
              className="form-select  "
              required
              label="prefix"
              name="prefix"
              disabled={props.update}
              defaultValue={"Mr"}
              onChange={(e) => textFieldChange(e)}
              value={formData.prefix ? formData.prefix : "Mr"}
            >
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Dr">Dr</option>
            </select>
          </div>
          <div className="px-1"></div>
          <div className="d-flex col-9">
            <div className="col-12 ">
              <CommonForm
                fieldType="text"
                required
                name="fname"
                placeholder="Name"
                value={formData.fname ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-xs-12 col-sm-6">
            <CommonForm
              fieldType="date"
              label={`Date of Birth `}
              required
              name="dob"
              maxDate={new Date(eighteenYearsAgo)}
              value={formData?.dob ?? ""}
              onChange={(e) => {
                handleDatePickerChange(e, "dob");
              }}
              {...props}
            />
            {err && (
              <div className="file-up-helper mt-1">
                Please select a valid date
              </div>
            )}
          </div>

          <div className="col-xs-12 col-sm-6 mt-2 d-flex justify-content-start align-items-center pt-3 ">
            <div className="control-label ">
              <b>Gender</b> :{" "}
            </div>
            <div className="d-flex ">
              <CommonForm
                fieldType="radio"
                label="Male"
                required
                name="gender"
                value="Male"
                checked={formData?.gender === "Male"}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Female"
                required
                name="gender"
                checked={formData?.gender === "Female"}
                value="Female"
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Transqueer"
                required
                name="gender"
                checked={formData?.gender === "Transgender"}
                value="Transgender"
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Marital Status</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="marital_status"
            value={formData?.marital_status ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value="Married"
              // select={formData?.category_name === item.cat_name}
            >
              Married
            </option>
            <option
              value="Unmarried"
              // select={formData?.category_name === item.cat_name}
            >
              Unmarried
            </option>
            <option
              value="Widowed"
              // select={formData?.category_name === item.cat_name}
            >
              Widowed
            </option>
            <option
              value="Seperated"
              // select={formData?.category_name === item.cat_name}
            >
              Seperated
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            required
            name="parent_name"
            label="Father/Spouse Name"
            placeholder="Father/Spouse Name"
            value={formData.parent_name ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Category</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="caste"
            value={formData?.caste ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>--Select--</option>
            <option
              value="General"
              // select={formData?.category_name === item.cat_name}
            >
              General
            </option>
            <option
              value="OBC"
              // select={formData?.category_name === item.cat_name}
            >
              OBC
            </option>
            <option
              value="SC"
              // select={formData?.category_name === item.cat_name}
            >
              SC
            </option>
            <option
              value="ST"
              // select={formData?.category_name === item.cat_name}
            >
              ST
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">
            Are you physically handicapped?
          </div>
          <select
            className="form-select selectbox mandatory-select "
            name="handicapped"
            value={formData?.handicapped ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value="No"
              // select={formData?.category_name === item.cat_name}
            >
              No
            </option>
            <option
              value="Yes"
              // select={formData?.category_name === item.cat_name}
            >
              Yes
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="email"
            label="Email"
            disabled={props.update}
            value={formData?.email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            required
            name="email"
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="email"
            label="Alternate Email"
            value={formData?.alt_email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            name="alt_email"
            {...props}
          />
        </div>
        {/* <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Phone Number"
            disabled
            value={formData?.mobile}
            required
            name=""
            {...props}
          />
        </div> */}
        <label className="control-label mylabel mb-2">Phone Number</label>
        <div className="d-flex col-12 align-items-end phone-side">
          <div className="col-1 ">
            <CommonForm
              fieldType="text"
              // name="alt_code"
              maxLength="4"
              value={91}
             disabled
             
            />
          </div>
          <div className="p-1"></div>
          <div className="col-11">
          <CommonForm
            fieldType="text"
            // label="Phone Number"
            disabled
            value={formData?.mobile}
            required
            name=""
            {...props}
          />
          </div>
        </div>
        <label className="control-label mylabel mb-2">{formData?.native_kerala == 0 ? "NRI Phone Number" : "Alternate Phone"}</label>
        <div className="d-flex col-12 align-items-end phone-side">
          <div className="col-1 ">
            <CommonForm
              fieldType="text"
              name="alt_code"
              maxLength="4"
              value={formData.alt_code ?? ""}
              onChange={(e) => {
                handleNumberChange(e);
              }}
              // required = {formData?.native_kerala == 0 ? true : false}
              {...props}
            />
          </div>
          <div className="p-1"></div>
          <div className="col-11">
            <CommonForm
              fieldType="text"
              name="alt_contact"
              maxLength="10"
              value={formData.alt_contact ?? ""}
              // required = {formData?.native_kerala == 0 ? true : false}
              onChange={(e) => {
                handleNumberChange(e);
              }}
              {...props}
            />
          </div>
        </div>

        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Address"
            multiline
            required
            className="no-margin"
            name="address"
            value={formData?.address ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Pin Code"
            maxLength="6"
            minLength="6"
            value={formData?.pin_code}
            required
            onChange={(e) => {
              handlePinChange(e);
            }}
            name="pin_code"
            {...props}
          />
          {pinErr && (
            <div className="file-up-helper pt-1">Please enter a valid pin</div>
          )}
        </div>
        {/* <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            required
            name="blood_group"
            placeholder="Blood group"
            label="Blood Group"
            value={formData.blood_group ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div> */}
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Blood Group</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="blood_group"
            value={formData?.blood_group ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select--</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Type of Identity Proof</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="identity_type"
            value={formData?.identity_type ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select--</option>
            <option value="Aadhaar">Aadhaar</option>
            <option value="Pan">Pan</option>
            <option value="Driving license">Driving license</option>
            <option value="Passport">Passport</option>
            <option value="Election id">Election id</option>
            <option value="OCI(Overseas Citizenship of India)">OCI(Overseas Citizenship of India)</option>
            
          </select>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            required
            maxLength={20}
            name="identity_no"
            label="Identity No "
            value={formData.identity_no ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        
        <div className="col-12">
          <CommonForm
            fieldType="file"
            label="Upload  Identication Proof"
            name="identity_file"
            {...props}
            id="identity_file"
            fileName={formData?.file_name ?? formData?.identity_file}
            //filename={formData.file_name}
            accept=".pdf"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />
          <div className="file-up-helper">
            *Upload only .pdf format and Size should be
            less than 1MB
          </div>
        </div>
        <div className="col-12">
          <CommonForm
            fieldType="file"
            label="Upload Photo"
            name="photo"
            {...props}
            id="photo"
            fileName={formData?.photo_name }
            //filename={formData.file_name}
            accept=".jpg,.jpeg,.png"
            type="file"
            variant="outlined"
            onChange={handlePhotoChange}
          />
          <div className="file-up-helper">
            *Upload only .jpg , .jpeg, .png , format and Size should be
            less than 1MB
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Highest Qualification</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="qualification_type"
            value={formData?.qualification_type ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select--</option>
            <option value="Technical">Technical</option>
            <option value="Non-Technical">Non-Technical</option>
          </select>
        </div>
        {formData?.qualification_type && (
          <div className="col-xs-12 col-sm-12 mb-3">
            <select
              className="form-select selectbox mandatory-select "
              required
              name="qualification"
              value={formData?.qualification ?? ""}
              aria-label="Default select example"
              // onChange={(e) => selectFieldChange(e)}
              onChange={(e) => textFieldChange(e)}
            >
              <option>-- Select --</option>
              <option
                value="PHD"
                // select={formData?.category_name === item.cat_name}
              >
                PHD
              </option>
              <option
                value="Post Graduate"
                // select={formData?.category_name === item.cat_name}
              >
                Post Graduate
              </option>
              <option
                value="Graduate"
                // select={formData?.category_name === item.cat_name}
              >
                Graduate
              </option>
              <option
                value="Under Graduation"
                // select={formData?.category_name === item.cat_name}
              >
                Under Graduation
              </option>
              <option
                value="Diploma"
                // select={formData?.category_name === item.cat_name}
              >
                Diploma
              </option>
              {formData?.qualification_type === "Technical" && (
                <option
                  value="ITI"
                  // select={formData?.category_name === item.cat_name}
                >
                  ITI
                </option>
              )}{" "}
              {formData?.qualification_type === "Non-Technical" && (
                <option
                  value="12th"
                  // select={formData?.category_name === item.cat_name}
                >
                  12th
                </option>
              )}
              {formData?.qualification_type === "Non-Technical" && (
                <option
                  value="10th"
                  // select={formData?.category_name === item.cat_name}
                >
                  10th
                </option>
              )}
            </select>
          </div>
        )}
        <div className="header-label">
          Educational Qualification (from latest to 10th)
        </div>
        {formData.education?.map((e, i) => (
          <div className="section-datas-cont mb-3">
            <div className="section-Cont">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Board/University Name"
                    required
                    name="university"
                    value={e?.university}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Location"
                    required
                    name="location"
                    value={e?.location}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Year of Passing"
                    required
                    name="year"
                    minlength="4"
                    maxLength="4"
                    value={e?.year}
                    onChange={(e) => {
                      handleEducationalNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Grade"
                    required
                    minlength="1"
                    maxLength="4"
                    name="grade"
                    value={e?.grade}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="GPA"
                    required
                    minlength="1"
                    maxLength="4"
                    name="gpa"
                    value={e?.gpa}
                    onChange={(e) => {
                      handleEducationalNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Percentage"
                    required
                    minlength="1"
                    maxLength="4"
                    name="percentage"
                    value={e?.percentage}
                    onChange={(e) => {
                      handleEducationalNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>

            <div className="more-btn-cnt">
              {formData.education.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addeducationalQualifications(i)}
                >
                  Add More
                </button>
              )}
              {formData.education.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeducationalQualifications(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))}
{formData.fresher == "No" &&
<>
        <div className="header-label">
          Employment Details (from current employment onwards)
        </div>
        {formData.employment?.map((e, i) => (
          <div className="section-datas-cont">
            <div className="section-Cont">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Name of Organization"
                    required
                    name="employer"
                    value={e?.employer}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Contact No"
                    required
                    minlength="10"
                    maxLength="10"
                    name="contact"
                    value={e?.contact}
                    onChange={(e) => {
                      handleExperiancelNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="email"
                    label="Email Id"
                    required
                    name="email"
                    value={e?.email}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Address"
                    required
                    multiline
                    name="address"
                    value={e?.address}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
               
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Position"
                    required
                    name="position"
                    value={e?.position}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 d-flex justify-content-center pt-2">
                  
                  <CommonForm
                   fieldType="checkbox"
                   value={e.working}
                   checked={e.working === true ? true : false}
                   onChange={(e) => handleexpCheckChange(e, i)}
                   label="Are you currently working?"
                   {...props}
                 />
               </div>
              
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="date"
                    label="From"
                    required
                    name="from"
                    maxDate={new Date()}
                    value={e?.from}
                    onChange={(e) => {
                      handleExpDatePickerChange(e, i, "from");
                    }}
                    {...props}
                  />
                </div>
                {formData?.employment[i].working === false &&
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="date"
                    label="To"
                    required
                    name="to"
                    maxDate={new Date()}
                    value={e?.to}
                    onChange={(e) => {
                      handleExpDatePickerChange(e, i, "to");
                    }}
                    {...props}
                  />
                </div>}
                {formData?.employment[i].working === false &&
                <div className="col-xs-12 col-sm-12 pt-3">
                  <CommonForm
                    fieldType="text"
                    label="Reason for Leaving"
                    multiline
                    required
                    value={e?.reason}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    className="no-margin"
                    name="reason"
                    {...props}
                  />
                </div>}
              
                <div className="col-12 pt-2">
                  <CommonForm
                    fieldType="file"
                    required={!e.file_name ? true : false}
                    label={formData?.employment[i].working === false ? "Experience certificate (self attested)" : "Appointment Order"}
                    name="experience_certificate"
                    onChange={(e) => {
                      handleExperienceFileChange(e, i);
                    }}
                    {...props}
                    fileName={e?.file_name}
                    //filename={e?.file_name}
                    id="experience_certificate"
                    accept=".pdf"
                    type="file"
                    variant="outlined"
                    // onChange={handleFileChange}
                  />
                  <div className="file-up-helper">
                    *Upload only .pdf format and Size
                    should be less than 1MB
                  </div>
                </div>
              </div>
            </div>

            <div className="more-btn-cnt">
              {formData.employment.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addExperience(i)}
                >
                  Add More
                </button>
              )}
              {formData.employment.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeExperiance(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))} </>}

        <div className="header-label">References</div>

        <div className="section-datas-cont mb-3">
          <div className="section-Cont">
            <div className=" d-flex justify-content-between">
              <div className="col-5">
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Name"
                    required
                    name="reference_name1"
                    value={formData?.reference_name1 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Designation"
                    required
                    name="reference_designation1"
                    value={formData?.reference_designation1 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Contact"
                    required
                    maxLength="10"
                    minLength="10"
                    name="reference_contact1"
                    value={formData?.reference_contact1 ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Email"
                    required
                    name="reference_email1"
                    value={formData?.reference_email1 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
              <div className="p-1 border-end"></div>
              <div className=" col-5">
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Name"
                    required
                    name="reference_name2"
                    value={formData?.reference_name2 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Designation"
                    required
                    name="reference_designation2"
                    value={formData?.reference_designation2 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Contact"
                    required
                    maxLength="10"
                    minLength="10"
                    name="reference_contact2"
                    value={formData?.reference_contact2 ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Email"
                    required
                    name="reference_email2"
                    value={formData?.reference_email2 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label">Area of Interest</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="interest_type"
            value={formData?.interest_type ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option value="">--Select--</option>
            <option value="Administration">Administration</option>
            <option value="Technical">Technical</option>
            <option value="Management">Management</option>
          </select>
        </div>
        {formData?.interest_type && (
          <div>
            <div className="header-label">Interests</div>
            <div className="check-box-cont mb-2">
              {/* {formData.interest_type === "Administration" &&
                admin_type.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    // required={!formData.any_other_flag && !handleValid()}
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckChange(e, index, item?.domain_name)
                    }
                    label={item.domain_name}
                    {...props}
                  />
                ))} */}
              {/* {formData.interest_type === "Technical" &&
                technical_type.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    // required={!formData.any_other_flag && !handleValid()}
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckChange(e, index, item?.domain_name)
                    }
                    label={item.domain_name}
                    {...props}
                  />
                ))} */}
              {/* {formData.interest_type === "Management" &&
                management_type.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    // required={!formData.any_other_flag && !handleValid()}
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckChange(e, index, item?.domain_name)
                    }
                    label={item.domain_name}
                    {...props}
                  />
                ))} */}
              {formData.interest_type &&
                formData?.expertise_area.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    checked={item.checked}
                    onChange={(e) => handleCheckChange(e, index)}
                    label={item.domain_name}
                    {...props}
                  />
                ))}

              <CommonForm
                fieldType="checkbox"
                onChange={handleOtherChange}
                label="Any Other"
                name="any_other_flag"
                checked={formData.any_other_flag}
                value={formData.any_other_flag}
                {...props}
              />
            </div>
            {/* if any other field checked ,below is mandatory */}
            {formData.any_other_flag && (
              <div>
                <CommonForm
                  fieldType="text"
                  placeholder="Please specify the Domain.."
                  required
                  maxLength={50}
                  name="any_other_domain"
                  value={formData.any_other_domain ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>
            )}
            {/* ////////////////////////////// */}
          </div>
        )}
        {/* {formData.expertise_area?.map((e, i) => (
          <div className="section-datas-cont"> */}
        {/* <div className="col-xs-12 col-sm-12"> */}
        {/* <CommonForm
              fieldType="text"
              label="Area of Expertise"
              required
              value={e?.expertise_name}
              onChange={(e) => {
                handleExperticeChange(e, i);
              }}
              className="no-margin"
              name="expertise_name"
              {...props}
            /> */}
        {/* </div> */}

        {/* <div className="more-btn-cnt">
              {formData.expertise_area.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addExpertice(i)}
                >
                  Add More
                </button>
              )}
              {formData.expertise_area.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeExpertice(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))} */}

        {/* <div className="fieldset">
          <div className="header-label">Any other area of interest</div>
          <div
          // className="check-box-cont"
          >
            <div className="p-10 check-box-cont">
              {" "}
              {props.areaOfInterest?.map((item, index) => (
                <CommonForm
                  fieldType="checkbox"
                  value={item.uuid}
                  checked={item.checked}
                  required={!handleValid()}
                  onChange={(e) => handleCheckChange(e, index)}
                  label={item.area_of_interest_name}
                  {...props}
                />
              ))}
            </div>
          </div>
        </div> */}

        {/* <div className="col-12">
          <CommonForm
            fieldType="file"
            required={!formData?.file_name ? true : false}
            label="Upload  your Photo"
            name="photo"
            {...props}
            id="photo"
            fileName={formData?.file_name}
            //filename={formData.file_name}
            accept=".jpg,.jpeg,.png"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />
          <div className="file-up-helper">
            *Upload only .jpg , .jpeg, .png , format and Size should be less
            than 1MB
          </div>
        </div> */}
        <div className="mt-3">
          <CommonForm
            fieldType="checkbox"
            onChange={(e) => handleConfirmationChange(e)}
            label="I certify that the above information I mentioned are true and to the best of my knowledge"
            name="conf1"
            checked={formData.conf1}
            value={formData.conf1}
            {...props}
          />
          <CommonForm
            fieldType="checkbox"
            onChange={(e) => handleConfirmationChange(e)}
            label="I understand that all the information in the application are captured to create a job seeker registry."
            name="conf2"
            checked={formData?.conf2}
            value={formData?.conf2}
            {...props}
          />
        </div>
        
        <div className="footer-buttons">
          <button type="submit" className="primary-btn">
            SUBMIT TO PREVIEW
          </button>
        </div>

      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
