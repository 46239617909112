export const MenuDatas = [
  {
    label_en: "Registration",
    label_ma: "രജിസ്‌ട്രേഷൻ",
    id: 1,
    hideOnAuth: true,

    children: [
      {
        label_en: "Apply for a New Company in KSPACE Premises",
        label_ma: "കെസ്‌പെയ്‌സിൽ ഒരു പുതിയ കമ്പനി രജിസ്റ്റർ ചെയ്യുക",

        id: 11,
        show: true,
        path: "/new-company/kspace",
        desc_en:
          "Register your request to setup an enterprises within KSPACE.Prior to registration, ensure you have the necessary details such as area requirements and project proposal ready for upload.",
        title_en: "New company registration",
        title_ma: "പുതിയ കമ്പനി രജിസ്റ്റർ ചെയ്യുക",
        desc_ma:
          "കെസ്‌പെയ്‌സിൽ ഒരു എൻ്റർപ്രൈസസ് സജ്ജീകരിക്കുന്നതിനുള്ള നിങ്ങളുടെ അഭ്യർത്ഥന രജിസ്റ്റർ ചെയ്യുക. രജിസ്ട്രേഷന് മുമ്പ്, സ്ഥലത്തിൻ്റെ ആവശ്യകതകളും അപ്‌ലോഡ് ചെയ്യാനുള്ള പ്രോജക്റ്റ് പ്രൊപ്പോസലും നിങ്ങളുടെ പക്കലുണ്ടെന്ന് ഉറപ്പാക്കുക.",
      },
      {
        label_en: "Apply for a New Company in Own Premises",
        label_ma: "കെസ്‌പെയ്‌സിൽ ഒരു പുതിയ കമ്പനി രജിസ്റ്റർ ചെയ്യുക",

        id: 11,
        show: true,
        path: "/new-company/own",
        desc_en:
          "Register your request to setup an enterprises within KSPACE.Prior to registration, ensure you have the necessary details such as area requirements and project proposal ready for upload.",
        title_en: "New company registration",
        title_ma: "പുതിയ കമ്പനി രജിസ്റ്റർ ചെയ്യുക",
        desc_ma:
          "കെസ്‌പെയ്‌സിൽ ഒരു എൻ്റർപ്രൈസസ് സജ്ജീകരിക്കുന്നതിനുള്ള നിങ്ങളുടെ അഭ്യർത്ഥന രജിസ്റ്റർ ചെയ്യുക. രജിസ്ട്രേഷന് മുമ്പ്, സ്ഥലത്തിൻ്റെ ആവശ്യകതകളും അപ്‌ലോഡ് ചെയ്യാനുള്ള പ്രോജക്റ്റ് പ്രൊപ്പോസലും നിങ്ങളുടെ പക്കലുണ്ടെന്ന് ഉറപ്പാക്കുക.",
      },
      {
        label_en: "Affiliate an Existing Aerospace Unit in Kerala with KSPACE",
        id: 12,
        show: true,
        path: "/register-kerala",
        // desc_en: "Register your existing enterprises in Kerala to KSPACE",
        title_en: "Registration - Affiliate your existing company with KSPACE.",
        desc_en:
          "Affiliate your existing company with KSPACE. Prior to registration, ensure you have the necessary details such as company brochure/leaflet ready for upload.",
        desc_ma:
          "നിങ്ങളുടെ നിലവിലുള്ള കമ്പനിയെ കെസ്പേസുമായി അഫിലിയേറ്റ് ചെയ്യുക. രജിസ്‌ട്രേഷന് മുമ്പ്, അപ്‌ലോഡ് ചെയ്യാൻ തയ്യാറായ കമ്പനിയുടെ ബ്രോഷർ/ലഘുലേഖ പോലുള്ള ആവശ്യമായ വിശദാംശങ്ങൾ നിങ്ങളുടെ പക്കലുണ്ടെന്ന് ഉറപ്പാക്കുക.",
        label_ma:
          "കേരളത്തിൽ നിലവിലുള്ള ഒരു എയ്റോസ്പേസ് യൂണിറ്റിനെ കെസ്പേസുമായി അഫിലിയേറ്റ് ചെയ്യുക",
        title_ma:
          " രജിസ്ട്രേഷൻ - കേരളത്തിൽ നിലവിലുള്ള ഒരു എയ്റോസ്പേസ് യൂണിറ്റിനെ കെസ്പേസുമായി അഫിലിയേറ്റ് ചെയ്യുക",
      },
      // {
      //   label_en: "Aerospace Institutions in Kerala",
      //   id: 13,
      //   show: true,
      //   path: "/register-kerala",
      //   desc_en: "Register your existing enterprises in Kerala to  KSPACE.",

      //   title_en: "Registration - Aerospace Institutions in Kerala",
      //   desc_ma:
      //     "കേരളത്തിൽ നിലവിലുള്ള നിങ്ങളുടെ സംരംഭങ്ങൾ കെസ്‌പെയ്‌സിൽ  രജിസ്റ്റർ ചെയ്യുക.",
      //   label_ma: "കേരളത്തിലെ എയ്റോസ്പേസ്  സ്ഥാപനങ്ങൾ",
      //   title_ma: " രജിസ്ട്രേഷൻ - കേരളത്തിലെ എയ്റോസ്പേസ്  സ്ഥാപനങ്ങൾ",
      // },
      // {
      //   label_en: "Existing Aerospace institutions in India with KSPACE",
      //   id: 14,
      //   show: true,
      //   path: "/register-india",
      //   desc_en: "Register your existing enterprises in India to  KSPACE.",
      //   title_en: "Registration - Aerospace Institutions in India",
      //   desc_ma:
      //     "ഇന്ത്യയിൽ  നിലവിലുള്ള നിങ്ങളുടെ സംരംഭങ്ങൾ കെസ്‌പെയ്‌സിൽ  രജിസ്റ്റർ ചെയ്യുക.",
      //   title_ma: "രജിസ്ട്രേഷൻ - ഇന്ത്യയിൽ ഒരു കമ്പനി രജിസ്റ്റർ ചെയ്യുക",
      //   label_ma:
      //     "കെസ്പേസിൽ രജിസ്റ്റർ ചെയ്യാൻ ആഗ്രഹിക്കുന്ന ഇന്ത്യയിൽ നിലവിലുള്ള എയ്റോസ്പേസ് സ്ഥാപനങ്ങൾ",
      // },

      {
        label_en: "Resource Personnel",
        id: 15,
        show: true,
        path: "/resource-personal-reg",

        desc_en:
          "Register your request to be part of resource pool in KSPACE.Prior to registration, ensure you have the necessary details such as educational certificates, experience certificates and photo ready for upload.",
        title_en: "Resource Personnel Registration",
        desc_ma:
          "കെസ്‌പെയ്‌സിൽ റിസോഴ്‌സ് പൂളിന്റെ ഭാഗമാകാനുള്ള നിങ്ങളുടെ അഭ്യർത്ഥന രജിസ്റ്റർ ചെയ്യുക. രജിസ്‌ട്രേഷന് മുമ്പ്, വിദ്യാഭ്യാസ സർട്ടിഫിക്കറ്റുകൾ, എക്സ്പീരിയൻസ് സർട്ടിഫിക്കറ്റുകൾ, ഫോട്ടോ എന്നിവ അപ്‌ലോഡ് ചെയ്യാൻ തയ്യാറാണെന്ന് ഉറപ്പാക്കുക.",
        title_ma: "റിസോഴ്സ് പേഴ്സണൽ രജിസ്ട്രേഷൻ",
        label_ma: "റിസോഴ്സ് പേഴ്‌സണൽ",
      },
      {
        label_en: "Expert Manpower",
        id: 16,
        show: true,
        path: "/expert-manpower-reg",
        desc_en:
          "Register your request to be part of workers registry in KSPACE.Prior to registration, ensure you have the necessary details such as educational certificates, experience certificates and photo ready for upload.",
        title_en: "Register an Expert Manpower",
        desc_ma:
          "കെസ്‌പെയ്‌സ് -ലെ തൊഴിലാളികളുടെ രജിസ്‌ട്രിയുടെ ഭാഗമാകാനുള്ള നിങ്ങളുടെ അഭ്യർത്ഥന രജിസ്റ്റർ ചെയ്യുക. രജിസ്‌ട്രേഷന് മുമ്പ്, വിദ്യാഭ്യാസ സർട്ടിഫിക്കറ്റുകൾ, എക്സ്പീരിയൻസ് സർട്ടിഫിക്കറ്റുകൾ, ഫോട്ടോ എന്നിവ അപ്‌ലോഡ് ചെയ്യാൻ തയ്യാറാണെന്ന് ഉറപ്പാക്കുക.",
        title_ma: "ഒരു എസ്പെർട് മാൻപവർ രജിസ്റ്റർ ചെയ്യുക",
        label_ma: "എസ്പെർട് മാൻപവർ",
      },
      {
        label_en: "Academic Institutions",
        id: 17,
        show: true,
        path: "/institute-reg",

        desc_en:
          "Register your request to be part of Academic Institutions registry in KSPACE.Prior to registration, ensure you have the necessary details such as registration certificate, affiliation details and request letter ready for upload.",
        title_en: " Registration for Collaboration with Academic Institutions",
        desc_ma:
          "കെസ്‌പെയ്‌സ്-ൽ അക്കാദമിക് സ്ഥാപനങ്ങളുടെ രജിസ്ട്രിയുടെ ഭാഗമാകാനുള്ള നിങ്ങളുടെ അഭ്യർത്ഥന രജിസ്റ്റർ ചെയ്യുക. രജിസ്ട്രേഷന് മുമ്പ്, രജിസ്ട്രേഷൻ സർട്ടിഫിക്കറ്റ്, അഫിലിയേഷൻ വിശദാംശങ്ങൾ, അപേക്ഷ എന്നിവ അപ്ലോഡ് ചെയ്യാൻ തയ്യാറാണെന്ന് ഉറപ്പാക്കുക.",
        title_ma: "അക്കാദമിക് സ്ഥാപനങ്ങളുമായുള്ള സഹകരണത്തിനുള്ള രജിസ്ട്രേഷൻ",
        label_ma: "അക്കാദമിക് സ്ഥാപനങ്ങൾ",
      },
      {
        label_en: "Job Seekers",
        id: 18,
        show: true,
        path: "/candidates-reg",

        desc_en:
          "Register your request to be part of Job Seeker registry in KSPACE.Prior to registration, ensure you have the necessary details such as certificates, a copy Aadhaar/Pan/Driving Licence/Passport/Election Card ready for upload.",
        title_en: " Registration to be a part of Job Seeker Registry",
        desc_ma:
          "KSPACE-ൽ തൊഴിലന്വേഷകരുടെ രജിസ്‌ട്രിയുടെ ഭാഗമാകാനുള്ള നിങ്ങളുടെ അഭ്യർത്ഥന രജിസ്റ്റർ ചെയ്യുക. രജിസ്‌ട്രേഷന് മുമ്പ്, സർട്ടിഫിക്കറ്റുകൾ, ആധാർ/പാൻ/ഡ്രൈവിംഗ് ലൈസൻസ്/പാസ്‌പോർട്ട്/ഇലക്ഷൻ കാർഡ് എന്നിവ അപ്‌ലോഡ് ചെയ്യാൻ തയ്യാറാണെന്ന് ഉറപ്പാക്കുക.",
        title_ma: "തൊഴിലന്വേഷക രജിസ്ട്രിയുടെ ഭാഗമാകാനുള്ള രജിസ്ട്രേഷൻ",
        label_ma: "ഉദ്യോഗാർഥികൾ",
      },
    ],
  },

  {
    label_en: "Directory of Aerospace Institutions",
    label_ma: "എയ്റോസ്പേസ്  സ്ഥാപനങ്ങളുടെ ഡയറക്ടറി",

    id: 2,
    children: [
      {
        label_en: "KSPACE Premises",
        id: 21,
        show: true,
        path: "/directory-kspace",
        desc_en: "Provides the list of enterprises operating in KSPACE.",
        title_en: "Directory of Kerala Spacepark Companies",
        desc_ma: "കെസ്‌പെയ്‌സിൽ പ്രവർത്തിക്കുന്ന സംരംഭങ്ങളുടെ ലിസ്റ്റ്.",
        title_ma: "കേരള സ്പേസ് പാർക്ക് കമ്പനികളുടെ ഡയറക്ടറി",
        label_ma: "കെസ്‌പെയ്‌സ്",
      },
      {
        label_en: "Kerala Companies affiliated with KSPACE",
        id: 22,
        show: true,
        path: "/directory-kerala",
        desc_en:
          "Provides the list of Aero (Space) enterprises operating in Kerala.",
        title_en: "   Aero (Space) Operating Institutions in Kerala",
        desc_ma:
          "കേരളത്തിൽ പ്രവർത്തിക്കുന്ന എയ്‌റോ (സ്‌പേസ്) സംരംഭങ്ങളുടെ ലിസ്റ്റ്.",
        title_ma: "കേരളത്തിലെ എയ്റോസ്പേസ്  സ്ഥാപനങ്ങൾ",
        label_ma: "KSPACE-മായി അഫിലിയേറ്റ് ചെയ്തിട്ടുള്ള കേരള കമ്പനികൾ",
      },
      {
        label_en: "India",
        id: 23,
        show: true,
        path: "/directory-india",
        specialPermissions: [8, 4, 5, 10, 9],
        error_en: "You have to be registered to access this section",
        error_ma:
          "ഈ വിഭാഗം അക്സസ്സ് ചെയ്യുവാൻ നിങ്ങൾ റെജിസ്ട്രേഷൻ പൂർത്തിയാക്കേണ്ടതാണ്.",

        desc_en:
          "Provides the list of Aero (Space) enterprises operating in India.",
        title_en: " Directory of Aero (Space) Enterprises in India",
        desc_ma:
          "ഈ വിഭാഗം അക്സസ്സ് ചെയ്യുവാൻ നിങ്ങൾ റെജിസ്ട്രേഷൻ പൂർത്തിയാക്കേണ്ടതാണ്.",
        title_ma: "ഇന്ത്യ",
        label_ma: "ഇന്ത്യ",
      },
      {
        label_en: "International",
        id: 24,
        show: true,
        path: "/directory-international",
        specialPermissions: [8, 4, 5, 10, 9],
        error_en: "You have to be registered to access this section",
        error_ma:
          "ഈ വിഭാഗം അക്സസ്സ് ചെയ്യുവാൻ നിങ്ങൾ റെജിസ്ട്രേഷൻ പൂർത്തിയാക്കേണ്ടതാണ്.",

        desc_en:
          " Provides the list of Aero (Space)enterprises operating outside India.",
        title_en: " Directory of International Aero (Space) Enterprises",
        desc_ma:
          "ഇന്ത്യയ്ക്ക് പുറത്ത് പ്രവർത്തിക്കുന്ന എയ്‌റോ (സ്‌പേസ്) സംരംഭങ്ങളുടെ ലിസ്റ്റ്.",
        title_ma: "ഇന്റർനാഷണൽ എയ്‌റോ (സ്‌പേസ്) ",
        label_ma: "ഇന്റർനാഷണൽ",
      },
    ],
  },
  //others
  {
    label_en: "KSPACE Related",
    label_ma: "മറ്റുള്ളവ",
    id: 8,
    children: [
      {
        label_en: "Spacepark Advisors",
        id: 81,
        show: true,
        path: "/advisors",
        title_en: " Spacepark Advisors",
        desc_en: "Provides the list of KSPACE Advisory Team.",
        desc_ma: "കെസ്‌പെയ്‌സ് ഉപദേശക സംഘത്തിന്റെ ലിസ്റ്റ് നൽകുന്നു.",
        title_ma: "സ്പേസ്പാർക്ക് ഉപദേശകർ",
        label_ma: "സ്പേസ്പാർക്ക് ഉപദേശകർ",
      },
      {
        label_en: "Governing Body",
        id: 81,
        show: true,
        path: "/governing-body",
        title_en: " Governing Body",
        desc_en: "Provides KSPACE Governing Body Constitution",
        desc_ma: "കെസ്‌പെയ്‌സ് ഭരണസമിതി ലിസ്റ്റ് നൽകുന്നു.",
        title_ma: "ഭരണസമിതി",
        label_ma: "ഭരണസമിതി",
      },
      {
        label_en: "Executive Council",
        id: 81,
        show: true,
        path: "/executive-council",
        title_en: " Executive Council",
        desc_en: "Provides KSPACE Executive Council Constitution",
        desc_ma: "കെസ്‌പെയ്‌സ് എക്സിക്യൂട്ടീവ് കൗൺസിൽ ലിസ്റ്റ് നൽകുന്നു.",
        title_ma: "എക്സിക്യൂട്ടീവ് കൗൺസിൽ",
        label_ma: "എക്സിക്യൂട്ടീവ് കൗൺസിൽ",
      },
      {
        label_en: "Core Team",
        id: 81,
        show: true,
        path: "/core-team",
        title_en: " Core Team",
        desc_en: "Provides the list of KSPACE Core Team.",
        desc_ma: "കെസ്‌പെയ്‌സ് കോർ ടീം ലിസ്റ്റ് നൽകുന്നു.",
        title_ma: "കോർ ടീം",
        label_ma: "കോർ ടീം",
      },
      {
        label_en: "Tenders",
        id: 53,
        show: true,
        path: "/tenders",
        // specialPermissions: [4, 5],
        // auth: true,
        title_en: "Tenders",
        desc_en:
          "Provides list of documents related to market studies in space sector.",
        desc_ma:
          "ബഹിരാകാശ മേഖലയിലെ മാർക്കറ്റ് പഠനങ്ങളുമായി ബന്ധപ്പെട്ട രേഖകളുടെ ലിസ്റ്റ് നൽകുന്നു.",
        title_ma: "ടെൻഡറുകൾ",
        label_ma: "ടെൻഡറുകൾ",
        error_en:
          "You don't have permission to access this section. Only KSPACE and Kerala companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
      {
        label_en: "Careers",
        id: 53,
        show: true,
        path: "/careers",

        auth: true,
        title_en: "Careers",
        desc_en: " Provides the Career Opportunities in KSPACE",
        desc_ma: "കെസ്‌പെയ്‌ലെ തൊഴിലവസരങ്ങളുടെ ലിസ്റ്റ് ",

        title_ma: "തൊഴിലവസരങ്ങൾ ",
        label_ma: "തൊഴിലവസരങ്ങൾ ",
      },
      {
        label_en: "News and Updates",
        id: 53,
        show: true,
        path: "/news",

        auth: true,
        title_en: "News and Updatess",
        desc_en: " Provides a list of News and Updates",
        desc_ma: "വാർത്തകളുടെയും അപ്ഡേറ്റുകളുടെയും പട്ടിക",

        title_ma: " വാർത്തകളും അപ് ഡേറ്റുകളും ",
        label_ma: " വാർത്തകളും അപ് ഡേറ്റുകളും ",
      },
      {
        label_en: "Policies and Regulations",
        id: 53,
        show: true,
        path: "/policies",

        auth: true,
        title_en: "Policies and Regulations",
        desc_en: " Provides a list of Policies and Regulations",
        desc_ma: "നയങ്ങളുടെയും നിയന്ത്രണങ്ങളുടെയും ലിസ്റ്റ് ",

        title_ma: " നയങ്ങളും ചട്ടങ്ങളും ",
        label_ma: " നയങ്ങളും ചട്ടങ്ങളും ",
      },
      {
        label_en: "Financial Support and Incentives",
        id: 53,
        show: true,
        path: "/incentives",

        auth: true,
        title_en: "Financial Support and Incentives",
        desc_en: " Provides a list of Policies and Regulations",
        desc_ma: "നയങ്ങളുടെയും നിയന്ത്രണങ്ങളുടെയും ലിസ്റ്റ് ",

        title_ma: " നയങ്ങളും ചട്ടങ്ങളും ",
        label_ma: " നയങ്ങളും ചട്ടങ്ങളും ",
      },
      {
        label_en: "Contact Us",
        id: 82,
        show: true,
        path: "/contact",
        isDirect: true,
        desc_ma: "",
        title_ma: "",
        label_ma: "ഞങ്ങളെ സമീപിക്കുക",
      },
    ],
  },
  //

  {
    label_en: "Directory of Resource Personnel",
    label_ma: "രജിസ്റ്റർ ചെയ്തിട്ടുള്ള റിസോഴ്സ് പേഴ്‌സണൽ  ഡയറക്ടറി",
    // specialPermissions: [4, 5, 9, 10],
    id: 4,
    children: [
      {
        label_en: "Resource Personnel",
        label_ma: "റിസോഴ്സ് പേഴ്‌സണൽ ",
        id: 41,
        specialPermissions: [4, 5, 9],
        show: true,
        path: "/resource-personal",
        // next: "/resource-personal",
        desc_en: " Provides the list of resource personnel in KSPACE.",
        title_en: "Resource Personnel",
        title_ma: "റിസോഴ്സ് പേഴ്‌സണൽ ",
        desc_ma: "കെസ്‌പെയ്‌സ്--ലെ റിസോഴ്സ് പേഴ്സണൽമാരുടെ ലിസ്റ്റ് നൽകുന്നു.",
        error_en:
          "You don't have permission to access this section. Only KSPACE Companies, Kerala Companies and Registered Personnel have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
      {
        label_en: "Expert Manpower",
        label_ma: "എസ്പെർട് മാൻപവർ",
        id: 42,
        specialPermissions: [4, 5, 10],
        show: true,
        path: "/expert-manpower",
        // next: "/expert-manpower",
        desc_en: "Provides the list of expert manpowers in KSPACE.",
        // desc_en: "Register your request to be part of workers registry in KSpace.",
        title_en: " Expert Manpower",
        title_ma: "എസ്പെർട് മാൻപവർ",
        desc_ma: "കെസ്‌പെയ്‌സ്-ലെ വിദഗ്ധരായ മനുഷ്യശക്തികളുടെ പട്ടിക നൽകുന്നു",
        error_en:
          "You don't have permission to access this section. Only KSPACE Companies, Kerala Companies and Registered Personnel have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
    ],
  },
  {
    label_en: "Directory of Registered Candidates",
    label_ma: "രജിസ്റ്റർ ചെയ്തിട്ടുള്ള പേഴ്‌സണൽ  ഡയറക്ടറി",
    // specialPermissions: [4, 5, 9, 10],
    id: 4,
    children: [
      {
        label_en: "Candidates",
        label_ma: "ഉദ്യോഗാർത്ഥികൾ",
        id: 42,
        specialPermissions: [4, 5, 6],
        show: true,
        path: "/candidates",
        // next: "/expert-manpower",
        desc_en: "Provides the list of registered job seekers.",
        // desc_en: "Register your request to be part of workers registry in KSpace.",
        title_en: "Candidates",
        title_ma: "ഉദ്യോഗാർത്ഥികൾ",
        desc_ma: "രജിസ്റ്റർ ചെയ്‌തിട്ടുള്ള ഉദ്യോഗാർഥികളുടെ പട്ടിക നൽകുന്നു ",
        error_en:
          "You don't have permission to access this section. Only KSPACE Companies, Kerala Companies and Indian Companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. KSPACE, കേരള, ഇന്ത്യ  കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
      {
        label_en: "Downloaded Candidate Profiles",
        label_ma: "ഡൌൺലോഡ് ചെയ്‌ത ഉദ്യോഗാർഥികളുടെ പ്രൊഫൈലുകൾ ",
        id: 42,
        specialPermissions: [4, 5, 6],
        show: true,
        path: "/candidate-profiles",
        // next: "/expert-manpower",
        desc_en: "Provides the list of downloaded profiles of job seekers.",
        // desc_en: "Register your request to be part of workers registry in KSpace.",
        title_en: "Downloaded Profiles",
        title_ma: "ഡൌൺലോഡ് ചെയ്‌ത പ്രൊഫൈലുകൾ ",
        desc_ma:
          "ഡൌൺലോഡ് ചെയ്‌ത ഉദ്യോഗാർഥികളുടെ പ്രൊഫൈലുകളുടെ പട്ടിക നൽകുന്നു ",
        error_en:
          "You don't have permission to access this section. Only KSPACE Companies, Kerala Companies and Indian Companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. KSPACE, കേരള, ഇന്ത്യ  കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
    ],
  },
  {
    label_en: "Market Research",
    label_ma: "വിപണി ഗവേഷണം",
    id: 5,
    children: [
      {
        label_en: "Publications",
        id: 51,
        show: true,
        specialPermissions: [4, 5],
        path: "/publications",
        desc_en:
          "Provides list of documents related to market studies in space sector.",
        title_en: "Publications",
        desc_ma:
          "ബഹിരാകാശ മേഖലയിലെ മാർക്കറ്റ് പഠനങ്ങളുമായി ബന്ധപ്പെട്ട രേഖകളുടെ ലിസ്റ്റ് നൽകുന്നു.",

        title_ma: "പ്രസിദ്ധീകരണങ്ങൾ",
        label_ma: "പ്രസിദ്ധീകരണങ്ങൾ",
        error_en:
          "You don't have permission to access this section. Only KSPACE and Kerala companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
      {
        label_en: "Events",
        id: 52,
        show: true,
        path: "/events",

        title_en: "Events",
        desc_en:
          "Provides a list of events conducted by both KSPACE & Other organizations.",
        desc_ma:
          "കെസ്‌പെയ്‌സിനൊപ്പം മറ്റ് ഓർഗനൈസേഷനുകൾ നടത്തുന്ന ഇവന്റുകളുടെ ഒരു ലിസ്റ്റ് നൽകുന്നു.",
        title_ma: "ഇവന്റുകൾ",
        label_ma: "ഇവന്റുകൾ",
      },
      {
        label_en: "Trade Enquiries",
        id: 53,
        show: true,
        path: "/private-tenders",
        specialPermissions: [4, 5],
        auth: true,
        title_en: "Tenders",
        desc_en:
          "Provides list of documents related to market studies in space sector.",
        desc_ma:
          "ബഹിരാകാശ മേഖലയിലെ മാർക്കറ്റ് പഠനങ്ങളുമായി ബന്ധപ്പെട്ട രേഖകളുടെ ലിസ്റ്റ് നൽകുന്നു.",
        title_ma: "ടെൻഡറുകൾ",
        label_ma: "ടെൻഡറുകൾ",
        error_en:
          "You don't have permission to access this section. Only KSPACE and Kerala companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
    ],
  },
  {
    label_en: "Business Ideas",
    label_ma: "ബിസിനസ്സ് ആശയങ്ങൾ",
    id: 6,
    children: [
      {
        label_en: "Submit a New Business Proposal",

        id: 61,
        show: true,
        path: "/submit-proposal",
        desc_en:
          "Register as a Resource Person of KSPACE to submit your business idea.",
        title_en: "Submit Project Proposal",
        desc_ma: "",
        title_ma: "പുതിയ പ്രൊപോസൽ സമർപ്പിക്കുക",
        label_ma: "പുതിയ പ്രൊപോസൽ സമർപ്പിക്കുക",
      },
      {
        label_en: "Directory of the Business Proposals",
        id: 62,
        show: true,
        path: "/business-proposal-list",
        // desc_en: "Register your request in  resource pool of  KSPACE to submit business ideas.",
        title_en: "Directory of the Business Proposals",
        desc_ma: "",
        title_ma: "ബിസിനസ് പ്രൊപ്പോസലിന്റെ ഡയറക്ടറി",
        label_ma: "ബിസിനസ് പ്രൊപ്പോസലിന്റെ ഡയറക്ടറി",
        specialPermissions: [4, 5],
        error_en:
          "You don't have permission to access this section. Only KSPACE and Kerala companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },

      {
        label_en: "Directory of Technology Transfer Proposals",
        id: 63,
        show: true,
        path: "/tech-transfer-proposal-list",
        // desc_en: "Register your request in  resource pool of  KSPACE to submit business ideas.",
        title_en: "Directory of Technology Transfer Proposals",
        desc_ma: "",
        specialPermissions: [4, 5],
        error_en:
          "You don't have permission to access this section. Only KSPACE and Kerala companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
    ],
  },

  {
    label_en: "Gallery",
    label_ma: "ഗാലറി",
    id: 7,
    children: [
      {
        label_en: "Images",
        id: 71,
        show: true,
        path: "/gallery/images",
        isDirect: true,
        desc_ma: "",
        title_ma: "",
        label_ma: "ചിത്രങ്ങൾ",
      },
      {
        label_en: "Videos",
        id: 72,
        show: true,
        path: "/gallery/video",
        isDirect: true,
        desc_ma: "",
        title_ma: "",
        label_ma: "വീഡിയോകൾ",
      },
    ],
  },
  //association with professional bodies
  {
    label_en: "Association with Professional Bodies / Institutions",
    label_ma: "പ്രഫഷണൽ ബോഡീസുമായുള്ള അസോസിയേഷൻ ",
    id: 3,
    children: [
      // {
      //   label_en: "Professional Bodies / Institutions",
      //   id: 31,
      //   show: true,
      //   path: "/professional-bodies",
      //   title_en: "Associated Professional Bodies / Institutions",
      //   desc_en:
      //     "Provides the list of KSPACE Association with Professional Bodies.",
      //   desc_ma:
      //     " പ്രൊഫഷണൽ ബോഡികളുമായുള്ള കെസ്‌പെയ്‌സ് അസോസിയേഷന്റെ ലിസ്റ്റ് നൽകുന്നു.",
      //   title_ma: "അസോസിയേറ്റഡ് പ്രൊഫഷണൽ ബോഡികൾ / സ്ഥാപനങ്ങൾ",
      //   label_ma: "പ്രൊഫഷണൽ ബോഡികൾ / സ്ഥാപനങ്ങൾ",
      // },
      {
        label_en: "Directory of Registered Institutions",
        id: 32,
        show: true,

        path: "/institute-list",
        desc_en:
          "Provides the list of KSPACE collaboration with Academic Institutions",
        title_en: "Directory of Registered Institutions",
        desc_ma: "",
        title_ma: "രജിസ്റ്റർ ചെയ്ത സ്ഥാപനങ്ങളുടെ ഡയറക്ടറി",
        label_ma: "രജിസ്റ്റർ ചെയ്ത സ്ഥാപനങ്ങളുടെ ഡയറക്ടറി",
      },
      {
        label_en: "Submit a Student Project Proposal",
        id: 33,
        show: true,
        specialPermissions: [8],
        path: "/proposal-submit",
        desc_en: null,
        title_en: "Submit a Student Project Proposal",
        desc_ma: "",
        title_ma: "ഒരു വിദ്യാർത്ഥി പ്രോജക്റ്റ് പ്രൊപ്പോസൽ സമർപ്പിക്കുക",
        label_ma: "ഒരു വിദ്യാർത്ഥി പ്രോജക്റ്റ് പ്രൊപ്പോസൽ സമർപ്പിക്കുക",
        error_en:
          "You don't have permission to access this section. Only Academic Institutions registered with KSPACE have access to this section.",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. അക്കാദമിക് സ്ഥാപനങ്ങൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
      {
        label_en: "Directory of Student Project Proposals",
        id: 34,
        show: true,
        specialPermissions: [8, 4, 5],
        path: "/student-proposal-directory",
        title_en: "Directory of Student Project Proposal",
        desc_ma: "",
        title_ma: "വിദ്യാർത്ഥി പ്രോജക്റ്റ് പ്രൊപ്പോസലിൻറെ ഡയറക്ടറി",
        label_ma: "വിദ്യാർത്ഥി പ്രോജക്റ്റ് പ്രൊപ്പോസലിൻറെ ഡയറക്ടറി",
        error_en:
          "You don't have permission to access this section. Only Academic Institutions, KSPACE and Kerala companies have access to this section. ",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല.കെസ്പേസിൽ രജിസ്റ്റർ ചെയ്തിട്ടുള്ള അക്കാദമിക് സ്ഥാപനങ്ങൾ, കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
      {
        label_en: "Internship / Training Request",
        id: 35,
        show: true,
        path: "/training-reg",
        specialPermissions: [8],
        desc_en: null,
        title_en: "Internship/Training Request",
        desc_ma: "",
        title_ma: "ഇന്റേൺഷിപ്പ്/പരിശീലന അഭ്യർത്ഥന",
        label_ma: "ഇന്റേൺഷിപ്പ്/പരിശീലന അഭ്യർത്ഥന",
        error_en:
          "You don't have permission to access this section. Only Academic Institutions registered with KSPACE have access to this section",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല.കെസ്പേസിൽ രജിസ്റ്റർ ചെയ്തിട്ടുള്ള അക്കാദമിക് സ്ഥാപനങ്ങൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്.",
      },
      {
        label_en: "Directory of Internships / Training Requests",
        id: 36,
        show: true,
        path: "/training-list",
        specialPermissions: [8, 4, 5],
        desc_en: null,
        title_en: "Directory of Internship/Training Request",
        desc_ma: "",
        title_ma: "ഇന്റേൺഷിപ്പ്/പരിശീലന അഭ്യർത്ഥനയുടെ ഡയറക്ടറി",
        label_ma: "ഇന്റേൺഷിപ്പ്/പരിശീലന അഭ്യർത്ഥനയുടെ ഡയറക്ടറി",
        error_en:
          "You don't have permission to access this section. Only Academic Institutions and companies registered with KSPACE have access to this section.",
        error_ma:
          "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെസ്പേസിൽ രജിസ്റ്റർ ചെയ്തിട്ടുള്ള അക്കാദമിക് സ്ഥാപനങ്ങൾക്കും കമ്പനികൾക്കും മാത്രമേ ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളൂ.",
      },
    ],
  },

  //
];
