import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import DatePickerField from "../../../../../components/inputs/datepicker";
import TextField from "../../../../../components/inputs/textfield";
import Snackbar from "../../../../../components/snackbar/snackbar";

import Loader from "../../../../../components/loader/loader";
import {
  admin_notification_add,
  admin_notification_update,
} from "../../../admin.api";
import "./dialog.css";

let required = [
  "notification_date",
  "notification_title_en",
  "notification_title_ma",
  "notification_desc_ma",
  "notification_desc_en",
];
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      isActive: false,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleDatePickerChange = (e, value) => {
    let date = e.value;
    if (date) {
      this.setState({
        formData: {
          ...this.state.formData,
          notification_date: date,
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          notification_date: null,
        },
      });
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      this.setState({ isActive: true });
      if (edit) {
        admin_notification_update(formData.uuid, formData)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isActive: false,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_notification_add(this.state.formData)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };

  render() {
    const { submitted, formData, open, edit, isActive } = this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <Loader open={isActive} />
            <div className="overlay">
              <div className="big-dia-scroll dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit
                        ? "Edit Notification"
                        : view
                          ? "Notification"
                          : "Add Notification"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar d-flex justify-content-start">
                    <hr />
                  </div>
                  <form action="">
                    <div className="row book-form">
                      <div className="">
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Title[Eng]"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.notification_title_en ?? ""}
                            name="notification_title_en"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Title[Mal]"
                            name="notification_title_ma"
                            variant="outlined"
                            disabled={view}
                            value={formData.notification_title_ma ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>
                        <div className="col-12 not-required">
                          <DatePickerField
                            // required
                            value={formData.notification_date ?? null}
                            minDate={tomorrow}
                            label="End Date"
                            required
                            onChange={(e) =>
                              this.handleDatePickerChange(
                                e,
                                "notification_date"
                              )
                            }
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            name="notification_desc_en"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            label="Description[Eng]"
                            disabled={view}
                            value={formData.notification_desc_en ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            // disabled={this.props.view}
                            id="outlined-basic"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.notification_desc_ma ?? ""}
                            multiline
                            label="Description[Mal]"
                            name="notification_desc_ma"
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Link"
                            name="link"
                            variant="outlined"
                            disabled={view}
                            value={formData.link ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
