import React, { useEffect, useRef, useState } from "react";
// import Snackbar from "../../../../../../components/snackbar/snackbar";
import Snackbar from "../../../../../components/snackbar/snackbar";
import moment from "moment";
import { studentInternCreate } from "../../../user.api";
// import { user_company_domain_list, user_request } from "../../user.api";
import {
  builtup,
  euip,
  jointVenture,
  landon,
  ownland,
} from "./components/areadata";
import Qualifications from "./components/qualifications";
import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";
import Loader from "../../../../../components/loader/loader";
let eighteenYearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
let convertedDate = new Date(eighteenYearsAgo);
export default function NewCompanyRequest(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [defaultkey, setdefaultkey] = useState("");
  const [basicData, setBasicData] = useState({
    date_of_birth: moment(eighteenYearsAgo).format("DD-MM-YYYY"),
    gender: "Male",
  });
  const [areaData, setAreaData] = useState({});
  const [domainData, setDomain] = useState([]);
  const [addCount, setCount] = useState(1);
  const myRef = useRef(null);
  const [data, setData] = useState({});
  const [languageData, setLanguageData] = useState([
    { language: "Malayalam", checked: false },
    { language: "English", checked: false },
    { language: "Hindi", checked: false },
  ]);
  const [openView, setOpenView] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  // const getDomainTypes = () => {
  //   user_company_domain_list().then((res) => {
  //     setDomain(res.data);
  //     setCount(addCount + 1);
  //   });
  // };

  const action = (id, data) => {
    myRef.current.scrollIntoView();
    if (id === 0) {
      let dom = [];

      setActiveStep(1);
      setBasicData({ ...basicData, ...data, domain_types: dom });
    } else if (id === 1) {
      setActiveStep(0);
    } else if (id === 2) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    const form = new FormData();

    let langData = [];
    languageData.forEach((item) => {
      if (item.checked) {
        langData.push(item.language);
      }
    });
    if (basicData.any_other_lang)
      basicData.otherLanguages.forEach((item) => {
        langData.push(item.language);
      });

    form.append("address", basicData.address ?? null);
    form.append(
      "date_of_birth",
      basicData.date_of_birth
        ? moment(basicData.date_of_birth, "DD-MM-YYYY").format("YYYY-MM-DD")
        : ""
    );
    form.append("full_name", basicData.full_name ?? null);
    form.append("gender", basicData.gender ?? null);
    form.append("photo", basicData.photo ?? null);

    langData.forEach((item, i) => {
      form.append(`languages[${i}][language]`, item);
    });

    basicData.qualifications.forEach((item, i) => {
      form.append(`qualifications[${i}][board]`, item.board);
      form.append(`qualifications[${i}][exam_passed]`, item.exam_passed);
      form.append(`qualifications[${i}][year_passed]`, item.year_passed);
      form.append(`qualifications[${i}][mark]`, item.mark);
      form.append(
        `qualifications[${i}][certificate_photo]`,
        item.certificate_photo
      );
    });
    setIsActive(true);
    studentInternCreate(form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  // useEffect(() => {
  //   if (domainData.length === 0) {
  //     getDomainTypes();
  //   }
  //   // handleChangedata("3");
  // }, []);

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({
        date_of_birth: moment(eighteenYearsAgo).format("DD-MM-YYYY"),
        gender: "Male",
      });

      setLanguageData([
        { language: "Malayalam", checked: false },
        { language: "English", checked: false },
        { language: "Hindi", checked: false },
      ]);
    }
  };

  return (
    <div className="page-bg" ref={myRef}>
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Internship/Training Request</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="items-cont container">
            <div className="form-inner-section">
              {activeStep === 0 && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  action={action}
                  formData={basicData}
                  languageData={languageData}
                  setLanguageData={setLanguageData}
                />
              )}

              {activeStep === 1 && (
                <View
                  {...props}
                  basicData={basicData}
                  languageData={languageData}
                  action={action}
                />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
            {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
