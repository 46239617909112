"use client";
// import { useAppStore } from "@/stores";
import { Button } from "react-bootstrap";
// import { FaFileCsv } from "react-icons/fa";
// import { toast } from "react-hot-toast";

const CSVExport = ({ fetchData, title, ...props }) => {
  // const appstore = useAppStore();
  const getTableText = (data, divider) => {
    const columns = Object.keys(data[0]);
    const th = `${columns.join(divider)}`;
    const td = data
      .map((item) => Object.values(item).join(`"${divider}"`))
      .join('"\n"');
    return `${th}\n"${td}"`;
  };
  const exportToCSV = (text, fileName) => {
    const hiddenElement = document.createElement("a");
    const date = new Date();
    hiddenElement.href =
      "data:text/plain;charset=utf-8," + encodeURIComponent(text);
    hiddenElement.download = `${fileName}-${date.toISOString()}.csv`;
    hiddenElement.click();
  };
  async function generateCSV() {
    // appstore.setLoadingState(true);
    await fetchData().then((res) => {
      console.log(res);
      const text = getTableText(res, ",");
      exportToCSV(text, title);
    });
  }
  return (
    <div>
      {/* <Button
        label="Export"
        // icon={FaFileCsv}
        
      /> */}
      <button className="px-2 py-2 search-style border-0 text-white rounded" onClick={() => generateCSV()}>
        {/* <img src="/resources/logo/file.png" className="csvExp" /> */}
       Export Csv
      </button>
    </div>
  );
};

export default CSVExport;
