import React, { useEffect, useState, useRef } from "react";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import {
  expertManPowerCreateLookup,
  expertManPowerCreate,
  candidaterCreate,
} from "../../user.api";

import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";
import moment from "moment";
let eighteenYearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
export default function ExpManPowerReg(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");

  const [basicData, setBasicData] = useState({
    mobile: props.phone,
    dob: moment(eighteenYearsAgo).format("DD-MM-YYYY"),
    gender: 1,
    conf1: false,
    conf2: false,
    // caste: "General",
    handicapped: "No",
    education: [
      {
        university: "",
        year: "",
        location: "",
        grade: "",
        gpa: "",
        percentage : ""
      },
    ],
    employment: [
      {
        employer: "",
        contact: "",
        address: "",
        from: "",
        to: "",
        email: "",
        reason: "",
        position: "",
        exp_certificate: null,
        working: false
      },
    ],
    expertise_area: [
      // {
      //   expertise_name: "",
      // },
    ],
  });

  const [areaOfInterest, setAreaOfInterest] = useState([]);
  const [category, setCategory] = useState([]);
  const [addCount, setCount] = useState(1);
  const myRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  console.log(props);

  const action = (id, data, key) => {
    console.log(data);
    myRef.current.scrollIntoView();
    if (id === 0) {
      setActiveStep(1);
      setBasicData({ ...data });
    } else if (id === 1) {
      setActiveStep(0);
      setdefaultkey(key);
    } else if (id === 2) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    let edudata = [];
    let expData = [];
    let expArea = [];
    let areaInte = [];
    // basicData.expertise_area.forEach((item) => {
    //   expArea.push(item.exp);
    // });
    // basicData.education.forEach((item) => {
    //   let items = {};
    //   // let fileBase64 = getBase64(item.file_upload_certificate)
    //   //   .then((result) => {
    //   //     item.experience_certificate["base64"] = result;
    //   //     console.log("File Is", item.experience_certificate);
    //   //     continueFlag = true;
    //   //   })
    //   //   .catch((err) => {
    //   //     console.log(err);
    //   //   });
    //   items["university"] = item.university;
    //   items["year"] = item.year;
    //   items["location"] = item.location;
    //   items["grade"] = item.grade;
    //   items["gpa"] = item.gpa;

    //   edudata.push(items);
    // });

    // basicData.employment.forEach((item) => {
    //   let items = {};
    //   // let fileBase64 = getBase64(item.experience_certificate)
    //   //   .then((result) => {
    //   //     item.experience_certificate["base64"] = result;
    //   //     console.log("File Is", item.experience_certificate);
    //   //     continueFlag = true;
    //   //   })
    //   //   .catch((err) => {
    //   //     console.log(err);
    //   //   });
    //   items["employer"] = item.employer;
    //   items["contact"] = item.contact;
    //   items["address"] = item.address;
    //   items["from"] = item.from;
    //   items["to"] = item.to;
    //   items["email"] = item.email;
    //   items["position"] = item.position;
    //   items["reason"] = item.reason;
    //   items["exp_certificate"] = item.exp_certificate;
    //   // item.experience_certificate;
    //   expData.push(items);
    // });

    // basicData.area_of_interest.forEach((item) => {
    //   areaInte.push(item.uuid);
    // });

    // let finalData = {};
    // finalData = {
    //   native_kerala : basicData?.native_kerala ?? null,
    //   nri_keralite : basicData?.nri_keralite ?? null,
    //   prefix : basicData?.title ?? "Mr",
    //   name : basicData?.fname ?? "",
    //   dob: basicData.dob
    //   ? moment(basicData.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
    //   : "",
    //   gender: basicData.gender ?? null,
    //   marital_status:basicData?.marital_status ?? "",
    //   parent_name : basicData?.parent_name ?? "",
    //   caste : basicData?.caste ?? "",
    //   hadicapped :basicData?.hadicapped ?? "",
    //   email: basicData.email ?? null,
    //   alt_email : basicData?.alt_email ?? null,
    //   contact : basicData.mobile ?? null,
    //   address: basicData.address ?? null,
    //   alt_contact :basicData?.alt_contact ?? null,
    //   pin_code :basicData?.pin_code ?? null,
    //   blood_group : basicData?.blood_group ?? null ,
    //   identity_no : basicData?.identity_no ?? null,
    //   identity_file : basicData?.photo ?? null,
    //   qualification_type : basicData?.qualification_type ?? null,
    //   qualification : basicData?.qualification ?? null,

    //   category: basicData.category ?? null,
    //   category_name: basicData.category_name ?? null,

    //   ...(basicData.alternate_phone
    //     ? { country_code: basicData.country_code }
    //     : {}),
    //   alternate_phone: basicData.alternate_phone ?? "",
    //   // country_code: basicData.country_code ?? "",
    //   //  file_name: basicData.file_name ?? null,

    //   // full_name: "Full Name",
    //   // age:"18+",
    //   // first_name: basicData.first_name ?? null,
    //   // last_name: basicData.last_name ?? null,
    //   // ...(basicData.title ? { title: basicData.title } : { title: "Mr" }),
    //   // photo: basicData.photo ?? null,
    //   reference_name1 : basicData?.reference_name1 ?? null,
    //   reference_designation1 : basicData?.reference_designation1 ?? null ,
    //   reference_contact1 : basicData?.reference_contact1 ?? null ,
    //   reference_email1 : basicData?.reference_email1 ?? null ,
    //   reference_name2 : basicData?.reference_name2 ?? null,
    //   reference_designation2 : basicData?.reference_designation2 ?? null ,
    //   reference_contact2 : basicData?.reference_contact2 ?? null ,
    //   reference_email2 : basicData?.reference_email2 ?? null,
    //   interest_type : basicData?.interest_type ?? null,
    //   // interest : expArea,
    //   employment: expData ?? null,
    //   interest: expArea ?? null,
    //   education: edudata ?? null,
    //   // area_of_interest: areaInte ?? null,
    // };
    let payload = new FormData();

    payload.append("native_kerala", basicData?.native_kerala ?? null);
    payload.append("nri_keralite", basicData?.nri_keralite ?? null);
    payload.append("fresher", basicData?.fresher ?? null);
    payload.append("prefix", basicData?.prefix ?? "Mr");
    payload.append("name", basicData?.fname ?? "");
    payload.append(
      "dob",
      basicData.dob
        ? moment(basicData.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        : ""
    );
    payload.append("gender", basicData.gender ?? null);
    payload.append("marital_status", basicData?.marital_status ?? "");
    payload.append("parent_name", basicData?.parent_name ?? "");
    payload.append("caste", basicData?.caste ?? "");
    payload.append("handicapped", basicData?.handicapped ?? "");
    payload.append("email", basicData.email ?? null);
    payload.append("alt_email", basicData?.alt_email ?? "");
    payload.append("contact", basicData.mobile ?? null);
    payload.append("address", basicData.address ?? null);
    payload.append("alt_contact", basicData?.alt_contact ?? "");
    payload.append("alt_code", basicData?.alt_code ?? "");
    payload.append("pin_code", basicData?.pin_code ?? null);
    payload.append("blood_group", basicData?.blood_group ?? null);
    payload.append("identity_type", basicData?.identity_type ?? null);
    payload.append("identity_no", basicData?.identity_no ?? null);
    payload.append("identity_file", basicData?.identity_file ?? null);
    payload.append("photo", basicData?.photo ?? null);
    payload.append("qualification_type", basicData?.qualification_type ?? null);
    payload.append("qualification", basicData?.qualification ?? null);
    payload.append("category", basicData.category ?? null);
    payload.append("category_name", basicData.category_name ?? null);
    payload.append("alternate_phone", basicData.alternate_phone ?? "");
    if (basicData.alternate_phone)
      payload.append("country_code", basicData.country_code);

    payload.append("reference_name1", basicData?.reference_name1 ?? null);
    payload.append(
      "reference_designation1",
      basicData?.reference_designation1 ?? null
    );
    payload.append("reference_contact1", basicData?.reference_contact1 ?? null);
    payload.append("reference_email1", basicData?.reference_email1 ?? null);
    payload.append("reference_name2", basicData?.reference_name2 ?? null);
    payload.append(
      "reference_designation2",
      basicData?.reference_designation2 ?? null
    );
    payload.append("reference_contact2", basicData?.reference_contact2 ?? null);
    payload.append("reference_email2", basicData?.reference_email2 ?? null);
    payload.append("interest_type", basicData?.interest_type ?? null);
    if(basicData?.any_other_domain && basicData?.any_other_domain !== "" && basicData?.any_other_domain != "null") {
      payload.append("any_other_domain", basicData?.any_other_domain ?? null);

    }

    // let expArea = [];
    basicData.expertise_area.forEach((item) => {
      if (item?.checked) {
        expArea.push(item.domain_name);
      }
    });
    payload.append("interest", JSON.stringify(expArea));

    basicData.education.forEach((item, index) => {
      payload.append(`education[${index}][university]`, item.university);
      payload.append(`education[${index}][year]`, item.year);
      payload.append(`education[${index}][location]`, item.location);
      payload.append(`education[${index}][grade]`, item.grade);
      payload.append(`education[${index}][gpa]`, item.gpa);
      payload.append(`education[${index}][percentage]`, item.percentage);
    });
if(basicData.fresher === "No"){ 
    basicData.employment.forEach((item, index) => {
      payload.append(`employment[${index}][employer]`, item.employer);
      payload.append(`employment[${index}][contact]`, item.contact);
      payload.append(`employment[${index}][address]`, item.address);
      payload.append(`employment[${index}][from]`, item.from);
      payload.append(`employment[${index}][to]`, item.to);
      payload.append(`employment[${index}][email]`, item.email);
      payload.append(`employment[${index}][position]`, item.position);
      payload.append(`employment[${index}][reason]`, item.reason);
      payload.append(`employment[${index}][working]`, item.working == true ? "Yes" : "No");
      payload.append(
        `employment[${index}][exp_certificate]`,
        item.exp_certificate
      ); // file appended directly
    })}
    setIsActive(true);
    // console.log(finalData)
    candidaterCreate(payload)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const navLabel = [
    { id: 0, activeStep: 0, name: "Basic Information" },
    { id: 1, activeStep: 1, name: "Qualifications" },
  ];

  const headlperText = {
    header: "",
  };

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});
      props.logout();
    }
  };

  return (
    <div className="page-bg" ref={myRef}>
      <div>
        <Loader open={isActive} />
        <div className="dash-banner ">
          <div className="tit-txt">Job Seekers</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="itemc">
            {activeStep < 2 && (
              <>
                <div className="header-helper-text">{headlperText.header}</div>
              </>
            )}
            <div className="fomy form-inner-section">
              {activeStep === 0 && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  action={action}
                  formData={basicData}
                  setBasicData={setBasicData}
                  areaOfInterest={areaOfInterest}
                  setAreaOfInterest={setAreaOfInterest}
                  category={category}
                />
              )}

              {activeStep === 1 && (
                <View
                  {...props}
                  basicData={basicData}
                  areaOfInterest={areaOfInterest}
                  action={action}
                />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
            {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
