import React, { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import moment from "moment";
import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import CSVExport from "../../../../components/csvExport";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import CustomTable from "../../../../components/table/table";
import { admin_intern_delete, studentInternList } from "../../admin.api";
import "./view.css";

export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = () => {
    //
  };
  const handleProposalList = () => {
    setIsActive(true);
    studentInternList().then((res) => {
      let tdata = res.data;
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tdata);
      console.log( tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleProposalList();
  }, []);
  const handleView = (data) => {
    let path = "/intershiptraining-view/" + data.id;
    props.history.push(path);
  };
  const handleEdit = () => { };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_intern_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDownload = () => { };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleProposalList();
    }
  };
  // console.log(data[0])
  async function fetchExportData() {
    const exportdata = [];
    data.forEach((item) => {
      exportdata.push({
        ["Full Name"]: item.full_name,
        Address: item.address,
        ["Date of Birth"]: item.date_of_birth,
        Date: item.created_at
          ? moment(item.created_at).format("DD-MMM-YYYY hh:mm A")
          : "--",
        Status:
          item.status == 0
            ? "Pending"
            : item.status == 1
              ? "Requested"
              : item.status == 5
                ? "Rejected"
                : item.status == 4
                  ? "Approved"
                  : "",
      });
    });
    return exportdata;
  }
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        {/* <h6>
          <b>Internship / Training Requests</b>
        </h6> */}
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">
            <b>Internship / Training Requests</b>
          </h6>
          <CSVExport title="Internship" fetchData={fetchExportData} />
        </div>
        <hr />
        {/* --------------admin----- */}

        <CustomTable
          {...props}
          columns={[
            { title: "Full Name", field: "full_name" },
            { title: "Institution", type: "intinst" }, //this is a one off way. do not copy this piece of code to reuse
            { title: "Address", field: "address" },
            { title: "Date of Birth", field: "date_of_birth", type: "date" },
            {
              title: "Status",
              field: "status",
              type: "commonStatusInternship",
            },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                { type: "view", handleClick: handleView },

                { type: "delete", handleClick: handleDelete },
              ]
              : [
                { type: "view", handleClick: handleView },
                // { type: "delete", handleClick: handleDelete },
              ]
          }
        />

        {/* <CustomTable
            {...props}
            columns={[
              { title: "Proposal Title", field: "project_title" },
              { title: "Project Guide", field: "guide" },
              { title: "Short Detail", field: "detail" },

              { title: "Status", field: "status" },
            ]}
            data={data}
            actions={[
              { type: "view", handleClick: handleView },

              { type: "delete", handleClick: handleDelete },
            ]}
            handleDownload={handleDownload}
          /> */}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Student Proposal?"
          data={activeData}
          message="Are you sure to delete this Proposal?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
