import * as EmailValidator from "email-validator";
export const FormValidate = (data, valid) => {
  const error = {};
  if (valid) {
    valid.forEach((item) => {
      if (
        !data[item] ||
        data[item] === null ||
        data[item] === undefined ||
        data[item] === ""
      ) {
        error[item] = true;
      } else if (
        data[item] &&
        data[item] !== null &&
        data[item] !== undefined &&
        data[item] !== ""
      ) {
        delete error[item];
      }
    });
  }
  return error;
};

export const EmailValidate = (value) => {
  // const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  // if(pattern.test(value) === true){
  //   return true
  // } else {
  //   return false
  // }
  console.log(EmailValidator.validate(value))
  return EmailValidator.validate(value);
};
export const parseQueryParams = (params = {}) => {
  if (!Object.entries(params).length) return "";
  return Object.entries(params).reduce(
    (acc, [key, value]) => acc + `${key}=${value}&`,
    "?"
  );
};
