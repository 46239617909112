import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import PartnerSection from "../../../../components/page/home/org-structures/part";
import { public_orgstruc_list } from "../../public.api";

const partData = [
  {
    image: "/logo.png",
    type: "0",
  },
  {
    image: "/DUK.jpeg",
    type: "1",
  },
  {
    image: "/ICTAK.png",
    type: "1",
  },
  {
    image: "/vssc.jpg",
    type: "1",
  },
];
export default function Partners(props) {
  const [data, setdata] = useState([]);
  const [activeItem, setActiveItem] = useState("0");
  const [url, setUrl] = useState("");
  const [isActive, setIsActive] = useState(false);

  // const handleOrgList = () => {
  //   setIsActive(true);
  //   public_orgstruc_list().then((res) => {
  //     setdata(res.data);
  //     setUrl(res.current_url);
  //     setIsActive(false);
  //   });
  // };
  // useEffect(() => {
  //   handleOrgList();
  // }, []);
  //
  console.log(activeItem);
  return (
    <div className="org-structure">
      <div className="org-strecture-title">
        {props.state.language === "ma" ? "Our Partners" : "Our Partners"}
      </div>
      <div className="strec-cont">
        <div className="tab-cont-cont">
          <div className="tab-cont">
            <div
              className={
                activeItem === "0" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("0")}
            >
              <div>
                {props.state.language === "ma"
                  ? "Affiliated Institutions"
                  : "Affiliated Institutions"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "0"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeItem === "1" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("1")}
            >
              <div>
                {props.state.language === "ma"
                  ? "Collaboration with KSPACE"
                  : "Collaboration with KSPACE"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "1"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeItem === "2" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("2")}
            >
              <div>
                {props.state.language === "ma"
                  ? "Members"
                  : "Members"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "2"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            {/* <div
              className={
                activeItem === "2" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("2")}
            >
              <div>
                {props.state.language === "ma" ? "കോർ ടീം" : "Core Team"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "2"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="strec-item-cont">
          <PartnerSection
            {...props}
            data={partData}
            fullwidth={true}
            active={activeItem}
            isActive={isActive}
          />
        </div>
      </div>
    </div>
  );
}
