import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../components/loader/loader";
import Underline from "../../../components/page/home/underline/underline";
import About from "./components/about";
import Accomplishments from "./components/accomplishments";
import Banner from "./components/banner";
import HomeEvents from "./components/events";
import Gallery from "./components/gallery";
import News from "./components/news";
import OrgStructureComp from "./components/orgstructure";
import Personalities from "./components/quotes";
import ScopeAct from "./components/scopeofact";
import jwtDecode from "jwt-decode";

import "./home.css";
import Partners from "./components/partner";
import NewsUpdates from "./components/NewsUpdates";
import Snackbar from "../../../components/infoBar/snackbar";

export default function Home(props) {
  const top = useRef(null);
  const news = useRef(null);
  const gallery = useRef(null);
  const event = useRef(null);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let prevPath = sessionStorage.getItem("continueFrom");
  const scrollToPrevPath = (path) => {
    if (path === "events") event.current.scrollIntoView();
    else if (path === "news") news.current.scrollIntoView();
    else if (path === "gallery") gallery.current.scrollIntoView();
    else top.current.scrollIntoView();

    sessionStorage.removeItem("continueFrom");
  };
  const statusViewed = sessionStorage.getItem("viewed")
 
  useEffect(() => {
    scrollToPrevPath(prevPath);
  }, []);
  console.log(
    jwtDecode(
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wva3NwYWNlLmlub3ZhY2UuaW5cL2FwaVwvcmVmcmVzaCIsImlhdCI6MTY1NDg1OTAzMywiZXhwIjoxNjU0OTQ1NTg1LCJuYmYiOjE2NTQ4NTkxODUsImp0aSI6Im0xWGVCVFJVN3JvU1IwMTQiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJ1c2VyX3R5cGUiOjF9.CS1sDPM4oOywOBPx1tjP951bWcc8PYKwZ5KiSUTfFu8"
    )
  );
  useEffect(() => {
if(!statusViewed){
  setSnackMessage(`You may see some test data`);
  setIsSnackError(true);
  setSnackOpen(true);

}

  },[statusViewed])
  const snackbarClose = (flag) => {
    sessionStorage.setItem("viewed", "yes")
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);  
    // if (!flag) {
    //   handleEventList();
    // }
  };
  return (
    <div className="bgc-white" ref={top}>
      {/* ======================= Banner  =================================================================== */}
      <Banner {...props} />
      {/* =================================================================================================== */}
      {/* ======================= News  ===================================================================== */}
      <div className="news bgc-default" ref={news}>
        <News {...props} />
      </div>
      <div  style={{"paddingTop" : "20px", "paddingBottom" : "20px"}}>
      <NewsUpdates {...props} />
      </div>
      {/* =================================================================================================== */}
      {/* ======================= Scope  ==================================================================== */}
      <ScopeAct {...props} />
      {/* =================================================================================================== */}
      {/* ======================= About  ==================================================================== */}
      <div className=" bgc-white">
        <About {...props} />
      </div>

      {/* =================================================================================================== */}
      {/* ======================= Organization Strecture  =================================================== */}
      <div className="bgc-white">
        <OrgStructureComp {...props} />
      </div>
      {/* <div className="pad-20 bgc-white"></div> */}
      <div className="events">
        <Personalities {...props} />
      </div>
      {/* =================================================================================================== */}
      {/* ======================= Events  =================================================================== */}
      <div className="new-events" ref={event}>
        <div className="event-main-title">
          {props.state.language === "ma" ? "ഇവന്റുകൾ" : "Events"}
        </div>

        <HomeEvents {...props} />
      </div>

      {/* =================================================================================================== */}
      {/* ======================= Gallery  ================================================================== */}
      <div className="bgc-white gal-con-new" ref={gallery}>
        <div className="galleryTitle">
          {props.state.language === "ma" ? "ഗാലറി" : "Our Gallery"}
        </div>

        <Gallery {...props} />
      </div>
      {/* =================================================================================================== */}
      {/* ======================= Quotes  =================================================================== */}
      {/*  */}

      {/* =================================================================================================== */}
      {/* ======================= Accomplishment  =========================================================== */}

      <div className="bgc-white">
        <Accomplishments {...props} />
      </div>
      <div className="bgc-white">
        <Partners {...props} />
      </div>

      {/* =================================================================================================== */}
      {/* <Snackbar
        message={snackMessage}
        open={snackOpen}
        close={snackbarClose}
      /> */}
    </div>
  );
}
