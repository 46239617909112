import React, { useEffect, useState, useRef } from "react";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import {
  expertManPowerCreateLookup,
  expertManPowerCreate,
} from "../../user.api";

import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";
import moment from "moment";
let eighteenYearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
export default function ExpManPowerReg(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");

  const [basicData, setBasicData] = useState({
    mobile: props.phone,
    date_of_birth: moment(eighteenYearsAgo).format("DD-MM-YYYY"),
    gender: 1,
    educational_qualifications: [
      {
        examination_passed: "",
        board_of_university: "",
        year_of_pass: "",
        mark_grade: "",
        file_upload_certificate: null,
      },
    ],
    work_experience: [
      {
        organization_name: "",
        experience: "",
        nature_of_work: "",
        designation: "",
        reason_for_leaving: "",
        experience_certificate: null,
      },
    ],
    expertise_area: [
      {
        expertise_name: "",
      },
    ],
  });

  const [areaOfInterest, setAreaOfInterest] = useState([]);
  const [category, setCategory] = useState([]);
  const [addCount, setCount] = useState(1);
  const myRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  console.log(props);

  const action = (id, data, key) => {
    console.log(data);
    myRef.current.scrollIntoView();
    if (id === 0) {
      setActiveStep(1);
      setBasicData({ ...data });
    } else if (id === 1) {
      setActiveStep(0);
      setdefaultkey(key);
    } else if (id === 2) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    let edudata = [];
    let expData = [];
    let expArea = [];
    let areaInte = [];
    basicData.expertise_area.forEach((item) => {
      expArea.push(item.expertise_name);
    });
    basicData.educational_qualifications.forEach((item) => {
      let items = {};
      // let fileBase64 = getBase64(item.file_upload_certificate)
      //   .then((result) => {
      //     item.experience_certificate["base64"] = result;
      //     console.log("File Is", item.experience_certificate);
      //     continueFlag = true;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      items["examination_passed"] = item.examination_passed;
      items["board_of_university"] = item.board_of_university;
      items["file_upload_certificate"] = item.file_upload_certificate;
      items["year_of_pass"] = item.year_of_pass;
      items["mark_grade"] = item.mark_grade;

      edudata.push(items);
    });

    basicData.work_experience.forEach((item) => {
      let items = {};
      // let fileBase64 = getBase64(item.experience_certificate)
      //   .then((result) => {
      //     item.experience_certificate["base64"] = result;
      //     console.log("File Is", item.experience_certificate);
      //     continueFlag = true;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      items["organization_name"] = item.organization_name;
      items["experience"] = item.experience;
      items["nature_of_work"] = item.nature_of_work;
      items["designation"] = item.designation;
      items["reason_for_leaving"] = item.reason_for_leaving;
      items["experience_certificate"] = item.experience_certificate;
      // item.experience_certificate;
      expData.push(items);
    });

    basicData.area_of_interest.forEach((item) => {
      areaInte.push(item.uuid);
    });
    let finalData = {};
    finalData = {
      address: basicData.address ?? null,
      date_of_birth: basicData.date_of_birth
        ? moment(basicData.date_of_birth, "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      category: basicData.category ?? null,
      category_name: basicData.category_name ?? null,
      email: basicData.email ?? null,
      ...(basicData.alternate_phone
        ? { country_code: basicData.country_code }
        : {}),
      alternate_phone: basicData.alternate_phone ?? "",
      // country_code: basicData.country_code ?? "",
      //  file_name: basicData.file_name ?? null,
      gender: basicData.gender ?? null,
      mobile: basicData.mobile ?? null,
      full_name: "Full Name",
      age:"18+", 
      first_name: basicData.first_name ?? null,
      last_name: basicData.last_name ?? null,
      ...(basicData.title ? { title: basicData.title } : { title: "Mr" }),
      photo: basicData.photo ?? null,
      experience: expData ?? null,
      expertise_area: expArea ?? null,
      education: edudata ?? null,
      area_of_interest: areaInte ?? null,
    };
    setIsActive(true);
    expertManPowerCreate(finalData)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const navLabel = [
    { id: 0, activeStep: 0, name: "Basic Information" },
    { id: 1, activeStep: 1, name: "Qualifications" },
  ];

  const headlperText = {
    header: "",
  };

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});
      props.logout();
    }
  };

  return (
    <div className="page-bg" ref={myRef}>
      <div>
        <Loader open={isActive} />
        <div className="dash-banner ">
          <div className="tit-txt">Expert Manpower</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="itemc">
            {activeStep < 2 && (
              <>
                <div className="header-helper-text">{headlperText.header}</div>
              </>
            )}
            <div className="fomy form-inner-section">
              {activeStep === 0 && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  action={action}
                  formData={basicData}
                  setBasicData={setBasicData}
                  areaOfInterest={areaOfInterest}
                  setAreaOfInterest={setAreaOfInterest}
                  category={category}
                />
              )}

              {activeStep === 1 && (
                <View
                  {...props}
                  basicData={basicData}
                  areaOfInterest={areaOfInterest}
                  action={action}
                />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
            {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
