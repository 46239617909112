import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../../components/commonform/commonForm";
import { EmailValidate } from "../../../../../../components/formValidate";
import Snackbar from "../../../../../../components/snackbar/snackbar";
import { is_email_registered } from "../../../../user.api";
import moment from "moment";

// import { user_area_types_list, user_locations_list } from "../../../user.api";

let area_management = [{ id: "1" }, { id: "2" }, { id: "3" }];
export default function AreaDetails(props) {
  const [formData, setFormData] = useState(props.formData);
  const [keyvalue, setKeyvalue] = useState(0);
  const [count, setCount] = useState(1);
  const [error, setError] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  useEffect(() => {
    setFormData(props.formData);
  }, [props.formData]);
  const handleNextSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (props.update) {
      let isValidDate = false;

      isValidDate = moment(formData.registration_date, "DD-MM-YYYY").isValid();

      // console.log(new Date(formData.file_registration_proof));

      if (
        formData.file_registration_proof !== null &&
        formData.file_registration_proof !== undefined &&
        formData.file_registration_proof !== ""
      ) {
        if (
          formData.file_submitted_record !== null &&
          formData.file_submitted_record !== undefined &&
          formData.file_submitted_record !== ""
        ) {
          if (
            formData.registration_date !== null &&
            formData.registration_date !== undefined &&
            formData.registration_date !== "" &&
            isValidDate &&
            formData.registration_date !== "00-00-0000" &&
            formData.registration_date !== "30-11--1"
          ) {
            props.action(0, formData);
          } else {
            setSnackOpen(true);
            setSnackMessage("Please choose a valid registration date");
            setIsSnackError(true);
          }
        } else {
          setSnackOpen(true);
          setSnackMessage("Please choose a valid request letter");
          setIsSnackError(true);
        }
      } else {
        setSnackOpen(true);
        setSnackMessage("Please choose a valid registration proof");
        setIsSnackError(true);
      }
    } else {
      let emailData = { email: formData.email };

      is_email_registered(emailData)
        .then((res) => {
          // setSnackMessage(res.message);
          // setIsSnackError(false);
          // setIsActive(false);
          let isValidDate = false;

          isValidDate = moment(
            formData.registration_date,
            "DD-MM-YYYY"
          ).isValid();

          // console.log(new Date(formData.file_registration_proof));

          if (
            formData.file_registration_proof !== null &&
            formData.file_registration_proof !== undefined &&
            formData.file_registration_proof !== ""
          ) {
            if (
              formData.file_submitted_record !== null &&
              formData.file_submitted_record !== undefined &&
              formData.file_submitted_record !== ""
            ) {
              if (emailRegex.test(formData?.email) === true) {
                props.action(0, formData);
              } else {
                setSnackOpen(true);
                setSnackMessage("Please enter a valid email");
                setIsSnackError(true);
              }
            } else {
              setSnackOpen(true);
              setSnackMessage("Please choose a valid request letter");
              setIsSnackError(true);
            }
          } else {
            setSnackOpen(true);
            setSnackMessage("Please choose a valid registration proof");
            setIsSnackError(true);
          }
          // setSnackOpen(true);
        })
        .catch((error) => {
          setSnackMessage(error.response.data.message);
          setIsSnackError(true);
          setSnackOpen(true);
          // setIsActive(false);
        });
    }
    // props.action(0, formData);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleCourseChange = (e, i) => {
    let data = { ...formData };

    data.course_details[i] = {
      ...data.course_details[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };

  const addCourse = (i) => {
    let data = { ...formData };

    data.course_details.push({
      course_name: "",
      course_type: "Technical",
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeCourse = (i) => {
    let data = { ...formData };
    data.course_details.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const textFieldChange = (e) => {
    if (e.target.name === "email" || e.target.name === "alternate_email") {
      let erroremail = !EmailValidate(e.target.value);

      setError({ ...error, [e.target.name]: erroremail });
    }
    let name = e.target.name;

    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);
    setKeyvalue(keyvalue + 1);
  };
  // const handleNumberChange = (e) => {

  //   let name = e.target.name;
  //   let value = e.target.value;
  //   let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
  //   let data = formData;
  //   data[name] = newValue;
  //   setFormData(data);
  //   setKeyvalue(keyvalue + 1);
  // };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const handleDatePickerChange = (e, name) => {
    let date = e.value;

    console.log(date);
    if (date) {
      setFormData({ ...formData, [name]: date });
      setKeyvalue(keyvalue + 1);
    } else {
      setFormData({ ...formData, [name]: null });

      setKeyvalue(keyvalue + 1);
    }
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let fileName =
      e.target.name === "file_registration_proof"
        ? "file_name_proof"
        : "file_name_request";

    let type = e.target.files[0].name.split(".").pop();

    if (
      type?.toLowerCase() === "png" ||
      type?.toLowerCase() === "jpeg" ||
      type?.toLowerCase() === "jpg" ||
      type?.toLowerCase() === "pdf"
    ) {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        setFormData({
          ...formData,
          [fileName]: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          [fileName]: null,
          [e.target.name]: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        [fileName]: null,
        [e.target.name]: null,
      });
    }
  };
  console.log(formData);
  return (
    <div className="expert ">
      <form onSubmit={handleNextSubmit} className="">
        <div>
          <div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Name of the Institution"
                required
                name="institution_name"
                value={formData.institution_name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="number"
                label="Phone Number"
                required
                disabled
                // multiline
                name="phone_number"
                value={formData.phone_number ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-xs-12 col-sm-12">
              <label className="control-label mylabel mb-2">
                Alternate Phone
              </label>
              <div className="d-flex col-12 align-items-end ">
                <div className="col-1 ">
                  <CommonForm
                    fieldType="text"
                    error={error.country_code}
                    name="country_code"
                    maxLength="4"
                    value={formData.country_code ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="p-1"></div>
                <div className="col-11">
                  <CommonForm
                    fieldType="text"
                    error={error.alternate_phone}
                    name="alternate_phone"
                    maxLength="10"
                    value={formData.alternate_phone ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Registered Office Address"
                required
                multiline
                name="address"
                value={formData.address ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="email"
                label="Email"
                disabled={props.update}
                value={formData?.email ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                required
                name="email"
                {...props}
              />
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Affiliation Details with Affiliation Number"
                required
                name="affiliation"
                multiline
                value={formData.affiliation ?? ""}
                onChange={(e) => {
                  // handleDatePickerChange(e, "registration_date");
                  textFieldChange(e);
                }}
                {...props}
                maxDate={new Date()}
              />
            </div>

            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="number"
                label="Total Number of Students"
                required
                name="total_students"
                pattern="[0-9]"
                value={formData.total_students ?? ""}
                onChange={(e) => {
                  handleNumberChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="file"
                required={!formData.file_name_proof ? true : false}
                label="Upload Proof of Registration"
                name="file_registration_proof"
                {...props}
                id="upload_proposal"
                fileName={formData.file_name_proof}
                className="no-margin"
                accept=".pdf"
                type="file"
                variant="outlined"
                onChange={handleFileChange}
              />
              <div className="file-up-helper ">
                *Upload only .pdf format and Size should be less than 1MB
              </div>
            </div>

            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Headquarters Details (limit: 20 Words)
                
                "
                required
                maxLength={150}
                value={formData.headquaters_detail ?? ""}
                name="headquaters_detail"
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Name and Designation of Authorized Person with contact  details"
                multiline
                required
                name="contact_person_details"
                className="no-margin"
                value={formData.contact_person_details ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="header-label">Courses Offered</div>
            {formData.course_details?.map((e, i) => (
              <div className="section-datas-cont mb-3">
                <div className="section-Cont ">
                  <div className="row pb-3">
                    
                      <div className="col-xs-5 col-sm-6 mt-2">
                      <div className=" input-group">
                        {" "}
                        <label className={"control-label mylabel mb-2"}>
                          Course Type
                        </label>
                      </div>
                      <select
                        id="course_type"
                        className="mandatory-select form-select selectbox w-100"
                        required
                        label="bbbb"
                        name="course_type"
                        onChange={(e) => handleCourseChange(e, i)}
                        value={e?.course_type ?? ""}
                      >
                        <option value="Technical">Technical</option>
                        <option value="Non-Technical">Non-Technical</option>
                      </select>
                      </div>
                      <div className="col-xs-6 col-sm-6 mt-2">
                      <div className=" input-group">
                        {" "}
                        <label className={"control-label mylabel mb-2"}>
                          Course
                        </label>
                      </div>
                      <select
                        id="course_name"
                        className="mandatory-select form-select selectbox w-100"
                        required
                        label="bbbb"
                        name="course_name"
                        onChange={(e) => handleCourseChange(e, i)}
                        value={e?.course_name ?? ""}
                      >
                        {e.course_type === "Technical" ? (
                          <>
                          <option value="">Select Course</option>
                            <option value="ITI">ITI</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Graduation">Graduation</option>
                            <option value="Post-Graduation">Post-Graduation</option>
                            <option value="MPhil">MPhil</option>
                            <option value="PhD">PhD</option>
                          </>
                        ) : e.course_type === "Non-Technical" ? (
                          <>
                            {" "}
                            <option value="">Select Course</option>
                            <option value="12th">12th</option>
                            <option value="Graduation">Graduation</option>
                            <option value="Post-Graduation">Post-Graduation</option>
                            <option value="MPhil">MPhil</option>
                            <option value="PhD">PhD</option>
                          </>
                        ) : <option value="">Select Course Type</option>}
                      </select>
                      </div>
                      {/* <CommonForm
                        fieldType="text"
                        label="Course Name"
                        required
                        name="course_name"
                        value={e?.course_name}
                        onChange={(e) => {
                          handleCourseChange(e, i);
                        }}
                        {...props}
                      /> */}
                   
                  </div>
                </div>

                <div className="more-btn-cnt">
                  {formData.course_details.length === i + 1 && (
                    <button
                      style={{ marginLeft: 5 }}
                      type="button"
                      {...props}
                      className="btn btn-primary"
                      onClick={(e) => addCourse(i)}
                    >
                      Add More
                    </button>
                  )}
                  {formData.course_details.length > 1 && (
                    <button
                      {...props}
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => removeCourse(i)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="col-xs-12 col-sm-12">
              <CommonForm
                fieldType="text"
                label="Brief note on Institution activities"
                multiline
                required
                value={formData.notes_institutions ?? ""}
                className="no-margin"
                name="notes_institutions"
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="section-datas-cont">
              <div className="col-12">
                <CommonForm
                  fieldType="file"
                  required={!formData.file_name_request ? true : false}
                  label="Please submit request letter in institution Letter Head of the Institution/Signing Authority."
                  name="file_submitted_record"
                  {...props}
                  id="file_submitted_record"
                  accept=".pdf"
                  type="file"
                  variant="outlined"
                  fileName={formData.file_name_request}
                  onChange={handleFileChange}
                />
                <div className="file-up-helper">
                  *Upload only .pdf format and Size should be less than 1MB
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-buttons ">
          <button type="submit" className="primary-btn ">
            SUBMIT TO PREVIEW
          </button>
        </div>
      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
