import React, { useEffect, useRef, useState } from "react";
// import Snackbar from "../../../../../../components/snackbar/snackbar";
import Snackbar from "../../../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";
import { user_company_domain_list, studentPropCreate } from "../../../user.api";
import {
  builtup,
  euip,
  jointVenture,
  landon,
  ownland,
} from "./components/areadata";
import Qualifications from "./components/qualifications";
import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";
import Loader from "../../../../../components/loader/loader";
export default function NewCompanyRequest(props) {
  let userData = jwtDecode(props.state.payload);
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");
  const [basicData, setBasicData] = useState({
    phone_number: props.phone,
  });
  const [areaData, setAreaData] = useState({});
  const myRef = useRef(null);
  const [addCount, setCount] = useState(1);
  const [data, setData] = useState({});
  const [openView, setOpenView] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [areamange, setareaManage] = useState(builtup);
  console.log(userData.email);
  const action = (id, data, key) => {
    myRef.current.scrollIntoView();
    if (id === 0) {
      setActiveStep(1);
      setBasicData({ ...basicData, ...data });
    } else if (id === 1) {
      setActiveStep(0);
      setdefaultkey(key);

      // setBasicData({ ...basicData, ...data });
    } else if (id === 2) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    const form = new FormData();

    form.append("project_title", basicData.project_title ?? null);
    form.append("project_guide1", basicData.project_guide1 ?? null);
    form.append("project_guide1_phone", basicData.project_guide1_phone ?? null);
    form.append("project_guide2_phone", basicData.project_guide2_phone ?? null);
    form.append("project_guide1_email", basicData.project_guide1_email ?? null);
    form.append("project_guide2_email", basicData.project_guide2_email ?? null);
    form.append("project_guide2", basicData.project_guide2 ?? null);
    form.append("email", userData?.user?.email ?? null);
    form.append("institution_id", userData?.user?.institution_id ?? null);

    // form.append("email", basicData.email ?? null);
    form.append("file_upload_proposal", basicData.file_upload_proposal ?? null);
    form.append(
      "short_proposal_details",
      basicData.short_proposal_details ?? null
    );
    // form.append("phone_no", basicData.phone_no ?? null);

    let inst = userData?.user?.institution_id;
    basicData.students.forEach((item, index) => {
      form.append(`students[${index}][student_name]`, item.student_name);
      form.append(`students[${index}][semester]`, item.semester);
      form.append(`students[${index}][branch]`, item.branch);
      form.append(`students[${index}][mark]`, item.mark);
      form.append(`students[${index}][photo]`, item.photo);
    });
    // form.append("institution_id", inst);
    setIsActive(true);
    studentPropCreate(form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  // useEffect(() => {
  //   if (domainData.length === 0) {
  //     getDomainTypes();
  //   }
  //   // handleChangedata("3");
  // }, []);

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.logout();
    }
  };

  return (
    <div className="page-bg" ref={myRef}>
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Project Proposal</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="items-cont container">
            {activeStep < 2 && (
              <>
                {/* <div className="d-flex">
                  {navLabel.map((item, index) => (
                    <div
                      className={
                        index % 2 === 1
                          ? item.activeStep === activeStep
                            ? "headerbuttoneven active"
                            : "headerbuttoneven"
                          : item.activeStep === activeStep
                          ? "headerbuttonodd active"
                          : "headerbuttonodd"
                      }
                    >
                      <div
                        className={
                          index % 2 === 1 ? "indexCont even" : "indexCont "
                        }
                      >
                        {index + 1}
                      </div>
                      <div> {item.name}</div>
                    </div>
                  ))}
                </div> */}
              </>
            )}
            <div className="form-inner-section">
              {activeStep === 0 && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  action={action}
                  formData={basicData}
                />
              )}

              {activeStep === 1 && (
                <View {...props} basicData={basicData} action={action} />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
            {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
