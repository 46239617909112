import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import CustomTable from "../../../../components/table/table";
import {
  studentProposaldetails,
  student_prop_status_update,
} from "../../admin.api";
import "./view.css";
import moment from "moment";

export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const handleDownload = () => {};
  const handleDownloadProposal = () => {
    let path = `${result}${data.file_upload_proposal}`;
    window.open(path, "_blank");
  };
  const handleEdit = () => {};
  const getDetails = (id) => {
    studentProposaldetails(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  // const saMessage = (status) => {
  //   Swal.fire({
  //     // inputLabel: 'Message',
  //     // input : 'textarea',
  //     showCancelButton: false,
  //     confirmButtonColor: '#0A223D',
  //     confirmButtonText: 'Submit',
  //     inputAttributes: {
  //       'aria-label': 'Type your message here',
  //   },
  //   inputValidator: (value) => {
  //     if (!value) {
  //       return 'Please describe the reason'
  //     }
  //   },
  // showCancelButton: true,
  //   }).then((message) => {
  //     if (message.isConfirmed === true) {
  //       let data ={}
  //       if (adminType === 1) {
  //         if (status === 1) {
  //           data = { status_id: status, accademic_project_id: selectedParam.id, admin_message: message.value };
  //         } else {
  //           data = { status_id: status, accademic_project_id: selectedParam.id, user_message: message.value };
  //         }
  //       } else {
  //         data = { status_id: status, accademic_project_id: selectedParam.id, super_admin_message: message.value };
  //       }
  //       // let data = { status_id: status, accademic_project_id: selectedParam.id, message :message.value };
  //   setIsActive(true);
  //   student_prop_status_update(data)
  //     .then((res) => {
  //       setSnackMessage(res.message);
  //       setIsSnackError(false);
  //       setSnackOpen(true);
  //       setIsActive(false);
  //     })
  //     .catch((error) => {
  //       setSnackMessage(error.message);
  //       setIsSnackError(true);
  //       setSnackOpen(true);
  //       setIsActive(false);
  //     });
  //     }
  //   })
  // }
  const handleAdminActions = (status) => {
    let data = { status_id: status, accademic_project_id: selectedParam.id };
    setIsActive(true);
    student_prop_status_update(data)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/institutionproposal-list");
    }
  };
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Student Proposal Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Proposal Title</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.project_title}</div>
          </div>
          
          <div className="row">
            <div className="col-3">Project Guides</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <p>
                <div className="d-flex col-12">
                  <div className="col-2"> <b>Guide 1</b></div>
                  <div className="col-1">:</div>
                  <div className="col-7">
                    <div>Name : {data.project_guide1}</div>
                    <div>Phone : {data.project_guide1_phone}</div>
                    <div>Email : {data.project_guide1_email}</div>
                  </div>
                </div>
               
              </p>
              <p>
                <div className="d-flex col-12">
                  <div className="col-2"> <b>Guide 2</b></div>
                  <div className="col-1">:</div>
                  <div className="col-7">
                    <div>Name : {data.project_guide2}</div>
                    <div>Phone : {data.project_guide2_phone}</div>
                    <div>Email : {data.project_guide2_email}</div>
                  </div>
                </div>
               
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-3">Students Details</div>
            <div className="col-1">:</div>
            <div className="col-7 mt-00">
              <CustomTable
                {...props}
                columns={[
                  { title: "Student Name", field: "student_name" },
                  { title: "Semester", field: "semester" },
                  { title: "Branch", field: "branch" },
                  { title: "Previous year Mark", field: "mark" },

                  { title: "Photo", field: "photo", type: "image" },
                ]}
                data={data.studentsadded ?? []}
                handleDownload={handleDownload}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">Short Details of Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.short_proposal_details}</div>
          </div>
          <div className="row">
            <div className="col-3">Uploaded your Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <button
                type="button"
                onClick={(e) => handleDownloadProposal(data)}
                className="btn btn-reject btn-dark btn-sm"
              >
                Download Proposal
              </button>
            </div>
          </div>
          {data?.created_at &&
          <div className="row">
            <div className="col-3">Created At</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.created_at ? moment(data?.created_at).format("DD-MM-YYYY") : ""}</div>
          </div>}

          {adminType === 1 && (
            <>
              {data.status === 0 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(1)}
                  >
                    Submit for Acceptance
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-approve btn-warning mr-2"
                    onClick={(e) => saMessage(0)}
                  >
                    Revert
                  </button> */}
                </div>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {data.status === 1 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(2)}
                  >
                    Approve
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-approve btn-warning mx-2"
                    onClick={(e) => saMessage(0)}
                  >
                    Revert
                  </button> */}

                  <button
                    type="button"
                    className="btn btn-reject  btn-danger "
                    onClick={(e) => handleAdminActions(3)}
                  >
                    Reject
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
