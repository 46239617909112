import React, { useEffect, useState } from "react";

import CustomIcons from "../../../components/icons/kIcons.component";
import { user_dash_list } from "../user.api";
import DashApplicationDetails from "./components/application/dashApplication";
import CommonAppDetails from "./components/commonAppDetails/dashApplication";
import DashProfileDetails from "./components/profile/dashProfile";
import "./mydash.css";
import "./dashboard.css";
import Loader from "../../../components/loader/loader";
import jwtDecode from "jwt-decode";

export default function DashBoard(props) {
  const [activeMenu, setActiveMenu] = useState("application");
  const [data, setdata] = useState([]);
  const [mainData, setMainData] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [appData, setAppData] = useState([]);
  const [url, setUrl] = useState("");

  const handleDash = () => {
    setIsActive(true);
    user_dash_list().then((res) => {
      console.log(typeof res);
      setMainData(res);
      setdata(res.data);
      // console.log(res.data)
      setIsActive(false);
      setAppData(res.applications);
      setUrl(res.current_url);

      localStorage.setItem("app" , JSON.stringify(res.applications))
    });
  };
  useEffect(() => {
    handleDash();
  }, []);
  const update = () => {
    props.history.push("/update-company");
  };
  const handleMenus = (menu) => {
    setActiveMenu(menu);
  };

  let userData = jwtDecode(props.state.payload);
  console.log(userData);
  const getPath = (da) => {
    console.log(da);
    switch (da) {
      case 0:
        return "/update-company"; // refer the user roles document

      case 4:
        return "/update-kspace";
      case 5:
        return "/update-kerala";
      case 6:
        return "/update-india";

      case 8:
        return "/update-academy";
      case 9:
        return "/update-resource";
      case 10:
        return "/update-manpower";
      case 20:
        return "/update-candidate";
      default:
        return "/";
    }
  };
  const updateProposal = (d) => {
    props.history.push(getPath(d));
  };
  // console.log(userData)
  console.log(props.state);
  return (
    <div className="dash">
      <div className="dash-banner "></div>
      <Loader open={isActive} />
      <div className="container ">
        <div className="dashBox-container">
          <div className="dashboard-box">
            <div className="dash-items">
              {/* ------------------my dashboard------------------------------------ */}
              <div className="mydash">
                <div className="fill-color">
                  <div className="dash-content-container">
                    <div className="dash-content-details-container">
                      <div className="">
                        {activeMenu === "application" && (
                          <DashApplicationDetails
                            data={
                              userData?.user?.user_type === 0 || userData?.user?.user_type === 4
                                ? data?.applicants
                                : data
                            }
                            userType={userData?.user?.user_type}
                            appData={appData}
                            url={url}
                            updateProposal={updateProposal}
                            handleMenus={handleMenus}
                            status={data?.application_status_message}
                            {...props}
                          />
                        )}

                        {activeMenu === "profile" && (
                          <DashProfileDetails
                            data={appData}
                            userData={appData}
                            userType={userData?.user?.user_type}
                            url={url}
                            handleMenus={handleMenus}
                            status={data?.application_status_message}
                            {...props}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
