import React, { Component } from "react";
import { FormValidate } from "../../../../components/formValidate";
import CustomButton from "../../../../components/inputs/button";
import DatePickerField from "../../../../components/inputs/datepicker";
import TextField from "../../../../components/inputs/textfield";
import Snackbar from "../../../../components/snackbar/snackbar";

import Loader from "../../../../components/loader/loader";
import { admin_news_add, admin_news_edit } from "../../admin.api";
import "./dialog.css";

let required = ["title", "description"];
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      isActive: false,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
      fileError: false,
      fileChange: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        // this.setState({ fileChange: true });
        this.setState({ fileError: false });
        this.setState({
          formData: {
            ...this.state.formData,
            file: e.target.files[0],
            file_name: e.target.files[0].name,
          },
        });
      } else {
        this.setState({ fileError: true });
        this.setState({
          formData: { ...this.state.formData, file: null, file_name: null },
        });
      }
    } else {
      this.setState({ fileError: true });
      this.setState({
        formData: { ...this.state.formData, file: null, file_name: null },
      });
    }
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleDatePickerChange = (e, value) => {
    let date = e.value;
    if (date) {
      this.setState({
        formData: {
          ...this.state.formData,
          notification_date: date,
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          notification_date: null,
        },
      });
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;
    console.log(formData);
    e.preventDefault();
    let payload = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "status") {
      } else if (key === "file" && typeof formData?.file != "string") {
        payload.append("file", formData?.file);
      } else if (key !== "status" && key !== "file") {
        payload.append(key, formData[key]);
      }
    });

    if (Object.keys(error).length === 0) {
      this.setState({ isActive: true });
      if (edit) {
        admin_news_edit(formData.id, payload)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isActive: false,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_news_add(payload)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };

  render() {
    const { submitted, formData, open, edit, isActive } = this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <Loader open={isActive} />
            <div className="overlay">
              <div className="big-dia-scroll dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit
                        ? "Edit News and Updates"
                        : view
                        ? "News and Updates"
                        : "Add News and Updates"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar d-flex justify-content-start">
                    <hr />
                  </div>
                  <form action="">
                    <div className="row book-form">
                      <div className="">
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Title"
                            variant="outlined"
                            maxLength={70}
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.title ?? ""}
                            name="title"
                            required
                          />
                        </div>
                        {/* <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Title"
                            name="notification_title_ma"
                            variant="outlined"
                            disabled={view}
                            value={formData.notification_title_ma ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div> */}
                        {/* <div className="col-12 not-required">
                          <DatePickerField
                            // required
                            value={formData.notification_date ?? null}
                            minDate={tomorrow}
                            label="End Date"
                            required
                            onChange={(e) =>
                              this.handleDatePickerChange(
                                e,
                                "notification_date"
                              )
                            }
                          />
                        </div> */}
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            name="description"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            label="Description"
                            disabled={view}
                            value={formData.description ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        {/* <div className="col-12">
                          <TextField
                            // disabled={this.props.view}
                            id="outlined-basic"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.notification_desc_ma ?? ""}
                            multiline
                            label="Description[Mal]"
                            name="notification_desc_ma"
                            variant="outlined"
                            required
                          />
                        </div> */}
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Link"
                            name="link"
                            variant="outlined"
                            disabled={view}
                            value={formData.link ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            // required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            type="file"
                            // view="straight"
                            id="outlined-basic"
                            onChange={(e) => this.handleFileChange(e)}
                            label="File"
                            disabled={view}
                            fileName={formData.file_name}
                            accept=".pdf,.docx,.doc"
                            // required
                          />
                          {this.state.fileError && (
                            <div className="file-up-helper text-center">
                              *Upload only .pdf and Size should be less than 1MB
                            </div>
                          )}
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
