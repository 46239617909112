import React from "react";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import "../home/home.css";
import "../gallery/events.css";

function Terms(props) {
  return (
    <>
      <div className="container-fluid event-bg">
        <div className="row m-55">
          <h3>
            {props.state.language === "ma"
              ? "Terms and Conditions"
              : "Terms and Conditions"}
          </h3>
        </div>
      </div>
      <div className=" mtb-25">
        <div className="container my-5 pt-5 pb-5" style={{ fontSize: "16px" }}>
          <p>
            <b>Terms and Conditions</b>: This document outlines the terms and
            conditions that govern the use of kspace.kerala.gov.in , a website
            operated by KSPACE. By accessing or using kspace.kerala.gov.in, you agree to be
            bound by these terms and conditions. If you do not agree to these
            terms, you may not use kspace.kerala.gov.in.
          </p>
          <br />
          <p>
            <b> Intellectual Property</b>: The content of kspace.kerala.gov.in ,
            including but not limited to text, graphics, images, and code, is
            protected by intellectual property rights. You may not use the
            content for commercial purposes without obtaining written permission
            from KSPACE.
          </p>
          <br />
          <p>
            <b>User Contributions</b>: kspace.kerala.gov.in may allow users to
            submit, upload, or otherwise make available content, including but
            not limited to text, photos, and videos (“User Contributions”). User
            Contributions must not infringe any third-party rights or otherwise
            be illegal, offensive, or inappropriate. KSPACE has the right to
            remove any User Contributions that violate these terms and
            conditions.
          </p>
          <br />
          <p>
            <b>User Conduct</b>: You agree to use kspace.kerala.gov.in only for
            lawful purposes and in a way that does not infringe the rights of,
            restrict, or inhibit anyone else’s use of
            kspace.kerala.gov.in. Prohibited behavior includes, but is not
            limited to:{" "}
            <p>
              {" "}
              - Using kspace.kerala.gov.in for commercial purposes without
              obtaining written permission from KSPACE.
            </p>
            <p>
              {" "}
              - Engaging in conduct that is unlawful, harmful, threatening,
              abusive, harassing, tortuous, defamatory, vulgar, obscene,
              libelous, invasive of another’s privacy, hateful, or racially,
              ethnically, or otherwise objectionable.
            </p>
            <p>
              {" "}
              - Transmitting unsolicited or unauthorized advertising,
              promotional materials, junk mail, spam, chain letters, or any
              other form of solicitations.
            </p>
            <p>
              {" "}
              - Attempting to gain unauthorized access to kspace.kerala.gov.in
              or computer systems or networks connected to kspace.kerala.gov.in.
            </p>
            <p>
              {" "}
              - Interfering with another user’s use of kspace.kerala.gov.in .
            </p>{" "}
          </p>
          <br />
          <p>
            <b>Disclaimer of Warranties</b>: kspace.kerala.gov.in and its
            content are provided “as is” and KSPACE makes no representations or
            warranties of any kind, express or implied, with respect to
            kspace.kerala.gov.in or its content. KSPACE does not warrant that
            kspace.kerala.gov.in will be available at any time or location or
            that the content is accurate, complete, or current.
          </p>
          <br />
          <p>
            <b>Limitation of Liability</b>: KSPACE shall not be liable for any
            damages of any kind arising from the use of kspace.kerala.gov.in,
            including but not limited to direct, indirect, incidental, punitive,
            and consequential damages.
          </p>
          <br />
          <p>
            <b>Governing Law</b>: These terms and conditions shall be governed
            by and construed in accordance with the laws of the State, without
            giving effect to any principles of conflicts of law.
          </p>
          <br />
          <p>
            <b>Changes to These Terms and Conditions</b>: KSPACE reserves the
            right to modify these terms and conditions at any time. Your
            continued use of kspace.kerala.gov.in following the posting of
            changes to these terms and conditions constitutes acceptance of
            those changes.
          </p>
          <br />
          <p>
            <b>Contact Information</b>: If you have any questions about these
            terms and conditions, please contact KSPACE at{" "}
            <a href="mailto:info@kspace.org.in" >info@kspace.org.in</a> .
          </p>
        </div>
      </div>
    </>
  );
}

export default Terms;
