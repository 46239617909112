import React, { useEffect, useState } from "react";
import MultiCarousal from "../../../../pages/public/home/components/components/multicarousals/multi.carousal";
export default function PartnerSection(props) {
  const [isActive, setIsActive] = useState(props.isActive);
  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);
  let lang = props.state.language;
  return (
    <div>
      {/* <div className="chief-item"> */}
      {/* <div>
          <img
            className="org-avatar chief"
            src={props.data?.Image}
            alt=""
          />
        </div> */}
      {/* <div className="org-title">{props.data[lang]?.Title}</div> */}
      {/* <div className="org-designation">
          <b> Chief Minister of Kerala</b>
        </div> */}
      {/* <div className="org-designation">{props.data[lang]?.Designation}</div> */}
      {/* </div> */}
      <div className="org-sub-containers ">
        {props?.active != 2 &&
          props.data?.map(
            (item) =>
              item?.type === props?.active && (
                <div
                  className="core-item"
                  // className={props.fullwidth ? "org-core-item" : "org-sub-item"}
                >
                  <div className="par-avatar-cover">
                    <img className="par-avatar" src={item.image} alt="" />
                  </div>
                </div>
              )
          )}
        {props?.active == 2 && (
          <MultiCarousal>
            {props?.data?.map(
              (item, index) =>
                item?.type === props?.active && (
                  <div
                    className="core-item"
                    // className={props.fullwidth ? "org-core-item" : "org-sub-item"}
                  >
                    <div className="par-avatar-cover">
                      <img className="par-avatar" src={item.image} alt="" />
                    </div>
                  </div>
                )
            )}
          </MultiCarousal>
        )}
      </div>
    </div>
  );
}
