import { Button } from "bootstrap";
import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import CustomIcons from "../../../components/icons/kIcons.component";
import TextField from "../../../components/inputs/textfield";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import CustomTable from "../../../components/table/table";
import {
  admin_applications_view,
  admin_app_submit,
  admin_status,
} from "../admin.api";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import "./view.css";
import moment from "moment";

export default function View(props) {
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const [appdata, setAppdata] = useState({});
  const [adminStatus, setadminStatus] = useState({});
  const [printMode, setPrintMode] = useState(false);
  const [count, setCount] = useState(1);
  const [status, setStatus] = useState([
    {
      id: 1,
      status_title: "Submit for Acceptance",
      status_code: "1",
    },
    {
      id: 5,
      status_title: "Revert with Message",
      status_code: "5",
    },
    // {
    //   id: 3,
    //   status_title: "Reject with Reason",
    //   status_code: "2",
    // },
  ]);
  const [snackMessage, setSnackMessage] = useState("");
  const [statusLabel, setstatusLabel] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const { id } = useParams();

  const onSearch = () => {
    //
  };
  const getApplication = () => {
    setIsActive(true);
    admin_applications_view(id)
      .then((res) => {
        setAppdata(res.data);
        setIsActive(false);
        setstatusLabel(res.applicattion_status_label);
      })
      .catch((err) => {
        setIsSnackError(true);
        setIsActive(false);
        setSnackOpen(true);
        setSnackMessage(err.response.data.message);
      });
  };

  useEffect(() => {
    getApplication();
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push(-1);
    }
  };
  const handleChange = (e) => {
    setadminStatus({ ...adminStatus, [e.target.name]: e.target.value });
    setCount(count + 1);
    console.log(adminStatus);
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    // if (type === "pdf" || type === "doc" || type === "docx") {
    let mb = size / Math.pow(1024, 2);

    if (mb < 1) {
      setadminStatus({
        ...adminStatus,
        file_name: e.target.files[0].name,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setSnackMessage("File size is greater than 1MB");
      setIsSnackError(true);
      setSnackOpen(true);
      setadminStatus({
        ...adminStatus,
        file_name: e.target.files[0].name,
        [e.target.name]: e.target.files[0],
      });
    }
    // }
    // else
    // {
    //   setSnackMessage("File should be in .doc / .docx / .pdf format");
    //   setIsSnackError(true);
    //   setSnackOpen(true);
    // }
  };
  const handleAdminStatusUpdate = (e) => {
    e.preventDefault();

    let size = adminStatus?.sample_file?.size || 0;
    let mb = size / Math.pow(1024, 2) || 0;
    if (mb < 1) {
      const form = new FormData();

      form.append("file_name", adminStatus.file_name ?? null);
      form.append("status_id", adminStatus.status_id ?? null);
      form.append("sample_file", adminStatus.sample_file ?? "");
      if (adminStatus.status_id == 1) {
        form.append("admin_message", adminStatus.message ?? null);
      } else if (adminStatus.status_id == 5) {
        form.append("user_message", adminStatus.message ?? null);
      }

      form.append("application_id", appdata.id ?? null);

      setIsActive(true);
      admin_app_submit(form)
        .then((res) => {
          setSnackMessage(res.message);
          setIsSnackError(false);
          setSnackOpen(true);
          setIsActive(false);
        })
        .catch((err) => {
          setSnackMessage(err.response.data.message);
          setIsSnackError(true);
          setIsActive(false);
          setSnackOpen(true);
        });
    } else {
      setSnackMessage("File size is greater than 1MB");
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const handlePrint = () => {
    var originalContents = document.body.innerHTML;
    var printReport = document.getElementById("printable-area").innerHTML;
    document.body.innerHTML = printReport;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const handleDownloadProposal = () => {
    let path = `${result}${appdata.upload_proposal}`;
    window.open(path, "_blank");
  };
  const componentRef = useRef();
  console.log(appdata);

  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <div className="col-12 d-flex justify-content-end">
          <ReactToPrint
            onBeforePrint={() => setPrintMode(true)}
            onAfterPrint={() => setPrintMode(false)}
            trigger={() => (
              <button
                type="button"
                // onClick={(e) => handlePrint()}
                className="btn btn-warning1 btn-sm mr-2 mb-2 dwnld"
              >
                <CustomIcons iconName="download" iconClass="down" />
                Download info
              </button>
            )}
            content={() => componentRef.current}
          />
          <button
            type="button"
            onClick={(e) => handleDownloadProposal()}
            className="btn btn-success ml-2 btn-sm mb-2 "
          >
            <CustomIcons iconName="download" iconClass="down" />
            Download Proposal
          </button>
        </div>
        <form onSubmit={handleAdminStatusUpdate}>
          <div ref={componentRef} className={printMode ? "p-2" : "p-4"}>
            <h6>
              <b>New Company Request</b>
            </h6>

            <hr />

            <div className="data">
              <div className="row">
                <div className="col-3">Company Name</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.company_name ?? ""}</div>
              </div>
              
              <div className="row">
                <div className="col-3">Contact Person Name</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.person_name ?? ""}</div>
              </div>
              <div className="row">
                <div className="col-3">Contact Person Designation</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.person_designation}</div>
              </div>
              <div className="row">
                <div className="col-3">Address</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.address ?? ""}</div>
              </div>
              <div className="row">
                <div className="col-3">Phone</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.phone_no ?? ""}</div>
              </div>
              <div className="row">
                <div className="col-3">Email</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.email ?? ""}</div>
              </div>
              <div className="row">
                <div className="col-3">Website Link</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.site_link ?? ""}</div>
              </div>
              <div className="row">
                <div className="col-3">Alternate Phone</div>
                <div className="col-1">:</div>
                <div className="col-7">
                  {appdata.alternate_phone && (
                    <>
                      {appdata.country_code &&
                        appdata.country_code !== "" &&
                        appdata.country_code !== "undefined" &&
                        appdata.country_code !== "null" && (
                          <>
                            {appdata.country_code ?? ""}{" "}
                            {appdata.country_code && appdata.country_code !== ""
                              ? "-"
                              : ""}{" "}
                          </>
                        )}
                    </>
                  )}
                  {appdata.alternate_phone ?? ""}
                </div>
              </div>
              <div className="row">
                <div className="col-3">Alternative Email</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.alternate_email ?? ""}</div>
              </div>
              <div className="row">
                <div className="col-3">Abstract Project Proposal</div>
                <div className="col-1">:</div>
                <div className="col-7">
                  {appdata.abstract_project_proposal ?? ""}
                </div>
              </div>
              <div className="row">
                <div className="col-3">Product Profile</div>
                <div className="col-1">:</div>
                <div className="col-7">
                  {appdata?.product_profiles?.map((item, i) => (
                    <div key={i}>
                      <div className="col-12 mb-1">
                        <b>{item.product_name ?? ""}</b>
                      </div>
                      <div className="col-12 mb-2 ">
                        <div>{ReactHtmlParser(item.product_desc)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-3">Domain Type</div>
                <div className="col-1">:</div>
                <div className="col-7">
                  {appdata?.domain_types?.map((dom, i) => (
                    <div key={i}>{dom.domain_name}</div>
                  ))}
                  {(appdata?.any_other_flag == 1 ||
                    (appdata?.any_other_domain &&
                      appdata?.any_other_domain != null)) && (
                    <div>{appdata?.any_other_domain}</div>
                  )}

                  {/* // ) : (
                //   <>{appdata?.any_other_domain}</>
                // )} */}
                  <></>
                </div>
              </div>
              {/* {appdata?.any_other_flag === 1 && (
              <div className="row">
                <div className="col-3">Any Other Domain</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.any_other_domain ?? ""}</div>
              </div>
            )} */}
              <div className="row">
                <div className="col-3">Capital Investment Expected in Crores</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.capital_investment ?? ""}</div>
              </div>
              <div className="row">
                <div className="col-3">Initial Direct Employment</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.ex_man_power ?? ""}</div>
              </div>
              {/* <div className="row">
              <div className="col-3">Document Uploaded</div>
              <div className="col-1">:</div>
              <div className="col-7 ">{appdata?.file_name}</div>
            </div> */}
            </div>
            {appdata.area_type && (
              <h6>
                <b>Area Requirements</b>
              </h6>
            )}
            <hr />
            <div className="data">
              {appdata.area_type === "built_up_space" && (
                <div className="row">
                  <div className="col-3">Built-up space on Rent per Month</div>
                  <div className="col-1">:</div>
                  <div className="col-7">
                    <CustomTable
                      className="areatable"
                      {...props}
                      columns={[
                        { title: "No", field: "id", type: "dynId" },
                        { title: "Location", field: "location" },
                        { title: "Area in sq.ft", field: "area_in_sqft" },
                      ]}
                      data={appdata?.area_management}
                    />
                  </div>
                </div>
              )}
              {appdata.area_type === "equipped_facility" && (
                <div className="row">
                  <div className="col-3">Equipped Facility per Month </div>
                  <div className="col-1">:</div>
                  <div className="col-7">
                    <CustomTable
                      className="areatable"
                      {...props}
                      columns={[
                        { title: "No", field: "number", type: "dynId" },
                        { title: "Location", field: "location" },
                        { title: "Area in sq.ft", field: "area_in_sqft" },
                      ]}
                      data={appdata?.area_management}
                    />
                  </div>
                </div>
              )}
              {appdata.area_type === "land_on" && (
                <div className="row">
                  <div className="col-3">Land on Lease </div>
                  <div className="col-1">:</div>
                  <div className="col-7">
                    <CustomTable
                      className="areatable"
                      {...props}
                      columns={[
                        { title: "No", field: "number", type: "dynId" },
                        { title: "Location", field: "location" },
                        { title: "Area  in Acres", field: "area_in_acres" },
                        { title: "Years", field: "lease_year" },
                      ]}
                      data={appdata?.area_management}
                    />
                  </div>
                </div>
              )}
              {appdata.area_type === "joint_venture" && (
                <div className="row">
                  <div className="col-3">Joint Venture</div>
                  <div className="col-1">:</div>
                  <div className="col-7 venure">
                    {appdata?.area_management?.map((item, i) => (
                      <div className="row venture" key={i}>
                        <div className="col-12 mb-1">
                          {/* <label>Location&nbsp;:&nbsp;</label> */}
                          <b>{item.location}</b>
                        </div>
                        <div className="col-12 mb-1">
                          <label>
                            Area in
                            sq.ft&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                          </label>
                          <b>{item.area_in_sqft}</b>
                        </div>
                        <div className="col-12 mb-3 text-justify">
                          <label>
                            <b>Area Type</b>&nbsp;:&nbsp;
                          </label>
                          {item.area}
                        </div>
                        <div className="col-12 mb-3 text-justify">
                          <label>
                            <b>Investment Model</b>&nbsp;:&nbsp;
                          </label>
                          {item.investment_model}
                        </div>
                        <div className="col-12 mb-3 text-justify">
                          <label>
                            <b>Management Plan</b>&nbsp;:&nbsp;
                          </label>
                          {item.management_plan}
                        </div>
                        <div className="col-12 mb-3 text-justify">
                          <label>
                            <b>Address</b>&nbsp;:&nbsp;
                          </label>
                          {item.land_address}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {appdata.area_type === "own_land" && (
                <div className="row">
                  <div className="col-3">Own Premises </div>
                  <div className="col-1">:</div>
                  <div className="col-7">
                    <CustomTable
                      className="areatable"
                      {...props}
                      columns={[
                        { title: "Location", field: "location" },
                        { title: "Available Area", field: "area_in_sqft" },
                        { title: "Survey Number", field: "survey_no" },
                        { title: "Block Number", field: "block_no" },
                        { title: "Village Name", field: "village_name" },
                        // { title: "Available Area", field: "area" },
                        {
                          title: "Nature Of Holding",
                          field: "nature_of_holding",
                        },
                      ]}
                      data={appdata?.area_management}
                    />
                  </div>
                </div>
              )}
              <div className="nio row">
                <div className="d-flex ">
                  <div className="col-3 ">Current Application Status</div>
                  <div className="col-1">:</div>
                  <div className="col-7 blink_me1">
                    {appdata?.application_status == 0
                      ? "Pending"
                      : appdata?.application_status == 1
                      ? "Submitted for acceptance"
                      : appdata?.application_status == 2
                      ? "Accepted"
                      : appdata?.application_status == 3
                      ? "Super Admin Reverted"
                      : appdata?.application_status == 4
                      ? "Rejected"
                      : appdata?.application_status == 5
                      ? "Admin Reverted"
                      : "Agreement Execution"}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3">Created At</div>
                <div className="col-1">:</div>
                <div className="col-7">{appdata.created_at ? moment(appdata.created_at).format("DD-MM-YYYY") : ""}</div>
              </div>
              {appdata?.to_super_admin_message_content &&
                appdata?.to_super_admin_message_content !== "" && (
                  <div className="row">
                    <div className="col-3">Message from Super Admin</div>
                    <div className="col-1">:</div>
                    <div className="col-7 ">
                      {appdata?.to_super_admin_message_content}
                    </div>
                  </div>
                )}
            </div>
          </div>
          {(appdata.application_status === 0 ||
            appdata.application_status === 3) && (
            <>
              {status?.length !== 0 && (
                <>
                  <div className="col-6 mt-3 status">
                    <label>
                      <b>Select Action</b>
                    </label>
                    <select
                      className="form-select mt-2"
                      aria-label="Default select example"
                      onChange={handleChange}
                      name="status_id"
                      required
                      value={adminStatus.status_id}
                    >
                      <option value="">Select Action</option>
                      {status?.map((item, i) => (
                        <>
                          {!item.hidden && (
                            <option value={item.id} key={i}>
                              {item.status_title}
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  </div>

                  {(appdata.application_status === 0 ||
                    appdata.application_status === 3) && (
                    <div className="col-6 mt-3">
                      <div className="form-group">
                        {/* <label for="exampleFormControlTextarea1">Example textarea</label> */}
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Message.."
                          rows="3"
                          required={
                            adminStatus.sample_file === null ||
                            adminStatus.sample_file === undefined ||
                            adminStatus.sample_file === ""
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          name="message"
                          value={adminStatus.message}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  {(appdata.application_status === 0 ||
                    appdata.application_status === 3) && (
                    <div className="col-6 mb-01 mt-3">
                      {adminStatus.status_id == 5 && (
                        <>
                          <TextField
                            name="file_name"
                            type="file"
                            required={
                              adminStatus.message === null ||
                              adminStatus.message === undefined ||
                              adminStatus.message === ""
                                ? true
                                : false
                            }
                            variant="outlined"
                            onChange={handleFileChange}
                            // accept=".pdf,.doc"
                          />
                          <small className="indicator">
                            Size should be less than 1MB
                          </small>
                        </>
                      )}
                      {/* <Button className="btn btn-danger">Clear File</Button> */}
                    </div>
                  )}
                </>
              )}

              {status?.length !== 0 && (
                <button type="submit" className="btn mb-2 mysubbtn">
                  Submit
                </button>
              )}
            </>
          )}
        </form>
      </div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
