import jwtDecode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import {
  update_expert_registration,
  user_get_candidate_details,
  user_get_candidatereg_details,
  user_get_manpower_details,
} from "../../user.api";
import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";
import moment from "moment";
import { string } from "prop-types";

const admin_type = [
  {
    domain_name: "Admin/HR",
    checked: false,
  },
  {
    domain_name: "Finance/Accounts",
    checked: false,
  },
  {
    domain_name: "Marketing/Sales",
    checked: false,
  },
];

const technical_type = [
  {
    domain_name: "Design/Analysis",
    checked: false,
  },
  {
    domain_name: "Production",
    checked: false,
  },
  {
    domain_name: "Planning",
    checked: false,
  },
  {
    domain_name: "Operations",
    checked: false,
  },
  {
    domain_name: "Quality Control",
    checked: false,
  },
  {
    domain_name: "Testing",
    checked: false,
  },
  {
    domain_name: "Supervision",
    checked: false,
  },
  {
    domain_name: "Technician",
    checked: false,
  },
];

const management_type = [
  {
    domain_name: "Project Management",
    checked: false,
  },
  {
    domain_name: "Operations Management",
    checked: false,
  },
  {
    domain_name: "Logistics Management",
    checked: false,
  },
  {
    domain_name: "Planning",
    checked: false,
  },
  {
    domain_name: "Finance",
    checked: false,
  },
];
export default function ExpManPowerReg(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");
  const [basicData, setBasicData] = useState({
    mobile: props.phone,
    gender: 1,
  });
  let userData = jwtDecode(props.state.payload);
  console.log(userData)
  const id = JSON.parse(localStorage.getItem("app"))
  const [areaOfInterest, setAreaOfInterest] = useState([]);
  const [category, setCategory] = useState([]);
  const [addCount, setCount] = useState(1);
  const myRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  console.log(props);
  const getFileNmae = (url) => {
    let name = null;

    name = url.substring(url.lastIndexOf("/") + 1);
    return name;
  };
  const getInitialDatas = () => {
    user_get_candidate_details(id?.id).then((res) => {
      let edu = [];
      let wkE = [];
      let aoE = [];
      let data = res?.data;
      let ed = data?.education;
      let wk = data?.employment;
      let ae = JSON.parse(data.interest);
      console.log(ae)
      let new_exp = [];
      if (data?.interest_type === "Administration") {
        new_exp = admin_type;

        ae.forEach((item2) => {
          admin_type.forEach((item, i) => {
            if (item?.domain_name === item2) {
              new_exp[i].checked = true;
            }
          });
        });
      } else if (data?.interest_type === "Technical") {
        new_exp = technical_type;

        ae.forEach((item2) => {
          technical_type.forEach((item, i) => {
            if (item?.domain_name === item2) {
              new_exp[i].checked = true;
            }
          });
        });
      } else if (data?.interest_type === "Management") {
        new_exp = management_type;

        ae.forEach((item2) => {
          management_type.forEach((item, i) => {
            if (item?.domain_name === item2) {
              new_exp[i].checked = true;
            }
          });
        });
      }
      console.log(new_exp)
      ed.forEach((item) => {
        edu.push({
          ...item,
          examination_passed: item.examination_passed,
          board_of_university: item.board_of_university,
          year_of_pass: item.year_of_pass,
          mark_grade: item.mark_grade,
          percentage : item.percentage,
          file_upload_certificate: item.file_upload_certificate,
          file_name: item.file_upload_certificate
            ? getFileNmae(item.file_upload_certificate)
            : null,
        });
      });
      // ae.forEach((item) => {
      //   aoE.push({ ...item, expertise_name: item.expertise_name });
      // });
      wk?.forEach((item) => {
        wkE.push({
          ...item,
          employer: item?.employer,
          contact: item?.contact,
          address: item?.address,
          from: item?.from,
          to: item?.to,
          email: item?.email,
          reason: item?.reason,
          position: item?.position,
          working : item?.working && item?.working == "Yes" ? true : false,
          exp_certificate: item?.exp_certificate ?? null,
          file_name : item?.exp_certificate
        });
      });
      setBasicData({
        ...data,
        fname : data?.name,
        gender: data.gender ,
        // handicapped : data?.handicapped == 1 ? "1" : "0",
        category: data.category,
        mobile : data?.contact,
        educational_qualifications: edu,
        dob: moment(data?.dob, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
        expertise_area: new_exp,
        any_other_flag : data?.any_other_domain ? true : false,
        any_other_domain : data?.any_other_domain,
       employment: wkE,
       identity_file: data.identity_file,
       photo_file : data?.photo,
        conf1 :true,
        conf2:true,
        // mobile: data.phone_no,
        file_name: data.identity_file ? getFileNmae(data.identity_file) : null,
      });
      setCount(addCount + 1);
    });
  };

  const action = (id, data, key) => {
    console.log(data);
    myRef.current.scrollIntoView();
    if (id === 0) {
      setActiveStep(1);
      setBasicData({ ...data });
    } else if (id === 1) {
      setActiveStep(0);
      setdefaultkey(key);
    } else if (id === 2) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    let edudata = [];
    let expData = [];
    let expArea = [];
    let areaInte = [];
    // basicData.expertise_area.forEach((item) => {
    //   expArea.push(item.exp);
    // });
    // basicData.education.forEach((item) => {
    //   let items = {};
    //   // let fileBase64 = getBase64(item.file_upload_certificate)
    //   //   .then((result) => {
    //   //     item.experience_certificate["base64"] = result;
    //   //     console.log("File Is", item.experience_certificate);
    //   //     continueFlag = true;
    //   //   })
    //   //   .catch((err) => {
    //   //     console.log(err);
    //   //   });
    //   items["university"] = item.university;
    //   items["year"] = item.year;
    //   items["location"] = item.location;
    //   items["grade"] = item.grade;
    //   items["gpa"] = item.gpa;

    //   edudata.push(items);
    // });

    // basicData.employment.forEach((item) => {
    //   let items = {};
    //   // let fileBase64 = getBase64(item.experience_certificate)
    //   //   .then((result) => {
    //   //     item.experience_certificate["base64"] = result;
    //   //     console.log("File Is", item.experience_certificate);
    //   //     continueFlag = true;
    //   //   })
    //   //   .catch((err) => {
    //   //     console.log(err);
    //   //   });
    //   items["employer"] = item.employer;
    //   items["contact"] = item.contact;
    //   items["address"] = item.address;
    //   items["from"] = item.from;
    //   items["to"] = item.to;
    //   items["email"] = item.email;
    //   items["position"] = item.position;
    //   items["reason"] = item.reason;
    //   items["exp_certificate"] = item.exp_certificate;
    //   // item.experience_certificate;
    //   expData.push(items);
    // });

    // basicData.area_of_interest.forEach((item) => {
    //   areaInte.push(item.uuid);
    // });
   
    // let finalData = {};
    // finalData = {
    //   native_kerala : basicData?.native_kerala ?? null,
    //   nri_keralite : basicData?.nri_keralite ?? null,
    //   prefix : basicData?.title ?? "Mr",
    //   name : basicData?.fname ?? "",
    //   dob: basicData.dob
    //   ? moment(basicData.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
    //   : "",
    //   gender: basicData.gender ?? null,
    //   marital_status:basicData?.marital_status ?? "",
    //   parent_name : basicData?.parent_name ?? "",
    //   caste : basicData?.caste ?? "",
    //   hadicapped :basicData?.hadicapped ?? "",
    //   email: basicData.email ?? null,
    //   alt_email : basicData?.alt_email ?? null,
    //   contact : basicData.mobile ?? null,
    //   address: basicData.address ?? null,
    //   alt_contact :basicData?.alt_contact ?? null,
    //   pin_code :basicData?.pin_code ?? null,
    //   blood_group : basicData?.blood_group ?? null ,
    //   identity_no : basicData?.identity_no ?? null,
    //   identity_file : basicData?.photo ?? null,
    //   qualification_type : basicData?.qualification_type ?? null,
    //   qualification : basicData?.qualification ?? null,

    //   category: basicData.category ?? null,
    //   category_name: basicData.category_name ?? null,
      
    //   ...(basicData.alternate_phone
    //     ? { country_code: basicData.country_code }
    //     : {}),
    //   alternate_phone: basicData.alternate_phone ?? "",
    //   // country_code: basicData.country_code ?? "",
    //   //  file_name: basicData.file_name ?? null,
     
   
    //   // full_name: "Full Name",
    //   // age:"18+", 
    //   // first_name: basicData.first_name ?? null,
    //   // last_name: basicData.last_name ?? null,
    //   // ...(basicData.title ? { title: basicData.title } : { title: "Mr" }),
    //   // photo: basicData.photo ?? null,
    //   reference_name1 : basicData?.reference_name1 ?? null,
    //   reference_designation1 : basicData?.reference_designation1 ?? null ,
    //   reference_contact1 : basicData?.reference_contact1 ?? null ,
    //   reference_email1 : basicData?.reference_email1 ?? null ,
    //   reference_name2 : basicData?.reference_name2 ?? null,
    //   reference_designation2 : basicData?.reference_designation2 ?? null ,
    //   reference_contact2 : basicData?.reference_contact2 ?? null ,
    //   reference_email2 : basicData?.reference_email2 ?? null,
    //   interest_type : basicData?.interest_type ?? null,
    //   // interest : expArea,
    //   employment: expData ?? null,
    //   interest: expArea ?? null,
    //   education: edudata ?? null,
    //   // area_of_interest: areaInte ?? null,
    // };
    let payload = new FormData();

    payload.append("native_kerala", basicData?.native_kerala ?? null);
    payload.append("nri_keralite", basicData?.nri_keralite ?? null);
    payload.append("fresher", basicData?.fresher ?? null);
    payload.append("prefix", basicData?.prefix ?? "Mr");
    payload.append("name", basicData?.fname ?? "");
    payload.append("dob", basicData.dob ? moment(basicData.dob, "DD-MM-YYYY").format("YYYY-MM-DD") : "");
    payload.append("gender", basicData.gender ?? null);
    payload.append("marital_status", basicData?.marital_status ?? "");
    payload.append("parent_name", basicData?.parent_name ?? "");
    payload.append("caste", basicData?.caste ?? "");
    payload.append("handicapped", basicData?.handicapped ?? "");
    payload.append("email", basicData.email ?? null);
    payload.append("alt_email", basicData?.alt_email ?? "");
    payload.append("contact", basicData.mobile ?? null);
    payload.append("address", basicData.address ?? null);
    payload.append("alt_contact", basicData?.alt_contact ?? "");
    payload.append("alt_code", basicData?.alt_code ?? "");
    payload.append("pin_code", basicData?.pin_code ?? null);
    payload.append("blood_group", basicData?.blood_group ?? null);
    payload.append("identity_type", basicData?.identity_type ?? null);
    payload.append("identity_no", basicData?.identity_no ?? null);
    if(typeof(basicData?.identity_file) != "string"){
      payload.append("identity_file", basicData?.identity_file ?? null);
    }
    if(typeof(basicData?.photo) != "string"){
      payload.append("photo", basicData?.photo ?? null);
    }
    
    payload.append("qualification_type", basicData?.qualification_type ?? null);
    payload.append("qualification", basicData?.qualification ?? null);
    payload.append("category", basicData.category ?? null);
    payload.append("category_name", basicData.category_name ?? null);
    payload.append("alternate_phone", basicData.alternate_phone ?? "");
    if (basicData.alternate_phone) payload.append("country_code", basicData.country_code);
    
    payload.append("reference_name1", basicData?.reference_name1 ?? null);
    payload.append("reference_designation1", basicData?.reference_designation1 ?? null);
    payload.append("reference_contact1", basicData?.reference_contact1 ?? null);
    payload.append("reference_email1", basicData?.reference_email1 ?? null);
    payload.append("reference_name2", basicData?.reference_name2 ?? null);
    payload.append("reference_designation2", basicData?.reference_designation2 ?? null);
    payload.append("reference_contact2", basicData?.reference_contact2 ?? null);
    payload.append("reference_email2", basicData?.reference_email2 ?? null);
    payload.append("interest_type", basicData?.interest_type ?? null);
    if(basicData?.any_other_domain && basicData?.any_other_domain !== "" && basicData?.any_other_domain != null){
      payload.append("any_other_domain", basicData?.any_other_domain ?? null);

    }
    
    // let expArea = [];
    basicData.expertise_area.forEach((item) => {
      if(item?.checked){
        expArea.push(item.domain_name);
      }

    });
    payload.append("interest", JSON.stringify(expArea));
    
    basicData.education.forEach((item, index) => {
      payload.append(`education[${index}][university]`, item.university);
      payload.append(`education[${index}][year]`, item.year);
      payload.append(`education[${index}][location]`, item.location);
      payload.append(`education[${index}][grade]`, item.grade);
      payload.append(`education[${index}][gpa]`, item.gpa);
      payload.append(`education[${index}][percentage]`, item.percentage);
    });
    if(basicData.fresher === "No"){ 
    basicData.employment.forEach((item, index) => {
      payload.append(`employment[${index}][employer]`, item.employer);
      payload.append(`employment[${index}][id]`, item.id ?? "");
      payload.append(`employment[${index}][contact]`, item.contact);
      payload.append(`employment[${index}][address]`, item.address);
      payload.append(`employment[${index}][from]`, item.from);
      payload.append(`employment[${index}][to]`, item.to);
      payload.append(`employment[${index}][email]`, item.email);
      payload.append(`employment[${index}][position]`, item.position);
      payload.append(`employment[${index}][working]`, item.working);
      payload.append(`employment[${index}][reason]`, item.reason);
      if(typeof(item?.exp_certificate) != "string"){
        payload.append(`employment[${index}][exp_certificate]`, item.exp_certificate);
      }
       // file appended directly
    })}
    setIsActive(true);
    // console.log(finalData)
    user_get_candidatereg_details(basicData?.id,payload)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const navLabel = [
    { id: 0, activeStep: 0, name: "Basic Information" },
    { id: 1, activeStep: 1, name: "Qualifications" },
  ];

  const headlperText = {
    header: "",
  };

  useEffect(() => {
    getInitialDatas();
  }, []);

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});
      props.history.push("/dashboard");
    }
  };

  return (
    <div className="page-bg" ref={myRef}>
      <div>
        <Loader open={isActive} />
        <div className="dash-banner ">
          <div className="tit-txt">Update - Job Seekers</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="itemc">
            {activeStep < 2 && (
              <>
                <div className="header-helper-text">{headlperText.header}</div>
              </>
            )}
            <div className="fomy form-inner-section">
              {activeStep === 0 && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  update={true}
                  action={action}
                  formData={basicData}
                  setBasicData={setBasicData}
                  areaOfInterest={areaOfInterest}
                  setAreaOfInterest={setAreaOfInterest}
                  category={category}
                />
              )}

              {activeStep === 1 && (
                <View
                  {...props}
                  update={true}
                  basicData={basicData}
                  areaOfInterest={areaOfInterest}
                  action={action}
                />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
            {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
