import React from "react";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import "../home/home.css";
import "../gallery/events.css";

function PrivacyPolicy(props) {
  return (
    <>
      <div className="container-fluid event-bg">
        <div className="row m-55">
          <h3>
            {props.state.language === "ma"
              ? "Privacy Policy"
              : "Privacy Policy "}
          </h3>
        </div>
      </div>
      <div className=" mtb-25">
        <div className="container my-5 pt-5 pb-5" style={{ fontSize: "16px" }}>
          <p>
            <b>Privacy Policy</b>: This privacy policy outlines the information
            KSPACE collects when you use KSPACE, how the information is used,
            and with whom the information is shared.
          </p>
          <br />
          <p>
            <b>Information Collection and Use</b>: KSPACE may collect
            information from you when you use KSPACE, such as your name, email
            address, and other personal information. The information collected
            is used to improve KSPACE and to provide you with the services you
            request.
          </p>
          <br />
          <p>
            <b>Cookies</b>: KSPACE may use “cookies” to improve your experience.
            Cookies are small data files that are placed on your computer or
            device and are used to track your usage of KSPACE. You can choose to
            accept or decline cookies by changing your browser settings, but
            doing so may limit your ability to use KSPACE.
          </p>
          <br />
          <p>
            <b>Information Sharing</b>: KSPACE may share your personal
            information with third parties for the purpose of providing you with
            the services you request. Your personal information will not be
            shared with third parties for their marketing purposes.
          </p>
          <br />
          <p>
            <b>Security</b>: KSPACE takes the security of your personal
            information seriously and implements appropriate technical and
            organizational measures to protect your personal information.
            However, no method of transmission over the internet or method of
            electronic storage is 100% secure. Therefore, KSPACE cannot
            guarantee its absolute security.
          </p>
          <br />
          <p>
            <b>Changes to This Privacy Policy</b>: KSPACE reserves the right to
            modify this privacy policy at any time. Your continued use of KSPACE
            following the posting of changes to this privacy policy constitutes
            acceptance of those changes.
          </p>
          <br />
          <p>
            <b>Contact Information</b>: If you have any questions about this
            privacy policy, please contact KSPACE at{" "}
            <i>pd.spacepark@ksitil.org.</i>
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
