import { public_news_list } from "../../public.api";
// import { Link } from "react-router-dom";

import moment from "moment";
import React, { useEffect, useState } from "react";
import Marquee from "../../../../components/marquee";

export default function News(props) {
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");

  let lang = props.state.language;

  const fetchNews = () => {
    public_news_list().then((res) => {
      let newsdata = res.data;
      let newsdatafil = newsdata.filter((item) => { return moment(item[lang]?.notification_date, "YYYY-MM-DD").toDate() >= moment().toDate() })

      setData(newsdatafil);
      setUrl(res.current_url);
    });
  };
  const fetchDetails = (id) => {
    sessionStorage.setItem("continueFrom", "news");
    let path = `/news-detail/${id}`;
    props.history.push(path);
  };
  useEffect(() => {
    fetchNews();
  }, []);

  // class News extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       data: [],
  //       url: "",
  //       key: 0,
  //     };
  //   }
  //   componentDidMount = () => {
  //     this.fetchNews();
  //   };
  //   fetchNews = () => {
  //     public_news_list().then((res) => {
  //       this.setState(
  //         {
  //           data: res.data,
  //           url: res.currentUrl,
  //           key: this.state.key + 1,
  //         },
  //         this.props.checkLoaderStatus("newsActive", false)
  //       );
  //     });
  //   };
  // render() {
  //   const { data, url, key } = this.state;
  //   let lang = this.props.state.language;
  return (
    <div className="w-100">
      <div className="pos-rel">
        <div className="news-head-label">
          <div className="news-label-text">Latest News & Notifications</div>
        </div>
        <div>
          <Marquee>
            {data &&
              data.map((item) => (
                <>


                  <div
                    className="d-flex news-items"
                    onClick={(e) => {
                      fetchDetails(item[lang]?.uuid);
                    }}
                  >
                    <div className="d-flex">
                      <div className="new-scroll-new">New</div>

                      {/* <div className="new-scroll-skew"> */}
                      <div className="new-scroll">{item[lang]?.Title}</div>
                      {/* </div> */}
                    </div>
                  </div>

                </>
              ))

            }
          </Marquee>
        </div>
      </div>
    </div>
  );
}
