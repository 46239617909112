import React, { useEffect, useState } from "react";
export default function OrgStructure(props) {
  const [isActive, setIsActive] = useState(props.isActive);
  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);
  let lang = props.state.language;
  return (
    <div>
      <div className="chief-item">
        <div>
          <img
            className="org-avatar chief"
            src={props.data[lang]?.Image}
            alt=""
          />
        </div>
        <div className="org-title">{props.data[lang]?.Title}</div>
        {/* <div className="org-designation">
          <b> Chief Minister of Kerala</b>
        </div> */}
        <div className="org-designation">{props.data[lang]?.Designation}</div>
      </div>
      <div className="org-sub-containers ">
        {props.data["members"]?.map((item) => (
          <div
            className="core-item"
            // className={props.fullwidth ? "org-core-item" : "org-sub-item"}
          >
            <div>
              <img className="org-avatar" src={item[lang].Image} alt="" />
            </div>
            <div className="org-title">{item[lang].Title}</div>
            <div className="org-designation">{item[lang].Designation}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
